const CODE_LENGTH = 4;

export const generateRandomCode = () => {
    let length = CODE_LENGTH;
    let codeArray = [];
    for (let i = 0; i < length; i++) {
        let number = Math.floor(Math.random() * Math.floor(10));
        codeArray.push(number);
    }
    return codeArray.join("");
};
