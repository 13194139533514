import styled from "@emotion/styled";
import { contentPadding } from "omse-components";

export const ContentContainer = styled("section")(
    ({ theme }) => `
    padding-left: ${contentPadding.left}px;
    padding-right: ${contentPadding.right}px;
    padding-top: ${contentPadding.top / 2}px;
    padding-bottom: ${contentPadding.bottom}px;
    max-width: ${contentPadding.maxWidth}px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 1em;
    
    ${theme.breakpoints.down("sm")} {
        padding: ${contentPadding.mobile};
    }
    
    ${theme.breakpoints.up("lg")} {
        /* INTERIM UNTIL TEAM SPACES IS
            LOCATED IN THE SECONDARY NAV */
        margin: 0 auto;
        min-width: ${contentPadding.maxWidth}px;
    }
`
);
