import { defineMessages } from "react-intl";

const emptyStateMessages = defineMessages({
    title1Generic: {
        id: "emptyStateMessages.title1Generic",
        defaultMessage: "It's quiet in here. Create your first Team Space!",
        description: "Empty State > Title",
    },
    title1Contractor: {
        id: "emptyStateMessages.title1Contractor",
        defaultMessage: "No Team Spaces",
        description: "Empty State > Title (Contractor)",
    },
    body1Generic: {
        id: "emptyStateMessages.body1Generic",
        defaultMessage: "Set up a Team Space. Leave it visible to everyone or lock it down.",
        description: "Empty State > Body Text",
    },
    body1Contractor: {
        id: "emptyStateMessages.body1Contractor",
        defaultMessage: "You're not currently part of any Team Spaces.{br}To be added to a Team Space, please contact an OS Data Hub organisation Admin.",
        description: "Empty State > Body Text (Contractor)",
    },
    title2: {
        id: "emptyStateMessages.title2",
        defaultMessage: "What is a Team Space?",
        description: "Empty State > Intro Title",
    },
    body2Generic: {
        id: "emptyStateMessages.body2Generic",
        defaultMessage: "A Team Space is a convenient way for you to group things that you are working on. They can be visible to everyone in your organisation, or locked so that only certain members of your organisation can see and access them.",
        description: "Empty State > Intro Body",
    },
    body2Contractor: {
        id: "emptyStateMessages.body2Contractor",
        defaultMessage: "A Team Space is a convenient way for your organisation to group together things it is working on. They can be visible to everyone in your organisation, or locked so that only certain members of your organisation can see and access them.",
        description: "Empty State > Intro Body (Contractor)",
    },
    createTeamSpaceButton: {
        id: "emptyStateMessages.createTeamSpaceButton",
        defaultMessage: "Create your first Team Space",
        description: "Empty State > Create Team Space button",
    },
});

export default emptyStateMessages;
