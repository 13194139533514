import React, {useCallback, useEffect, useRef, useState, useMemo} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useRouteMatch, useLocation, useHistory} from 'react-router';
import {loadDataPackage, clearLoadedDataPackage} from "../../modules/dataPackages/actions";
import routePaths, {getLocation} from "../../util/routes";
import {styles} from "./DownloadStyles";
import {createUseStyles} from 'react-jss';
import FeatureCheck from "../../components/FeatureCheck";
import BackLink from "../../components/BackLink";
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {CircularProgress, Typography} from '@mui/material';
import {contentPadding, Notification, osColour, DropDownMenu} from 'omse-components';
import {ReactComponent as Icon} from '../../components/icons/dataPackage-large.svg';
import {ReactComponent as InfoIcon} from '../../components/icons/info-notification.svg';
import classNames from 'classnames';
import DataPackageVersions from "./dataPackage/DataPackageVersions";
import DataPackageMetadata from "./dataPackage/DataPackageMetadata";
import DataPackageName from "./dataPackage/DataPackageName";
import TabGroup from "../../components/TabGroup";
import ChangeLogTable, {DATAPACKAGE} from "./dataPackage/ChangeLogTable";
import ResupplyDataPackageDialog from "./dataPackage/ResupplyDataPackageDialog"
import {hasEditNgdDownloadsPermission, hasEditPremiumDownloadsPermission, hasCom3Catalogue} from "../../util/permissions";
import DeleteDataPackageDialog from "./dataPackage/DeleteDataPackageDialog";
import StopUpdatesDialog from "./dataPackage/StopUpdatesDialog";
import PartnerLicences from "../../components/PartnerLicences";
import UpdateFormatDialog from './dataPackage/UpdateFormatDialog';
import {isNgdDataPackage} from "../../../shared/ngd";
import { getPremiumProductOptions } from "../../modules/premium/actions";
import {isEaiUser} from '../../util/plans';
import {getNGDDefaultFormatOptions} from "./premiumItem/useDataPackageNgdOptions";
import {getRecipe} from "../../modules/recipes/actions";
import usePartnerContracts from "../../hooks/usePartnerContracts";
import {NOT_NEEDED} from "../../constants/state";

export const DOWNLOADS_HASH = '#downloads';

const useStyles = createUseStyles(styles);

const messages = defineMessages({
    title: {
        id: 'DataPackage.title',
        defaultMessage: 'Data package',
        description: 'Title for the data package view'
    },
    back: {
        id: 'DataPackage.back',
        defaultMessage: 'Data packages',
        description: 'Label for the back link'
    },
    error: {
        id: 'DataPackage.error',
        defaultMessage: 'There was a problem loading your data package.',
        description: 'Message shown if the data package cannot be loaded.'
    },
    download: {
        id: 'DataPackage.download',
        defaultMessage: 'View and download files',
        description: 'Label for the download link.'
    },
    creating: {
        id: 'DataPackage.creating',
        defaultMessage: "We're creating your data package.",
        description: 'Text shown when there is no version available.'
    },
    soon: {
        id: 'DataPackage.soon',
        defaultMessage: "It will be available soon.",
        description: 'Text shown when there is no version available.'
    },
    tabGroupAriaLabel: {
        id: 'DataPackage.tabGroupAriaLabel',
        defaultMessage: 'Download files and change logs',
        description: 'Aria label for tabs'
    },
    downloadFiles: {
        id: 'DataPackage.downloadFiles',
        defaultMessage: 'Download files by version',
        description: 'Label for download files by version tab'
    },
    changeLog: {
        id: 'DataPackage.changeLog',
        defaultMessage: 'View change log',
        description: 'Label for view change log tab'
    },
    delete: {
        id: 'DataPackage.delete',
        defaultMessage: "Delete package",
        description: 'delete button'
    },
    stopUpdates: {
        id: 'DataPackage.stopUpdates',
        defaultMessage: "Stop updates",
        description: 'stop updates button'
    },
    expand: {
        id: 'DataPackage.expand',
        defaultMessage: "Order more data",
        description: 'expand button'
    },
    resupply: {
        id: 'DataPackage.resupply',
        defaultMessage: "Get a resupply",
        description: 'resupply button'
    },
    updateFormat: {
        id: 'DataPackage.updateFormat',
        defaultMessage: "Change download format",
        description: 'change download format button'
    },
    lockedNotifTitle: {
        id: 'DataPackage.lockedNotifTitle',
        defaultMessage: "A request is being processed.",
        description: 'Locked info box title'
    },
    lockedNotifCaption: {
        id: 'DataPackage.lockedNotifCaption',
        defaultMessage: "No further changes can be made until the process has completed.",
        description: 'Locked info box caption'
    },
    packageOptions: {
        id: 'DataPackage.packageOptions',
        defaultMessage: 'Package options',
        description: 'Package options'
    }
});

const myStyles = createUseStyles(theme => ({
    subtitle: {
        marginTop: theme.spacing(4)
    },
    aligned: {
        display: 'flex',
        alignItems: 'center'
    },
    padRight: {
        marginRight: theme.spacing(1)
    },
    bold: {
        fontWeight: theme.typography.body2.fontWeight
    },
    downloadLink: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1)
    },
    availableSoon: {
        marginTop: theme.spacing(4),
        color: osColour.primary.berry,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
        }
    },
    info: {
        display: 'flex'
    },
    packageMetadata: {
        backgroundColor: osColour.neutral.clouds,
        padding: theme.spacing(3),
        maxWidth: `calc(${contentPadding.maxWidth}px - ${theme.spacing(6)})`
    },
    buttons: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: contentPadding.maxWidth,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'start'
        }
    },
    detailContainer: {
        display: 'flex',
        flexFlow: 'column wrap',
        alignContent: 'flex-start'
    },
    actionButtons: {
        display: 'flex',
        alignItems: 'center',
        marginTop: 32,
        marginBottom: 16,
        [theme.breakpoints.down('md')]: {
            flexDirection: 'row-reverse'
        }
    },
    button: {
        marginLeft: theme.spacing(1)
    },
    lockedNotification: {
        marginTop: theme.spacing(3),
        maxWidth: contentPadding.maxWidth
    },
    actionLoadingIcon: {
        margin: theme.spacing(0, 1)
    }
}));

export default function DataPackage() {
    const classes = useStyles();
    const myClasses = myStyles();
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();
    const intl = useIntl();

    const [savedState] = useState({
        ...location?.state?.stored
    });

    const [dataPackage, setDataPackage] = useState(null);
    const [showResupplyRequestDialog, setShowResupplyRequestDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showStopUpdateDialog, setShowStopUpdateDialog] = useState(false);
    const [showUpdateFormatDialog, setShowUpdateFormatDialog] = useState(false);
    const [openTabIndex, setOpenTabIndex] = useState(0);

    let {result, error} = useSelector(state => state.dataPackages.current);
    const user = useSelector(state => state.user.current.result);
    const deleteWorking = useSelector(state => state.dataPackages.delete.working);
    const stopUpdatesWorking = useSelector(state => state.dataPackages.stopUpdates.working);
    const updateFormatWorking = useSelector(state => state.dataPackages.updateFormat.working);
    const config = useSelector(state => state.config.current.result);
    const productOptions = useSelector(state => state.premium?.options.result);
    const recipe = useSelector(state => state.recipes?.recipe?.result);

    let product = useSelector(state => state.premium.product.result);
    if(product && product.id !== result?.productId) {
        product = null;
    }

    const partnerLicenses = usePartnerContracts(dataPackage?.productId);
    const isLicensed = () => {
        if (!partnerLicenses) return false;
        if (partnerLicenses === NOT_NEEDED) return true;
        if (Array.isArray(partnerLicenses) && partnerLicenses.length > 0) return true;
        return false;
    }

    const formatOptions = product?.dataFormats || getNGDDefaultFormatOptions();

    const packageId = match.params.packageId;

    useEffect(() => {
        if(dataPackage && dataPackage.recipeId){
            dispatch(getRecipe(dataPackage.recipeId));
        }
    }, [dispatch, dataPackage]);
    const dataPackageActionsDisabled = recipe && recipe.deleted;

    // Premium data packages which do not require updates have an updateScheduleId of 'NOTREQD'. NGD and NGD Powered Premium Products which
    // do not require updates have an updateScheduleId of 'ONCE'. This comes from the 'frequency' column in the 'library' database's 'subscription'
    // table. NGD and NGD Powered Premium Products whose status is 'COMPLETED' may once have required updates, but since been stopped by the user.
    const updatesNotRequired = dataPackage && (dataPackage.updateScheduleId === "NOTREQD" || dataPackage.updateScheduleId === "ONCE" || dataPackage.status === "COMPLETED");

    let hasDataPackagePermission;

    if (dataPackage && isNgdDataPackage(dataPackage)){
        hasDataPackagePermission = hasEditNgdDownloadsPermission(user);
    } else {
        hasDataPackagePermission = hasEditPremiumDownloadsPermission(user);
    }

    function expandDisabled() {
        if (!isLicensed()) return true;
        return  dataPackage && (dataPackage.fixedPriceAreas || dataPackage.locked);
    }

    function resupplyDisabled() {
        if (!isLicensed()) return true;
        if(!dataPackage || (dataPackage && !isNgdDataPackage(dataPackage))) return false;
        if(dataPackage.versions.length <= 0) return true;
        if((dataPackage.updateScheduleId === 'ANNUALLY' || dataPackage.updateScheduleId === 'MONTHLY') && dataPackage.supplyType === 'FULL') return true;
        const lastSupplyType = dataPackage.versions[0].supplyType;
        return lastSupplyType !== "COU" && dataPackage.hasLatestData;
    }

    function changeFormatDisabled() {
        if (!isLicensed()) return true;
        if(!dataPackage || (dataPackage && !isNgdDataPackage(dataPackage))) return false;
        if(dataPackage.versions.length <= 0) return true;
        return dataPackage?.supplyType?.includes("COU");
    }

    const working = deleteWorking || stopUpdatesWorking || updateFormatWorking;
    const DATA_PACKAGE_OPTIONS = {
        "PACKAGE_OPTIONS": {label: intl.formatMessage(messages.packageOptions), value: '', heading: true},
        "STOP_UPDATES": {label: intl.formatMessage(messages.stopUpdates), value: () => setShowStopUpdateDialog(true), disabled: (working || updatesNotRequired || dataPackageActionsDisabled)},
        "EXPAND_AOI": {label: intl.formatMessage(messages.expand), value: expand, disabled: dataPackageActionsDisabled || expandDisabled()},
        "RESUPPLY": {label: intl.formatMessage(messages.resupply), value: () => setShowResupplyRequestDialog(true), disabled: dataPackageActionsDisabled || (dataPackage?.locked || resupplyDisabled())},
        "UPDATE_FORMAT": {label: intl.formatMessage(messages.updateFormat), value: () => setShowUpdateFormatDialog(true), disabled: (dataPackageActionsDisabled || working || dataPackage?.locked || changeFormatDisabled())},
        "DELETE": {label: intl.formatMessage(messages.delete), value: () => setShowDeleteDialog(true), disabled: working}
    };

    // Actions dropdown
    const dataPackageActions = [
        DATA_PACKAGE_OPTIONS.PACKAGE_OPTIONS, DATA_PACKAGE_OPTIONS.STOP_UPDATES, DATA_PACKAGE_OPTIONS.EXPAND_AOI,
        DATA_PACKAGE_OPTIONS.RESUPPLY, {separator: true}, DATA_PACKAGE_OPTIONS.UPDATE_FORMAT
    ];

    if (!hasCom3Catalogue(user)) {
        dataPackageActions.push(DATA_PACKAGE_OPTIONS.DELETE);
    }


    const onActionChange = selectedVal => {
        if (typeof selectedVal === 'function') {
            selectedVal();
        }
    }

    // always clear the package and reload as we cant guarantee whats in the store is correct. this is because the expand
    // page may be reading other data packages (e.g. on load polygon modal) and putting them in the same redux state
    // placeholder i.e. dataPackages.current
    useEffect(() => {
        dispatch(clearLoadedDataPackage());
        dispatch(loadDataPackage(packageId));
        return () => {
            dispatch(clearLoadedDataPackage());
        }
    }, [packageId, dispatch]);

    useEffect(() => {
        if (result && packageId && result.id === packageId) {
            dispatch(getPremiumProductOptions(result.productId));
            setDataPackage(result);
        }
    }, [dispatch, dataPackage, result, packageId]);

    const tabContent = useMemo(() => {
        const tabsToShow = [];
        if(dataPackage?.changeLog?.length) {
            tabsToShow.push({heading: messages.changeLog, component: <ChangeLogTable changeLog={dataPackage.changeLog} type={DATAPACKAGE}/>});
        }
        if (dataPackage && dataPackage.versions && dataPackage.versions.length) {
            tabsToShow.unshift({heading: messages.downloadFiles, component: <DataPackageVersions dataPackage={dataPackage}/>})
        }
        return tabsToShow;
    }, [dataPackage])

    const downloadRef = useRef();
    const scrollToDownloads = useCallback(() => {
        if(downloadRef.current) {
            if (tabContent) {
                const downloadsTabIndex = tabContent.findIndex(element => element.heading.id === messages.downloadFiles.id);
                if (openTabIndex !== downloadsTabIndex) {
                    setOpenTabIndex(downloadsTabIndex);
                }
            }
            downloadRef.current.scrollIntoView();
        }
    }, [openTabIndex, tabContent]);

    function expand() {
        // When this component unmounts we clear the data package from the redux store. To save the expand page from
        // immediately re-loading it we can pass over the version that we are holding in location state.
        const path = routePaths.dataPackageExpand.replace(':packageId', packageId);
        const newLocation = getLocation(path, location, {}, { state: { dataPackage, canGoBack: true } } );
        history.push(newLocation);
    }

    // If the location includes the 'downloads' hash, and we have a set of links, then scroll to that area.
    // We scroll at most once, and after that the user is in control.
    const [autoScrolled, setAutoScrolled] = useState(false);
    useEffect(() => {
        if(dataPackage && location.hash === DOWNLOADS_HASH && !autoScrolled) {
            scrollToDownloads();
            setAutoScrolled(true);
        }
    }, [dataPackage, location, autoScrolled, scrollToDownloads]);

    let content, subtitle;

    if(!error && !dataPackage) {
        content = <CircularProgress size={32} className={classes.loader}/>;
    } else if(error) {
        content = <Notification variant='error' appearance='inline'>
            <Typography variant='body1'>
                <FormattedMessage {...messages.error}/>
            </Typography>
        </Notification>;
    } else if (dataPackage) {
        const versionCount = dataPackage.versions && dataPackage.versions.length;

        let buttonAreaContent;
        if(versionCount > 0) {
           buttonAreaContent = <div className={myClasses.downloadLink}>
                <a href={DOWNLOADS_HASH} onClick={scrollToDownloads}>
                    <FormattedMessage {...messages.download}/>
                </a>
           </div>
        } else {
            buttonAreaContent = <div className={myClasses.availableSoon}>
                <div className={myClasses.info}>
                    <InfoIcon width={24} height={24} className={myClasses.padRight}/>
                    <Typography variant='h3'
                                component='span'
                                className={classNames(myClasses.bold, myClasses.padRight)} >
                        <FormattedMessage {...messages.creating}/>
                    </Typography>
                </div>
                <Typography variant='h3'
                            component='span'>
                    <FormattedMessage {...messages.soon}/>
                </Typography>
            </div>
        }

        subtitle = <div className={myClasses.subtitle}>
            <div className={myClasses.aligned}>
                <Icon width={44} height={48} className={myClasses.padRight}/>
                <div className={myClasses.detailContainer}>
                    <DataPackageName dataPackage={dataPackage}/>
                    <Typography variant='h3' component='h2' color='textSecondary'>
                        {dataPackage.id}
                    </Typography>
                </div>
            </div>

            {
                dataPackage.locked && versionCount > 0 &&
                    <Notification appearance='inline' variant='info' className={myClasses.lockedNotification}>
                        <Typography variant='body2'>
                            <FormattedMessage {...messages.lockedNotifTitle}/>
                        </Typography>
                        <Typography variant='body1'>
                            <FormattedMessage {...messages.lockedNotifCaption}/>
                        </Typography>
                    </Notification>
            }

            <div className={myClasses.buttons}>
                { buttonAreaContent }

                {
                    hasDataPackagePermission &&
                    <div className={myClasses.actionButtons}>
                        <DropDownMenu items={dataPackageActions}
                            onChange={item => onActionChange(item.value)}
                            buttonVariant='outlined'
                            buttonLabel={intl.formatMessage(messages.packageOptions)}
                            buttonId='dataPackageActions'
                            staticButtonText
                        />
                    </div>
                }
            </div>
            <PartnerLicences productId={dataPackage.productId}/>
            <DataPackageMetadata productOptions={productOptions} dataPackage={dataPackage} user={user} config={config} recipe={recipe}/>

        </div>;

        content = <TabGroup tabs={tabContent}
                            tabGroupAriaLabel={messages.tabGroupAriaLabel}
                            onTabChange={setOpenTabIndex}
                            openTab={openTabIndex} />;
    }

    return <FeatureCheck feature='premium' >
        {showResupplyRequestDialog &&
            <ResupplyDataPackageDialog dataPackage={dataPackage} onClose={() => setShowResupplyRequestDialog(false)} isEaiUser={isEaiUser(user)}/>
        }

        {showDeleteDialog &&
            <DeleteDataPackageDialog dataPackage={dataPackage} onClose={() => setShowDeleteDialog(false)} />
        }

        {showStopUpdateDialog &&
            <StopUpdatesDialog dataPackage={dataPackage} onClose={() => setShowStopUpdateDialog(false)} />
        }

        {showUpdateFormatDialog &&
            <UpdateFormatDialog
                dataPackage={dataPackage}
                onClose={() => setShowUpdateFormatDialog(updateFormatWorking)}
                formatOptions={isNgdDataPackage(dataPackage) && formatOptions}
                premiumProductFormats={productOptions.formats?.items || []}
                premiumRelationships={productOptions.relationships?.items || []}
            />
        }

        <BackLink path={routePaths.dataPackages} label={messages.back} state={savedState}/>
        <div className={classes.rootWithBackLink}>
            <Typography variant='h1' color='primary'>
                <FormattedMessage {...messages.title}/>
            </Typography>
            {subtitle}
        </div>
        <div ref={downloadRef} className={classes.contentWithMobilePadding}>
            {content}
        </div>
    </FeatureCheck>;
}
