import { Fragment } from 'react';
import classNames from 'classnames';
import { ReactComponent as ArrowForward } from "../../../components/icons/forward-arrow-large.svg";
import { ENTER, ESCAPE } from '../../../constants/keys';
import { routes } from '../../../util/routes';
import { useHistory, useLocation } from 'react-router-dom';
import { getLocation } from '../../../util/routes';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch } from 'react-redux';
import { closeMenu } from '../../../modules/menu/actions';
import styled from '@emotion/styled';
import { ExternalLink, osColour } from 'omse-components';

const StyledListItem = styled(ListItem)(({ theme }) => `
    padding: ${theme.spacing(0.5)};
    margin: 0;
    width: auto;
    align-items: start;
    &.selected {
        border-radius: 0;
        padding-left: 0;
        border-left: 4px solid ${osColour.primary.berry};
        & span {
            color: ${osColour.primary.berry};
            font-weight: 600;
        }
    }
    li&:not(.heading):not(.nestedList):hover {
        background-color: rgba(0, 0, 0, 0.04);
        cursor: pointer;
    }
    &.selected .nested {
        padding-left: 37px;
    }
    &.heading {
        padding-top: 10px;
        & span {
            font-size: 1.375rem;
        }
    }
    & .forwardArrow {
        position: absolute;
        right: 16px;
    }
    li& > div {
        text-decoration: none;
        width: 100%;
        padding: 11px 15px;
        color: #000;
    }
    li& > div > span > a {
        font-size: inherit;
        text-decoration: none;
        color: #000;
    }
    li& > div > span > a > svg,
    li& > div > span > a:visited > svg, {
        color: ${osColour.neutral.charcoal};
    }
    &.nestedList {
        padding: 0;
        flex-direction: column;
        & ul, & li {
          width: 100%;
        }
    }
    &.nestedList li > div {
        padding-left: 35px;
        padding-top: 7px;
        padding-bottom: 7px;
    }
    &.nestedList li.selected > div {
        padding-left: 37px;
    }
`);

const NavigationItem = ({ item, nested, menuState, setMenuState, backState, setBackState, filter }) => {
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const routeIsInMenu = (route) => {
        const search = (item) => (item.route === route) || ((item.items && item.items.find(search)) || (item.inline && item.inline.find(search)));
        return menuState?.items?.find(search);
    }

    const activeRoute = routes.find(route => location.pathname.toLowerCase().indexOf(route.path.toLowerCase()) === 0 && routeIsInMenu(route));
    const selected = Boolean(item.route && activeRoute && (activeRoute.path === item.route.path));
    const open = Boolean(item?.inline && item.inline.find(i => i.route === activeRoute));
    const heading = Boolean(item.heading === true);

    const itemClick = item => {
        if (item.action) {
            item.action();
        }
        if (item.route && item.route.path) {
            const to = getLocation(item.route.path, location);
            history.push(to);
            dispatch(closeMenu());
        } else {
            if (item.items && item.items.length > 0) {
                setMenuState(item);
                setBackState([...backState, menuState]);
            }
        }
    }

    const itemClass = classNames({
        selected,
        heading
    });

    const filteredNestedItems = item.inline && item.inline.filter(filter);

    let itemId = item?.id ? item.id : item?.label?.id;
    if (item.route) {
        itemId += item.route.path;
    }

    const isExternalLink = item?.externalLink
    return item?.label && <Fragment key={item.label?.id}>
            <StyledListItem className={itemClass}
                            id={itemId}
                            onClick={() => itemClick(item)}
                            onKeyDown={e => {
                                if (e.key === ENTER) {
                                    itemClick(item);
                                }
                                if (e.key === ESCAPE) {
                                    dispatch(closeMenu());
                                }
                            }}
                            divider={!!item.divider}
                            role="button"
            >
                <ListItemText primaryTypographyProps={{variant: nested ? 'body1' : 'h3', component: 'span'}}>
                    {!isExternalLink ? <FormattedMessage {...item.label} /> : <ExternalLink message={item.label}
                                                                                            href={item.href}/>}
                    {item.items?.length > 0 &&
                        <ArrowForward className='forwardArrow' height={20} width={20}/>
                    }
                </ListItemText>
            </StyledListItem>
            {(selected || open) && filteredNestedItems && filteredNestedItems.length !== 0 &&
                <StyledListItem className='nestedList'>
                    <ul>
                        {filteredNestedItems.map((menuItem, i) => (
                            <NavigationItem
                                item={menuItem}
                                nested={true}
                                key={'menuItem' + i}
                                menuState={menuState}
                                backState={backState}
                                setMenuState={setMenuState}
                                setBackState={setBackState}
                                filter={filter}
                            />
                        ))}
                    </ul>
                </StyledListItem>
            }
        </Fragment>;
}

NavigationItem.propTypes = {
    item: PropTypes.object.isRequired,
    nested: PropTypes.bool,
    menuState: PropTypes.object,
    setMenuState: PropTypes.func.isRequired,
    backState: PropTypes.array,
    setBackState: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired
}

NavigationItem.defaultProps = {
    nested: false,
    backState: []
};

export default NavigationItem;
