import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        id: "DeleteTeamSpaceDialog.title",
        defaultMessage: "Permanently delete this Team Space?",
        description: "Title for the delete dialog",
    },
    buttonLabel: {
        id: "DeleteTeamSpaceDialog.buttonLabel",
        defaultMessage: "Delete Team Space",
        description: "Button label for the delete dialog",
    },
    confirmationCode: {
        id: "DeleteTeamSpaceDialog.confirmationCode",
        defaultMessage: "Code",
        description: "Label for confirmation code",
    },
    confirmationLabel: {
        id: "DeleteTeamSpaceDialog.confirmationLabel",
        defaultMessage: "Enter the above code to confirm this action",
        description: "Label for confirmation input",
    },
    ariaLabel: {
        id: "DeleteTeamSpaceDialog.ariaLabel",
        defaultMessage: "Enter the following code to confirm this action: {code}",
        description: "Label used to help screen readers handle the input control",
    },
    closeAriaLabel: {
        id: "DeleteTeamSpaceDialog.closeAriaLabel",
        defaultMessage: "Close the dialog",
        description: "Close the dialog SR",
    },
    selectDestination: {
        id: "DeleteTeamSpaceDialog.selectDestination",
        defaultMessage: "What should happen to the {total, plural, =1 {# {resourceType}} other {# {resourceType}s}} in this Team Space?",
        description: "selectDestination",
    },
    apiProject: {
        id: "DeleteTeamSpaceDialog.apiProject",
        defaultMessage: "API Project",
        description: "apiProject",
    },
    content1: {
        id: "DeleteTeamSpaceDialog.content1",
        defaultMessage: "This action is permanent.",
        description: "content1",
    },
    content2: {
        id: "DeleteTeamSpaceDialog.content2",
        defaultMessage: "There are {total, plural, =0 {no {resourceType}s} other {}} in this Team Space.",
        description: "content2",
    },
    noResultsPrompt: {
        id: "DeleteTeamSpaceDialog.noResultsPrompt",
        defaultMessage: "No matching Team Spaces.",
        description: "noResultsPrompt",
    },
    moveToUnassigned: {
        id: "DeleteTeamSpaceDialog.moveToUnassigned",
        defaultMessage: '<strong>Move</strong> API Project(s) into the "My Projects" area (everyone will see them).',
        description: "moveToUnassigned",
    },
    moveToAnotherTeamSpace: {
        id: "DeleteTeamSpaceDialog.moveToAnotherTeamSpace",
        defaultMessage: '<strong>Move</strong> the API Project(s) into another Team Space.',
        description: "moveToAnotherTeamSpace",
    },
    findATeamSpace: {
        id: "DeleteTeamSpaceDialog.findATeamSpace",
        defaultMessage: 'Find a Team Space',
        description: "findATeamSpace",
    },
    disabledCodeInput: {
        id: "DeleteTeamSpaceDialog.disabledCodeInput",
        defaultMessage: "Delete Team Space confirmation is disabled. Select a destination Team Space to continue.",
        description: "disabledCodeInput",
    }
});

export default messages;