import PropTypes from 'prop-types'
import { Button } from '@mui/material';
import { AddCircle } from "@mui/icons-material";
import teamSpaceMessages from "../../TeamSpace.msg";
import { FormattedMessage } from 'react-intl';

export default function AddResourceButton ({ productType, onClick, actionText }) {
    return (
        <Button
            variant="contained"
            startIcon={<AddCircle />}
            data-testid={`open-dialog-add-${productType
                .toString()
                .replace(/\s+/g, "")
                .toLowerCase()}`}
            onClick={onClick}
        >
            <FormattedMessage
                {...teamSpaceMessages.addButton}
                values={{ actionText: actionText, product: productType }}
            />
        </Button>
    );
};

AddResourceButton.propTypes = {
    productType: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    actionText: PropTypes.string.isRequired,
};
