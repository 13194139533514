import { FormattedMessage, useIntl } from "react-intl";
import ClickAwayTooltip from "../../../components/ClickAwayTooltip";
import { Typography } from "@mui/material";
import { ADMIN_ROLE } from "../../../../shared/roles";
import { osColour, DropDownMenu } from "omse-components";
import { ReactComponent as InfoIcon } from "../../../components/icons/info-notification.svg";
import { ReactComponent as OverflowIcon } from "../../../components/icons/overflow-menu.svg";
import styled from "@emotion/styled";
import { css } from "@emotion/css";
import { useSelector } from "react-redux";
import ownersTableMessages from "./OwnersTable.msg";
import PropTypes from "prop-types";
import MemberInfo from "./MemberInfo";
import { useManageTeamSpacePermission } from "../util/useManageTeamSpacePermission";
import { dialogVariants } from "./constants";

const StyledTable = styled("table")(
    ({ theme }) => `
    width: 100%;
    border: none;
    border-collapse: collapse;
    margin: ${theme.spacing(2)} 0 0 0;

    & > thead {
        color: ${osColour.neutral.charcoal};
        top: 0;
        position: sticky;
        width: 100%;
        box-shadow: 0 2px 0 ${osColour.neutral.rock};
    }

    & > thead > tr > th {
        padding: 0 0 0.2em 0;
        font-weight: bold;
        gap: 0.4em;
        display: flex;
        align-items: center;
    }

    & > tbody {
        width: 100%;
        display: block;
        overflow-y: auto;

        min-height: 8dvh;
        max-height: 8dvh;

        @media screen and (min-height: 400px) {
            min-height: 36dvh;
            max-height: 36dvh;
        }

        @media screen and (min-height: 600px) {
            min-height: 40dvh;
            max-height: 40dvh;
        }

        @media screen and (min-height: 680px) {
            min-height: 50dvh;
            max-height: 50dvh;
        }

        @media screen and (min-height: 801px) {
            min-height: 18dvh;
            max-height: 18dvh;
        }

        @media screen and (min-height: 801px) and (max-width: 600px) {
            min-height: 57dvh;
            max-height: 57dvh;
        }

        @media screen and (min-height: 900px) {
            min-height: 20dvh;
            max-height: 20dvh;
        }

        @media screen and (min-height: 900px) and (max-width: 600px) {
            min-height: 60dvh;
            max-height: 60dvh;
        }

        @media screen and (min-height: 1000px) {
            min-height: 24dvh;
            max-height: 24dvh;
        }

        @media screen and (min-height: 1000px) and (max-width: 600px) {
            min-height: 66dvh;
            max-height: 66dvh;
        }

    }

    & > tbody > tr {
        padding: 0.4em 0.6em 0 0;
        display: flex;
        gap: 1em;
        flex-wrap: none;
        align-items: center;
    }

    & > tbody > tr > td:first-of-type {
        flex-grow: 1;
    }

    ${theme.breakpoints.down("sm")} {
        & > tbody > tr td:first-of-type {
            padding: ${theme.spacing(1, 0, 0.5, 0)}
        }

    }
`,
);

const OwnerActions = styled.div(
    ({ theme }) => `
    & svg {
        color: ${osColour.neutral.charcoal}
    }
    & li:first-of-type:not(.Mui-disabled) {
        color: ${osColour.status.error};
    }
    & button {
        padding: ${theme.spacing(1)};
    }
    ${theme.breakpoints.down("sm")} {
        & button {
            padding: ${theme.spacing(0.5)};
        }
    }
`,
);

// Tooltip
const clickAwayTooltipClass = css`
    float: none !important;
    margin-left: 6px;
    display: inline-flex;
`;

const clickAwayTooltipLinkClass = css`
    & > span:nth-child(1) {
        display: flex;
    }
`;

export default function OwnersTable({ setOwners, owners, working, variant }) {
    const intl = useIntl();
    const user = useSelector(state => state.user.current.result);
    const teamSpaceResult = useSelector(state => state.teamSpaces.getTeamSpace.result);
    const hasManageTeamSpacePermission = useManageTeamSpacePermission(user, teamSpaceResult);

    const removeOwner = (owner) => {
        if (owner?.datahubDeveloperId) {
            setOwners(
                owners.filter(
                    (existing) => existing.datahubDeveloperId !== owner.datahubDeveloperId,
                ),
            );
        }
    };

    const sortedOwners = [...owners].sort((a) =>
        a.datahubDeveloperId === user.datahubDeveloperId ? -1 : 1,
    );

    return (
        <StyledTable>
            <thead>
                <tr>
                    <Typography variant="body1" component="th" colSpan={2}>
                        <FormattedMessage {...ownersTableMessages.ownersTableHeading} />
                        <ClickAwayTooltip
                            id="ownerHelp"
                            classes={{
                                clickAwayTooltip: clickAwayTooltipClass,
                                tooltipLink: clickAwayTooltipLinkClass,
                            }}
                            icon={
                                <InfoIcon width={24} height={24} color={osColour.status.warning} />
                            }
                            body={
                                <FormattedMessage
                                    {...ownersTableMessages.ownerHelp}
                                    values={{ strong: (chunks) => <strong>{chunks}</strong> }}
                                />
                            }
                            ariaLabel={intl.formatMessage(ownersTableMessages.ownerHelp, {
                                b: (chunks) => chunks,
                            })}
                        />
                    </Typography>
                </tr>
            </thead>
            <tbody>
                {sortedOwners.map(
                    (owner) =>
                        owner && (
                            <tr key={owner.datahubDeveloperId}>
                                <td>
                                    <Typography component="div">
                                        <MemberInfo info={owner} user={user} />
                                    </Typography>
                                </td>
                                <td>
                                    {(hasManageTeamSpacePermission || variant === dialogVariants.newTeamSpace) &&
                                        <OwnerActions>
                                            <DropDownMenu
                                                disabled={working}
                                                icon={<OverflowIcon height={24} width={24} />}
                                                items={[
                                                    (owner.role === ADMIN_ROLE) ?
                                                        (owner.datahubDeveloperId === user.datahubDeveloperId) ? {
                                                            label: intl.formatMessage(
                                                                ownersTableMessages.cannotRemoveAdminLoggedIn,
                                                            ),
                                                            value: ownersTableMessages.cannotRemoveAdminLoggedIn.id,
                                                            disabled: true,
                                                        }
                                                            : {
                                                                label: intl.formatMessage(
                                                                    ownersTableMessages.cannotRemoveAdmin,
                                                                ),
                                                                value: ownersTableMessages
                                                                    .cannotRemoveAdmin.id,
                                                                disabled: true,
                                                            }
                                                        : {
                                                            label: intl.formatMessage(
                                                                ownersTableMessages.remove,
                                                            ),
                                                            value: ownersTableMessages.remove.id,
                                                            action: () => removeOwner(owner),
                                                        },
                                                ]}
                                                placement="bottom-end"
                                            />
                                        </OwnerActions>
                                    }
                                </td>
                            </tr>
                        ),
                )}
            </tbody>
        </StyledTable>
    );
}

OwnersTable.propTypes = {
    setOwners: PropTypes.func.isRequired,
    owners: PropTypes.array.isRequired,
};
