import { useSelector } from "react-redux";
import {
    USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN,
    USER_OPEN_DATA_PLAN,
    USER_OS_INTERNAL_PLAN,
    USER_PARTNER, USER_PREMIUM_DATA_PLAN, USER_PSGA_PLAN
} from "../../shared/plans";
import { PART_CATALOGUE } from "../../shared/catalogues";

function getEligibility(user) {
    let eligibility;
    const plan = user?.plan;
    const catalogues = user?.catalogues

    switch (plan) {
        case USER_OPEN_DATA_PLAN:
            eligibility = { visible: true, eligible: false }
            break;
        case USER_PREMIUM_DATA_PLAN:
            if(!!catalogues?.length && catalogues.includes(PART_CATALOGUE)) {
                eligibility = { visible: false, eligible: false }
            } else {
                eligibility = { visible: true, eligible: true }
            }
            break;
        case USER_ENERGY_AND_INFRASTRUCTURE_DATA_PLAN:
            eligibility = { visible: false, eligible: false }
            break;
        case USER_PSGA_PLAN:
            eligibility = { visible: true, eligible: true }
            break;
        case USER_OS_INTERNAL_PLAN:
            eligibility = { visible: false, eligible: false }
            break;
        case USER_PARTNER:
            eligibility = { visible: false, eligible: false }
            break;
        default:
            eligibility = { visible: true, eligible: false }
    }

    return eligibility;
}

export default function useDelEligibility() {
    // TODO: Add checks for customers whose del has expired or have been previously declined it.
    const user = useSelector(state => state.user.current.result);
    return getEligibility(user);
}


