import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {Typography} from "@mui/material";
import {createUseStyles} from "react-jss";
import {ExternalLink} from "omse-components";
import {Container} from "@mui/system";
import DelTermsActions from "./DelTermsActions";

const messages = defineMessages({
    title: {
        id: 'DelTerms.title',
        defaultMessage: 'Try OS Premium Data downloads, free for 6 months',
        description: 'Title of DEL terms'
    },
    p1: {
        id: 'DelTerms.p1',
        defaultMessage: 'Our Data Exploration Licence (DEL) lets you explore and use OS premium data for free, for 6 months. ' +
            'Develop your ideas before deciding if the paid-for service is for you.',
        description: 'Paragraph 1 of DEL terms'
    },
    p2: {
        id: 'DelTerms.p2',
        defaultMessage: 'You can view the full <a>terms and conditions (PDF)</a>, meanwhile here’s  what you need to know:',
        description: 'Paragraph 2 of DEL terms'
    },
    p2LinkAriaLabel: {
        id: 'DelTerms.p2LinkAriaLabel',
        defaultMessage: 'Click here to view the DEL terms and conditions (PDF) (Opens in a new tab)',
        description: 'Aria label for the link in p2'
    },
    subtitle1: {
        id: 'DelTerms.subtitle1',
        defaultMessage: 'What we need for this licence',
        description: 'Subtitle 1 of DEL terms'
    },
    p3: {
        id: 'DelTerms.p3',
        defaultMessage: 'We ask that you provide us with information about you and your company or organisation.',
        description: 'Paragraph 3 of DEL terms'
    },
    p4: {
        id: 'DelTerms.p4',
        defaultMessage: 'Acknowledge that you\'re using exploration data and that you\'re doing so in the right way by using our <a>style guide (PDF)</a>.',
        description: 'Paragraph 4 of DEL terms'
    },
    p4LinkAriaLabel: {
        id: 'DelTerms.p4LinkAriaLabel',
        defaultMessage: 'Click here to view our style guide (PDF) (Opens in a new tab)',
        description: 'Aria label for the link in p4'
    },
    p5: {
        id: 'DelTerms.p5',
        defaultMessage: 'Make sure any prototypes you provide, or any OS data you provide is returned to you or destroyed ' +
            'within seven days. Tell us if doing this might disrupt your long term plans and we can discuss appropriate licensing.',
        description: 'Paragraph 5 of DEL terms'
    },
    p6: {
        id: 'DelTerms.p6',
        defaultMessage: 'Take sensible measures to make sure all exploration data supplied to you is secure from any unauthorised use or access.',
        description: 'Paragraph 6 of DEL terms'
    },
    subtitle2: {
        id: 'DelTerms.subtitle2',
        defaultMessage: 'What you must not do under this licence',
        description: 'Subtitle 2 of DEL terms'
    },
    p7: {
        id: 'DelTerms.p7',
        defaultMessage: 'Do not make any exploration data available to third parties or create any products or services ' +
            'which have benefited from, relied on, or made use of our exploration data.',
        description: 'Paragraph 7 of DEL terms'
    },
    p8: {
        id: 'DelTerms.p8',
        defaultMessage: 'If you want to do that, then tell us. We can then agree how to make this happen.',
        description: 'Paragraph 8 of DEL terms'
    },
    subtitle3: {
        id: 'DelTerms.subtitle3',
        defaultMessage: 'Want to use our data internally?',
        description: 'Subtitle 3 of DEL terms'
    },
    p9: {
        id: 'DelTerms.p9',
        defaultMessage: 'Data available under the DEL can only be used to develop your idea for a product or service for ' +
            'third parties, or to see if the data would be suitable to meet your own internal business requirements.',
        description: 'Paragraph 9 of DEL terms'
    },
    p10: {
        id: 'DelTerms.p10',
        defaultMessage: 'If you want to use the data for your own internal business use upon expiry of your DEL, you\'ll ' +
            'need to contact one of our <a>Licensed Partners</a> to licence the data.',
        description: 'Paragraph 10 of DEL terms'
    },
    p10LinkAriaLabel: {
        id: 'DelTerms.p10LinkAriaLabel',
        defaultMessage: 'Click here to view information on finding a Licensed Partner (Opens in a new tab)',
        description: 'Aria label for the link in p10'
    },
    subtitle4: {
        id: 'DelTerms.subtitle4',
        defaultMessage: 'Education and research use',
        description: 'Subtitle 4 of DEL terms'
    },
    p11: {
        id: 'DelTerms.p11',
        defaultMessage: 'The DEL is not intended for educational use or academic research. If you require OS data for ' +
            'these purposes, please see our <a>Education Licence</a>.',
        description: 'Paragraph 11 of DEL terms'
    },
    p11LinkAriaLabel: {
        id: 'DelTerms.p11LinkAriaLabel',
        defaultMessage: 'Click here to view information on our Education License (Opens in a new tab)',
        description: 'Aria label for the link in p11'
    },
});

const useStyles = createUseStyles(theme => ({
    delContainer: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        [theme.breakpoints.down("md")]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        }
    },
    title: {
        paddingBottom: theme.spacing(4)
    },
    subtitle: {
        paddingBottom: theme.spacing(2)
    }
}));

function DelTerms() {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Container maxWidth="lg"  className={classes.delContainer}>
            <Typography variant="h1" color="primary" className={classes.title}>
                <FormattedMessage {...messages.title} />
            </Typography>
            <Typography variant="body1" color="textPrimary" paragraph={true}>
                <FormattedMessage {...messages.p1} />
            </Typography>
            <Typography variant="body1" color="textPrimary" paragraph={true}>
                <FormattedMessage {...messages.p2} values={{
                    a: message =>
                        <ExternalLink
                            href={"https://www.ordnancesurvey.co.uk/documents/licences/data-exploration-licence.pdf"}
                            message={message}
                            type="generic"
                            ariaLabel={intl.formatMessage(messages.p2LinkAriaLabel)} />
                }}/>
            </Typography>

            <Typography variant="h2" color="primary" className={classes.subtitle}>
                <FormattedMessage {...messages.subtitle1} />
            </Typography>
            <Typography variant="body1" color="textPrimary" paragraph={true}>
                <FormattedMessage {...messages.p3} />
            </Typography>
            <Typography variant="body1" color="textPrimary" paragraph={true}>
                <FormattedMessage {...messages.p4} values={{
                    a: message =>
                        <ExternalLink
                            href={"https://www.ordnancesurvey.co.uk/documents/partner-documents/ordnance-survey-style-guide-for-third-parties.pdf"}
                            message={message}
                            type="generic"
                            ariaLabel={intl.formatMessage(messages.p4LinkAriaLabel)} />
                }}/>
            </Typography>
            <Typography variant="body1" color="textPrimary" paragraph={true}>
                <FormattedMessage {...messages.p5} />
            </Typography>
            <Typography variant="body1" color="textPrimary" paragraph={true}>
                <FormattedMessage {...messages.p6} />
            </Typography>

            <Typography variant="h2" color="primary" className={classes.subtitle}>
                <FormattedMessage {...messages.subtitle2} />
            </Typography>
            <Typography variant="body1" color="textPrimary" paragraph={true}>
                <FormattedMessage {...messages.p7} />
            </Typography>
            <Typography variant="body1" color="textPrimary" paragraph={true}>
                <FormattedMessage {...messages.p8} />
            </Typography>

            <Typography variant="h2" color="primary" className={classes.subtitle}>
                <FormattedMessage {...messages.subtitle3} />
            </Typography>
            <Typography variant="body1" color="textPrimary" paragraph={true}>
                <FormattedMessage {...messages.p9} />
            </Typography>
            <Typography variant="body1" color="textPrimary" paragraph={true}>
                <FormattedMessage {...messages.p10} values={{
                    a: message =>
                        <ExternalLink
                            href={"https://www.ordnancesurvey.co.uk/customers/businesses/find-a-business-partner"}
                            message={message}
                            type="generic"
                            ariaLabel={intl.formatMessage(messages.p10LinkAriaLabel)} />
                }}/>
            </Typography>

            <Typography variant="h2" color="primary" className={classes.subtitle}>
                <FormattedMessage {...messages.subtitle4} />
            </Typography>
            <Typography variant="body1" color="textPrimary" paragraph={true}>
                <FormattedMessage {...messages.p11} values={{
                    a: message =>
                        <ExternalLink
                            href={"https://www.ordnancesurvey.co.uk/licensing/education-licence"}
                            message={message}
                            type="generic"
                            ariaLabel={intl.formatMessage(messages.p11LinkAriaLabel)} />
                }}/>
            </Typography>

            <DelTermsActions />
        </Container>
    )
}

export default DelTerms;