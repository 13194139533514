import { defineMessages } from "react-intl";

const membersTabMessages = defineMessages({
    makePrivate: {
        id: "membersTabMessages.makePrivate",
        defaultMessage: "Lock this Team Space.",
        description: "The 'toggle locked' <Switch /> label.",
    },
    noResultsAdminPrompt: {
        id: "membersTabMessages.noResultsAdminPrompt",
        defaultMessage: "No results. To add a user not shown, <a>invite them to the OS Data Hub</a>.",
        description: "No results admin prompt",
    },
    lockedTooltip: {
        id: "membersTabMessages.unlockedTooltip",
        defaultMessage: "Locked Team Spaces can only be seen and accessed by assigned Owners and Members.",
        description: "The 'toggle is open' <Switch /> tooltip content.",
    },
    unlockedTooltipP1: {
        id: "membersTabMessages.lockedTooltipP1",
        defaultMessage: "<strong>Viewers</strong> can view the Team Space and access API Projects, but can’t add or make changes to API Projects inside the Team Space.",
        description: "The 'toggle is private' <Switch /> tooltip content.",
    },
    unlockedTooltipP2: {
        id: "membersTabMessages.lockedTooltipP2",
        defaultMessage: "<strong>Editors</strong> can add and remove API Projects, but can’t manage who has access to the Team Space.",
        description: "The 'toggle is private' <Switch /> tooltip content.",
    },
    openMessage: {
        id: "membersTabMessages.unlockedMessage",
        defaultMessage: "This Team Space is unlocked so everyone can see it.<isManageAccessViewContent><br></br>You may lock this Team Space above and start adding members.</isManageAccessViewContent>",
        description: "The 'Open' team context message.",
    },
});

export default membersTabMessages;
