const { defineMessages } = require("react-intl");

const deleteTeamSpaceResourceErrors = defineMessages({
    deleteApiProjectServerError: {
        id: "deleteTeamSpaceResourceErrors.deleteApiProjectServerError",
        description: "Delete API Project Server Error",
        defaultMessage: "We ran into a problem our end while deleting the API Project. Please try again and if the problem persists, please {link}",
    },
});

export default deleteTeamSpaceResourceErrors;