import { useState } from "react";
import PropTypes from 'prop-types';
import { DropDownMenu } from "omse-components";
import { osColour } from "omse-components";
import { VIEWER, EDITOR, getApiProjectRole } from "../util/roleSelection";
import membersTableMessages from "./MembersTable.msg";
import { FormattedMessage, useIntl } from "react-intl";
import { StaticEditorRole, StaticViewerRole } from "./RoleSelectReadOnly";
import { css } from "@emotion/css";

const viewerMemberClass = css`
    color: ${osColour.primary.berry};
`;

const editorMemberClass = css`
    color: ${osColour.primary.darkerMoss};
`;

export default function RoleSelect({ initialRole, member, members, setMembers, readOnly }) {
    const intl = useIntl();
    const [role, setRole] = useState(initialRole || "");

    const handleChange = (selected) => {
        const newRole = selected.value;
        setRole(newRole);

        const memberIndex = members.findIndex(m => m.datahubDeveloperId === member.datahubDeveloperId);
        if (memberIndex !== -1) {
            const updatedMembers = [...members];

            updatedMembers[memberIndex] = {
                ...updatedMembers[memberIndex],
                apiProjectRole: getApiProjectRole(newRole)
            };

            setMembers(updatedMembers);
        }
    };

    if (readOnly) {
        if (role === VIEWER) {
            return (
                <StaticViewerRole>
                    <FormattedMessage {...membersTableMessages.viewer} />
                </StaticViewerRole>
            );
        }
        if (role === EDITOR) {
            return (
                <StaticEditorRole>
                    <FormattedMessage {...membersTableMessages.editor} />
                </StaticEditorRole>
            )
        }
    }

    return (
        <DropDownMenu
            buttonProps={{
                "aria-label": intl.formatMessage(membersTableMessages.roleSelectAriaLabel),
                className: (role === EDITOR? editorMemberClass: undefined)
            }}
            buttonId="memberRole"
            disabled={false}
            onChange={handleChange}
            value={role}
            items={[
                {
                    label: intl.formatMessage(
                        membersTableMessages.viewer,
                    ),
                    value: VIEWER,
                    className: viewerMemberClass,
                },
                {
                    label: intl.formatMessage(
                        membersTableMessages.editor,
                    ),
                    value: EDITOR,
                    className: editorMemberClass
                },
            ]}
            placement="bottom-end"
        />
    );
}

RoleSelect.propTypes = {
    initialRole: PropTypes.string,
    member: PropTypes.shape({
        datahubDeveloperId: PropTypes.string.isRequired,
        apiProjectRole: PropTypes.string,
    }).isRequired,
    members: PropTypes.arrayOf(PropTypes.object).isRequired,
    setMembers: PropTypes.func.isRequired,
};
