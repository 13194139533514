import React, {useCallback, useEffect, useRef, useState} from 'react';
import {osColour} from "omse-components";
import {useDispatch, useSelector} from "react-redux";
import {createUseStyles} from 'react-jss';
import {hasCom3Catalogue} from "../../../../util/permissions";
import features from '../../../../../shared/features';
import FeatureCheck from "../../../../components/FeatureCheck";
import {
    getDataPackagePrice,
    deleteDataPackagePrice
} from "../../../../modules/dataPackages/actions";
import {Box, IconButton, Popover, Table, TableBody, TableCell, TableRow, Typography, CircularProgress} from "@mui/material";
import ClickAwayTooltip from '../../../../components/ClickAwayTooltip'
import { Warning as WarningIcon } from '@mui/icons-material';
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {ReactComponent as InfoIcon} from '../../../../components/icons/info-notification.svg';
import {ReactComponent as CloseIcon} from '../../../../components/icons/close-small.svg';
import VisuallyHidden from "../../../../components/util/VisuallyHidden";
import {debounce} from 'lodash';

const messages = defineMessages({
    priceError: {
        id:'PackagePricePanel.error',
        defaultMessage: "There was an error calculating your price. Please try again later.",
        description: "Error message for the price panel"
    },
    orderTotal: {
        id: 'PackagePricePanel.orderTotal',
        defaultMessage: 'Data Package Total',
        description: 'Total label'
    },
    orderSummaryTitle: {
        id: 'PackagePricePanel.orderSummary.title',
        defaultMessage: 'Order summary',
        description: 'Title of the order summary popover'
    },
    annualPaymentOptionWording: {
        id: 'PackagePricePanel.orderSummary.annualPaymentOptionWording',
        defaultMessage: 'As you have chosen the annual option, the applicable annual price will be invoiced each year.',
        description: 'Order Summary annual payment popover caption, appears at the bottom'
    },
    annualDataPrice: {
        id: 'PackagePricePanel.orderSummary.annualDataPrice',
        defaultMessage: 'Annual data price',
        description: 'Table entry'
    },
    userDiscount: {
        id: 'PackagePricePanel.orderSummary.userDiscount',
        defaultMessage: 'Terminal band / User discount',
        description: 'Table entry'
    },
    priceAfterDiscount: {
        id: 'PackagePricePanel.orderSummary.priceAfterDiscount',
        defaultMessage: 'Annual price after discount',
        description: 'Table entry'
    },
    minAnnualContractCharge: {
        id: 'PackagePricePanel.orderSummary.minAnnualContractCharge',
        defaultMessage: 'Minimum annual contract charge',
        description: 'Table entry'
    },
    multipliedContractTerm: {
        id: 'PackagePricePanel.orderSummary.multipliedContractTerm',
        defaultMessage: 'Mulitplied by contract term x {contractTerm}',
        description: 'Table entry'
    },
    invoicePrice: {
        id: 'PackagePricePanel.orderSummary.invoicePrice',
        defaultMessage: 'Invoice price for current year (ex. VAT)',
        description: 'Table entry'
    },
    proratedForYear: {
        id: 'PackagePricePanel.orderSummary.proratedForYear',
        defaultMessage: 'Prorated for this year',
        description: 'Table entry'
    },
    limitedToGbPrice: {
        id: 'PackagePricePanel.orderSummary.limitedToGbPrice',
        defaultMessage: 'GB Price Cap',
        description: 'Table entry'
    },
});

const styles = createUseStyles((theme) => ({
    priceContainer:{
        display: 'flex',
        alignItems: "center",
        justifyContent: 'space-between',
        padding: 10,
        margin: 10,
        borderTop: `1px solid ${osColour.neutral.mist}`,
        '&>div':{
            display:"flex",
            alignItems:"center"
        }
    },
    price: {
        color: osColour.neutral.charcoal,
        textAlign: "right",
        display: "inline"
    },
    clickaway: {
        height:24
    },
    iconButton: {
        padding: 0,
        color: osColour.neutral.charcoal,
    },
    summaryPopover: {
        padding: 20,
        width: 470,
        boxSizing: 'border-box',
        [theme.breakpoints.down('md')]: {
            width: '100%'
        }
    },
    summaryHeading: {
        display:'flex',
        flexDirection:'row-reverse',
        justifyContent:'space-between'
    },
    summaryTable:{
        borderTop: `1px solid ${osColour.neutral.mist}`,
        borderBottom: `1px solid ${osColour.neutral.mist}`,
        '& tr':{
            border:0
        },
        '& tr:nth-child td': {
            color: osColour.status.success
        },
        '& tr:nth-child(-n+5) td':{
            fontWeight: 'normal'
        },
        '& td': {
            padding: 5,
            border:0,
            textAlign: 'right'
        }
    },
    row2: {
        '& td':{
            color: osColour.status.success
        }
    }
}));

export default function PackagePricePanel(props) {
    const {waitTime=500} = props;
    const dispatch = useDispatch();
    const draftOrder = useSelector(state => state.dataPackages.draftOrder);
    const { AOI,
        tiled,
        tiles,
        polygon,
        productId,
        area,
        format,
        renewalPeriod,
        terminals,
        users,
        updates
    } = draftOrder;
    const priceState = useSelector(state => state.dataPackages?.price);
    const { annualDataPrice,
        userDiscount,
        priceAfterDiscount,
        minContractCharge,
        contractTerm,
        invoicePrice,
        proratedYear,
        totalPrice,
        contractTermPrice,
        limitedToGbPrice
    } = priceState.result || {};
    const user = useSelector(state => state.user.current.result);
    const infoRef = useRef(null);
    const [summaryOpen, setSummaryOpen] = useState(false);
    const classes = styles();
    const intl = useIntl();

    // eslint-disable-next-line
    const debouncedGetDataPackagePrice = useCallback(debounce(() => {
        //according to eslint/react-hooks/exhaustive-deps rule useCallback requires an inline function.
        // If we do this the debounce does not work.
        if (hasCom3Catalogue(user)) {
            dispatch(getDataPackagePrice(draftOrder));
        }

    }, waitTime), [dispatch, AOI, tiles, updates, polygon, productId, area, format, renewalPeriod, terminals, users, user, draftOrder.id]);

    useEffect(() => {
        dispatch(deleteDataPackagePrice());

        const isExpansion = !!draftOrder.id;

        // if this hook is triggered, then a new price can be retrieved
        //if it is a tiled product,we need tiles.
        const hasTiles = tiled && tiles?.length > 0;
        //If it's not tiled but the area should be a polygon, then we need the AOI.
        const hasPolygon = !tiled && area === "Polygon" && !!AOI;
        //Check that it's not tiled and the area has been predefined.
        const hasPredefinedArea = !tiled && !!area && area !== "Polygon";
        //if all are false then the package's details have not been filled in.
        const areaCondition = (hasTiles || hasPolygon || hasPredefinedArea);
        //These fields are only needed
        const hasInitialMandatoryFields = renewalPeriod && (terminals || users);
        if (format && updates && (isExpansion || hasInitialMandatoryFields) && areaCondition) {
            debouncedGetDataPackagePrice();
        }

        return ()=> {
            dispatch(deleteDataPackagePrice());

        }
    },[draftOrder.id, AOI, tiled, tiles, polygon, updates, productId, area, format, renewalPeriod, terminals, users, debouncedGetDataPackagePrice, dispatch])

    const toggleSummaryWindow = (evt) => {
        setSummaryOpen(!summaryOpen);
    }

    const annualPayment = /^[2-5]_YEARSPA$/.test(renewalPeriod);
    const popover = <Popover open={summaryOpen}
                             onClose={() => {setSummaryOpen(false)}}
                             anchorEl={infoRef.current}
                             classes={{
                                 paper: classes.summaryPopover
                             }}
                             anchorOrigin={{
                                 horizontal: 'right',
                                 vertical: 'top'
                             }}
                             transformOrigin={{
                                 horizontal: 'right',
                                 vertical: 'bottom'
                             }}
    >
        <Box className={classes.summaryHeading} >
            <IconButton
                className={classes.iconButton}
                onClick={()=>setSummaryOpen(false)}
                size="large">
                <CloseIcon/>
                <VisuallyHidden>Close Breakdown</VisuallyHidden>
            </IconButton>
            <Typography variant="h2">
                <FormattedMessage {...messages.orderSummaryTitle}/>
            </Typography>
        </Box>

        <Table className={classes.summaryTable} aria-label="Pricing breakdown table">
            <TableBody>
                {annualDataPrice &&
                    <TableRow>
                        <TableCell align="right">
                            <FormattedMessage {...messages.annualDataPrice}/>
                        </TableCell>
                        <TableCell>£{Number(annualDataPrice).toFixed(2)}</TableCell>
                    </TableRow>
                }
                {(limitedToGbPrice && area === "Polygon") &&
                    <TableRow>
                        <TableCell align="right" data-testid="price-cap-text">
                            <FormattedMessage {...messages.limitedToGbPrice}/>
                        </TableCell>
                        <TableCell data-testid="price-cap-value">
                            £{Number(limitedToGbPrice).toFixed(2)}
                        </TableCell>
                    </TableRow>
                }
                {userDiscount &&
                    <TableRow className={classes.row2}>
                        <TableCell align="right">
                            <FormattedMessage {...messages.userDiscount}/>
                        </TableCell>
                        <TableCell>-£{Number(userDiscount).toFixed(2)}</TableCell>
                    </TableRow>
                }
                {priceAfterDiscount &&
                    <TableRow>
                        <TableCell align="right">
                            <FormattedMessage {...messages.priceAfterDiscount}/>
                        </TableCell>
                        <TableCell>£{Number(priceAfterDiscount).toFixed(2)}</TableCell>
                    </TableRow>
                }
                {minContractCharge &&
                    <TableRow>
                        <TableCell align="right">
                            <FormattedMessage {...messages.minAnnualContractCharge}/>
                        </TableCell>
                        <TableCell>£{Number(minContractCharge).toFixed(2)}</TableCell>
                    </TableRow>
                }
                {(contractTerm && contractTermPrice) &&
                    <TableRow>
                        <TableCell align="right">
                            <FormattedMessage {...messages.multipliedContractTerm} values={{contractTerm:contractTerm}}/>
                        </TableCell>
                        <TableCell>£{Number(contractTermPrice).toFixed(2)}</TableCell>
                    </TableRow>
                }
                {invoicePrice &&
                    <TableRow>
                        <TableCell align="right">
                            <FormattedMessage {...messages.invoicePrice}/>
                        </TableCell>
                        <TableCell>£{Number(invoicePrice).toFixed(2)}</TableCell>
                    </TableRow>
                }
                {proratedYear &&
                    <TableRow>
                        <TableCell align="right">
                            <FormattedMessage {...messages.proratedForYear}/>
                        </TableCell>
                        <TableCell>£{Number(proratedYear).toFixed(2)}</TableCell>
                    </TableRow>
                }
            </TableBody>
        </Table>
        {annualPayment &&
            <Typography variant='body1'>
                <FormattedMessage {...messages.annualPaymentOptionWording}/>
            </Typography>
        }
    </Popover>;

    let priceContent = null;
    if (priceState.working) {
        priceContent = <CircularProgress size={20}/>;
    } else if (priceState.error) {
        let warningIcon = <WarningIcon color="secondary"/>;
        priceContent = <ClickAwayTooltip
            id="price_error"
            classes={{clickAwayTooltip: classes.clickaway}}
            icon={warningIcon}
            body={messages.priceError}
            ariaLabel={intl.formatMessage(messages.priceError)}
        />
    } else if (totalPrice) {
        priceContent = <>
            <Typography variant='h2' data-testid="price-text" color='primary' className={classes.price}>£{Number(totalPrice).toFixed(2)}<Typography variant="caption" component="span">ex.VAT</Typography></Typography>
            {(annualDataPrice || userDiscount || priceAfterDiscount || minContractCharge || contractTerm || invoicePrice || proratedYear) &&
                <IconButton
                    ref={infoRef}
                    className={classes.iconButton}
                    onClick={toggleSummaryWindow}
                    size="large">
                    <InfoIcon/>
                    <VisuallyHidden>Info Button</VisuallyHidden>
                </IconButton>
            }
        </>
    }

    return <FeatureCheck feature={features.EAI}>
        <Box className={classes.priceContainer} data-testid="price-panel">
            <Box>
                <Typography variant="h2">
                    <FormattedMessage {...messages.orderTotal} />:
                </Typography>
            </Box>
            <Box>
                { priceContent }
            </Box>
        </Box>
        {popover}
    </FeatureCheck>
}
