import BarGraph from "./BarGraph";
import {defineMessages} from "react-intl";
import React from "react";
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";
import {
    OPEN,
    PREMIUM_FREE,
    PREMIUM_CHARGEABLE,
    PSGA,
    PREMIUM_FREE_SECONDARY,
    INTERNAL,
    statsLabels
} from './barGraph/styles/graph.js';
import {USER_PSGA_PLAN} from "../../shared/plans";

const messages = defineMessages({
    countLabel: {
        id: 'OverallUserUsage.totalGraphLabel',
        defaultMessage: 'total transactions this month',
        description: 'Label for total graph label'
    }
});

function OverallUserUsage(props) {
    const {userStatsResult, keyOnly, className} = props;
    const userDetails = useSelector(state => state.user.current.result);

    let totalUsage = 0;
    let breakdown = [];
    if (userStatsResult && userStatsResult.timestamp) {

        const allDataSets = {
            open: {
                name: statsLabels.open,
                data: userStatsResult.open.transactions,
                class: OPEN
            },
            premiumFree: {
                name: statsLabels.premium.free,
                data: userStatsResult.premium.free.transactions,
                class: PREMIUM_FREE
            },
            premiumChargeable: {
                name: statsLabels.premium.chargeable,
                data: userStatsResult.premium.chargeable.transactions,
                class: PREMIUM_CHARGEABLE
            },
            psga: {
                name: statsLabels.psga,
                data: userStatsResult.psga.transactions,
                class: PSGA
            },
            internal: {
                name: statsLabels.internal,
                data: userStatsResult.internal.transactions,
                class: INTERNAL
            }
        }

        for (let dataType in allDataSets) {
            totalUsage += allDataSets[dataType].data;
            breakdown.push(allDataSets[dataType]);
        }

        if (userDetails.plan === USER_PSGA_PLAN) {
            allDataSets.premiumFree.class = PREMIUM_FREE_SECONDARY;
        }

        const graphData = [{total: totalUsage, breakdown: breakdown}];

        return (
            <BarGraph graphData={graphData}
                total={totalUsage}
                count={totalUsage}
                graphStyle={{height: 22, marginTop: 4}}
                countLabel={{...messages.countLabel}}
                displayCountLabel={true}
                displayCount={true}
                displayKey={true}
                keyOnly={keyOnly}
                className={className}
            />
        );
    }
    return null;
}

OverallUserUsage.propTypes = {
    userStatsResult: PropTypes.object,
    keyOnly: PropTypes.bool
};

export default OverallUserUsage;
