import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import routePaths from "../../util/routes";
import Link from "../../components/Link";
import { osColour } from "omse-components";
import teamSpacesListMessages from "./TeamSpacesList.msg";
import { ReactComponent as TeamSpaceIcon } from "../../components/icons/teamSpace.svg";
import { ReactComponent as TeamSpaceLockedIcon } from "../../components/icons/teamSpaceLocked.svg";
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
} from "@mui/material";
import { useCreatedByName } from "./util/useCreatedByName";

const ResultsInfo = styled(Box)`
    & .error {
        color: ${osColour.status.error};
    }
`;

const CustomTable = styled(Table)`
    width: 100%;

    & > thead > tr {
        border-bottom: 1px solid ${osColour.primary.berry};
    }

    & > thead > tr > th {
        color: ${osColour.primary.berry};
        font-weight: bold;
        border: none;
    }

    & > thead > tr > th.teamSpaceName {
        width: 35%;
    }

    & > thead > tr > th.teamSpaceAuthor {
        width: 25%;
    }

    & > thead > tr > th.teamSpaceDescription {
        width: 40%;
    }

    & > tbody > tr {
        border-bottom: 1px solid ${osColour.neutral.mist};
    }

    & > tbody > tr > td {
        font-weight: normal;
        color: ${osColour.neutral.charcoal};
        border-bottom: none;
    }

    & > tbody > tr > td:first-of-type {
        display: flex;
        align-items: center;
        gap: 1em;
    }

    & > tbody > tr > td:first-of-type > svg {
        flex-shrink: 0;
        width: 2.2em;
        height: 2em;
    }

    & > tbody > tr > td:last-of-type > span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @supports (-webkit-line-clamp: 2) {
            overflow: hidden;
            white-space: initial;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
`;

const TeamSpacesListDesktop = ({ results }) => {
    const intl = useIntl();
    const getCreatedByName = useCreatedByName();
    return (
        <TableContainer component={Box}>
            <ResultsInfo data-testid="results-total">
                {results.length ? (
                    <Typography variant="body1" role="alert">
                        {intl.formatMessage(teamSpacesListMessages.searchHasResults, {
                            total: results.length,
                            isPlural: results.length === 1 ? "" : "s",
                        })}
                    </Typography>
                ) : (
                    <Typography role="alert" variant="body1" className="error">
                        {intl.formatMessage(teamSpacesListMessages.searchHasNoResults)}
                    </Typography>
                )}
            </ResultsInfo>
            <CustomTable>
                <TableHead>
                    <TableRow>
                        <TableCell className="teamSpaceName">
                            {intl.formatMessage(teamSpacesListMessages.name)}
                        </TableCell>
                        <TableCell className="teamSpaceAuthor">
                            {intl.formatMessage(teamSpacesListMessages.createdBy)}
                        </TableCell>
                        <TableCell className="teamSpaceDescription">
                            {intl.formatMessage(teamSpacesListMessages.description)}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody data-testid="teamspaces-results">
                    {results?.map(
                        (result) =>
                            result && (
                                <TableRow key={result.id}>
                                    <TableCell>
                                        {result.locked ? (
                                            <TeamSpaceLockedIcon
                                                aria-label={intl.formatMessage(
                                                    teamSpacesListMessages.lockedAria
                                                )}
                                            />
                                        ) : (
                                            <TeamSpaceIcon
                                                aria-label={intl.formatMessage(
                                                    teamSpacesListMessages.unlockedAria
                                                )}
                                            />
                                        )}
                                        <Link
                                            path={routePaths.teamSpace.replace(
                                                ":teamSpaceId",
                                                result.id
                                            )}
                                            state={{
                                                teamSpace: {
                                                    name: result.name,
                                                    locked: result.locked,
                                                    description: result.description,
                                                    createdByName: getCreatedByName(result)
                                                },
                                            }}
                                        >
                                            {result.name}
                                        </Link>
                                    </TableCell>
                                    <TableCell>
                                        {getCreatedByName(result)}
                                    </TableCell>
                                    <TableCell>
                                        <span>{result.description}</span>
                                    </TableCell>
                                </TableRow>
                            )
                    )}
                </TableBody>
            </CustomTable>
        </TableContainer>
    );
};

TeamSpacesListDesktop.propTypes = {
    results: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            locked: PropTypes.bool,
            description: PropTypes.string,
            createdByName: PropTypes.string,
        })
    ),
};

export default TeamSpacesListDesktop;
