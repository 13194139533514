import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {defineMessages, FormattedMessage} from "react-intl";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";

const messages = defineMessages({
    title: {
        id: "DelCancelApplicationDialog.title",
        defaultMessage: "Cancel DEL Application",
        description: "Title for DelCancelApplicationDialog"
    },
    confirmationMessage: {
        id: "DelCancelApplicationDialog.confirmationMessage",
        defaultMessage: "Are you sure you want to cancel applying for the Data Exploration Licence? You will lose all progress.",
        description: "Confirmation message for DelCancelApplicationDialog"
    },
    cancelButton: {
        id: "DelCancelApplicationDialog.cancelButton",
        defaultMessage: "Continue application",
        description: "Cancel button for DelCancelApplicationDialog"
    },
    confirmButton: {
        id: "DelCancelApplicationDialog.confirmButton",
        defaultMessage: "Cancel application",
        description: "Confirm button for DelCancelApplicationDialog"
    }
});

function DelCancelApplicationDialog({setShowCancelDialog, open}) {
    const history = useHistory();

    const handleCancel = () => {
        setShowCancelDialog(false);
    };

    const handleConfirm = () => {
        history.push('/');
    };

    return (
        <Dialog open={open}>
            <DialogTitle>
                <FormattedMessage {...messages.title} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormattedMessage {...messages.confirmationMessage} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCancel}>
                    <FormattedMessage {...messages.cancelButton} />
                </Button>
                <Button variant="contained" color="primary" onClick={handleConfirm}>
                    <FormattedMessage {...messages.confirmButton} />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DelCancelApplicationDialog;