import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { osColour } from 'omse-components';
import { ReactComponent as ArrowBack } from "../../../components/icons/backward-arrow-large.svg";
import { ReactComponent as CloseIcon } from "../../../components/icons/close-large.svg";
import featureCheck from "../../../util/featureCheck";
import { closeMenu } from "../../../modules/menu/actions";
import IconButton from '@mui/material/IconButton';
import messages from '../MenuMessages';
import OrganisationSelect from '../OrganisationSelect';
import { canShowToUser, getAlignFillStyle } from '../../../util/menu';
import { getMainMenu } from './data/app-menu';
import { getAccountMenu } from './data/account-menu';
import { ClickAwayListener } from '@mui/material';
import { WORKSPACE } from '../../../../shared/features';
import WorkspaceSwitch from '../WorkspaceMobileSwitch';
import styled from '@emotion/styled';
import NavigationItem from './NavigationItem';
import { hasShowWorkspacePermission } from '../../../util/permissions';

const StyledContainer = styled('div', { shouldForwardProp: prop => prop !== 'isAccountVariant' })(({ theme, isAccountVariant }) => `
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: ${isAccountVariant ? 11 : 3};
    overflow-x: hidden;
    overflow-y: auto;
    background-color: ${osColour.neutral.clouds};
    flex: 1 0;
    top: 0;
    right: ${isAccountVariant ? 0 : 'initial'};
    @media (min-width: 400px) {
        max-width: 400px;  
        box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 20px 5px;
    }
    & nav {
        padding-top: ${theme.spacing(1.5)};
        padding-bottom: 6rem;
        flex: 1 0;
        & > ul, & ul ul {
            padding: 0;
            margin: 0;
        }
    }
    & .closeIcon {
        color: ${osColour.neutral.stone};
        cursor: pointer;
        padding: 10px;
        top: ${theme.spacing(1)};
        right: 10px;
        z-index: 11;
        position: ${isAccountVariant ? 'absolute' : 'fixed'};
    }
    & .backItem {
        cursor: pointer;
        padding: ${theme.spacing(2)};
        width: auto;
        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
          cursor: pointer;
        }
        & div {
            padding-left: 5px;
        }
    }
`);

const containsPath = (path, subPath) => {
    return subPath && subPath.length > 1 && path.indexOf(subPath) === 0;
}

export const Navigation = ({ headerRef, variant }) => {
    const isAccountVariant = variant === 'account';
    const location = useLocation();
    const dispatch = useDispatch();
    const intl = useIntl();

    const [backState, setBackState] = useState([]);
    const [menuState, setMenuState] = useState({ items: [] });

    const userDetails = useSelector(state => state.user.current.result);
    const loggedIn = Boolean(userDetails && userDetails.active);
    const menu = useSelector(state => state.menu);
    const open = Boolean(menu && (isAccountVariant ? menu.account : menu.nav));
    const orgId = useSelector(state => state.organisation.current?.id);
    const config = useSelector(state => state.config.current?.result);

    const initMenu = useCallback((path) => {
        const menu = isAccountVariant ? getAccountMenu(userDetails, config) : getMainMenu(userDetails, config);

        setMenuState(menu);

        let temp = [];
        let back = [];
        let current = menu;

        // Search for a matching route in menu
        const findEntry = (item) => {

            for (let entry of Object.entries(item)) {

                let k = entry[0];
                let v = entry[1];

                if (typeof v === 'object' && (!v.userCheck || v.userCheck(userDetails))) { 

                    // Route matches path or an inline route matches
                    if ((k === 'route' && (v.path.toLowerCase() === path || (item.partialMatch && containsPath(path, v.path)))) ||
                        (k === 'inline' && v.some(i => i.route.path.toLowerCase() === path || containsPath(path, i.route.path)))) {
                        current = temp.pop();
                        back = [...temp];
                        break;
                    }

                    // Iterate through every entry value until found
                    if (back.length === 0 && (Number.isInteger(parseInt(k)) || k === 'items')) {
                        // Skip composite items to prioritize matching with original submenus.
                        if(item?.composite) {
                            break;
                        }
                        // If key is further menu items, push item to temp store
                        if (k === 'items') {
                            temp.push(item);
                        }

                        findEntry(v);

                        if (k === 'items') {
                            temp.pop();
                        }
                    }
                }
            }
        }
        findEntry(current);
        setBackState(back);
        setMenuState(current);
    }, [config, isAccountVariant, userDetails]);

    useEffect(() => {
        if (open) {
            const locationPathname = location.pathname.toLowerCase();
            initMenu(locationPathname);
        }
    }, [open, initMenu, location.pathname]);

    const filter = item => {
        // If it's not a feature then it's fine to show or else you have to check.
        const canShowFeature = !item.feature || featureCheck(item.feature, config);
        if (!canShowFeature || item.hidden) {
            return false;
        }

        if (!loggedIn) {
            // If the user is not logged in you have to check whether the route is public.
            const canShowToPublic = (item.route && item.route.public) || item.public;
            return canShowToPublic;
        } else {
            // If we are logged in, we can show it most of the time, but we might need a quick check
            return canShowToUser(item, userDetails, orgId);
        }
    };

    const back = () => {
        // Pop item from back state to menu state
        if (backState.length > 0) {
            let newMenu = backState.slice(backState.length - 1);
            setMenuState(newMenu[0]);
            setBackState(backState.slice(0, -1));
        }
    }

    const close = event => {
        dispatch(closeMenu());
        if (event) {
            event.preventDefault();
        }
    }

    useEffect(() => {
        if (!open) {
            setMenuState({ items: [] });
        }
    }, [open])
    const menuInitalised = menuState?.items.length > 0;
    return (open &&
        <ClickAwayListener onClickAway={close}>
            <StyledContainer isAccountVariant={isAccountVariant} style={isAccountVariant ? {} : getAlignFillStyle(headerRef)}>
                {isAccountVariant &&
                    <IconButton
                        onClick={close}
                        className='closeIcon'
                        aria-label="close menu"
                        size="large">
                        <CloseIcon height={24} width={24} />
                    </IconButton>
                }
                {menuInitalised &&
                    <div role='complementary' aria-label={intl.formatMessage(messages.label)}>
                        <List className='navList' component='nav' aria-label={(isAccountVariant ? 'account' : 'navigation') + ' menu'}>

                            {!isAccountVariant &&
                                <OrganisationSelect variant='list' onSelect={close} />
                            }
                            <ul>
                                {backState.length > 0 &&
                                    <ListItem className='backItem' onClick={back} id={messages.back.id} role='button'>
                                        <ArrowBack height={20} width={20} />
                                        <ListItemText primaryTypographyProps={{ variant: 'h3', component: 'span' }}>
                                            <FormattedMessage {...messages.back} />
                                        </ListItemText>
                                    </ListItem>
                                }
                                {menuState?.items?.filter(filter).map((item, i) => (
                                    <NavigationItem
                                        item={item}
                                        key={'NavigationItem-' + item.label?.id}
                                        menuState={menuState}
                                        backState={backState}
                                        setMenuState={setMenuState}
                                        setBackState={setBackState}
                                        filter={filter}
                                    />
                                ))}
                            </ul>

                            {
                                featureCheck(WORKSPACE, config) && 
                                hasShowWorkspacePermission(userDetails) && (
                                    <WorkspaceSwitch />
                                )
                            }
                        </List>
                    </div>
                }
            </StyledContainer>
        </ClickAwayListener>
    );
}

Navigation.propTypes = {
    variant: PropTypes.oneOf(['app', 'account'])
};

Navigation.defaultProps = {
    variant: 'app'
};

export default Navigation;