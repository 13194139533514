import { defineMessages } from "react-intl";

const teamSpacesErrorMessages = defineMessages({
    nameFieldRequired: {
        id: "teamSpacesErrorMessages.nameFieldRequired",
        description: "User interaction error > nameFieldRequired",
        defaultMessage: "Name is required.",
    },
    nameFieldLengthInvalid: {
        id: "teamSpacesErrorMessages.nameFieldLengthInvalid",
        description: "User interaction error > nameFieldLengthInvalid",
        defaultMessage: "Name must be between 1 and 255 characters.",
    },
    descriptionFieldLengthInvalid: {
        id: "teamSpacesErrorMessages.descriptionFieldLengthInvalid",
        description: "User interaction error > descriptionFieldLengthInvalid",
        defaultMessage: "Description must be less than 255 characters.",
    },
    invalidRole: {
        id: "teamSpacesErrorMessages.invalidRole",
        description: "User interaction error > invalidRole",
        defaultMessage: "Invalid user role. Please contact your organisation administrator.",
    },
    invalidPermission: {
        id: "teamSpacesErrorMessages.invalidPermission",
        description: "User interaction error > invalidPermission",
        defaultMessage: "Permission assignment error.",
    },
});

export default teamSpacesErrorMessages;
