import { useCallback } from "react";
import PropTypes from "prop-types";
const { Typography, Input } = require("@mui/material");
const { FormattedMessage, useIntl } = require("react-intl");
import messages from "./DeleteTeamSpaceDialog.msg";
import styled from "@emotion/styled";

const Container = styled.div(({ theme }) => `
    display: flex;
    flex-direction: column;
    margin-top: ${theme.spacing(3)};
    align-items: center;

    & > div:last-of-type {
        margin-top: ${theme.spacing(1)};
        max-width: 160px;
    }
    & input {
        font-size: ${theme.typography.h2.fontSize};
        line-height: ${theme.typography.h2.lineHeight};
        text-align: center;
    }
    
    &[aria-disabled="true"] {
        opacity: 0.7;
    }
`
);

const ConfirmationCodePrompt = ({
    code,
    inputRef,
    inputtedCode,
    setInputtedCode,
    handleKey,
    disabled,
}) => {
    const intl = useIntl();
    const generateLabel = useCallback(() => {
        return intl.formatMessage(messages.ariaLabel, { code });
    }, [intl, code]);

    const handleChange = useCallback(
        (event) => {
            let inputtedCode = event.target.value;
            // Strip non-numeric characters out of the string
            inputtedCode = inputtedCode
                .split("")
                .filter((char) => char >= "0" && char <= "9")
                .join("");

            setInputtedCode(inputtedCode);
        },
        [setInputtedCode]
    );

    return (
        <Container {...(disabled ? { ["aria-disabled"]: true } : {})}>
            <Typography variant="body2">
                <FormattedMessage {...messages.confirmationCode} />
            </Typography>
            <Typography variant="h2" color="primary" component="div" paragraph={true}>
                {disabled ? <>&nbsp;</> : code}
            </Typography>
            <Typography variant={"body2"}>
                <FormattedMessage {...messages.confirmationLabel} />
            </Typography>
            <Input
                disabled={disabled}
                onChange={handleChange}
                onKeyDown={handleKey}
                type="text"
                disableUnderline={true}
                value={disabled ? "" : inputtedCode}
                inputRef={inputRef}
                inputProps={{
                    "aria-label": disabled
                        ? intl.formatMessage(messages.disabledCodeInput)
                        : generateLabel(),
                    minLength: "4",
                    maxLength: "4",
                }}
            />
        </Container>
    );
};

export default ConfirmationCodePrompt;

ConfirmationCodePrompt.propTypes = {
    code: PropTypes.string,
    handleKey: PropTypes.func.isRequired,
    setInputtedCode: PropTypes.func.isRequired,
    inputtedCode: PropTypes.string,
    inputRef: PropTypes.object,
    disabled: PropTypes.bool,
};
