import React, {Fragment} from 'react';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import {DropDownMenu, border1, osColour} from 'omse-components';
import {defineMessages} from 'react-intl';
import PropTypes from 'prop-types';
import {addProductToProject, openNewProjectDialog} from '../../modules/project/actions';
import ProductDocumentation from '../../components/ProductDocumentation';
import ServiceTag from "../../components/ServiceTag";
import {hasManageProjectsPermission} from "../../util/permissions";
import useProjectsLimit from '../../hooks/useProjectsLimit';
import {useDispatch, useSelector} from "react-redux";
import {createUseStyles} from 'react-jss';
import ProductFreeTrialMessage from './ProductFreeTrialMessage';
import ProductFreeTrialButton from './ProductFreeTrialButton';
import ProductFreeTrialHoverTooltip from './ProductFreeTrialHoverTooltip';
import {canUseProduct} from '../../../shared/product-util';
import {ACTIVE} from '../../../shared/free-trial-state';
import ProductUnlockSteps from './ProductUnlockSteps';
import {withTermsActionCheck} from '../../util/terms';
import {apiTermsName, COST_TYPE_MIXED, COST_TYPE_PREMIUM} from '../../../shared/constants';
import {DEV_MODE} from "../../../shared/project-modes";
import {isEaiUser} from "../../util/plans";
import {Box} from "@mui/material";

const messages = defineMessages({
    addNewProject: {
        id: 'Product.AddProject',
        defaultMessage: 'Add to NEW PROJECT',
        description: 'Add to NEW PROJECT'
    }
});

const useStyles = createUseStyles(theme => ({
    root: {
        padding: theme.spacing(1),
        marginTop: theme.spacing(1),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        borderBottom: border1,
        '&:last-of-type': {
            border: 0
        },
        [theme.breakpoints.down('sm')]: {
            padding: '24px 0',
            margin: 0
        }
    },
    listHeaderContainer: {
        width:'100%',
        [theme.breakpoints.up('sm')]: {
            display:'flex'
        }
    },
    listHeader: {
        width: '100%'
    },
    listHeader1: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse'
        }
    },
    listHeaderInfo: {
        display: 'flex',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            alignItems: 'flex-start',
            flexDirection: 'column'
        }
    },
    productDoc: {
        marginRight: theme.spacing(1), // Prevents free trial message getting too close on small screens.
        marginBottom: theme.spacing(1)
    },
    freeTrialMessage: {
        marginLeft: 'auto',
        marginBottom: theme.spacing(1),
        textAlign: 'right',
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            textAlign: 'left'
        }
    },
    productTitle: {
        marginBottom: theme.spacing(1),
        marginRight: theme.spacing(1.5),
        flex:'1 0 auto',
        [theme.breakpoints.down('md')]: {
            flex: '0 0 auto'
        }
    },
    productDetails: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        flex: '1 1 auto'
    },
    actionsContainer: {
        flex: '0 0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        [theme.breakpoints.up('sm')]: {
            marginLeft: 0
        },
        [theme.breakpoints.down('md')]: {
            marginTop: theme.spacing(2)
        }
    },
    freeTrialButton: {
        marginBottom: theme.spacing(2)
    },
    listContent: {
        marginBottom: theme.spacing(4),
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0
        }
    },
    listContentFirstRow: {
        [theme.breakpoints.up('md')]: {
            display: 'flex',
            alignItems: 'center'
        },
    },
    productUnlockSteps: {
        marginTop: `${theme.spacing(1)} !important`
    },
    productDescription: {
        marginRight: theme.spacing(4),
        flex: '1 1 auto'
    },
    serviceTag: {
        marginLeft: theme.spacing(2)
    },
    tags: {
        flex: '0 0 auto',
        '& div:first-child': {
            marginLeft: 0
        }
    },
    errorMessages: {
        marginTop: theme.spacing(2),
        color: osColour.status.error
    }
}));

export default function Product({product, allowAddToProject, projectDialogId}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    
    const projects = useSelector(state => state.projects.current.result);
    const user = useSelector(state => state.user.current.result);
    const org = useSelector(state => state.organisation.current);
    const [,initialising] = useProjectsLimit();
    const productIsPremiumOnly =  product.costType === COST_TYPE_PREMIUM
    const isEaiUserAndPremiumProduct = isEaiUser(user) && productIsPremiumOnly;
    const isEaiUserAndMixedProduct = isEaiUser(user) && product.costType === COST_TYPE_MIXED;

    let items = [];
    if (projects && projects.length > 0) {
        if (isEaiUserAndPremiumProduct) {
            items =  projects.filter(project => project.mode === DEV_MODE)
                .map(project => ({ label: project.name,
                    id: project.projectId,
                    action: () => dispatch(withTermsActionCheck(apiTermsName, addProductToProject(product, project), user, org))
                }))

        } else {
            items = projects.map(project => ({
                label: project.name,
                id: project.projectId,
                action: () => dispatch(withTermsActionCheck(apiTermsName, addProductToProject(product, project), user, org))
            }));
        }
    }

    if (!initialising) {
        if (!isEaiUser(user) || !productIsPremiumOnly) {
            items.splice(0, 0, {
                label: messages.addNewProject,
                action: () => dispatch(withTermsActionCheck(apiTermsName, openNewProjectDialog(projectDialogId, product.name), user, org))
            });
        }
    }

    return (<ListItem classes={{root: classes.root}}>
        <div className={classes.listHeaderContainer}>
            <div className={classes.listHeader}>
                <div className={classes.listHeader1}>
                    <Typography variant='subtitle1' component='h2'
                                className={classes.productTitle}>{product.displayName}</Typography>
                    <div className={classes.tags}>
                        {product.serviceType && <ServiceTag classes={{serviceTag: classes.serviceTag}} label={product.serviceType}/>}
                        {product.subServiceType && <ServiceTag classes={{serviceTag: classes.serviceTag}} label={product.subServiceType}/>}
                    </div>
                </div>
                <div className={classes.listHeaderInfo}>
                    <div className={classes.productDoc}>
                        <ProductDocumentation productName={product.displayName} productServiceType={product.serviceType} productSubServiceType={product.subServiceType}/>
                    </div>
                    <ProductFreeTrialMessage className={classes.freeTrialMessage} product={product} />
                </div>
            </div>
        </div>
        <div className={classes.listContent}>
            <div className={classes.listContentFirstRow}>
                <Typography variant='body1' className={classes.productDescription}>{product.description}</Typography>
                {(allowAddToProject && hasManageProjectsPermission(user)) && 
                    <div className={classes.actionsContainer}>
                        <ProductFreeTrialButton className={classes.freeTrialButton} product={product} />
                        <ProductFreeTrialHoverTooltip product={product}>
                            <DropDownMenu items={items}
                                disabled={!canUseProduct(product)}
                                buttonVariant='contained'
                                buttonColor={product.freeTrialState === ACTIVE ? 'secondary' : 'primary'}
                                buttonLabel='Add to API project'
                                staticButtonText
                                variant='block'
                                strategy='fixed'
                                placement='bottom-end'
                            />
                        </ProductFreeTrialHoverTooltip>
                    </div>
                }
            </div>
            <ProductUnlockSteps className={classes.productUnlockSteps} product={product} />
            <Box className={classes.errorMessages}>
                {isEaiUserAndMixedProduct &&
                    <Fragment>
                        <Typography variant="caption">This product contains open and premium data. </Typography>
                        <br/>
                        <Typography variant="caption">You have no API payment plan, so premium data transactions
                            will not work in Live mode projects. Please contact your account manager for further details on
                            how to get access to premium data.</Typography>
                    </Fragment>}
                {isEaiUserAndPremiumProduct &&
                    <Fragment>
                        <Typography variant="caption">This product contains premium data only. </Typography>
                        <br/>
                        <Typography variant="caption">You have no API payment plan, so premium data transactions
                            will not work in Live mode projects. Please contact your account manager for further details on
                            how to get access to premium data. </Typography>
                    </Fragment>}
            </Box>
        </div>
    </ListItem>);
}

Product.propTypes = {
    product: PropTypes.object,
    allowAddToProject: PropTypes.bool,
    projectDialogId: PropTypes.string.isRequired
};

Product.defaultProps = {
    allowAddToProject: true
}