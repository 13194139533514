import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Tab } from "@mui/material";
import { useIntl } from "react-intl";
import { LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import OwnersTab from "../shared/OwnersTab";
import MembersTab from "../shared/MembersTab";
import teamSpaceAccessMessages from "./TeamSpaceAccess.msg";
import { ownersTab, membersTab, dialogVariants, teamSpacePanels } from "../shared/constants";
import { StyledTabs } from "../shared/StyledTabs";

export default function TeamSpaceAccess(p) {
    const intl = useIntl();
    const [currentTab, setCurrentTab] = useState(ownersTab);

    useEffect(() => {
        p.setDisableNextAction(p.newTeamSpaceParameters.name?.trim().length === 0);
        return () => p.setDisableNextAction(false);
        // eslint-disable-next-line
    }, []);

    return (
        <Box data-testid={teamSpacePanels.access}>
            <StyledTabs
                classes={{ indicator: "indicator" }}
                value={currentTab}
                onChange={(_, newValue) => setCurrentTab(newValue)}
            >
                <Tab
                    value={ownersTab}
                    label={intl.formatMessage(teamSpaceAccessMessages.ownersTab)}
                    key={ownersTab}
                />
                <Tab
                    value={membersTab}
                    label={
                        <>
                            {intl.formatMessage(teamSpaceAccessMessages.membersTab)}
                            {p.newTeamSpaceParameters.locked ? (
                                <LockOutlined
                                    className="teamLocked"
                                    aria-label={intl.formatMessage(
                                        teamSpaceAccessMessages.membersTabLockedAria
                                    )}
                                />
                            ) : (
                                <LockOpenOutlined
                                    className="teamUnlocked"
                                    aria-label={intl.formatMessage(
                                        teamSpaceAccessMessages.membersTabUnlockedAria
                                    )}
                                />
                            )}
                        </>
                    }
                    key={membersTab}
                />
            </StyledTabs>
            {currentTab === ownersTab && (
                <OwnersTab
                    teamSpaceParameters={p.newTeamSpaceParameters}
                    owners={p.owners}
                    setOwners={p.setOwners}
                    selectedUserIds={p.selectedUserIds}
                    working={p.working}
                    variant={dialogVariants.newTeamSpace}
                />
            )}
            {currentTab === membersTab && (
                <MembersTab
                    teamSpaceParameters={p.newTeamSpaceParameters}
                    setTeamSpaceParameters={p.setNewTeamSpaceParameters}
                    members={p.members}
                    setMembers={p.setMembers}
                    selectedUserIds={p.selectedUserIds}
                    variant={dialogVariants.newTeamSpace}
                />
            )}
        </Box>
    );
}

TeamSpaceAccess.propTypes = {
    newTeamSpaceParameters: PropTypes.object.isRequired,
    setNewTeamSpaceParameters: PropTypes.func.isRequired,
    setDisableNextAction: PropTypes.func.isRequired,
};
