import PropTypes from 'prop-types';
import BarGraph from "../../../components/BarGraph";
import { USER_PREMIUM_DATA_PLAN, USER_PSGA_PLAN } from "../../../../shared/plans";
import {
    OPEN,
    PREMIUM_FREE,
    PREMIUM_CHARGEABLE,
    statsLabels,
    statsMessages,
    PSGA,
    PREMIUM_FREE_SECONDARY,
    INTERNAL
} from '../../../components/barGraph/styles/graph.js';
import { useSelector } from "react-redux";
import  {css} from '@emotion/css';
import { useTheme } from '@emotion/react';

const useGraphStyle = theme => css`
    height: ${theme.spacing(1.5)};
    margin-top: ${theme.spacing(0.5)};
`;

const ProjectStats = (props) => {
    const { userStatsResult, project, vatRate } = props;
    const userDetails = useSelector(state => state.user.current.result);

    const theme = useTheme();
    const graphClass = useGraphStyle(theme);

    if (!userStatsResult || !userStatsResult.timestamp) {
        return null;
    }

    let totalUserUsage =
        userStatsResult.open.transactions +
        userStatsResult.premium.free.transactions +
        userStatsResult.premium.chargeable.transactions +
        userStatsResult.psga.transactions +
        userStatsResult.internal.transactions;

    let projectStats = userStatsResult.projects && userStatsResult.projects[project.projectId];

    if (!projectStats) {
        projectStats = { open: 0, psga: 0, internal: 0, premium: { total: { transactions: 0, price: 0 }, free: 0, chargeable: 0 }};
    }

    let totalUsage = 0;
    let totalCost;
    if (vatRate && !isNaN(vatRate)) {
        totalCost = projectStats.premium.total.price * (1 + vatRate);
    }
    let breakdown = [];

    const allDataSets = {
        open: {
            name: statsLabels.open,
            data: projectStats.open,
            class: OPEN
        },
        premiumFree: {
            name: statsLabels.premium.free,
            data: projectStats.premium.free,
            class: PREMIUM_FREE
        },
        premiumChargeable: {
            name: statsLabels.premium.chargeable,
            data: projectStats.premium.chargeable,
            class: PREMIUM_CHARGEABLE
        },
        psga: {
            name: statsLabels.psga,
            data: projectStats.psga,
            class: PSGA
        },
        internal: {
            name: statsLabels.internal,
            data: projectStats.internal,
            class: INTERNAL
        }
    }

    for (let dataType in allDataSets) {
        totalUsage += allDataSets[dataType].data;
        breakdown.push(allDataSets[dataType]);
    }

    if (userDetails.plan === USER_PSGA_PLAN) {
        allDataSets.premiumFree.class = PREMIUM_FREE_SECONDARY;
    }

    const graphData = [{ total: totalUsage, breakdown: breakdown }];

    return (
        <BarGraph graphData={graphData}
            total={totalUserUsage}
            count={totalUsage}
            classes={{ graph: graphClass }}
            countLabel={{ ...statsMessages.countLabel }}
            costInPence={totalCost}
            costLabel={{ ...statsMessages.costLabel }}
            graphStyle={{ height: 14, marginTop: 4 }}
            graphContainerStyle={{ marginBottom: 16 }}
            variant={'small'}
            displayCount={true}
            displayCountLabel={true}
            displayCost={userDetails.plan === USER_PREMIUM_DATA_PLAN} />
    );

};

ProjectStats.propTypes = {
    project: PropTypes.object.isRequired,
    classes: PropTypes.object,
    userStatsResult: PropTypes.object,
    vatRate: PropTypes.number
};

export default ProjectStats;
