import React from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { contentPadding, osColour, border1 } from "omse-components";

const HeaderContainer = styled("header")(
    ({ theme }) => `
    & > div {
        padding-top: ${contentPadding.top}px;
        padding-left: ${contentPadding.left}px;
        padding-right: ${contentPadding.right}px;
        padding-bottom: ${contentPadding.bottom/4}px;
        max-width: ${contentPadding.maxWidth}px;

        display: flex;
        gap: 1em;
        flex-wrap: wrap;
        align-items: center;

        ${theme.breakpoints.up("lg")} {
            /* INTERIM UNTIL TEAM SPACES IS
                LOCATED IN THE SECONDARY NAV */
            margin: 0 auto;
            min-width: ${contentPadding.maxWidth}px;
        }

        ${theme.breakpoints.down("sm")} {
            flex-direction: column;
            align-items: stretch;
            padding: ${contentPadding.mobile};
        }
    }

    & > div > h1 {
        color: ${osColour.primary.berry};
        flex-grow: 1;
    }

`
);

const Header = (p) => {
    const { children } = p;
    return (
        <HeaderContainer>
            <div>{children}</div>
        </HeaderContainer>
    );
};

Header.propTypes = {
    children: PropTypes.node,
};

export default Header;
