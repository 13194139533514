import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

function useDelTerms() {
    const history = useHistory();
    const user = useSelector(state => state.user.current.result);

    return {
        history: history,
        isLoggedIn: !!user
    }
}

export default useDelTerms;