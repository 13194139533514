import {defineMessages} from 'react-intl';
import {USER_ROLE, ADMIN_ROLE, FINANCE_ROLE, CONTRACTOR_ROLE, CONTRACTOR_READ_ONLY_ROLE, OS_INTERNAL_ROLE} from '../../shared/roles';

const nameMatcher = /^[\w-.#$% ]+$/;
const phoneMatcher = /^[\d-+() ]+$/;
export const emailMatcher = /^[A-Z0-9.!#$%&'^_`{}~-]+@[A-Z0-9-]+(?:\.[A-Z0-9-]+)*$/i;

export const VALID_ROLES = [ADMIN_ROLE, FINANCE_ROLE, USER_ROLE];
export const PSGA_ROLES = [ADMIN_ROLE, USER_ROLE, CONTRACTOR_ROLE, CONTRACTOR_READ_ONLY_ROLE];
export const INTERNAL_ROLES = [ADMIN_ROLE, USER_ROLE];

export const messages = defineMessages({
    jobTitleValidationError: {
        id: 'jobTitleValidationError',
        defaultMessage: "The job title should consist of letters, numbers and these special characters ._#-$%",
        description: 'Message shown when the job title is invalid'
    },
    jobTitleLengthError: {
        id: 'jobTitleLengthError',
        defaultMessage: "The job title should be at most 100 characters long",
        description: 'Message shown when the job title is too long'
    },
    phoneValidationError: {
        id: 'phoneValidationError',
        defaultMessage: "The phone number should consist of numbers and these special characters +-()",
        description: 'Message shown when the phone number is invalid'
    },
    phoneLengthError: {
        id: 'phoneLengthError',
        defaultMessage: "The phone number should be at most 30 characters long",
        description: 'Message shown when the phone number is too long'
    },
    firstNameLengthError: {
        id: 'firstNameLengthError',
        defaultMessage: "First Name should be at most 25 characters long",
        description: 'Message shown when the first name is too long'
    },
    lastNameLengthError: {
        id: 'lastNameLengthError',
        defaultMessage: "Last Name should be at most 50 characters long",
        description: 'Message shown when the last name is too long'
    },
    emailValidationError: {
        id: 'emailValidationError',
        defaultMessage: "The email address is not valid. Please use the format: example@domain.co.uk",
        description: 'Message shown when the email address is invalid'
    },
    emailIsNotOS: {
        id: 'emailIsNotOS',
        defaultMessage: "You may only invite OS email addresses to join your organisation",
        description: 'Message shown if an internal user tries to invite an external user to join an internal org'
    },
});


// Please ensure that all of these email domains are in lowercase!
const internalDomains = [
    '@os.uk',
    '@os.co.uk',
    '@ordnancesurvey.co.uk',
    '@ordnancesurvey.mail.onmicrosoft.com',
    '@ordnancesurvey.onmicrosoft.com',
    '@ordsvy.gov.uk'
]

export function isOSEmail(email) {
    const lowerEmail = email.toLowerCase();
    return internalDomains.find(domain => lowerEmail.endsWith(domain));
}

const user = {
    messages,
    nameMatcher,
    phoneMatcher
};
export default user;