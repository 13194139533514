import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { osColour } from "omse-components";
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import teamSpacesRolesTableMessages from "./TeamSpacesRolesTable.msg";
import teamSpacesRolesPermissions from "../data/team-spaces-roles";

const CustomTable = styled(Table)`
    width: 100%;
    margin-bottom: 2em;

    & > thead > tr,
    & > tbody > tr {
        display: grid;
        grid-template-columns: 2fr repeat(5, minmax(0, 0.5fr));
        gap: 1em;
    }

    & > thead > tr {
        border-bottom: 2px solid ${osColour.primary.berry};
        align-items: end;
    }

    & > thead > tr > th {
        color: ${osColour.primary.berry};
        font-weight: bold;
        border: none;
        text-align: center;
    }

    & > thead > tr > th:first-of-type,
    & > tbody > tr > thead > th {
        text-align: left;
        border-bottom: none;
    }

    & > tbody > tr {
        border-bottom: 1px solid ${osColour.neutral.mist};
        align-items: center;
    }

    & > tbody > tr > th {
        font-weight: normal;
        color: ${osColour.neutral.charcoal};
        border-bottom: none;
    }

    & > tbody > tr > td {
        font-weight: normal;
        color: ${osColour.neutral.charcoal};
        border-bottom: none;
        text-align: center;
    }

    @media (max-width: 768px) {
        & > thead > tr,
        & > tbody > tr {
            grid-template-columns: 0.8fr repeat(5, 0.5fr);
        }
    }
`;

const CustomTableCell = styled(TableCell)`
    padding: 0.2em;
    white-space: normal;
    @media (max-width: 768px) {
        min-width: 100%;
        font-size: 0.8em;
    }
`;

export default function TeamSpacesRolesTable() {
    const intl = useIntl();

    return (
        <TableContainer component={Box}>
            <CustomTable role="table" aria-label="Team Spaces Roles Permissions">
                <TableHead>
                    <TableRow>
                        <CustomTableCell>
                            {intl.formatMessage(teamSpacesRolesTableMessages.permissions)}
                        </CustomTableCell>
                        <CustomTableCell>
                            {intl.formatMessage(teamSpacesRolesTableMessages.dataHubAdmin)}
                        </CustomTableCell>
                        <CustomTableCell>
                            {intl.formatMessage(teamSpacesRolesTableMessages.financeUser)}
                        </CustomTableCell>
                        <CustomTableCell>
                            {intl.formatMessage(teamSpacesRolesTableMessages.user)}
                        </CustomTableCell>
                        <CustomTableCell>
                            {intl.formatMessage(teamSpacesRolesTableMessages.contractorReadWrite)}
                        </CustomTableCell>
                        <CustomTableCell>
                            {intl.formatMessage(teamSpacesRolesTableMessages.contractorReadOnly)}
                        </CustomTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <CustomTableCell component="th">
                            {intl.formatMessage(teamSpacesRolesTableMessages.owner)}
                        </CustomTableCell>
                        {teamSpacesRolesPermissions
                            .filter((role) => role.id === "role:owner")
                            .map((role) =>
                                Object.keys(role)
                                    .filter((k) => k !== "id")
                                    .map((k) => (
                                        <CustomTableCell key={k}>
                                            {role[k] ? (
                                                <CheckIcon
                                                    role="img"
                                                    aria-hidden="false"
                                                    aria-label={intl.formatMessage(
                                                        teamSpacesRolesTableMessages.checkAria
                                                    )}
                                                />
                                            ) : (
                                                <ClearIcon
                                                    role="img"
                                                    aria-hidden="false"
                                                    aria-label={intl.formatMessage(
                                                        teamSpacesRolesTableMessages.crossAria
                                                    )}
                                                />
                                            )}
                                        </CustomTableCell>
                                    ))
                            )}
                    </TableRow>
                    <TableRow>
                        <CustomTableCell component="th">
                            {intl.formatMessage(teamSpacesRolesTableMessages.editor)}
                        </CustomTableCell>
                        {teamSpacesRolesPermissions
                            .filter((role) => role.id === "role:editor")
                            .map((role) =>
                                Object.keys(role)
                                    .filter((k) => k !== "id")
                                    .map((k) => (
                                        <CustomTableCell key={k}>
                                            {role[k] ? (
                                                <CheckIcon
                                                    role="img"
                                                    aria-hidden="false"
                                                    aria-label={intl.formatMessage(
                                                        teamSpacesRolesTableMessages.checkAria
                                                    )}
                                                />
                                            ) : (
                                                <ClearIcon
                                                    role="img"
                                                    aria-hidden="false"
                                                    aria-label={intl.formatMessage(
                                                        teamSpacesRolesTableMessages.crossAria
                                                    )}
                                                />
                                            )}
                                        </CustomTableCell>
                                    ))
                            )}
                    </TableRow>
                    <TableRow>
                        <CustomTableCell component="th">
                            {intl.formatMessage(teamSpacesRolesTableMessages.viewer)}
                        </CustomTableCell>
                        {teamSpacesRolesPermissions
                            .filter((role) => role.id === "role:viewer")
                            .map((role) =>
                                Object.keys(role)
                                    .filter((k) => k !== "id")
                                    .map((k) => (
                                        <CustomTableCell key={k}>
                                            {role[k] ? (
                                                <CheckIcon
                                                    role="img"
                                                    aria-hidden="false"
                                                    aria-label={intl.formatMessage(
                                                        teamSpacesRolesTableMessages.checkAria
                                                    )}
                                                />
                                            ) : (
                                                <ClearIcon
                                                    role="img"
                                                    aria-hidden="false"
                                                    aria-label={intl.formatMessage(
                                                        teamSpacesRolesTableMessages.crossAria
                                                    )}
                                                />
                                            )}
                                        </CustomTableCell>
                                    ))
                            )}
                    </TableRow>
                </TableBody>
            </CustomTable>
        </TableContainer>
    );
}
