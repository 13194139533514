import { useSelector } from "react-redux";


/* ToDo : These functions are to allow us to test the different states of the DEL application
before we have the required endpoints from bfs to set the status of the DEL application
they should be removed once the endpoints are available in ticket Magma-380935 */

function getADay(daysFromToday){
    const today = new Date();
    return new Date().setDate(today.getDate() + daysFromToday);
}

function setDelStatus(user, delApplicationDate, delExpiry, delApprovalStatus) {
    user.orgs[1] = { ...user.orgs[1], delApplicationDate, delExpiry, delApprovalStatus };
    return user;
}

function addExpired(user){
    setDelStatus(user, getADay(-2), getADay(-1), "Expired");
}

function addRevoked(user){
    setDelStatus(user, getADay(-1), getADay(1), "Revoked");
}

function addRejected(user){
    setDelStatus(user, getADay(-1), getADay(1), "Rejected");
}

function addActive(user){
    setDelStatus(user, getADay(-1), getADay(1), "Approved");
}

function addPending(user){
    setDelStatus(user, getADay(-1), undefined, "Applied");
}

const statusFunctionMap = {
    "Expired": addExpired,
    "Revoked": addRevoked,
    "Rejected": addRejected,
    "Active": addActive,
    "Applied": addPending
};

function modifyStatus(user){
    const modFunction = statusFunctionMap[localStorage.getItem("delStatusTest")];
    if(modFunction){
        modFunction(user);
    }
}

/* End of test functions */

export default function useDelStatus() {
    const user = useSelector(state => state.user.current.result);
    if (user) {
        modifyStatus(user); //ToDo : also remove this line 380935
        const delAppliedOrg = user?.orgs?.find(org => !!org.delApplicationDate);
        if (!delAppliedOrg) {
            return "";
        }
        const delExpired = delAppliedOrg?.delExpiry && delAppliedOrg.delExpiry < new Date();
        return delExpired ? "Expired" : delAppliedOrg.delApprovalStatus;
    }
    return "";
}




