import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {Typography} from "@mui/material";
import {createUseStyles} from "react-jss";
import {Container} from "@mui/system";
import {ReactComponent as LicenceIcon} from "../../../components/icons/licence.svg";

const messages = defineMessages({
    title: {
        id: 'DelApplicationRevoked.title',
        defaultMessage: 'Data Exploration Licence application',
        description: 'Title of DEL Application Revoked page'
    },
    subHeading: {
        id: 'DelApplicationRevoked.subHeading',
        defaultMessage: 'Data Exploration Licence is unavailable',
        description: 'Error message of DEL Application Revoked page'
    },
    p1: {
        id: 'DelApplicationRevoked.p1',
        defaultMessage: 'This is due to your licence expiring, has been declined, has been revoked or you have cancelled the licence early. \n' +
            ' \n' +
            'Please contact your account manager if you have any questions.',
        description: 'Paragraph 1 of DEL Application Revoked page'
    }
});

const useStyles = createUseStyles(theme => ({
    delContainer: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        maxWidth: theme.spacing(160),
        [theme.breakpoints.down("md")]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        }
    },
    title: {
        paddingBottom: theme.spacing(4)
    },
    subtitle: {
        paddingBottom: theme.spacing(2)
    },
    subHeading:{
        fontWeight: '900',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing(3)
    },
    paragraphContainer:{
        paddingLeft: theme.spacing(4),
        maxWidth: theme.spacing(95)
    },
    icon:{
        minWidth: theme.spacing(5)
    }
}));

function DelApplicationRevoked() {
    const classes = useStyles();
    const intl = useIntl();

    return (
        <Container maxWidth="lg" className={classes.delContainer}>
            <Typography variant="h1" color="primary" className={classes.title}>
                <FormattedMessage {...messages.title} />
            </Typography>
            <div className={classes.subHeading}>
                <LicenceIcon aria-hidden="true" className={classes.icon}/>
                <Typography variant="h2" color="primary">
                    <FormattedMessage {...messages.subHeading} />
                </Typography>
            </div>
            <div className={classes.paragraphContainer}>
                <Typography variant="body1" color="textPrimary" paragraph={true}>
                    <FormattedMessage {...messages.p1} />
                </Typography>
            </div>
        </Container>
    );
}

export default DelApplicationRevoked;