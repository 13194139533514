import { defineMessages } from "react-intl";

const dialogMessages = defineMessages({
    closeButton: {
        id: "dialogMessages.closeButton",
        defaultMessage: "Close",
        description: "Close Button (Aria Label)",
    },
});

export default dialogMessages;
