const { defineMessages } = require("react-intl");

const deleteTeamSpaceErrors = defineMessages({
    genericServerError: {
        id: "deleteTeamSpaceErrors.genericServerError",
        description: "Delete Team Space > Generic Server Error",
        defaultMessage: "We ran into a problem our end removing the Team Space. Please try again and if the problem persists, please {link}",
    },
});

export default deleteTeamSpaceErrors;
