import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { useId, useState } from "react";
import { osColour, theme } from "omse-components";
import { Input, InputLabel, Typography, Box } from "@mui/material";
import teamSpaceDetailsMessages from "./TeamSpaceDetails.msg";
import { teamSpacePanels } from "../shared/constants";

const Panel = styled(Box)`
    & > h2 {
        font-size: 1.6em;
        padding-bottom: ${theme.spacing(3)};
        color: ${osColour.neutral.charcoal};
    }
    & > div {
        margin-bottom: 1em;
    }
    & > div > em > span {
        color: ${osColour.status.error};
        font-style: normal;
    }
    & > div > div.MuiInputBase-root {
        margin: 0.6em 0em;
    }
    & > div > div > * {
        height: auto;
        padding: 0.6em;
        box-sizing: border-box;
    }
`;

const TeamSpaceDetails = (p) => {
    const intl = useIntl();
    const teamSpaceName = useId();
    const teamSpaceDescription = useId();
    const [isInvalid, setIsInvalid] = useState(false);

    const handleUpdateName = (val) => {
        p.setNewTeamSpaceParameters((prevState) => ({
            ...prevState,
            name: val,
        }));

        const isAllWhitespace = !val.replace(/\s/g, "").length;
        const isInvalidLength = val.length < 1 || val.length > 255;
        const isInvalidContent = val.startsWith(" ");
        // Trailing whitespace is trimmed on submission.

        setIsInvalid(Boolean(isInvalidLength || isAllWhitespace || isInvalidContent));
        p.setDisableNextAction(Boolean(isInvalidLength || isAllWhitespace || isInvalidContent));
    };

    return (
        <Panel data-testid={teamSpacePanels.details}>
            <Typography variant="h2">
                {intl.formatMessage(teamSpaceDetailsMessages.title)}
            </Typography>
            <Box>
                <InputLabel htmlFor={teamSpaceName}>
                    {intl.formatMessage(teamSpaceDetailsMessages.nameInputLabel)}
                </InputLabel>
                <Input
                    id={teamSpaceName}
                    data-testid="team-space-name"
                    type="text"
                    autoFocus={true}
                    fullWidth={true}
                    error={isInvalid}
                    value={p.newTeamSpaceParameters.name}
                    onChange={(e) => handleUpdateName(e.target.value)}
                    disableUnderline={true}
                />
                <em aria-live="polite">
                    {isInvalid && (
                        <Typography variant="caption" data-testid="invalidNameError">
                            {intl.formatMessage(teamSpaceDetailsMessages.nameErrorMessage)}
                        </Typography>
                    )}
                </em>
            </Box>
            <Box>
                <InputLabel htmlFor={teamSpaceDescription}>
                    {intl.formatMessage(teamSpaceDetailsMessages.descriptionInputLabel)}
                </InputLabel>
                <Input
                    id={teamSpaceDescription}
                    type="text"
                    fullWidth={true}
                    value={p.newTeamSpaceParameters.description}
                    onChange={(e) =>
                        p.setNewTeamSpaceParameters((prevState) => ({
                            ...prevState,
                            description: e.target.value,
                        }))
                    }
                    multiline
                    rows={6}
                    disableUnderline={true}
                />
            </Box>
        </Panel>
    );
};

export default TeamSpaceDetails;

TeamSpaceDetails.propTypes = {
    newTeamSpaceParameters: PropTypes.shape({}),
    setNewTeamSpaceParameters: PropTypes.func,
    setDisableNextAction: PropTypes.func.isRequired,
};
