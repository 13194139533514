const { API_PROJECT_PERMISSION_GROUPS } = require("../../../../shared/teamSpaces");
const { CONTRACTOR_READ_ONLY_ROLE } = require("../../../../shared/roles");

function viewerAccessOnlyPermitted(user) {
    return !!(user.role === CONTRACTOR_READ_ONLY_ROLE);
}

function getRoleSelectValue(member) {
    switch (member.apiProjectRole) {
        case API_PROJECT_PERMISSION_GROUPS.ApiProjectViewer:
            return exports.VIEWER;
        case API_PROJECT_PERMISSION_GROUPS.ApiProjectEditor:
            return exports.EDITOR
        default:
    }
}

function getApiProjectRole(selectValue) {
    switch (selectValue) {
        case exports.VIEWER:
            return API_PROJECT_PERMISSION_GROUPS.ApiProjectViewer;
        case exports.EDITOR:
            return API_PROJECT_PERMISSION_GROUPS.ApiProjectEditor;
        default:
    }
}

exports.EDITOR = 'Editor';
exports.VIEWER = 'Viewer';
exports.viewerAccessOnlyPermitted = viewerAccessOnlyPermitted;
exports.getRoleSelectValue = getRoleSelectValue;
exports.getApiProjectRole = getApiProjectRole;
