import { defineMessages } from "react-intl";

const teamSpaceHeaderMessages = defineMessages({
    editButtonLabel: {
        id: "teamSpaceHeaderMessages.buttonLabel",
        defaultMessage: "Team Space name, click to edit",
        description: "Label used for the aria-label attribute on the Team Space name edit button",
    },
    inputLabel: {
        id: "teamSpaceHeaderMessages.inputLabel",
        defaultMessage: "Team Space name",
        description:
            "Label used for the aria-label attribute on the Team space name name input field",
    },
    clickToEdit: {
        id: "teamSpaceHeaderMessages.clickToEdit",
        defaultMessage: "Click to edit the Team Space Name",
        description: "Label used for the alt text on the edit icon",
    },
    lockedAria: {
        id: "teamSpaceHeaderMessages.lockedAria",
        description: "Locked Team Space icon",
        defaultMessage: "Locked Team Space",
    },
    unlockedAria: {
        id: "teamSpaceHeaderMessages.unlockedAria",
        description: "Unlocked Team Space icon",
        defaultMessage: "Unlocked Team Space",
    },
    actions: {
        id: "teamSpaceHeaderMessages.actions",
        description: "Action dropdown menu",
        defaultMessage: "Actions",
    },
    actionsAria: {
        id: "teamSpaceHeaderMessages.actionsAria",
        description: "Action dropdown menu",
        defaultMessage: "Actions menu for Team Space",
    },
    createdBy: {
        id: "teamSpaceHeaderMessages.createdBy",
        description: "Created by Label",
        defaultMessage: "Created by: {name}",
    },
    nameEditButtonLabel: {
        id: 'teamSpaceHeaderMessages.nameEditButtonLabel',
        defaultMessage: 'Team Space name, click to edit',
        description: 'Label used for the aria-label attribute on the Team Space name button'
    },
    nameInputLabel: {
        id: 'teamSpaceHeaderMessages.nameInputLabel',
        defaultMessage: 'Team Space name',
        description: 'Label used for the aria-label attribute on the Team Space name input field'
    },
    nameClickToEdit: {
        id: 'teamSpaceHeaderMessages.nameClickToEdit',
        defaultMessage: 'Click to edit the Team Space name',
        description: 'Label used for the alt text on the edit icon'
    },
    descriptionEditButtonLabel: {
        id: 'teamSpaceHeaderMessages.descriptionEditButtonLabel',
        defaultMessage: 'Team Space description, click to edit',
        description: 'Label used for the aria-label attribute on the Team Space description button'
    },
    descriptionInputLabel: {
        id: 'teamSpaceHeaderMessages.descriptionInputLabel',
        defaultMessage: 'Team Space description',
        description: 'Label used for the aria-label attribute on the Team Space description input field'
    },
    descriptionClickToEdit: {
        id: 'teamSpaceHeaderMessages.descriptionClickToEdit',
        defaultMessage: 'Click to edit the Team Space description',
        description: 'Label used for the alt text on the edit icon'
    },
    descriptionPlaceholder: {
        id: "teamSpaceHeaderMessages.descriptionPlaceholder",
        defaultMessage: "Add a description here.",
        description: "Description placeholder",
    },
});

export default teamSpaceHeaderMessages