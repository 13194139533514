import { defineMessages } from "react-intl";

const resourceEmptyStateMessages = defineMessages({
    heading: {
        id: "resourceEmptyStateMessages.heading",
        defaultMessage: "Nothing here!",
        description: "Team Resources Empty State Heading",
    },
    summaryApiProjects: {
        id: "resourceEmptyStateMessages.summaryApiProjects",
        defaultMessage: "Let's add your first API Project.",
        description: "Team Resources Empty State API Projects CTA",
    },
});

export default resourceEmptyStateMessages;
