import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {Typography} from "@mui/material";
import {createUseStyles} from "react-jss";
import {Container} from "@mui/system";
import {ReactComponent as LicenceIcon} from "../../../components/icons/licence.svg";
import routePaths from "../../../util/routes";
import { useHistory } from "react-router-dom";
import { LinkButton } from "omse-components";

const messages = defineMessages({
    title: {
        id: 'DelApplicationSuccessful.title',
        defaultMessage: 'Data Exploration Licence',
        description: 'Title of DEL Application Complete page'
    },
    subHeading: {
        id: 'DelApplicationSuccessful.subHeading',
        defaultMessage: 'Your Data Exploration Licence is now active',
        description: 'Success message of DEL Application Complete page'
    },
    p1: {
        id: 'DelApplicationSuccessful.p1',
        defaultMessage : 'Start to explore OS Data:',
        description: 'Paragraph 1 of DEL Application Complete page'
    },
    p1Link1Text: {
        id: 'DelApplicationSuccessful.p1Link1Text',
        defaultMessage: 'OS Select+Build',
        description: 'Aria label for S+B link'
    },
    p1Link2Text: {
        id: 'DelApplicationSuccessful.p1Link2Text',
        defaultMessage: 'Premium data',
        description: 'Text for Premium data downloads link'
    },
    p1Link1AriaLabel: {
        id: 'DelApplicationSuccessful.p1Link1AriaLabel',
        defaultMessage: 'Link to OS Select+Build',
        description: 'Aria label for S+B link'
    },
    p1Link2AriaLabel: {
        id: 'DelApplicationSuccessful.p1Link2AriaLabel',
        defaultMessage: 'Link to Premium data',
        description: 'Aria label for Premium data downloads link'
    }
});

const useStyles = createUseStyles(theme => ({
    delContainer: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        maxWidth: theme.spacing(160),
        [theme.breakpoints.down("md")]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        }
    },
    title: {
        paddingBottom: theme.spacing(4)
    },
    subtitle: {
        paddingBottom: theme.spacing(2)
    },
    subHeading:{
        fontWeight: '900',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing(3)
    },
    paragraphContainer:{
        paddingLeft: theme.spacing(4),
        maxWidth: theme.spacing(100)
    }
}));

function DelApplicationSuccessful() {
    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();

    return (
        <Container maxWidth="lg" className={classes.delContainer}>
            <Typography variant="h1" color="primary" className={classes.title}>
                <FormattedMessage {...messages.title} />
            </Typography>
            <div className={classes.subHeading}>
                <LicenceIcon aria-hidden="true" />
                <Typography variant="h2" color="primary">
                    <FormattedMessage {...messages.subHeading} />
                </Typography>
            </div>
            <div className={classes.paragraphContainer}>
                <Typography variant="body1" color="textPrimary" paragraph={true}>
                    <FormattedMessage {...messages.p1} />
                </Typography>
                <ul>
                    <li>
                        <LinkButton onClick={() => {history.push(routePaths.recipeLibrary)}}
                                    aria-label={intl.formatMessage(messages.p1Link1AriaLabel)}>
                            <FormattedMessage {...messages.p1Link1Text} />
                        </LinkButton>
                    </li>
                    <li>
                        <LinkButton onClick={() => {history.push(routePaths.premiumDownloads)}}
                                    aria-label={intl.formatMessage(messages.p1Link2AriaLabel)}>
                            <FormattedMessage {...messages.p1Link2Text} />
                        </LinkButton>
                    </li>
                </ul>
            </div>
        </Container>
);
}

export default DelApplicationSuccessful;