const { TEAM_PERMISSION_GROUPS } = require("../../../../shared/teamSpaces");
const { isOrgAdmin } = require("./teamSpaceUser");

function useManageTeamSpacePermission(user, teamSpace) {
    let isTeamSpaceOwner;
    if (user?.datahubDeveloperId) {
        isTeamSpaceOwner = teamSpace?.teamMembers
            .find(teamMember => ((teamMember?.datahubDeveloperId === user.datahubDeveloperId) && (teamMember?.teamRole === TEAM_PERMISSION_GROUPS.TeamOwner)));
    }
    return Boolean((user && isOrgAdmin(user)) || isTeamSpaceOwner);
}

exports.useManageTeamSpacePermission = useManageTeamSpacePermission;