const { TEAM_PERMISSION_GROUPS, API_PROJECT_PERMISSION_GROUPS } = require("../../../../shared/teamSpaces");
const { isOrgAdmin } = require("./teamSpaceUser");

function useTeamSpacePermission(user, teamSpace) {
    let teamSpaceUser;
    
    if (user?.datahubDeveloperId) {
        teamSpaceUser = teamSpace?.teamMembers
            .find(teamMember => (teamMember?.datahubDeveloperId === user.datahubDeveloperId));
    }

    const canAddApiProjects =
        isOrgAdmin(user) ||
        teamSpaceUser?.teamRole === TEAM_PERMISSION_GROUPS.TeamOwner ||
        teamSpaceUser?.apiProjectRole === API_PROJECT_PERMISSION_GROUPS.ApiProjectEditor;

    const canMoveApiProjects =
        isOrgAdmin(user) ||
        teamSpaceUser?.teamRole === TEAM_PERMISSION_GROUPS.TeamOwner ||
        teamSpaceUser?.apiProjectRole === API_PROJECT_PERMISSION_GROUPS.ApiProjectEditor;

    const canRemoveApiProjects =
        isOrgAdmin(user) ||
        teamSpaceUser?.teamRole === TEAM_PERMISSION_GROUPS.TeamOwner ||
        teamSpaceUser?.apiProjectRole === API_PROJECT_PERMISSION_GROUPS.ApiProjectEditor;

    const canDeleteApiProjects =
        isOrgAdmin(user) ||
        teamSpaceUser?.teamRole === TEAM_PERMISSION_GROUPS.TeamOwner ||
        teamSpaceUser?.apiProjectRole === API_PROJECT_PERMISSION_GROUPS.ApiProjectEditor;

    return { canAddApiProjects, canMoveApiProjects, canRemoveApiProjects, canDeleteApiProjects };
}

export { useTeamSpacePermission };