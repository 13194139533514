import PropTypes from "prop-types";
import { ConfirmationDialog } from "omse-components";
import { defineMessages } from "react-intl";

const messages = defineMessages({
    title: {
        id: "RemoveResourceDialog.js.deleteTitle",
        defaultMessage: "Remove {removalName} from this Team Space?",
        description: "Title for the dialog",
    },
    contentApiProject: {
        id: "RemoveResourceDialog.contentApiProject",
        defaultMessage:
            "Your API Project will be removed from this Team Space. The API Project itself will not be deleted.",
        description: "Warning text for the dialog - API Project variant",
    },
    contentGeneric: {
        id: "RemoveResourceDialog.contentGeneric",
        defaultMessage:
            "Your resource will be removed from this Team Space. The resource itself will not be deleted.",
        description: "Warning text for the dialog",
    },
    buttonLabelApiProject: {
        id: "RemoveResourceDialog.buttonLabelApiProject",
        defaultMessage: "Remove API Project from Team Space",
        description: "Button label for the dialog - API Project variant",
    },
    buttonLabelGeneric: {
        id: "RemoveResourceDialog.buttonLabelGeneric",
        defaultMessage: "Remove resource from Team Space",
        description: "Button label for the dialog",
    },
});

const apiProjectVariant = "apiProject";

export const RemoveResourceDialog = ({
    closed,
    confirmed,
    removalName,
    working,
    error,
    variant,
}) => {
    return (
        <ConfirmationDialog
            title={messages.title}
            titleValues={{ removalName }}
            confirmationAction={confirmed}
            contentMessages={[
                variant === apiProjectVariant
                    ? messages.contentApiProject
                    : messages.contentGeneric,
            ]}
            handleClose={closed}
            confirmationButtonLabel={
                variant === apiProjectVariant
                    ? messages.buttonLabelApiProject
                    : messages.buttonLabelGeneric
            }
            working={working}
            error={error}
        />
    );
};

RemoveResourceDialog.propTypes = {
    closed: PropTypes.func.isRequired,
    confirmed: PropTypes.func.isRequired,
    removalName: PropTypes.string.isRequired,
    working: PropTypes.bool,
    error: PropTypes.string,
    variant: PropTypes.oneOf([apiProjectVariant]),
};
