import { defineMessages } from "react-intl";

const moveAPIProjectResultsMessages = defineMessages({
    searchLabel: {
        id: "moveAPIProjectResultsMessages.searchLabel",
        defaultMessage: "Search Team Spaces",
        description: "Search Label",
    },
    searchPlaceholder: {
        id: "moveAPIProjectResultsMessages.searchPlaceholder",
        defaultMessage: "Find a Team Space by name...",
        description: "Search Placeholder",
    },
    cannotFind: {
        id: "moveAPIProjectResultsMessages.cannotFind",
        defaultMessage: "Can't find the Team Space?",
        description: "Tooltip label for below the search box",
    },
    cannotFindButton: {
        id: "moveAPIProjectResultsMessages.cannotFindButton",
        defaultMessage: "More Information",
        description: "Tooltip button text for below the search box",
    },
    cannotFindTooltip: {
        id: "moveAPIProjectResultsMessages.cannotFindTooltip",
        defaultMessage: "To move an API Project between Team Spaces you must be listed as either an <strong>Owner</strong> or <strong>API Project Editor</strong> in the destination.",
        description: "Tooltip content for help finding a Team Space",
    },
    col1Title: {
        id: "moveAPIProjectResultsMessages.col1Title",
        defaultMessage: "Team Space",
        description: "Table Header Title for Col 1 and 2",
    },
    col2Title: {
        id: "moveAPIProjectResultsMessages.col2Title",
        defaultMessage: "Description",
        description: "Table Header Title for Col 3",
    },
    teamSpaceIconLocked: {
        id: "moveAPIProjectResultsMessages.teamSpaceIconLocked",
        defaultMessage: "Locked Team Space",
        description: "Aria-Label for Locked Team Space Icon",
    },
    teamSpaceIconUnlocked: {
        id: "moveAPIProjectResultsMessages.teamSpaceIconUnlocked",
        defaultMessage: "Unlocked Team Space",
        description: "Aria-Label for Unlocked Team Space Icon",
    },
});

export default moveAPIProjectResultsMessages;
