import React from 'react';
import figure1a from '../img/Figure1a.png';
import figure1b from '../img/Figure1b.png';
import figure2a from '../img/Figure2a.png';
import figure2c from '../img/Figure2c.png';
import figure2d from '../img/Figure2d.png';
import figure5a from '../img/Figure5a.png';
import figureChangeEmail from '../img/ChangeEmail.png';
import figureChangeEmail2 from '../img/ChangeEmail2.png';
import figureChangeOrgName from '../img/ChangeOrgName.png';
import mobileFigure1a from '../img/mobile/Figure1a.png';
import mobileFigure1b from '../img/mobile/Figure1b.png';
import mobileFigure2a from '../img/mobile/Figure2a.png';
import mobileFigure2a1 from '../img/mobile/Figure2a1.png';
import mobileFigure2a2 from '../img/mobile/Figure2a2.png';
import mobileFigure2c from '../img/mobile/Figure2c.png';
import mobileFigure2d from '../img/mobile/Figure2d.png';
import mobileFigure5a from '../img/mobile/Figure5a.png';
import PrincipalContactWarning from '../components/PrincipalContactWarning';
import { ExternalLink } from 'omse-components';

export default function account(getImg, {mobile}) {

    return [
        {
            id: 'accountSignUp',
            title: 'How do I sign up for an account?',
            loggedOutOnly: true,
            body: (
                <ol>
                    <li>
                        Go to <a href="https://osdatahub.os.uk">https://osdatahub.os.uk</a>
                    </li>
                    <li>
                        Click <i>Sign up</i>.
                        {getImg(mobile? mobileFigure1a : figure1a, 'OS Data Hub Screenshot: Landing Page', null, true)}
                    </li>
                    <li>
                        You'll be directed to the Pricing Plan page to select your plan.
                        {getImg(mobile? mobileFigure1b : figure1b, 'OS Data Hub Screenshot: Plans Page / Plan Types', null, true)}
                    </li>
                    <li>
                        Select the OS OpenData Plan, Premium Plan or Public Sector Plan.
                    </li>
                    <li>
                        Enter your email address and select <i>Send verification code</i>.
                    </li>
                    <li>
                        A verification code will be sent to your email.
                    </li>
                    <li>
                        Back in the Data Hub, enter the verification code.
                    </li>
                    <li>
                        Enter your first and last name.
                    </li>
                    <li>
                        Enter a password and confirm it.
                    </li>
                    <li>
                        Select <i>Create an account</i>.
                    </li>
                    <li>
                        If you selected the OS OpenData Plan, your account will have been created and you'll be
                        taken to your dashboard page.
                    </li>
                    <li>
                        If you selected the Premium Plan you'll be prompted to enter your organisation name, job title, telephone number and
                        credit/debit card details if appropriate.
                    </li>
                    <li>
                        If you selected the Public Sector Plan and your organisation is a member of the Public Sector Geospatial Agreement (PSGA) 
                        and you're a known user of that organisation, you'll be prompted to enter your organisation name, job title and telephone number.
                        You'll then have access to the organisation’s OS Data Hub account. If you're not a known user, you need to be invited by the
                        organisation’s admin user to join the account.
                    </li>
                </ol>
            )
        },
        {
            id: 'verificationCode',
            title: 'Where’s my verification code?',
            body: (
                <>
                    <p>
                        Verification codes are emailed during the initial registration process and the code expires after 5 minutes. 
                        If you have not received a verification code email within that time:
                    </p>
                    <ul>
                        <li>
                            Check your email ‘Send and Receive’ settings
                        </li>
                        <li>
                            Refresh your email client
                        </li>
                        <li>
                            Check your Spam folder
                        </li>
                    </ul>
                    <p>
                        If you believe your firewall could be blocking the email, please contact your technical support team where possible. 
                        Alternatively <ExternalLink type='support' /> for further assistance.
                    </p>
                </>
            )
        },
        {
            id: 'changePassword',
            title: 'How do I change my password?',
            body: (
                <ol>
                    <li>
                        Log in to the OS Data Hub.
                    </li>
                    <li>
                        Click on your display name.
                    </li>
                    {mobile ? (
                        <li>
                            In the menu select <i>Account settings</i>.
                            {getImg(mobileFigure2a, 'OS Data Hub Screenshot: Landing Page')}
                            {getImg(mobileFigure2a1, 'OS Data Hub Screenshot: Account / Log-Out Menu')}
                        </li>
                    ) : (
                        <li>
                            In the drop-down menu, select <i>Account</i>.
                            {getImg(figure2a, 'OS Data Hub Screenshot: Account Drop-Down Menu Button')}
                        </li>
                    )}
                    <li>
                        Select <i>Change password</i>.
                    </li>
                    <li>
                        Enter email address and select <i>Send verification code</i>.
                    </li>
                    <li>
                        A verification code will be sent to your email.
                    </li>
                    <li>
                        Back in the OS Data Hub, enter the verification code.
                    </li>
                    <li>
                        Enter new password and confirm.
                    </li>
                 </ol>
            )
        },
        {
            id: 'changeDisplayName',
            title: 'How do I change my display name?',
            body: (
                <>
                    <p>
                        <PrincipalContactWarning />
                    </p>
                    <ol>
                        <li>
                            Log in to the OS Data Hub.
                        </li>
                        <li>
                            Click on your display name.
                        </li>
                        {mobile ? (
                            <li>
                                In the menu select <i>Account settings</i>.
                                {getImg(mobileFigure2a, 'OS Data Hub Screenshot: Landing Page')}
                                {getImg(mobileFigure2a1, 'OS Data Hub Screenshot: Account / Log-Out Menu')}
                            </li>
                        ) : (
                            <li>
                                In the drop-down menu, select <i>Account</i>.
                                {getImg(figure2a, 'OS Data Hub Screenshot: Account Drop-Down Menu Button')}
                            </li>
                        )}
                        <li>
                            Change your first or last name (your first name is used as your display name).
                        </li>
                        <li>
                            Click <i>Save changes</i>.
                        </li>
                    </ol>
                </>
            )
        },
        {
            id: 'changeEmailAddress',
            title: 'How do I change my email address?',
            body: (
                <>
                    <p>
                        <PrincipalContactWarning />
                    </p>
                    <p>
                        To change your email address, you’re also required to reset your password.
                    </p>
                    <ol>
                        <li>
                            Log in to the OS Data Hub.
                        </li>
                        <li>
                            Click on your display name.
                        </li>
                        {mobile ? (
                            <li>
                                In the menu select <i>Account settings</i>.
                                {getImg(mobileFigure2a, 'OS Data Hub Screenshot: Landing Page')}
                                {getImg(mobileFigure2a1, 'OS Data Hub Screenshot: Account / Log-Out Menu')}
                            </li>
                        ) : (
                            <li>
                                In the drop-down menu, select <i>Account</i>.
                                {getImg(figure2a, 'OS Data Hub Screenshot: Account Drop-Down Menu Button')}
                            </li>
                        )}
                        <li>
                            Enter the new email address.
                        </li>
                        <li>
                            Click <i>Change email</i>.
                            {getImg(figureChangeEmail, 'OS Data Hub Screenshot: Change Email Button')}
                        </li>
                        <li>
                            Click <i>Get verification code</i>.
                        </li>
                        <li>
                            A verification code will be sent to your email.
                        </li>
                        <li>
                            Back in the OS Data Hub, enter the verification code.
                            {getImg(figureChangeEmail2, 'OS Data Hub Screenshot: Verify Email Modal')}
                        </li>
                        <li>
                            Click <i>Verify code</i>.
                        </li>
                        <li>
                            On the next screen click <i>Next</i>.
                        </li>
                        <li>
                            Choose a new password and type it again to confirm.
                        </li>
                        <li>
                            Click <i>Change email</i> and <i>reset password</i>.
                        </li>
                        <li>
                            Your email address and password are now updated.
                        </li>
                    </ol>
                </>
            )
        },        
        {
            id: 'changeMarketingPreferences',
            title: 'How do I change my Marketing preferences?',
            body: (
                <ol>
                    <li>
                        Log in to the OS Data Hub.
                    </li>
                    <li>
                        Click on your display name.
                    </li>
                    {mobile ? (
                        <li>
                            In the menu select <i>Account settings</i>.
                            {getImg(mobileFigure2a, 'OS Data Hub Screenshot: Landing Page')}
                            {getImg(mobileFigure2a1, 'OS Data Hub Screenshot: Account / Log-Out Menu')}
                        </li>
                    ) : (
                        <li>
                            In the drop-down menu, select <i>Account</i>.
                            {getImg(figure2a, 'OS Data Hub Screenshot: Account Drop-Down Menu Button')}
                        </li>
                    )}
                    <li>
                        In the secondary navigation menu, select <i>Contact preferences</i>.
                        {getImg(mobile ? mobileFigure2a2 : figure2c, 'OS Data Hub Screenshot: Contact Preferences Menu Selection')}
                    </li>
                    <li>
                        Update your preferences using the form.
                        {mobile && getImg(mobileFigure2c, 'OS Data Hub Screenshot: Contact Preferences Form (Top)')}
                    </li>
                    <li>
                        Click <i>Save changes</i>.
                        {getImg(mobile ? mobileFigure2d : figure2d, 'OS Data Hub Screenshot: Contact Preferences Form')}
                    </li>
                </ol>
            )
        },
        {
            id: 'changeOrganisationName',
            title: 'How do I change my organisation name?',
            body: (
                <>
                    <p>Only an Admin user can carry out this function under the Premium Plan.</p>
                    <ol>
                        <li>
                            Log in to the OS Data Hub.
                        </li>
                        <li>
                            Click on your display name.
                        </li>
                        {mobile ? (
                            <li>
                                In the menu select <i>Account settings</i>.
                                {getImg(mobileFigure2a, 'OS Data Hub Screenshot: Landing Page')}
                                {getImg(mobileFigure2a1, 'OS Data Hub Screenshot: Account / Log-Out Menu')}
                            </li>
                        ) : (
                            <li>
                                In the drop-down menu, select <i>Account</i>.
                                {getImg(figure2a, 'OS Data Hub Screenshot: Account Drop-Down Menu Button')}
                            </li>
                        )}
                        <li>
                            In the secondary navigation menu, select <i>Company Information</i>.
                        </li>
                        <li>
                            Click <i>Request a change of name</i> and enter the <i>New organisation/Company trading name</i> and <i>Reason for change</i>.
                            {getImg(figureChangeOrgName, 'OS Data Hub Screenshot: Change Organisation Name Modal')}
                        </li>
                        <li>
                            Click <i>Submit request</i>.
                        </li>
                    </ol>
                    <p>
                        Your request will be validated, and you may be contacted for further information. Your request will show as pending until the change is processed.
                    </p>
                </>
            )
        },        
        {
            id: 'generateApiKey',
            title: 'How do I generate an API key?',
            body: (
                <ol>
                    <li>
                        Open the <ExternalLink href="/" message="OS Data Hub" /> in a new tab.
                    </li>
                    {mobile?
                        <>
                            <li>
                                Click the navigation menu button.
                            </li>
                            <li>
                                Click <i>API Dashboard</i> in the menu.
                            </li>
                            <li>
                                Click <i>APIs</i> in the menu.
                            </li>
                        </>
                        :
                        <>
                            <li>
                                Click the <i>API Dashboard</i> tab. You must Log in to view this.
                            </li>
                            <li>
                                Click <i>APIs</i> in the secondary navigation menu.
                            </li>
                        </>
                    }
                    <li>
                        Click the <i>Add to API project</i> button of the API you wish to add, for example <i>OS Features API</i>.
                    </li>
                    <li>
                        If you already have a project, you may choose to add the <i>OS Maps API</i> into that project, or alternatively select <i>Add to NEW PROJECT</i> from the drop-down menu.
                    </li>
                    <li>
                        If creating a new project, enter the project name.
                    </li>
                    <li>
                        The next screen will contain the <i>Project API Key</i> and the <i>API Endpoint address (API URL)</i>.
                    </li>
                    <li>
                        You can return to this screen by clicking <i>My projects</i> at any point in the future if you need to <i>copy your API key</i> or the <i>API address</i>, or if you need to <i>regenerate your API Key</i>.
                    </li>
                </ol>
            )
        },
        {
            id: 'whereIsApiEndpoint',
            title: 'Where is my API endpoint?',
            body: (
                <ol>
                    {mobile?
                        <>
                            <li>
                                Click the navigation menu button.
                            </li>
                            <li>
                                Click <i>API Dashboard</i> in the menu.
                            </li>
                            <li>
                                Click <i>My projects</i> in the menu.
                            </li>
                        </>
                        :
                        <>
                            <li>
                                Click the <i>API Dashboard</i> tab. You must Log in to view this.
                            </li>
                            <li>
                                Click <i>My projects</i> in the secondary navigation menu.
                            </li>
                        </>
                    }
                    <li>
                        Select the project you're interested in.
                    </li>
                    <li>
                        Your API endpoint address will be displayed here.
                        {getImg(mobile? mobileFigure5a : figure5a, 'OS Data Hub Screenshot: API Project Information')}
                    </li>
                </ol>
            )
        },
        {
            id: 'browserCompatibility',
            title: 'What browsers and devices are compatible?',
            body: (
                <>
                    <p>
                        The OS Data Hub supports use of the following browsers and devices:
                    </p>
                    <p>
                        Desktop: Chrome (latest), Edge Chromium (latest), Safari (latest), Firefox (latest). Please note, Internet Explorer and previous versions of the specified browsers are not supported.
                    </p>
                    <p>
                        Mobile and Tablet: Android Chrome, iOS Safari. iPhone &amp; iPad latest build, plus previous two build models. Android devices are based upon equivalent to Samsung screen size.
                    </p>
                </>
            )
        }
    ]
}
