import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { Button, Typography } from '@mui/material';
import { createUseStyles } from 'react-jss';
import { osColour } from 'omse-components';
import PropTypes from 'prop-types';
import { PARTNER_MEMBER_URL } from '../util/routes';
import { titleKey, bodyKey, subtitleKey, buttonKey } from '../util/i18n';

const useStyles = createUseStyles(theme => ({
    container: {
        border: 'solid 1px ' + osColour.neutral.mist,
        borderRadius: '0 3px 3px 0',
        borderLeft: 'solid 4px ' + osColour.primary.berry,
        background: osColour.neutral.clouds,
        padding: theme.spacing(0, 3, 3, 3),
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            flex: 'auto',
            padding: theme.spacing(0, 2, 2, 2),
            border: 'none',
            borderLeft: 'solid 4px ' + osColour.primary.berry,
            borderRadius: 0
        }
    },
    titleText: {
        paddingTop: theme.spacing(3),
        color: osColour.primary.berry,
        fontFamily: 'OSGill',
        fontSize: '1.75rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem'
        }
    },
    subtitle: {
        color: osColour.neutral.stone,
        fontSize: '1rem',
        fontWeight: 'normal',
    },
    body: {
        flexGrow: 2,
        color: osColour.neutral.charcoal,
        marginTop: theme.spacing(2),
        lineHeight: '1.75rem',
        '& a': {
            fontSize: '1.125rem',
            display: 'inline'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
            '& a': {
                fontSize: '1rem'
            }
        }
    },
    actionButtons: {
        marginTop: theme.spacing(1),
        display: 'flex',
        flexWrap: 'wrap'
    },
    actionButton: {
        background: osColour.neutral.white,
        marginTop: theme.spacing(1),
        alignSelf: 'flex-start',
        '&:first-of-type': {
            marginRight: theme.spacing(2)
        },
    }
}));

export const FIND_PARTNER = 'findPartner';
export const variants = [FIND_PARTNER];

const componentName = 'InfoActionContent';

const titleElement = 'Title';
const bodyElement = 'Body';
const buttonElement = 'Button';

const componentPrefix = componentName + '.';
const findPartnerPrefix = componentPrefix + FIND_PARTNER;

export const messages = defineMessages({
    [titleKey(FIND_PARTNER)]: {
        id: findPartnerPrefix + titleElement,
        defaultMessage: 'Find an OS licensed partner',
        description: 'Title'
    },
    [bodyKey(FIND_PARTNER)]: {
        id: findPartnerPrefix + bodyElement,
        defaultMessage: 'If you are looking for an OS licensed partner, for example to use data for your internal business processes, please visit our partner finder.',
        description: 'Body'
    },
    [buttonKey(FIND_PARTNER)]: {
        id: findPartnerPrefix + buttonElement,
        defaultMessage: 'Search OS partners',
        description: 'Button'
    }
});

export const actionLinks = {
    [FIND_PARTNER]: PARTNER_MEMBER_URL
}

export default function InfoActionContent({ title, subtitle, body, actionButton, variant, bodyFormatted, actionNewTab }) {
    const classes = useStyles();
    let messageTitle = title;
    let messageBody = body;
    let messageSubtitle = subtitle;
    let messageButton;
    if (variant) {
        messageTitle = messages[titleKey(variant)];
        messageSubtitle = messages[subtitleKey(variant)];
        messageBody = messages[bodyKey(variant)];
        messageButton = messages[buttonKey(variant)];
    }

    const button1Displayed = (actionButton || messageButton);

    return <div className={classes.container}>
        {messageTitle &&
            <Typography variant='h3' className={classes.titleText}>
                <FormattedMessage {...messageTitle} />
            </Typography>
        }

        {messageSubtitle &&
            <Typography variant='subtitle1' className={classes.subtitle}>
                <FormattedMessage {...messageSubtitle} />
            </Typography>
        }

        {(messageBody || bodyFormatted) &&
            <Typography variant='body1' className={classes.body}>
                {bodyFormatted ? bodyFormatted : <FormattedMessage {...messageBody} />}
            </Typography>
        }

            <div className={classes.actionButtons}>
                {button1Displayed && <>
                    {actionButton || <>
                        {messageButton &&
                            <Button onClick={() => window.open(actionLinks[variant], actionNewTab ? '_blank' : '_self')}
                                variant='outlined' color='primary' className={classes.actionButton}>
                                <FormattedMessage {...messageButton} />
                            </Button>
                        }
                    </>
                    }
                </>
                }
            </div>
    </div>;
}

InfoActionContent.propTypes = {
    title: PropTypes.object,
    subtitle: PropTypes.object,
    body: PropTypes.object,
    bodyFormatted: PropTypes.object,
    actionButton: PropTypes.object,
    variant: PropTypes.oneOf(variants)
}

InfoActionContent.defaultProps = {
    actionNewTab: true
}
