import {defineMessages} from 'react-intl';
import termsMessages from '../legal/TermsTitles';

let menuMessages = defineMessages({
    label: {
        defaultMessage: 'Secondary',
        description: 'Aria-label used for the secondary navigation menu',
        id: 'Menu.label'
    },
    workspace: {
        id: 'Menu.Workspace',
        defaultMessage: 'Workspace',
        description: 'Label for the WorkspaceHeader option'
    },
    dashboard: {
        id: 'Menu.Dashboard',
        defaultMessage: 'Dashboard',
        description: 'Label for the DashboardHeader option'
    },
    teamSpaces: {
        id: "Menu.teamSpaces",
        defaultMessage: "Team Spaces",
        description: 'Label for the TeamSpacesHeader option',
    },
    history: {
        id: 'Menu.History',
        defaultMessage: 'API transaction history',
        description: 'Label for the API transaction history option'
    },
    apiProjects: {
        id: 'Menu.APIProjects',
        defaultMessage: 'My projects',
        description: 'Label for the My projects option'
    },
    apiProducts: {
        id: 'Menu.APIProducts',
        defaultMessage: 'APIs',
        description: 'Label for the APIs option'
    },
    docs: {
        id: 'Menu.Docs',
        defaultMessage: 'Docs',
        description: 'Label for the Docs option'
    },
    documentation: {
        id: 'Menu.Documentation',
        defaultMessage: 'Documentation',
        description: 'Label for the Documentation option'
    },
    support: {
        id: 'Menu.Support',
        defaultMessage: 'Support',
        description: 'Label for the Support option'
    },
    faqs: {
        id: 'Menu.faqs',
        defaultMessage: 'FAQs',
        description: 'Label for the Support FAQs option'
    },
    download: {
        id: 'Menu.Downloads',
        defaultMessage: 'Download',
        description: 'Label for the Download option'
    },
    openDataDownloads: {
        id: 'Menu.openDataDownloads',
        defaultMessage: 'OpenData downloads',
        description: 'Label for the OS OpenData downloads'
    },
    sampleDataDownloads: {
        id: 'Menu.sampleDataDownloads',
        defaultMessage: 'Sample Data Downloads',
        description: 'Label for the OS Sample Data downloads'
    },
    delTerms: {
        id: 'Menu.delTerms',
        defaultMessage: 'DelTerms', // label isn't visible
        description: 'Label for the DEL Terms'
    },
    delApply: {
        id: 'Menu.delApply',
        defaultMessage: 'DelApply', // label isn't visible
        description: 'Label for the DEL Apply'
    },
    osNetRinexData: {
        id: 'Menu.osNetRinexData',
        defaultMessage: 'OS Net RINEX data',
        description: 'Label for the OS Net RINEX data'
    },
    osNetStationHealth: {
        id: 'Menu.osNetStationHealth',
        defaultMessage: 'Station Health data',
        description: 'Sub menu label for the OS Net RINEX data'
    },
    premiumDownloads: {
        id: 'Menu.premiumDownloads',
        defaultMessage: 'Premium data downloads',
        description: 'Label for the Premium downloads'
    },
    osSelectBuild: {
        id: 'Menu.osSelectBuild',
        defaultMessage: 'OS Select+Build',
        description: 'Label for the OS Select+Build menu item'
    },
    dataPackages: {
        id: 'Menu.dataPackages',
        defaultMessage: 'Data packages',
        description: 'Label for the Data packages'
    },
    manageLicences: {
        id: 'Menu.manageLicences',
        defaultMessage: 'Manage licences',
        description: 'Label for the Manage licences'
    },
    dataHub: {
        id: 'Menu.dataHub',
        defaultMessage: 'Data Hub',
        description: 'Label for Data Hub'
    },
    api: {
        id: 'Menu.api',
        defaultMessage: 'API Dashboard',
        description: 'Label API'
    },
    downloadsApi: {
        id: 'Menu.downloadsApi',
        defaultMessage: 'OS Downloads API',
        description: 'OS Downloads API label'
    },
    oauthApi: {
        id: 'Menu.oauthApi',
        defaultMessage: 'OAuth 2 API',
        description: 'OAuth 2 API label'
    },
    featuresApi: {
        id: 'Menu.featuresApi',
        defaultMessage: 'OS Features API',
        description: 'OS Features API'
    },
    ngdFeaturesApi: {
        id: 'Menu.ngdFeaturesApi',
        defaultMessage: 'OS NGD API – Features',
        description: 'OS NGD API – Features'
    },
    ngdTilesApi: {
        id: 'Menu.ngdTilesApi',
        defaultMessage: 'OS NGD API – Tiles',
        description: 'OS NGD API – Tiles'
    },
    linkedIdentifiersApi: {
        id: 'Menu.linkedIdentifiersApi',
        defaultMessage: 'OS Linked Identifiers API',
        description: 'OS Linked Identifiers API'
    },
    namesApi: {
        id: 'Menu.namesApi',
        defaultMessage: 'OS Names API',
        description: 'OS Names API'
    },
    mapsApi: {
        id: 'Menu.mapsApi',
        defaultMessage: 'OS Maps API',
        description: 'OS Maps API'
    },
    matchApi: {
        id: 'Menu.matchApi',
        defaultMessage: 'OS Match & Cleanse API',
        description: 'OS Match & Cleanse API'
    },
    vectorApi: {
        id: 'Menu.vectorApi',
        defaultMessage: 'OS Vector Tile API',
        description: 'OS Vector Tile API'
    },
    placesApi: {
        id: 'Menu.placesApi',
        defaultMessage: 'OS Places API',
        description: 'OS Places API'
    },
    overview: {
        id: 'Menu.overview',
        defaultMessage: 'Overview',
        description: 'Overview'
    },
    gettingStarted: {
        id: 'Menu.gettingStarted',
        defaultMessage: 'Getting started guide',
        description: 'getting started guide'
    },
    techSpec: {
        id: 'Menu.techSpec',
        defaultMessage: 'Technical specification',
        description: 'techSpec'
    },
    account: {
        id: 'Menu.Account',
        defaultMessage: 'Account',
        description: 'Label for the Account'
    },
    accountSettings: {
        id: 'Menu.settings',
        defaultMessage: 'Account settings',
        description: 'Label for the Account settings option in the Account settings menu'
    },
    invite: {
        id: 'Menu.invite',
        defaultMessage: 'Invitations',
        description: 'Label for the Invitations option in the Account settings menu'
    },
    manageTeamMembers: {
        id: 'Menu.manageTeamMembers',
        defaultMessage: 'Manage team members',
        description: 'Label for the Manage team members option in the Account settings menu'
    },
    companyInfo: {
        id: 'Menu.companyInfo',
        defaultMessage: 'Company information',
        description: 'Label for the Company information option in the Account settings menu'
    },
    billingInformation: {
        id: 'Menu.billingInformation',
        defaultMessage: 'Billing information',
        description: 'Label for the Billing information option in the Account settings menu'
    },
    payments: {
        id: 'Menu.payments',
        defaultMessage: 'Payments',
        description: 'Label for the Payments option in the Account settings menu'
    },
    contactPreferences: {
        id: 'Menu.contactPreferences',
        defaultMessage: 'Contact preferences',
        description: 'Contact preferences label in Account menu'
    },
    plans: {
        id: 'Menu.plans',
        defaultMessage: 'Plans',
        description: 'Label for Plans'
    },
    errorsReporting: {
        id: 'Menu.errorsReporting',
        defaultMessage: 'Error Reporting',
        description: 'Label for Error Reporting'
    },
    vernacularNames: {
        id: 'Menu.vernacularNames',
        defaultMessage: 'Vernacular Names',
        description: 'Label for Vernacular Names'
    },
    supportAccountApi: {
        id: 'Menu.supportAccountApi',
        defaultMessage: 'Account and API',
        description: 'Account and API'
    },
   supportPlans: {
        id: 'Menu.supportPlans',
        defaultMessage: 'Plans',
        description: 'Plans'
    },
    supportDownload: {
        id: 'Menu.supportDownload',
        defaultMessage: 'Download',
        description: 'Download'
    },
    supportOsSelectAndBuild: {
        id: 'Menu.supportOsSelectAndBuild',
        defaultMessage: 'OS Select+Build',
        description: 'OS Select+Build'
    },
    supportTeamSpaces: {
        id: 'Menu.supportTeamSpaces',
        defaultMessage: 'Team Spaces',
        description: 'Team Spaces'
    },
    codeExamples: {
        id: 'Menu.codeExamples',
        defaultMessage: 'Code examples',
        description: 'Code examples'
    },
    apiTutorials: {
        id: 'Menu.apiTutorials',
        defaultMessage: 'API tutorials',
        description: 'API tutorials'
    },
    whatsNew: {
        id: 'Menu.whatsNew',
        defaultMessage: "What's new",
        description: "What's new"
    },
    legal: {
        id: 'Menu.legal',
        defaultMessage: 'Legal',
        description: 'Legal'
    },
    legalOverview: {
        id: 'Menu.legalOverview',
        defaultMessage: 'Overview',
        description: 'Menu item for the legal overview'
    },
    errorReportingTerms: {
        id: 'Menu.errorReportingTerms',
        defaultMessage: 'Error Reporting Terms',
        description: 'Error Reporting Terms'
    },
    vernacularNamesTerms: {
        id: 'Menu.vernacularNamesTerms',
        defaultMessage: 'Vernacular Names Terms',
        description: 'Vernacular Names Terms'
    },
    trialModeTerms: {
        id: 'Menu.trialModeTerms',
        defaultMessage: 'Trial Mode Terms',
        description: 'Trial Mode Terms'
    },
    frameworkContractTerms: {
        id: 'Menu.frameworkContractTerms',
        defaultMessage: 'Framework Contract (Partners) Terms',
        description: 'Framework Contract (Partners) Terms'
    },
    partnerContracts: {
        id: 'Menu.partnerContracts',
        defaultMessage: 'Partner Contracts',
        description: 'Partner Contracts'
    },
    sla: {
        id: 'Menu.sla',
        defaultMessage: 'Service Level Agreement',
        description: 'Service Level Agreement'
    },
    serviceStatus: {
        id: 'Menu.serviceStatus',
        defaultMessage: 'Service availability dashboard',
        description: 'Service availability dashboard'
    },
    brandLogo: {
        id: 'Menu.brandLogo',
        defaultMessage: 'Our brand logo',
        description: 'Our brand logo'
    },
    brandImportance: {
        id: 'Menu.brandImportance',
        defaultMessage: 'The importance of our brand',
        description: 'The importance of our brand'
    },
    logoOverview: {
        id: 'Menu.logoOverview',
        defaultMessage: 'Logo overview',
        description: 'Logo overview'
    },
    logoFullColour: {
        id: 'Menu.logoFullColour',
        defaultMessage: 'Logo overview - full colour',
        description: 'Logo overview - full colour'
    },
    logoWhite: {
        id: 'Menu.logoWhite',
        defaultMessage: 'Logo overview - white',
        description: 'Logo overview - white'
    },
    copyrightOverview: {
        id: 'Menu.copyrightOverview',
        defaultMessage: 'Copyright overview',
        description: 'Copyright overview'
    },
    logoCopyrightPositioning: {
        id: 'Menu.logoCopyrightPositioning',
        defaultMessage: 'Logo and copyright positioning',
        description: 'Logo and copyright positioning'
    },
    exclusionZone: {
        id: 'Menu.exclusionZone',
        defaultMessage: 'Exclusion zone',
        description: 'Exclusion zone'
    },
    minMax: {
        id: 'Menu.minMax',
        defaultMessage: 'Minimum and maximum',
        description: 'Minimum and maximum'
    },
    prohibitedUse: {
        id: 'Menu.prohibitedUse',
        defaultMessage: 'Prohibited use',
        description: 'Prohibited use'
    },
    back: {
        id: 'Menu.Back',
        defaultMessage: 'Back to main menu',
        description: 'Label for Back'
    },
    logOut: {
        id: 'Menu.LogOut',
        defaultMessage: 'Log out',
        description: 'Label for the Log out button'
    },
    dataHubHome: {
        defaultMessage: 'Data Hub home',
        description: 'Title for the Data Hub link',
        id: 'Menu.dataHubHome'
    },
    osLogo: {
        defaultMessage: 'Ordnance Survey logo',
        description: 'Alt text for the OS logo',
        id: 'Menu.osLogo'
    }
});

const menuToolTips = defineMessages({
    workspaceCallout: {
        defaultMessage: 'Your essential Data Hub items all in one place to help you work faster and smarter. Opting-in will redirect you to the Workspace page immediately, and you can opt-out at any time.',
        description: 'Workspace toggle callout',
        id: 'Header.workspaceCallout'
    },
    workspaceCalloutOff: {
        defaultMessage: 'Switch workspace off.',
        description: 'Workspace toggle callout turn off',
        id: 'Header.workspaceOff'
    },
    workspaceDisabled: {
        defaultMessage: 'To opt-into the Workspace preview, you must enable "Functional Cookies" and refresh the page so we can record your opt-in preference.',
        description: 'Workspace label when disabled',
        id: 'Header.workspaceDisabled'
    },
    workspaceDisabledDesktopTitle: {
        defaultMessage: 'Use of Functional Cookies is required to opt-into the workspace preview.',
        description: 'No Cookie Permission Dialog > Title',
        id: 'Header.workspaceDisabledDesktopTitle'
    },
    workspaceDisabledDesktopSummary: {
        defaultMessage: 'We use Functional Cookies to record your opt-in preferences on this device. Please enable "Functional Cookies" and then refresh the page.',
        description: 'No Cookie Permission Dialog > Body Content',
        id: 'Header.workspaceDisabledDesktopSummary'
    }
})

const workspaceMenuMessages = defineMessages({
    apis: {
        id: "Menu.APIS",
        defaultMessage: "APIs",
        description: "Label for API's option",
    },
    downloads: {
        id: "Menu.DownloadsSub",
        defaultMessage: "Downloads",
        description: "Label for Downloads option",
    },
    transactionHistory: {
        id: "Menu.TransactionHistory",
        defaultMessage: "Transaction history",
        description: "Label for Transaction history option",
    },
    codeExamples: {
        id: "Menu.CodeExamples",
        defaultMessage: "Code examples",
        description: "Label for Code examples option",
    },
    openData: {
        id: "Menu.OpenData",
        defaultMessage: "OpenData",
        description: "Label for OpenData option",
    },
    premiumData: {
        id: "Menu.PremiumData",
        defaultMessage: "Premium Data",
        description: "Label for Premium Data option",
    },
    ngdDocs: {
        id: "Menu.NGDDocs",
        defaultMessage: "NGD Documentation",
        description: "Label for NGD Documentation option",
    },
    apps: {
        id: "Menu.Apps",
        defaultMessage: "Apps",
        description: "Label for Apps option",
    },
    dataExplorer: {
        id: "Menu.DatahubExplorer",
        defaultMessage: "Data Hub Explorer",
        description: "Label for Data Hub Explorer option",
    },
    myTeam: {
        id: "Menu.MyTeam",
        defaultMessage: "My team",
        description: "Label for My team option",
    },
    myAccount: {
        id: "Menu.MyAccount",
        defaultMessage: "My account",
        description: "Label for My account option",
    },
    amendCookie: {
        id: "Menu.AmendCookie",
        defaultMessage: "Amend cookie preferences",
        description: "Button text for tooltip cookie amend"
    }
})

menuMessages = {
    ...menuMessages,
    ...menuToolTips,
    ...termsMessages,
    ...workspaceMenuMessages
}

export default menuMessages;
