import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { CommonDialog, CommonDialogActions } from "omse-components";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress, Typography } from "@mui/material";
import { getTeamSpaces } from "../../../modules/teamSpaces/actions";
import { API_PROJECT_PERMISSION_GROUPS, TEAM_PERMISSION_GROUPS } from "../../../../shared/teamSpaces";
import { MOVE_TEAMSPACE_OPTIONS, AUTOCOMPLETE_PREFIX } from "./constants";
import TeamSpaceSearch from "./TeamSpaceSearch";
import TeamSpaceDestinationOptions from "./TeamSpaceDestinationOptions";
import ConfirmationCodePrompt from "./ConfirmationCodePrompt";
import { generateRandomCode } from "./utils";
import { ENTER } from "../../../constants/keys";
import { isOrgAdmin } from "../util/teamSpaceUser";
import messages from "./DeleteTeamSpaceDialog.msg";
import styled from "@emotion/styled";

const DialogContainer = styled(CommonDialog)(({ theme }) => `
    & > div {
        display: flex;
        flex-direction: column
        align-items: center;
        margin-top: ${theme.spacing(3)};
    }
`);

export const DeleteTeamSpaceDialog = ({
    handleClose,
    confirmationAction,
    working,
    error,
    destinationTeamSpaceId,
    setDestinationTeamSpaceId,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const inputRef = useRef();

    const [inputtedCode, setInputtedCode] = useState("");
    const [code, setCode] = useState(generateRandomCode());
    const [autocompleteKey, setAutocompleteKey] = useState(AUTOCOMPLETE_PREFIX);
    const [filteredTeamSpaces, setFilteredTeamSpaces] = useState([]);
    const [radioValue, setRadioValue] = useState(MOVE_TEAMSPACE_OPTIONS.unassigned);
    
    const user = useSelector((state) => state.user.current.result);
    const teamSpaceResult = useSelector((state) => state.teamSpaces.getTeamSpace.result);
    const {result: teamSpacesResult, loading: teamSpacesLoading} = useSelector((state) => state.teamSpaces.getTeamSpaces);
    const hasTeamResources = !!(teamSpaceResult?.teamResources?.apiProjects.length);

    // Team Space - Target deleted
    useEffect(() => {
        setInputtedCode("");
        setCode(generateRandomCode());
        if (hasTeamResources && !teamSpacesResult) {
            dispatch(getTeamSpaces());
        }
    }, [dispatch, teamSpaceResult]);

    // Team Spaces - Set filtered Team Spaces:
    // Used as the destination for resources during the delete.
    useEffect(() => {
        if (user && teamSpacesResult?.length && teamSpaceResult) {
            // Filter Team Spaces to show any which are not the current Team Space
            // where the user has Owner/API project Editor access. 
            let newFilteredTeamSpaces;
            if (isOrgAdmin(user)) {
                newFilteredTeamSpaces = teamSpacesResult.filter(teamSpace => teamSpace.id !== teamSpaceResult.id);
            } else {
                newFilteredTeamSpaces = teamSpacesResult.filter(teamSpace => {
                    const hasOwnerApiProjectEditor = teamSpace.userRoles.teamRole === TEAM_PERMISSION_GROUPS.TeamOwner || teamSpace.userRoles.apiProjectRole === API_PROJECT_PERMISSION_GROUPS.ApiProjectEditor;
                    return (teamSpace.id !== teamSpaceResult.id && hasOwnerApiProjectEditor);
                });
            }
            setFilteredTeamSpaces(newFilteredTeamSpaces);
        }
    }, [teamSpacesResult, teamSpaceResult, user]);

    // Confirmation code - key event handler
    const handleKey = useCallback(
        (event) => {
            if (event.key === ENTER && inputtedCode === code) {
                event.preventDefault();
                if (confirmationAction) confirmationAction();
            }
        },
        [inputtedCode, code, confirmationAction]
    );

    // Confirmation Code - Focus on the input element
    // autoFocus of @mui Input in ineffective in a Dialog.
    const focus = () => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    };

    // Move resources - Option change
    function handleRadioChange(val) {
        setRadioValue(val);
        setDestinationTeamSpaceId(null);
        setCode(generateRandomCode());
        setInputtedCode("")
        if (val === MOVE_TEAMSPACE_OPTIONS.unassigned) {
            resetAutocomplete();
        }
    }

    // Move resources - Team Spaces search
    function resetAutocomplete() {
        setAutocompleteKey(`${AUTOCOMPLETE_PREFIX}-${Date.now()}`);
    }

    // Dialog confirmation
    function handleOnConfirm() {
        const selectedDesintationComplete =
            (radioValue === MOVE_TEAMSPACE_OPTIONS.unassigned ||
                (radioValue === MOVE_TEAMSPACE_OPTIONS.alternative && destinationTeamSpaceId));
        if (
            confirmationAction &&
                (!hasTeamResources || selectedDesintationComplete)                
        ) {
            confirmationAction();
        }
    }
    const confirmAllowed =
        (!hasTeamResources && inputtedCode === code) || // No Team resources
            (hasTeamResources && (inputtedCode === code) &&
            ((radioValue === MOVE_TEAMSPACE_OPTIONS.unassigned && !destinationTeamSpaceId) ||  // Move to unassigned selected
                (radioValue === MOVE_TEAMSPACE_OPTIONS.alternative && destinationTeamSpaceId)) // Move to Team Space selected
        ); 
    return (
        <DialogContainer
            onClose={handleClose}
            onEntered={focus}
            title={messages.title}
            actions={
                <CommonDialogActions
                    confirmLabel={messages.buttonLabel}
                    confirmAllowed={!!confirmAllowed}
                    onClose={handleClose}
                    onConfirm={handleOnConfirm}
                    showCancelButton={true}
                    working={working}
                />
            }
            error={error}
            closeAriaLabel={intl.formatMessage(messages.closeAriaLabel)}
        >
            <div>
                <Typography variant="h3" component="div" paragraph={true}>
                    <FormattedMessage {...messages.content1} />
                </Typography>
                {!hasTeamResources && (
                    <>
                        <Typography variant="h3" component="div" paragraph={true}>
                            <FormattedMessage
                                {...messages.content2}
                                values={{
                                    total: 0,
                                    resourceType: intl.formatMessage(messages.apiProject),
                                }}
                            />
                        </Typography>
                        <ConfirmationCodePrompt
                            code={code}
                            setCode={setCode}
                            inputtedCode={inputtedCode}
                            setInputtedCode={setInputtedCode}
                            handleKey={handleKey}
                            inputRef={inputRef}
                        />
                    </>
                )}
                {hasTeamResources && (
                    <div>
                        <Typography variant="body2" paragraph={true}>
                            <FormattedMessage
                                {...messages.selectDestination}
                                values={{
                                    total: teamSpaceResult.teamResources.apiProjects.length,
                                    resourceType: intl.formatMessage(messages.apiProject),
                                }}
                            />
                        </Typography>
                        <TeamSpaceDestinationOptions
                            setRadioValue={setRadioValue}
                            radioValue={radioValue}
                            filteredTeamSpaces={filteredTeamSpaces}
                            resetAutocomplete={resetAutocomplete}
                            handleRadioChange={handleRadioChange}
                        />
                        {teamSpacesLoading && (
                            <CircularProgress />
                        )}
                        {!!filteredTeamSpaces.length && (
                            <TeamSpaceSearch
                                filteredTeamSpaces={filteredTeamSpaces}
                                setDestinationTeamSpaceId={setDestinationTeamSpaceId}
                                autocompleteKey={autocompleteKey}
                                setRadioValue={setRadioValue}
                                resetAutocomplete={resetAutocomplete}
                                loading={teamSpacesLoading}
                            />
                        )}
                        
                        {!teamSpacesLoading && (
                            <ConfirmationCodePrompt
                                disabled={(radioValue === MOVE_TEAMSPACE_OPTIONS.alternative && !destinationTeamSpaceId)}
                                code={code}
                                setCode={setCode}
                                inputtedCode={inputtedCode}
                                setInputtedCode={setInputtedCode}
                                handleKey={handleKey}
                                inputRef={inputRef}                            
                            />
                        )}
                    </div>
                )}
            </div>
        </DialogContainer>
    );
};

DeleteTeamSpaceDialog.propTypes = {
    handleClose: PropTypes.func,
    confirmationAction: PropTypes.func,
    working: PropTypes.bool,
    error: PropTypes.string,
    teamSpaceResult: PropTypes.object,
    destinationTeamSpaceId: PropTypes.number,
    setDestinationTeamSpaceId: PropTypes.func,
};
