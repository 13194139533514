exports.latestTermsVersion = "1.6";
exports.latestApiTermsVersion = "4.4";
exports.latestDownloadTermsVersion = "1.1";
exports.latestEaiApiTermsVersion = "eai-1.0";

exports.generalTermsName = "datahubGeneralTerms";
exports.apiTermsName = "datahubApiTerms";
exports.downloadTermsName = "datahubDownloadTerms";
exports.eaiApiTermsName = "datahubEaiApiTerms"; //though we define this it should not be used as this field does not exist in dynamics.
exports.allTermsNames = [exports.generalTermsName, exports.apiTermsName, exports.downloadTermsName];

exports.allTermsLatestVersions = {};
exports.allTermsLatestVersions[exports.generalTermsName] = exports.latestTermsVersion;
exports.allTermsLatestVersions[exports.apiTermsName] = exports.latestApiTermsVersion;
exports.allTermsLatestVersions[exports.downloadTermsName] = exports.latestDownloadTermsVersion;
exports.allTermsLatestVersions[exports.eaiApiTermsName] = exports.latestEaiApiTermsVersion;

exports.premiumFreeLimitInPence = 100000;
exports.premiumFreeLimitInPounds = exports.premiumFreeLimitInPence / 100;

exports.NGDAD_PRODUCT_ID = "NGDAD";
exports.NGD_PRODUCT_ID = "OSNGD";

exports.RESUPPLY_REQUESTED = "RESUPPLY_REQUESTED";

exports.COST_TYPE_FREE = "free";
exports.COST_TYPE_PREMIUM = "premium";
exports.COST_TYPE_MIXED = "mixed";

exports.EAI_TIER_OVERAGE = "OVERAGE";
exports.EAI_TIER_1 = "TIER_1";
exports.EAI_TIER_2 = "TIER_2";
exports.EAI_TIER_3 = "TIER_3";

exports.PRODUCT_TYPES = {
    API_PROJECT: "apiProject",
    DATA_PACKAGE: "dataPackage",
};
