// This module contains page headings that can be used by generic pages. It is used by the NotLoggedIn and
// MissingPermission pages to put a suitable heading into the top of the page. There needs to be a heading
// for each non-public route in the app.

import {matchRoute} from "../util/routes";
import {defineMessages} from 'react-intl';
import {useLocation} from 'react-router';

const headings = defineMessages({
    account: {
        id: 'Headings.account',
        defaultMessage: 'Account settings',
        description: 'Heading for the account settings page'
    },
    billing: {
        id: 'Headings.billing',
        defaultMessage: 'Billing',
        description: 'Heading for the billing page'
    },
    payments: {
        id: 'Headings.payments',
        defaultMessage: 'Payments',
        description: 'Heading for the payments page'
    },
    accountInvitation: {
        id: 'Headings.accountInvitation',
        defaultMessage: 'Invitations',
        description: 'Heading for the invitations page'
    },
    manageTeamMembers: {
        id: 'Headings.manageTeamMembers',
        defaultMessage: 'Manage team members',
        description: 'Heading for the manage team members page'
    },
    companyInformation: {
        id: 'Headings.companyInformation',
        defaultMessage: 'Company information',
        description: 'Heading for the company information page'
    },
    contactPreferences: {
        id: 'Headings.contactPreferences',
        defaultMessage: 'Contact preferences',
        description: 'Heading for the contact preferences page'
    },
    history: {
        id: 'Headings.history',
        defaultMessage: 'API transaction history',
        description: 'Heading for the history page'
    },
    project: {
        id: 'Headings.project',
        defaultMessage: 'API project',
        description: 'Heading for the project page'
    },
    projects: {
        id: 'Headings.projects',
        defaultMessage: 'My projects',
        description: 'Heading for the projects page'
    },
    apis: {
        id: 'Headings.apis',
        defaultMessage: 'APIs',
        description: 'Heading for the APIs page'
    },
    workspace: {
        id: 'Headings.workspace',
        defaultMessage: 'Workspace',
        description: 'Heading for the Workspace page'
    },
    premiumDownloads: {
        id: 'Headings.premiumDownloads',
        defaultMessage: 'Premium data downloads',
        description: 'Heading for the premium data downloads page'
    },
    premiumItem: {
        id: 'Headings.premiumItem',
        defaultMessage: 'Premium data download',
        description: 'Heading for the premium data download page'
    },
    dataPackageNew: {
        id: 'Headings.dataPackageNew',
        defaultMessage: 'Add a data package',
        description: 'Heading for the new data package page'
    },
    dataPackageNewNgd: {
        id: 'Headings.dataPackageNewNgd',
        defaultMessage: 'Add a data package',
        description: 'Heading for the new data package page'
    },
    dataPackageNewNgdPowered: {
        id: 'Headings.dataPackageNewNgdPowered',
        defaultMessage: 'Add a data package',
        description: 'Heading for the new data package page'
    },
    dataPackageExpand: {
        id: 'Headings.dataPackageExpand',
        defaultMessage: 'Expand a data package',
        description: 'Heading for the expand a data package page'
    },
    dataPackages: {
        id: 'Headings.dataPackages',
        defaultMessage: 'Data packages',
        description: 'Heading for the data packages page'
    },
    dataPackage: {
        id: 'Headings.dataPackage',
        defaultMessage: 'Data package',
        description: 'Heading for the data package page'
    },
    manageLicences: {
        id: 'Headings.manageLicences',
        defaultMessage: 'Manage product licences',
        description: 'Heading for the manage licences page'
    },
    errorsAndOmissions: {
        id: 'Headings.errorsAndOmissions',
        defaultMessage: 'Error Reporting',
        description: 'Heading for the E&O welcome page'
    },
    errorReporting: {
        id: 'Headings.errorReporting',
        defaultMessage: 'My errors',
        description: 'Heading for the error reporting page'
    },
    recipeEditor: {
        id: 'Headings.recipeEditor',
        defaultMessage: 'Recipe editor',
        description: 'Heading for the recipe editor page'
    },
    recipeLibrary: {
        id: 'Headings.recipeLibrary',
        defaultMessage: 'Recipe library',
        description: 'Heading for the recipe library page'
    },
    recipeDetails: {
        id: 'Headings.recipeDetails',
        defaultMessage: 'Recipe details',
        description: 'Heading for the recipe details page'
    },
    premiumSetup: {
        id: 'Headings.premiumSetup',
        defaultMessage: 'Premium plan details',
        description: 'Heading for the premium setup page'
    },
    psgaSetup: {
        id: 'Headings.psgaSetup',
        defaultMessage: 'Public Sector Plan details',
        description: 'Heading for the PSGA setup page'
    },
    energyAndInfraSetup: {
        id: 'Headings.energyAndInfraSetup',
        defaultMessage: 'Energy and Infrastructure Plan details',
        description: 'Heading for the E&I setup page'
    },
    paymentSetup: {
        id: 'Headings.paymentSetup',
        defaultMessage: 'Premium plan payment details',
        description: 'Heading for the payment setup page'
    },
    invitation: {
        id: 'Headings.invitation',
        defaultMessage: 'Accept Invitation?',
        description: 'Heading for the invitation page'
    },
    vernacularNames: {
        id: 'Headings.vernacularNames',
        defaultMessage: 'Vernacular Names',
        description: 'Heading for the vernacular names page'
    },
    vernacularNamesReporting: {
        id: 'Headings.vernacularNamesReporting',
        defaultMessage: 'Vernacular Names Reporting Page',
        description: 'Heading for the vernacular names reporting page'
    },
    teamSpaces:{
        id: "Headings.teamSpaces",
        defaultMessage: "Team Spaces",
        description: "Team Spaces page title"
    },
    teamSpace:{
        id: "Headings.teamSpace",
        defaultMessage: "Team Space",
        description: "Team Space page title"
    },
    osNetRinexData: {
        id: "Headings.osNetRinexData",
        defaultMessage: 'OS Net RINEX data',
        description: 'Label for the OS Net RINEX data'
    }
});

export default function useHeading() {
    const location = useLocation();
    const route = matchRoute(location.pathname);
    if(route) {
        return headings[route.name];
    }
}
