import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { DropDownMenu, osColour } from "omse-components";
import { RESOURCE_TYPES } from "../../../shared/teamSpaces";
import teamSpaceMessages from "../TeamSpace.msg";
import { ReactComponent as OverflowIcon } from "../../components/icons/overflow-menu.svg";
import { useSelector } from "react-redux";
import { useTeamSpacePermission } from "./util/useTeamSpacePermission";
import styled from "@emotion/styled";

const StyledDropDownMenu = styled(DropDownMenu)`
    align-items: flex-end;    
    & li.deleteApiProject {
        color: ${osColour.primary.foxglove};
    }
`;

export const TeamResourceActionsMenu = ({ apiProject, setRemoveTeamResourceState, setApiProjectMoveDialogState }) => {
    const intl = useIntl();
    const user = useSelector((state) => state.user.current.result);
    const teamSpaceResult = useSelector((state) => state.teamSpaces.getTeamSpace?.result);

    const { canMoveApiProjects, canRemoveApiProjects, canDeleteApiProjects } = useTeamSpacePermission(user, teamSpaceResult);

    const menuItems = [];

    if (canMoveApiProjects) {
        menuItems.push({
            id: teamSpaceMessages.moveApiProject.id,
            value: teamSpaceMessages.moveApiProject.id,
            label: intl.formatMessage(teamSpaceMessages.moveApiProject),
            action: () => {
                setApiProjectMoveDialogState({
                    visible: true,
                    apiProjectId: apiProject.id,
                    apiProjectName: apiProject.name,
                });
            },
        });
    }

    if (canRemoveApiProjects) {
        menuItems.push({
            label: intl.formatMessage(teamSpaceMessages.removeApiProject),
            value: teamSpaceMessages.removeApiProject.id,
            id: teamSpaceMessages.removeApiProject.id,
            action: () => {
                setRemoveTeamResourceState({
                    resourceType: RESOURCE_TYPES.ApiProject,
                    resourceId: apiProject.id,
                    name: apiProject.name,
                });
            },
        });
    }

    if (canDeleteApiProjects) {
        menuItems.push({
            label: intl.formatMessage(teamSpaceMessages.deleteApiProject),
            value: teamSpaceMessages.deleteApiProject.id,
            id: teamSpaceMessages.deleteApiProject.id,
            action: () => {
                setRemoveTeamResourceState({
                    resourceType: RESOURCE_TYPES.ApiProject,
                    resourceId: apiProject.id,
                    name: apiProject.name,
                    deleteManagedResource: true,
                });
            },
            className: "deleteApiProject",
        });
    }

    if (menuItems.length) {
        return (
            <StyledDropDownMenu
                icon={<OverflowIcon height={24} width={24} />}
                placement="bottom-end"
                items={menuItems}
                buttonProps={{"aria-label": intl.formatMessage(teamSpaceMessages.apiProjectsActionsAriaLabel)}}
            />
        );
    }
};

TeamResourceActionsMenu.propTypes = {
    apiProject: PropTypes.shape({}).isRequired,
    setRemoveTeamResourceState: PropTypes.func.isRequired,
    setApiProjectMoveDialogState: PropTypes.func.isRequired,
}
