import {
    CircularProgress,
    Table,
} from "@mui/material";
import {createUseStyles} from "react-jss";
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { osColour } from "omse-components";
import { useMutation } from "@tanstack/react-query";
import { postDelApplication } from "../../../modules/downloads";
import routePaths, { useRedirect } from "../../../util/routes";

const messages = defineMessages({
    companyNameTitle:{
        id: 'DelApplyFormConfirmation.companyNameTitle',
        defaultMessage: 'Company or business name',
        description: 'Field Title for Company or business name'
    },
    organisationTypeTitle:{
        id: 'DelApplyFormConfirmation.organisationTypeTitle',
        defaultMessage: 'Organisation type',
        description: 'Field Title for Organisation type'
    },
    streetAddressTitle:{
        id: 'DelApplyFormConfirmation.streetAddressTitle',
        defaultMessage: 'Street address',
        description: 'Field Title for Street address'
    },
    addressLine2Title:{
        id: 'DelApplyFormConfirmation.addressLine2Title',
        defaultMessage: 'Address line 2',
        description: 'Field Title for Address line 2'
    },
    cityTitle:{
        id: 'DelApplyFormConfirmation.cityTitle',
        defaultMessage: 'City',
        description: 'Field Title for city'
    },
    countyTitle:{
        id: 'DelApplyFormConfirmation.countyTitle',
        defaultMessage: 'County',
        description: 'Field Title for county'
    },
    postCodeTitle:{
        id: 'DelApplyFormConfirmation.postCodeTitle',
        defaultMessage: 'Postcode',
        description: 'Field Title for Postcode'
    },
    countryTitle:{
        id: 'DelApplyFormConfirmation.countryTitle',
        defaultMessage: 'Country',
        description: 'Field Title for country'
    },
    jobTitleTitle:{
        id: 'DelApplyFormConfirmation.JobTitleTitle',
        defaultMessage: 'Job title',
        description: 'Field Title for Job title'
    },
    phoneNumberTitle:{
        id: 'DelApplyFormConfirmation.phoneNumberTitle',
        defaultMessage: 'Phone number',
        description: 'Field Title for Phone number'
    },
    usageIntentionsDropdownTitle:{
        id: 'DelApplyFormConfirmation.usageIntentionsDropdownTitle',
        defaultMessage: 'What do you plan to do with the data?',
        description: 'Usage intent drop down title'
    },
    internalBusinessUseTitle:{
        id: 'DelApplyFormConfirmation.internalBusinessUseTitle',
        defaultMessage: 'Internal business use only',
        description: 'Internal business use title'
    },
    internalBusinessUseTrueValue:{
        id: 'DelApplyFormConfirmation.internalBusinessUseTrueValue',
        defaultMessage: 'Yes',
        description: 'Internal business use true value'
    },
    internalBusinessUseFalseValue:{
        id: 'DelApplyFormConfirmation.internalBusinessUseFalseValue',
        defaultMessage: 'No',
        description: 'Internal business use false value'
    },
    licenceAcceptanceTitle:{
        id: 'DelApplyFormConfirmation.LicenceAcceptanceTitle',
        defaultMessage: 'Licence acceptance declaration',
        description: 'Licence acceptance declaration title'
    },
    licenceAcceptanceValue:{
        id: 'DelApplyFormConfirmation.LicenceAcceptanceValue',
        defaultMessage: 'I have read and understand the Data Exploration Licence and agree to the terms that will apply during and beyond the trial',
        description: 'Message stating the user has accepted the license for DEL'
    },
    applyButtonText :{
        id: 'DelApplyFormConfirmation.nextButtonText',
        defaultMessage: 'Submit application',
        description: 'Apply Button Text'
    },
    previousButtonText :{
        id: 'DelApplyFormConfirmation.previousButtonText',
        defaultMessage: 'Previous',
        description: 'Previous Button Text'
    }
});

const useStyles = createUseStyles(theme => ({
    infoTable: {
        width : theme.spacing(80),
        fontFamily: theme.typography.fontFamily,
        '& tr' : {
            color: osColour.neutral.stone,
            height: theme.spacing(5),
            borderBottom: `solid 1px ${osColour.neutral.mist}`,
            '& td' : {
                padding: theme.spacing(1),
                width: theme.spacing(40)
            }
        }
    },
    formContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    formContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.5),
        padding: theme.spacing(1.5),
        minWidth: theme.spacing(60),
        '& .MuiFormControl-root': {
            display: 'flex',
            flexDirection: 'column',
        }
    },
    errorText: {
        color: "#c63d54",
        fontWeight: 400,
        fontSize: "0.875rem"
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: theme.spacing(1.5)
    },
    spacer: {
        height: theme.spacing(3),
    },
    applyButton: {
        minWidth: theme.spacing(22)
    }
}));

export function DelApplyFormConfirmation({orgInfo, usageInfo, user, setApplicationSubmitted, setFormIndex}) {
    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();
    const redirect = useRedirect();

    {/*ToDo: Add test for this section once mock endpoint is supplied also remove console logs*/}
    const {mutate, isPending} = useMutation({
        mutationFn: value => postDelApplication(value),
        onSuccess:(res)=>{
            console.log("success");
            console.dir({...orgInfo, ...usageInfo});
            setApplicationSubmitted(true);
        },
        // ToDo: tests this section in MAGMA-380935 and add proper error handling
        onError:(err)=>{
            console.log(err);
            console.dir({...orgInfo, ...usageInfo});
        }
    });

    function buildRow(name, value) {
        return <tr>
            <td>
                <Typography variant="body">
                    <FormattedMessage {...name} />:
                </Typography>
            </td>
            <td>
                <Typography variant="body">
                    {value}
                </Typography>
            </td>
        </tr>;
    }

    return (
        <div className={classes.formContainer}>
            <div className={classes.formContentContainer}>
                <Table className={classes.infoTable}>
                    <tbody>
                        {buildRow(messages.companyNameTitle, orgInfo.organisation)}
                        {buildRow(messages.organisationTypeTitle, orgInfo.organisationType)}
                        {buildRow(messages.streetAddressTitle, orgInfo.streetAddress)}
                        {buildRow(messages.addressLine2Title, orgInfo.addressLine2)}
                        {buildRow(messages.cityTitle, orgInfo.city)}
                        {buildRow(messages.countyTitle, orgInfo.county)}
                        {buildRow(messages.postCodeTitle, orgInfo.postcode)}
                        {buildRow(messages.countryTitle, orgInfo.country)}
                        {buildRow(messages.jobTitleTitle, orgInfo.jobTitle)}
                        {buildRow(messages.phoneNumberTitle, orgInfo.phoneNumber)}
                    </tbody>
                </Table>
                <div className={classes.spacer}/>
                <Table className={classes.infoTable}>
                    <tbody>
                        {buildRow(messages.usageIntentionsDropdownTitle, usageInfo.usageType)}
                        {buildRow(messages.internalBusinessUseTitle, usageInfo.internalBusinessUse ? intl.formatMessage(messages.internalBusinessUseTrueValue) : intl.formatMessage(messages.internalBusinessUseFalseValue))}
                        {buildRow(messages.licenceAcceptanceTitle, intl.formatMessage(messages.licenceAcceptanceValue))}
                    </tbody>
                </Table>
                <div className={classes.buttonContainer}>
                    <Button variant="outlined" onClick={() => {setFormIndex(1)}}>
                        <FormattedMessage {...messages.previousButtonText} />
                    </Button>
                    <Button variant="contained" type="submit" className={classes.applyButton} onClick={() => {
                        mutate({...orgInfo, ...usageInfo, userId: user.id});
                    }}>
                        {isPending ? <CircularProgress color={"inherit"} size={24} data-testid="loading-spinner"/> :
                        <FormattedMessage {...messages.applyButtonText} />}
                    </Button>
                </div>
            </div>
        </div>
    )
}

DelApplyFormConfirmation.propTypes = {
    setShowForm: PropTypes.func
}