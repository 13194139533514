import { defineMessages } from "react-intl";

const moveAPIProjectCompleteMessages = defineMessages({
    title: {
        id: "moveAPIProjectCompleteMessages.title",
        defaultMessage: "API Project Moved Successfully",
        description: "Move API Project > Complete > Title",
    },
    summary: {
        id: "moveAPIProjectCompleteMessages.summary",
        defaultMessage: "{apiProjectName} has been moved to {newTeamSpaceNameLink}.",
        description: "Move API Project > Complete > Details",
    },
    regenerateApiKey: {
        id: "moveAPIProjectCompleteMessages.regenerateApiKey",
        defaultMessage: "Moving this API Project to another Team Space won't prevent organisation members with access to the existing API key from using this API Project. You may want to consider regenerating your API key.",
        description: "Move API Project > Complete > API Key Reminder",
    },
});

export default moveAPIProjectCompleteMessages;
