const { TEAM_PERMISSION_GROUPS } = require("../../../../shared/teamSpaces");
const {
    CONTRACTOR_READ_ONLY_ROLE,
    CONTRACTOR_ROLE,
    ADMIN_ROLE,
    USER_ROLE,
} = require("../../../../shared/roles");

function isContractorReadOnly(user) {
    return user.role === CONTRACTOR_READ_ONLY_ROLE;
}

function isContractorReadWrite(user) {
    return user.role === CONTRACTOR_ROLE;
}

function isContractor(user) {
    return !!(isContractorReadWrite(user) || isContractorReadOnly(user));
}

function isOrgAdmin(user) {
    return user.role === ADMIN_ROLE;
}

function isUser(user) {
    return user.role === USER_ROLE;
}

function getTeamSpaceOwners(teamSpace) {
    return teamSpace?.teamMembers.filter(
        (teamMember) => teamMember.teamRole === TEAM_PERMISSION_GROUPS.TeamOwner
    );
}

function getTeamSpaceMembers(teamSpace) {
    return teamSpace?.teamMembers.filter(
        (teamMember) => teamMember.teamRole === TEAM_PERMISSION_GROUPS.TeamViewer
    );
}

function formatContactName(contact) {
    return contact && (contact.firstName + " " +contact.lastName)
}

exports.isContractorReadWrite = isContractorReadWrite
exports.isContractorReadOnly = isContractorReadOnly
exports.isContractor = isContractor;
exports.isOrgAdmin = isOrgAdmin;
exports.isUser = isUser;
exports.getTeamSpaceOwners = getTeamSpaceOwners;
exports.getTeamSpaceMembers = getTeamSpaceMembers;
exports.formatContactName = formatContactName;