const { defineMessages } = require("react-intl");

const createTeamErrorMessages = defineMessages({
    genericServerError: {
        id: "createTeamErrorMessages.genericServerError",
        description: "User interaction error > Generic Server Error",
        defaultMessage: "We ran into a problem our end creating that team. Please try again and if the problem persists, please {link}",
    },
});

export default createTeamErrorMessages;
