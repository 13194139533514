import { useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { osColour } from "omse-components";
import SearchBox from "../../../components/SearchBox";
import moveAPIProjectResultsMessages from "./MoveAPIProjectResults.msg";
import { ReactComponent as InfoIcon } from "../../../components/icons/info-notification.svg";
import { ReactComponent as TeamSpaceIcon } from "../../../components/icons/teamSpace.svg";
import { ReactComponent as TeamSpaceLockedIcon } from "../../../components/icons/teamSpaceLocked.svg";
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Radio,
    Typography,
    ClickAwayListener,
    IconButton,
    Tooltip,
    FormControl,
    FormControlLabel,
    RadioGroup,
} from "@mui/material";

const StyledSearchRegion = styled(Box)`
    margin: 0 0 0.6em 0;

    & > div:last-of-type {
        padding: 0.4em 0 0.2em 0;
        display: flex;
        align-items: center;
        gap: 0.4em;
        color: ${osColour.neutral.stone};
    }
`;

const TeamSpaceName = styled(Box)`
    display: flex;
    gap: 1em;
    place-items: center;

    & > svg {
        flex-shrink: 0;
        width: 2.2em;
        height: 1.8em;
    }
`;

const StyledTableContainer = styled(TableContainer)`
    margin: 0;
    width: auto;
    height: 70%;
    border-radius: 0.2em;
    border: 1px solid ${osColour.neutral.clouds};

    /*
        If a FormControl is used the immediate descendent of 
        this container may NOT always be a table.
    */

    & table > thead > tr {
        border-bottom: 2px solid ${osColour.primary.berry};
    }

    & table > thead > tr > th {
        font-weight: bold;
        color: ${osColour.primary.berry};
    }

    & table > tbody > tr > td {
        font-weight: normal;
    }
`;

const MoveAPIProjectResults = ({ teamSpaces, selectedTeamSpace, setSelectedTeamSpace }) => {
    const intl = useIntl();
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [teamSpacesSearch, setTeamSpacesSearch] = useState("");
    const [teamSpacesFiltered, setTeamSpacesFiltered] = useState(teamSpaces);

    const handleSearchEvent = (term) => {
        setTeamSpacesSearch(term);

        if (term === "") {
            setTeamSpacesFiltered(teamSpaces);
            return;
        }

        setTeamSpacesFiltered(
            teamSpaces.filter((teamSpace) =>
                teamSpace.name?.toLowerCase().includes(term.toLowerCase())
            )
        );
    };

    const handleRowSelect = (e) => {
        const id = Number(e.target.value);
        setSelectedTeamSpace(id);
    };

    return (
        <>
            <StyledSearchRegion>
                <SearchBox
                    label={moveAPIProjectResultsMessages.searchLabel}
                    placeholder={moveAPIProjectResultsMessages.searchPlaceholder}
                    autofocus={true}
                    search={teamSpacesSearch}
                    setSearch={handleSearchEvent}
                />
                <Box>
                    <Typography variant="body1">
                        {intl.formatMessage(moveAPIProjectResultsMessages.cannotFind)}
                    </Typography>
                    <ClickAwayListener onClickAway={() => setTooltipVisible(false)}>
                        <Tooltip
                            arrow
                            open={tooltipVisible}
                            onClose={() => setTooltipVisible(false)}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={intl.formatMessage(
                                moveAPIProjectResultsMessages.cannotFindTooltip,
                                {
                                    strong: (chunks) => <strong>{chunks}</strong>,
                                }
                            )}
                        >
                            <IconButton size="small" onClick={() => setTooltipVisible(true)}>
                                <InfoIcon
                                    width={24}
                                    height={24}
                                    color={osColour.status.warning}
                                    aria-label={intl.formatMessage(
                                        moveAPIProjectResultsMessages.cannotFindButton
                                    )}
                                />
                            </IconButton>
                        </Tooltip>
                    </ClickAwayListener>
                </Box>
            </StyledSearchRegion>
            <StyledTableContainer component={Box}>
                <FormControl fullWidth={true}>
                    <RadioGroup name="selectedTargetTeamSpace">
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        {intl.formatMessage(
                                            moveAPIProjectResultsMessages.col1Title
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {intl.formatMessage(
                                            moveAPIProjectResultsMessages.col2Title
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {teamSpacesFiltered.map((teamSpace) => {
                                    const isSelected = selectedTeamSpace === teamSpace.id;
                                    return (
                                        <TableRow key={teamSpace.id} selected={isSelected}>
                                            <TableCell>
                                                <FormControlLabel
                                                    value={teamSpace.id}
                                                    control={<Radio />}
                                                    checked={isSelected}
                                                    onChange={handleRowSelect}
                                                    label={
                                                        <TeamSpaceName>
                                                            {teamSpace.locked ? (
                                                                <TeamSpaceLockedIcon
                                                                    aria-label={intl.formatMessage(
                                                                        moveAPIProjectResultsMessages.teamSpaceIconLocked
                                                                    )}
                                                                />
                                                            ) : (
                                                                <TeamSpaceIcon
                                                                    aria-label={intl.formatMessage(
                                                                        moveAPIProjectResultsMessages.teamSpaceIconUnlocked
                                                                    )}
                                                                />
                                                            )}
                                                            {teamSpace.name}
                                                        </TeamSpaceName>
                                                    }
                                                />
                                            </TableCell>
                                            <TableCell>{teamSpace.description}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </RadioGroup>
                </FormControl>
            </StyledTableContainer>
        </>
    );
};

export default MoveAPIProjectResults;

MoveAPIProjectResults.propTypes = {
    teamSpaces: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
            locked: PropTypes.bool.isRequired,
            description: PropTypes.string.isRequired,
        })
    ).isRequired,
    selectedTeamSpace: PropTypes.number.isRequired,
    setSelectedTeamSpace: PropTypes.func.isRequired,
};
