import {useForm} from "@tanstack/react-form";
import {
    DialogContent,
    MenuItem,
    TextField
} from "@mui/material";
import {createUseStyles} from "react-jss";
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import Button from "@mui/material/Button";
import { postcodeValidator  } from "postcode-validator";
import { isValidPhoneNumber } from "libphonenumber-js";

const messages = defineMessages({
    companyNameTitle:{
        id: 'DelApplyFormOrgInfo.companyNameTitle',
        defaultMessage: 'Company or business name',
        description: 'Field Title for Company or business name'
    },
    companyNameRequired:{
        id: 'DelApplyFormOrgInfo.companyNameRequired',
        defaultMessage: "Please enter your company or business name",
        description: 'Company or business name required message'
    },
    organisationTypeTitle:{
        id: 'DelApplyFormOrgInfo.organisationTypeTitle',
        defaultMessage: 'Organisation type',
        description: 'Field Title for Organisation type'
    },
    organisationTypeRequired:{
        id: 'DelApplyFormOrgInfo.organisationTypeRequired',
        defaultMessage: 'Please enter your organisation type',
        description: 'Organisation type required message'
    },
    streetAddressTitle:{
        id: 'DelApplyFormOrgInfo.streetAddressTitle',
        defaultMessage: 'Street address',
        description: 'Field Title for Street address'
    },
    streetAddressRequired:{
        id: 'DelApplyFormOrgInfo.streetAddressRequired',
        defaultMessage: 'Please enter your street address',
        description: 'Street address required message'
    },
    addressLine2Title:{
        id: 'DelApplyFormOrgInfo.addressLine2Title',
        defaultMessage: 'Address line 2',
        description: 'Field Title for Address line 2'
    },
    cityTitle:{
        id: 'DelApplyFormOrgInfo.cityTitle',
        defaultMessage: 'City',
        description: 'Field Title for city'
    },
    countyTitle:{
        id: 'DelApplyFormOrgInfo.countyTitle',
        defaultMessage: 'County',
        description: 'Field Title for county'
    },
    postCodeTitle:{
        id: 'DelApplyFormOrgInfo.postCodeTitle',
        defaultMessage: 'Postcode',
        description: 'Field Title for Postcode'
    },
    postCodeRequired:{
        id: 'DelApplyFormOrgInfo.postCodeRequired',
        defaultMessage: 'Please enter your postcode',
        description: 'Postcode required message'
    },
    postCodeInvalid:{
        id: 'DelApplyFormOrgInfo.postCodeInvalid',
        defaultMessage: 'Please enter a valid postcode',
        description: 'Postcode invalid message'
    },
    countryTitle:{
        id: 'DelApplyFormOrgInfo.countryTitle',
        defaultMessage: 'Country',
        description: 'Field Title for country'
    },
    jobTitleTitle:{
        id: 'DelApplyFormOrgInfo.JobTitleTitle',
        defaultMessage: 'Job title',
        description: 'Field Title for Job title'
    },
    jobTitleRequired:{
        id: 'DelApplyFormOrgInfo.JobTitleRequired',
        defaultMessage: 'Please enter your job title',
        description: 'Job title required message'
    },
    phoneNumberTitle:{
        id: 'DelApplyFormOrgInfo.phoneNumberTitle',
        defaultMessage: 'Phone number',
        description: 'Field Title for Phone number'
    },
    phoneNumberRequired:{
        id: 'DelApplyFormOrgInfo.phoneNumberRequired',
        defaultMessage: 'Please enter your phone number',
        description: 'Phone number required message'
    },
    phoneNumberInvalid:{
        id: 'DelApplyFormOrgInfo.phoneNumberInvalid',
        defaultMessage: 'Please enter a valid phone number consisting of numbers and these special characters +-()',
        description: 'Phone number invalid message'
    },
    publicSectorOrganisation:{
        id: 'DelApplyFormOrgInfo.publicSectorOrganisation',
        defaultMessage: 'Public sector organisation',
        description: 'Organisation type Public sector organisation'
    },
    corporationBusiness:{
        id: 'DelApplyFormOrgInfo.corporationBusiness',
        defaultMessage: 'Corporation/business',
        description: 'Organisation type Corporation/business'
    },
    soleTrader:{
        id: 'DelApplyFormOrgInfo.soleTrader',
        defaultMessage: 'Sole trader',
        description: 'Organisation type Sole trader'
    },
    education:{
        id: 'DelApplyFormOrgInfo.education',
        defaultMessage: 'Education',
        description: 'Organisation type Education'
    },
    privateNonCommercial :{
        id: 'DelApplyFormOrgInfo.privateNonCommercial',
        defaultMessage: 'Private individual (non-commercial use)',
        description: 'Organisation type Private individual (non-commercial use)'
    },
    other :{
        id: 'DelApplyFormOrgInfo.other',
        defaultMessage: 'Other',
        description: 'Organisation type Other'
    },
    nextButtonText :{
        id: 'DelApplyFormOrgInfo.nextButtonText',
        defaultMessage: 'Next',
        description: 'Next Button Text'
    },
    cancelButtonText :{
        id: 'DelApplyFormOrgInfo.cancelButtonText',
        defaultMessage: 'Cancel',
        description: 'Cancel Button Text'
    },
    fieldTooLongError: {
        id:'sampleDataForm.fieldTooLongError',
        defaultMessage: '{fieldName} must be no more than {maxLength} characters long',
        description: 'Field too long error message'
    }
});

const useStyles = createUseStyles(theme => ({
    formContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    formContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.5),
        padding: theme.spacing(1.5),
        minWidth: theme.spacing(60),
        '& .MuiFormControl-root': {
            display: 'flex',
            flexDirection: 'column',
        }
    },
    errorText: {
        color: "#c63d54",
        fontWeight: 400,
        fontSize: "0.875rem",
        maxWidth: theme.spacing(56),
        display: "block"
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: theme.spacing(1.5),
        '& button': {
            width: theme.spacing(10)
        }
    }
}));

// Default form values when there is no user
const defaultFormValues = {
    organisation: '',
    organisationType: '',
    streetAddress: '',
    addressLine2: '',
    city: '',
    county: '',
    postcode: '',
    country: '',
    jobTitle: '',
    phoneNumber: '',
};

// Default form values when user is logged in
function prePopulateFormData(user, orgInfo){
    user = user ? user : {};
    orgInfo.jobTitle = orgInfo.jobTitle || user.jobTitle || '';
    orgInfo.phoneNumber = orgInfo.phoneNumber || user.telephoneNumber || '';
    orgInfo.organisation = orgInfo.organisation || user.org || '';
    return orgInfo;
}

export function DelApplyFormOrgInfo({orgInfo, setOrgInfo, setFormIndex, user, setShowCancelDialog}) {
    const classes = useStyles();
    const intl = useIntl();
    const form = useForm({
        defaultValues: {
            ...prePopulateFormData(user, orgInfo)
        },
        onSubmit: async ({value}) => {
            setOrgInfo(value);
            setFormIndex(1);
        }
    });

    function validatePhoneNumber(value) {
        if (value.length < 1) return intl.formatMessage(messages.phoneNumberRequired);
        if (!isValidPhoneNumber(value, 'GB')) return intl.formatMessage(messages.phoneNumberInvalid);

    }

    function validatePostCode(value) {
        if (value.length < 1) return intl.formatMessage(messages.postCodeRequired);
        if (!postcodeValidator(value, 'GB')) return intl.formatMessage(messages.postCodeInvalid);

    }

    function validateFieldLength(fieldName, value, maxLength) {
        if (value.length > maxLength) return intl.formatMessage(messages.fieldTooLongError, {fieldName, maxLength});
    }

    const errorMessage = ({message}) => {
        return <span aria-live={"polite"} role={"alert"} className={classes.errorText}>{message}</span>
    }

    return (
        <div className={classes.formContainer}>
        <form
            onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                void form.handleSubmit();
            }}>
            <DialogContent className={classes.formContentContainer}>
                <form.Field
                    name="organisation"
                    validators={{
                        onBlur: ({ value }) => {
                            if (value.length < 1) return intl.formatMessage(messages.companyNameRequired);
                            return validateFieldLength("organisation", value, 126);
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            required={true}
                            label={intl.formatMessage(messages.companyNameTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({ message: field.state.meta.errors })} />
                    )} />

                <form.Field
                    name="organisationType"
                    validators={{
                        onBlur: ({ value }) => {
                            if (value === "") return intl.formatMessage(messages.organisationTypeRequired);
                        }
                    }}
                    children={field => (
                        <TextField
                            select
                            id={field.name}
                            required={true}
                            label={intl.formatMessage(messages.organisationTypeTitle)}
                            defaultValue={intl.formatMessage(messages.organisationTypeTitle)}
                            value={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({ message: field.state.meta.errors })}>
                            <MenuItem value="Public sector organisation"><FormattedMessage {...messages.publicSectorOrganisation} /></MenuItem>
                            <MenuItem value="Corporation/business"><FormattedMessage {...messages.corporationBusiness} /></MenuItem>
                            <MenuItem value="Sole trader"><FormattedMessage {...messages.soleTrader} /></MenuItem>
                            <MenuItem value="Education"><FormattedMessage {...messages.education} /></MenuItem>
                            <MenuItem value="Private individual (non-commercial use)"><FormattedMessage {...messages.privateNonCommercial} /></MenuItem>
                            <MenuItem value="Other"><FormattedMessage {...messages.other} /></MenuItem>
                        </TextField>
                    )} />

                <form.Field
                    name="streetAddress"
                    validators={{
                        onBlur: ({ value }) => {
                            if (value.length < 1) return intl.formatMessage(messages.streetAddressRequired);
                            return validateFieldLength("streetAddress", value, 126);
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            required={true}
                            label={intl.formatMessage(messages.streetAddressTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({ message: field.state.meta.errors })} />
                    )} />

                <form.Field
                    name="addressLine2"
                    validators={{
                        onBlur: ({ value }) => {
                            return validateFieldLength("addressLine2", value, 126);
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            label={intl.formatMessage(messages.addressLine2Title)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({ message: field.state.meta.errors })} />
                    )} />

                <form.Field
                    name="city"
                    validators={{
                        onBlur: ({ value }) => {
                            return validateFieldLength("city", value, 126);
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            label={intl.formatMessage(messages.cityTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({ message: field.state.meta.errors })} />
                    )} />

                <form.Field
                    name="county"
                    validators={{
                        onBlur: ({ value }) => validateFieldLength("county", value, 126)
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            label={intl.formatMessage(messages.countyTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({ message: field.state.meta.errors })} />
                    )} />

                <form.Field
                    name="postcode"
                    validators={{
                        onBlur: ({ value }) => {
                            return validatePostCode(value);
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            required={true}
                            label={intl.formatMessage(messages.postCodeTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({ message: field.state.meta.errors })} />
                    )} />

                <form.Field
                    name="country"
                    validators={{
                        onBlur: ({ value }) => validateFieldLength("country", value, 126)
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            label={intl.formatMessage(messages.countryTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({ message: field.state.meta.errors })} />
                    )} />

                <form.Field
                    name="jobTitle"
                    validators={{
                        onBlur: ({ value }) => {
                            if (value.length < 1) return intl.formatMessage(messages.jobTitleRequired);
                            return validateFieldLength("country", value, 126);
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            label={intl.formatMessage(messages.jobTitleTitle)}
                            required={true}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({ message: field.state.meta.errors })} />
                    )} />

                <form.Field
                    name="phoneNumber"
                    validators={{
                        onMount: ({ fieldApi, value }) => {
                            // The DataHub phone number is subject to less strict validation than in this form.
                            // This code ensures that if the phone number is pre-populated from the user object then
                            // it is validated onMount and shows the validation error in the UI straight away. It is
                            // conditional on value so that we don't immediately show the phoneNumberRequired error
                            // if the phone number is not pre-populated.
                            if (value) {
                                return validatePhoneNumber(value);
                            }
                        },
                        onBlur: ({fieldApi, value}) => {
                            // cleanup the onMount error the first to be run onBlur
                            const errorMap = fieldApi.state.meta.errorMap;
                            delete errorMap.onMount;

                            return validatePhoneNumber(value);
                        }
                    }}
                    children={field => (
                        <TextField
                            id={field.name}
                            required={true}
                            label={intl.formatMessage(messages.phoneNumberTitle)}
                            defaultValue={field.state.value}
                            onChange={(e) => field.handleChange(e.target.value)}
                            onBlur={field.handleBlur}
                            error={field.state.meta.errors.length > 0}
                            helperText={errorMessage({ message: field.state.meta.errors })} />
                    )} />
            </DialogContent>
            <div className={classes.buttonContainer}>
                <Button variant="outlined" onClick={() => {setShowCancelDialog(true)}}>
                    <FormattedMessage {...messages.cancelButtonText} />
                </Button>
                <Button variant="contained" type="submit">
                    <FormattedMessage {...messages.nextButtonText} />
                </Button>
            </div>
        </form>
        </div>
    )
}

DelApplyFormOrgInfo.propTypes = {
    setShowForm: PropTypes.func
}