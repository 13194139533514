import { defineMessages } from "react-intl";

const newTeamSpaceMessages = defineMessages({
    openButton: {
        id: "newTeamSpaceMessages.openButton",
        defaultMessage: "Create Team Space",
        description: "Create Team Space Dialog-Open Button",
    },
    backButton: {
        id: "newTeamSpaceMessages.backButton",
        defaultMessage: "Back",
        description: "New Team Space > Back Button",
    },
    nextButton: {
        id: "newTeamSpaceMessages.nextButton",
        defaultMessage: "Next",
        description: "New Team Space > Next Button",
    },
    doneButton: {
        id: "newTeamSpaceMessages.doneButton",
        defaultMessage: "Create Team Space",
        description: "New Team Space > Done Button",
    },
    cancelButton: {
        id: "newTeamSpaceMessages.cancelButton",
        defaultMessage: "Cancel",
        description: "New Team Space > Cancel Button",
    },
    stepLabelDetails: {
        id: "newTeamSpaceMessages.stepLabelDetails",
        defaultMessage: "Name",
        description: "New Team Space > Stepper > Step 1 (Details)",
    },
    stepLabelAccess: {
        id: "newTeamSpaceMessages.stepLabelAccess",
        defaultMessage: "Access",
        description: "New Team Space > Stepper > Step 2 (Access)",
    },
    alreadySelected: {
        id: "newTeamSpaceMessages.alreadySelected",
        defaultMessage: "This user has already been selected as {tab}",
        description: "Already selected within {tab} tab",
    },
    youSuffix: {
        id: "newTeamSpaceMessages.youSuffix",
        defaultMessage: " (you)",
        description: "You suffix",
    },
});

export default newTeamSpaceMessages;
