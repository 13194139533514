import { defineMessages } from "react-intl";

const apiProjectsTableMessages = defineMessages({
    apiProjectName: {
        id: "ApiProjectsTable.apiProject",
        defaultMessage: "API Project",
        description: "Heading for the API Project column in the table",
    },
    createdAt: {
        id: "ApiProjectsTable.createdAt",
        defaultMessage: "Updated",
        description: "Heading for the createdAt date column in the table",
    },
    mode: {
        id: "ApiProjectsTable.mode",
        defaultMessage: " ",
        description: "Heading for the mode column in the table",
    },
    more: {
        id: "ApiProjectsTable.more",
        defaultMessage: " ",
        description: "Heading for the more column in the table",
    },
    live: {
        id: "ApiProjectsTable.live",
        defaultMessage: "Live",
        description: "Label for the live mode indicator",
    },
    dev: {
        id: "ApiProjectsTable.dev",
        defaultMessage: "Dev",
        description: "Label for the dev mode indicator",
    },
});

export default apiProjectsTableMessages