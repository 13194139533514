const { defineMessages } = require("react-intl");
const { USER_ROLE, ADMIN_ROLE, FINANCE_ROLE, CONTRACTOR_ROLE, CONTRACTOR_READ_ONLY_ROLE, OS_INTERNAL_ROLE } = require("./roles");

const roleMessages = defineMessages({
    [USER_ROLE]: {
        id: "memberInfoMessages.User",
        defaultMessage: "User",
        description: "Prettified User Role Title",
    },
    [ADMIN_ROLE]: {
        id: "memberInfoMessages.Admin",
        defaultMessage: "Admin",
        description: "Prettified Admin Role Title",
    },
    [FINANCE_ROLE]: {
        id: "memberInfoMessages.Finance",
        defaultMessage: "Finance",
        description: "Prettified Finance Role Title",
    },
    [CONTRACTOR_ROLE]: {
        id: "memberInfoMessages.ContractorReadWriteAccess",
        defaultMessage: "Contractor (Read/Write)",
        description: "Prettified Contractor RW Role Title",
    },
    [CONTRACTOR_READ_ONLY_ROLE]: {
        id: "memberInfoMessages.ContractorReadAccess",
        defaultMessage: "Contractor (Read-Only)",
        description: "Prettified Contractor RO Role Title",
    },
    [OS_INTERNAL_ROLE]: {
        id: "memberInfoMessages.OSInternal",
        defaultMessage: "OS Internal",
        description: "Prettified OS Internal Role Title",
    },
});

module.exports = roleMessages;
