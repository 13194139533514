import { useEffect, useId, useMemo, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { CircularProgress, Tab, Tabs, Box, Typography } from "@mui/material";
import styled from "@emotion/styled";
import {
    contentPadding,
    DropDownMenu,
    osColour,
    Notification,
    ExternalLink,
} from "omse-components";
import FeatureCheck from "../components/FeatureCheck";
import {
    clearTeamSpace,
    createTeamResourceClear,
    getOrgUsers,
    getTeamSpace,
    removeTeamResource,
    removeTeamResourceClear,
} from "../modules/teamSpaces/actions";
import { TEAM_SPACES } from "../../shared/features";
import { RESOURCE_TYPES } from "../../shared/teamSpaces";
import teamSpaceMessages from "./TeamSpace.msg";
import TeamSpaceHeader from "./teamSpaces/TeamSpaceHeader";
import {
    apiProjectMap,
    apiProjectSortFunction,
    apiProjectSortOptions,
} from "./teamSpaces/sort/teamSpace";
import TeamSpaceError from "./teamSpaces/TeamSpaceError";
import AddAPIProject from "./teamSpaces/addResource/AddAPIProject";
import { DeleteAPIProjectDialog } from "./teamSpaces/removeResource/DeleteAPIProjectDialog";
import MoveAPIProject from "./teamSpaces/moveResource/MoveAPIProject";
import { RemoveResourceDialog } from "./teamSpaces/removeResource/RemoveResourceDialog";
import { getTeamSpacesErrorMessage } from "./teamSpaces/util/getTeamSpacesErrorMessage";
import removeErrors from "./teamSpaces/removeResource/removeTeamSpaceResourceErrors.msg";
import deleteErrors from "./teamSpaces/removeResource/deleteTeamSpaceResourceErrors.msg";
import { useTeamSpacePermission } from "./teamSpaces/util/useTeamSpacePermission";
import ResourceEmptyState from "./teamSpaces/resources/ResourceEmptyState";
import { PRODUCT_TYPES } from "../../shared/constants";
import SearchBox from "../components/SearchBox";
import ApiProjectsTable from "./teamSpaces/ApiProjectsTable";
import { isContractor } from "./teamSpaces/util/teamSpaceUser";

const ContentContainer = styled("section")(
    ({ theme }) => `
    padding-left: ${contentPadding.left}px;
    padding-right: ${contentPadding.right}px;
    padding-top: ${contentPadding.top / 4}px;
    padding-bottom: ${contentPadding.bottom}px;
    max-width: ${contentPadding.maxWidth}px;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    gap: 1em;

    ${theme.breakpoints.down("sm")} {
        padding: ${contentPadding.mobile};
    }

    ${theme.breakpoints.up("lg")} {
        /* INTERIM UNTIL TEAM SPACES IS
            LOCATED IN THE SECONDARY NAV */
        margin: 0 auto;
        min-width: ${contentPadding.maxWidth}px;
    }

    ul.apiProjects > li {
        display: flex;
        justify-content: space-between;
    }
`
);

const FilterControls = styled(Box)(
    ({ theme }) => `
    & > div:first-of-type {
        display: flex;
        gap: 1em;
        align-items: end;
        flex-direction: row;
        ${theme.breakpoints.down("md")} {
            gap: 0.4em;
            align-items: stretch;
            flex-direction: column;
        }
    }

    & > div:first-of-type > div:first-of-type {
        flex-grow: 1;
    }

    & > div:first-of-type > div:last-of-type {
        // Prevents layout shift.
        ${theme.breakpoints.up("md")} {
            min-width: 22em;
        }
    }

    & > div:last-of-type {
        margin: 0.6em 0 0 0;
    }

    & > div:last-of-type > .error {
        color: ${osColour.status.error};
    }
`
);

const StyledTabs = styled(Tabs)`
    overflow: visible;
    border-bottom: solid 1px ${osColour.neutral.mist};

    & .MuiTabs-flexContainer {
        justify-content: space-between;
        align-items: baseline;
    }

    & > div > div > button:not([role="tab"]) {
        margin-left: auto;
    }

    & > div > div > button[role="tab"] {
        font-size: 1em;
        margin: 0.4em 0.6em 0.6em 0.4em;
        padding: 0 0.4em;
        min-height: 2.2em;
    }

    & > div > .indicator {
        background: ${osColour.primary.berry};
        box-shadow: -0.4em 0 ${osColour.primary.berry}, 0.4em 0 ${osColour.primary.berry};
    }

    & .MuiTabs-scroller {
        overflow: visible !important;
    }
`;

const showTeamResourceRemovalDialogs = (removeTeamResourceState, resourceType) => {
    return !!(
        removeTeamResourceState &&
        removeTeamResourceState.resourceType === resourceType &&
        !!removeTeamResourceState.resourceId
    );
};

export default function TeamSpace() {
    const dataSortId = useId();

    const intl = useIntl();
    const params = useParams();
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.teamSpaces.getTeamSpace.loading);
    const orgUsers = useSelector((state) => state.teamSpaces.getOrgUsers.result);
    const teamSpaceResult = useSelector((state) => state.teamSpaces.getTeamSpace?.result);
    const teamSpaceError = useSelector((state) => state.teamSpaces.getTeamSpace.error);
    const user = useSelector((state) => state.user.current.result);
    const [currentTab, setCurrentTab] = useState(PRODUCT_TYPES.API_PROJECT);

    const [filterSearch, setFilterSearch] = useState("");
    const [filterOrdering, setFilterOrdering] = useState(0);

    const createTeamResourceResult = useSelector((state) => state.teamSpaces.createTeamResource);

    const removeTeamResourceResult = useSelector((state) => state.teamSpaces.removeTeamResource);
    const [removeTeamResourceState, setRemoveTeamResourceState] = useState(null);
    const [successNotice, setSuccessNotice] = useState();
    const [removeResourceErrorText, setRemoveResourceErrorText] = useState();

    const [apiProjectMoveDialogState, setApiProjectMoveDialogState] = useState({
        visible: false,
        apiProjectId: 0,
        apiProjectName: "",
    });

    // Remove resource confirmation/dispatch
    const removeTeamResourceConfirmed = () => {
        if (
            removeTeamResourceState?.resourceType === RESOURCE_TYPES.ApiProject &&
            removeTeamResourceState.resourceId
        ) {
            dispatch(
                removeTeamResource({
                    resourceId: removeTeamResourceState.resourceId,
                    teamId: teamSpaceResult.id,
                    deleteManagedResource: removeTeamResourceState.deleteManagedResource,
                })
            );
        }
    };

    // Close resource removal dialog callback
    const closeResourceRemovalDialog = () => {
        setRemoveTeamResourceState(null);
        setRemoveResourceErrorText(null);
    };

    // Add/Remove resource completion
    useEffect(() => {
        // Success
        if (createTeamResourceResult?.result && teamSpaceResult?.id) {
            setSuccessNotice(teamSpaceMessages.addedApiProjectSuccess);
            dispatch(getTeamSpace(params?.teamSpaceId));
            dispatch(createTeamResourceClear());
        }
        if (removeTeamResourceResult?.result && teamSpaceResult?.id) {
            const { deletedManagedResource, deletedResourceType } = removeTeamResourceResult.result;
            let successMessage = teamSpaceMessages.removedResourceSuccess;
            if (deletedResourceType === RESOURCE_TYPES.ApiProject) {
                if (deletedManagedResource) {
                    successMessage = teamSpaceMessages.deletedApiProjectSuccess;
                } else {
                    successMessage = teamSpaceMessages.removedApiProjectSuccess;
                }
            }
            setSuccessNotice(successMessage);
            setRemoveTeamResourceState(null);
            dispatch(getTeamSpace(teamSpaceResult.id));
            dispatch(removeTeamResourceClear());
        }

        // Error
        if (removeTeamResourceResult?.error) {
            let errorMessage;
            if (removeTeamResourceState.resourceType === RESOURCE_TYPES.ApiProject) {
                errorMessage = getTeamSpacesErrorMessage(
                    removeTeamResourceResult,
                    removeTeamResourceState.deleteManagedResource
                        ? deleteErrors.deleteApiProjectServerError
                        : removeErrors.removeApiProjectServerError
                );
            }
            if (errorMessage) {
                setRemoveResourceErrorText(
                    intl.formatMessage(errorMessage, { link: <ExternalLink type="support" /> })
                );
            }
            dispatch(removeTeamResourceClear());
        }
    }, [
        createTeamResourceResult,
        removeTeamResourceResult,
        dispatch,
        teamSpaceResult,
        removeTeamResourceState,
    ]);

    useEffect(() => {
        if (!orgUsers && user) {
            if (!isContractor(user)) {
                dispatch(getOrgUsers());
            }
        }
    }, [dispatch, user]);

    useEffect(() => {
        if (params.teamSpaceId) {
            dispatch(getTeamSpace(params.teamSpaceId));
        }
        return () => {
            dispatch(clearTeamSpace());
        };
    }, [dispatch, params.teamSpaceId]);

    const { canAddApiProjects } = useTeamSpacePermission(user, teamSpaceResult);

    const apiProjectsFiltered = useMemo(() => {
        if (!teamSpaceResult?.teamResources?.apiProjects) return [];
        return teamSpaceResult.teamResources.apiProjects
            .filter((apiProject) => {
                const term = filterSearch.toLowerCase();
                const name = apiProject.name?.toLowerCase();
                return name.indexOf(term) !== -1;
            })
            .map(apiProjectMap)
            .sort(apiProjectSortFunction(filterOrdering));
    }, [teamSpaceResult?.teamResources?.apiProjects, filterSearch, filterOrdering]);

    return (
        <FeatureCheck feature={TEAM_SPACES}>
            <TeamSpaceHeader error={teamSpaceError} user={user} />
            {successNotice && (
                <Notification
                    variant="success"
                    appearance="toast"
                    onClose={() => setSuccessNotice(null)}
                    autoClose
                >
                    <Typography variant="body1">
                        <FormattedMessage {...successNotice} />
                    </Typography>
                </Notification>
            )}
            <ContentContainer>
                {loading && <CircularProgress size={32} data-testid="loading-spinner" />}
                {!loading && (
                    <>
                        {teamSpaceError && (
                            <TeamSpaceError
                                httpStatus={teamSpaceError.httpStatus}
                                teamSpaceId={params.teamSpaceId}
                            />
                        )}
                        {!teamSpaceError && (
                            <>
                                <StyledTabs
                                    classes={{ indicator: "indicator" }}
                                    value={currentTab}
                                    onChange={(_, newValue) => setCurrentTab(newValue)}
                                    visibleScrollbar={true}
                                >
                                    <Tab
                                        value={PRODUCT_TYPES.API_PROJECT}
                                        label={intl.formatMessage(teamSpaceMessages.apiProjectsTab)}
                                        key={PRODUCT_TYPES.API_PROJECT}
                                    />
                                    {canAddApiProjects && <AddAPIProject />}
                                </StyledTabs>
                                {currentTab === PRODUCT_TYPES.API_PROJECT && (
                                    <>
                                        {teamSpaceResult?.teamResources?.apiProjects?.length ===
                                        0 ? (
                                            <ResourceEmptyState
                                                resourceType={PRODUCT_TYPES.API_PROJECT}
                                                canAddResource={canAddApiProjects}
                                            />
                                        ) : (
                                            <>
                                                <FilterControls>
                                                    <Box>
                                                        <SearchBox
                                                            label={teamSpaceMessages.searchLabel}
                                                            placeholder={
                                                                teamSpaceMessages.searchPlaceholder
                                                            }
                                                            autofocus={false}
                                                            search={filterSearch}
                                                            setSearch={setFilterSearch}
                                                        />
                                                        <DropDownMenu
                                                            label={teamSpaceMessages.sortLabel}
                                                            buttonId={dataSortId} // Needed for label.
                                                            buttonFontWeight="bold"
                                                            value={filterOrdering}
                                                            buttonVariant="outlined"
                                                            items={apiProjectSortOptions}
                                                            onChange={(selection) =>
                                                                setFilterOrdering(selection.value)
                                                            }
                                                        />
                                                    </Box>
                                                    <Box>
                                                        {apiProjectsFiltered.length ? (
                                                            <Typography
                                                                variant="body1"
                                                                role="alert"
                                                            >
                                                                {intl.formatMessage(
                                                                    teamSpaceMessages.searchHasResults,
                                                                    {
                                                                        total: apiProjectsFiltered.length,
                                                                        isPlural:
                                                                            apiProjectsFiltered.length ===
                                                                            1
                                                                                ? ""
                                                                                : "s",
                                                                    }
                                                                )}
                                                            </Typography>
                                                        ) : (
                                                            <Typography
                                                                role="alert"
                                                                variant="body1"
                                                                className="error"
                                                            >
                                                                {intl.formatMessage(
                                                                    teamSpaceMessages.searchHasNoResults
                                                                )}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </FilterControls>
                                                <Box data-testid="apiProjects">
                                                    <ApiProjectsTable
                                                        projectList={apiProjectsFiltered}
                                                        setRemoveTeamResourceState={
                                                            setRemoveTeamResourceState
                                                        }
                                                        setApiProjectMoveDialogState={
                                                            setApiProjectMoveDialogState
                                                        }
                                                        teamSpaceName={teamSpaceResult?.name}
                                                    />
                                                </Box>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </>
                )}
            </ContentContainer>

            {apiProjectMoveDialogState.visible && (
                <MoveAPIProject
                    apiProjectId={apiProjectMoveDialogState?.apiProjectId}
                    apiProjectName={apiProjectMoveDialogState?.apiProjectName}
                    setShowDialog={() =>
                        setApiProjectMoveDialogState({
                            visible: false,
                            apiProjectId: 0,
                            apiProjectName: "",
                        })
                    }
                />
            )}

            {showTeamResourceRemovalDialogs(removeTeamResourceState, RESOURCE_TYPES.ApiProject) && (
                <>
                    {removeTeamResourceState.deleteManagedResource ? (
                        <DeleteAPIProjectDialog
                            confirmed={removeTeamResourceConfirmed}
                            closed={closeResourceRemovalDialog}
                            working={removeTeamResourceResult.loading}
                            error={removeResourceErrorText}
                            apiProjectName={removeTeamResourceState.name}
                        />
                    ) : (
                        <RemoveResourceDialog
                            confirmed={removeTeamResourceConfirmed}
                            closed={closeResourceRemovalDialog}
                            removalName={removeTeamResourceState.name}
                            working={removeTeamResourceResult.loading}
                            error={removeResourceErrorText}
                            variant={PRODUCT_TYPES.API_PROJECT}
                        />
                    )}
                </>
            )}
        </FeatureCheck>
    );
}
