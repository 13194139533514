import { useEffect, useCallback } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { ExternalLink, contentPadding, osColour } from 'omse-components';
import Typography from '@mui/material/Typography';
import Link from '../components/Link';
import routes from '../util/routes';
import PlansOverview from './plans/PlansOverview'
import PricingTable from "./plans/PricingTable";
import { centeredContent, superScriptClass } from "./styles/common";
import ContentHeader, { centerContainer } from '../components/ContentHeader';
import PlanEnquiries from "./plans/PlanEnquiries";
import useFeatureCheck from '../util/useFeatureCheck';
import features from '../../shared/features';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';

const messages = defineMessages({
    heading: {
        id: 'Plans.heading',
        defaultMessage: 'Plans',
        description: 'Heading title'
    },
    title: {
        id: 'Plans.title',
        defaultMessage: 'Choose Your Plan',
        description: 'Content title when logged-out'
    },
    titleLoggedIn: {
        id: 'Plans.titleLoggedIn',
        defaultMessage: 'Our Plans',
        description: 'Content title when logged-in'
    },
    pricingSectionTitle: {
        id: 'Plans.pricingSectionTitle',
        defaultMessage: 'Premium Data APIs Pricing',
        description: 'Content title for the premium data pricing table'
    },
    pricingTerminology: {
        id: 'Plans.pricingTerminology',
        defaultMessage: 'Pricing terminology',
        description: 'Pricing terminology'
    },
    internalUse: {
        id: 'Plans.internalUse',
        defaultMessage: '- Our Premium Plan can only be used by an entity providing a service for a third party (whether that be the public, or another company). This explicitly excludes organisations that are using our data solely for internal business use. If you wish to use our data for internal business use you should <ap>contact one of our Partners</ap>. <lfaq>More about this in our FAQs.</lfaq>',
        description: 'Pricing terms 1'
    },
    premiumCosts: {
        id: 'Plans.premiumCosts',
        defaultMessage: '– The <b>Premium data pricing</b> table above shows you associated costs for each API. <l>More about API calls and how they are measured and costed in our FAQs.</l>',
        description: 'Pricing terms 2'
    },
    rateLimit: {
        id: 'Plans.rateLimit',
        defaultMessage: '– All our API data (OS OpenData and Premium) are subject to a 600 transactions-per-minute throttle for your live projects. This is equivalent to 100 concurrent users requesting a computer-screens worth of map tiles every 10 seconds. <l>More about throttling in our FAQs.</l>',
        description: 'Pricing terms 3'
    },
    freePremium: {
        id: 'Plans.freePremium',
        defaultMessage: '– The first £1,000 of Premium Data is free for Premium Plan subscribers every month. This entitles you to free calls to the Premium data contained within our APIs (excluding OS Places API and OS Match & Cleanse API) on your live projects. The free £1,000 credit is applied to your account on a monthly ‘use-it-or-lose-it’ basis. <l>More about Free Premium Data credits in our FAQs.</l>',
        description: 'Pricing terms 4'
    },
    developmentMode: {
        id: 'Plans.developmentMode',
        defaultMessage: '– Projects in development mode are not charged for accessing premium data, but are further subjected to a 50 transactions-per-minute throttle. <l>More about putting projects into development mode in our FAQs.</l>',
        description: 'Pricing terms 5'
    },
    vat: {
        id: 'Plans.vat',
        defaultMessage: '– Our quoted prices are subject to VAT. VAT will be charged at the applicable rate at time of payment.',
        description: 'Pricing terms 6'
    },
    psgaPlan: {
        id: 'Plans.psgaPlan',
        defaultMessage: '– Our Public Sector Plan is available to approved PSGA Member organisations offering free access to Premium data and OS APIs. PSGA Member organisations can invite contractors to access data under their organisation.',
        description: 'Pricing terms 8'
    },
    emergencyServices: {
        id: 'Plans.emergencyServices',
        defaultMessage: '– PSGA Members on the Authorised List i.e. Emergency Services under Category 1 responders, have unthrottled access to all OS APIs.',
        description: 'Pricing terms 9'
    },
});

const plansPageMaxWidth = 1100;

// Util functions for rich text formatting.
const createAnchorRichTextFunc = href => {
    return chunks => <ExternalLink href={href} message={chunks.toString()}/>;
}
const createLinkRichTextFunc = (path, hash) => {
    return chunks => <Link path={path} hash={hash}>{chunks}</Link>;
}
// The ordering of these messages needs to match the order that the references are defined in PlansOverview.js
const terms = [
    {
        text: messages.rateLimit,
        values: { l: createLinkRichTextFunc(routes.supportPlans, '#apiThrottling') }
    },
    {
        text: messages.premiumCosts,
        values: {
            b: chunks => <b>{chunks}</b>,
            l: createLinkRichTextFunc(routes.supportPlans, '#apiTransactions')
        }
    },
    {
        text: messages.freePremium,
        values: { l: createLinkRichTextFunc(routes.supportPlans, '#freePremiumAllocation') }
    },
    {
        text: messages.developmentMode,
        values: { l: createLinkRichTextFunc(routes.supportPlans, '#developmentLiveModes') }
    },
    {
        text: messages.internalUse,
        values: {
            aei: createAnchorRichTextFunc('https://www.ordnancesurvey.co.uk/contact-us'),
            ap: createAnchorRichTextFunc('https://www.ordnancesurvey.co.uk/business-government/partner-member'),
            lfaq: createLinkRichTextFunc(routes.supportPlans, '#differentPlans')
        }
    },
    { text: messages.vat },
    { text: messages.psgaPlan },
    { text: messages.emergencyServices }
];

const StyledContent = styled.div(({ theme }) => `
    ${centeredContent};
    max-width: ${plansPageMaxWidth}px;
    padding-top: ${contentPadding.top}px;
    ${theme.breakpoints.down('sm')} {
        padding-top: ${theme.spacing(4)};
        padding-left: 0;
        padding-right: 0;
    }
    & .choosePlanTitle {
        text-align: center;
        color: ${theme.palette.primary.main};
        margin-bottom: ${contentPadding.bottom}px;
        ${theme.breakpoints.down('sm')} {
            margin: ${contentPadding.mobile};
        }
    }
    & .pricingTitle {
        color: ${theme.palette.primary.main};
        margin: ${theme.spacing(8)} 0 ${theme.spacing(0)} 0;
        ${theme.breakpoints.down('sm')} {
            margin: ${theme.spacing(6)} 0.5em 0 0.5em;
        }
    }
    }
    & .pricingTerms {
        line-height: 1.33;
        color: ${osColour.neutral.charcoal};
        margin-top: 80px;
        & ol {
            padding-left: 0;
            list-style-position: inside;
            list-style: none;
        }
        & li {
            font-family: 'Source Sans Pro', sans-serif;
            font-weight: 600;
            font-size: 1.125rem;
            line-height: 1.33;
            margin-bottom: 24px;
            color: ${osColour.status.error};
            counter-increment: li;
        }
        & ol li:before {
            content: counter(li);
            margin-right: 5px;
        }
        & li > p {
            display: inline;
            font-weight: normal;
            font-size: 1.125rem;
            line-height: 1.388;
            color: ${osColour.neutral.charcoal};
        }
        & li > p > a {
            display: inline;
            margin-left: 3px;
            font-size: 1.125rem;
            line-height: 1.33;
        }
        ${theme.breakpoints.down('sm')} {
            padding: 0 16px 80px 16px;
            margin-top: 64px;
        }
    }
`);

const useSpaceAboveStyles = theme => css`
    margin-top: ${theme.spacing(8)};
`;

export default function Plans() {
    const theme = useTheme();
    const user = useSelector((state) => state?.user.current?.result);
    const spaceAboveClass = useSpaceAboveStyles(theme);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash && hash.length > 1) {
            document.getElementById(hash.substr(1)).scrollIntoView();
        }
    }, []);

    const handleClick = useCallback((e) => {
        if (e.target.hash.length > 1) {
            e.preventDefault();
            document.getElementById(e.target.hash.substr(1)).scrollIntoView();
        }
    }, []);

    return (
        <>
            <ContentHeader
                title={messages.heading}
                maxWidth={plansPageMaxWidth}
                variant={centerContainer}
            />
            <StyledContent>
                <Typography variant='h1' component='h2' className='choosePlanTitle'>
                    <FormattedMessage {...(user) ? messages.titleLoggedIn : messages.title} />
                </Typography>
                <PlansOverview />
                {useFeatureCheck(features.EAI) && (
                    <PlanEnquiries classes={{ container: spaceAboveClass }} />
                )}
                <Typography variant="h1" component='h2' className='pricingTitle'>
                    <FormattedMessage {...messages.pricingSectionTitle} />
                </Typography>
                <PricingTable
                    customVatFootnoteLink={(
                        <sup>
                            <a className={superScriptClass} onClick={handleClick} href="#6">6</a>
                        </sup>
                    )}
                    customRateLimitFootnoteLink={(
                        <sup>
                            <a className={superScriptClass} onClick={handleClick} href="#1">1</a>
                        </sup>
                    )}
                />
                <div className='pricingTerms'>
                    <Typography variant='h1' component='h2' className='pricingTitle'>
                        <FormattedMessage {...messages.pricingTerminology} />
                    </Typography>
                    <ol>
                        {terms.map((t, i) => (
                            <li key={t.text + i}>
                                <Typography variant='body2' id={i + 1}>
                                    <FormattedMessage {...t.text} values={t.values} />
                                </Typography>
                            </li>
                        ))}
                    </ol>
                </div>
            </StyledContent>
        </>
    );
}