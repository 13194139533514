import { defineMessages } from "react-intl";

const ownersTableMessages = defineMessages({
    ownersTableHeading: {
        id: "ownersTableMessages.ownersTableHeading",
        defaultMessage: "Owners",
        description: "Owners table heading",
    },
    remove: {
        id: "ownersTabMessages.remove",
        defaultMessage: "Remove",
        description: "Remove label",
    },
    cannotRemoveAdmin: {
        id: "ownersTabMessages.cannotRemoveAdmin",
        defaultMessage: "You cannot remove Admin users.",
        description: "Cannot remove label",
    },
    cannotRemoveAdminLoggedIn: {
        id: "ownersTabMessages.cannotRemoveAdminLoggedIn",
        defaultMessage: "As an Admin, you are automatically an Owner.",
        description: "Cannot remove label for logged-in admin",
    },
    ownerHelp: {
        id: "ownersTableMessages.ownerHelp",
        defaultMessage: "<strong>Owners</strong> (and <strong>organisation Admins</strong>) have full management of a Team Space, including defining who can access it.",
        description: "Owner help",
    },
});

export default ownersTableMessages;
