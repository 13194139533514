import { defineMessages } from "react-intl";

const teamSpacesFaqHeader = defineMessages({
    title: {
        id: "teamSpacesFaqHeader.title",
        defaultMessage: "Support",
        description: "The title of Team Spaces FAQ page.",
    },
    heading: {
        id: "teamSpacesFaqHeader.heading",
        defaultMessage: "FAQs: Team Spaces",
        description: "The heading of Team Spaces FAQ page.",
    },
    subHeading: {
        id: "teamSpacesFaqHeader.subHeading",
        defaultMessage: "The following FAQs describe how to use Team Spaces:",
        description: "The subheading of Team Spaces FAQ page.",
    },
});

const teamsSpacesFaq1 = defineMessages({
    title: {
        id: "teamsSpacesFaq1.title",
        defaultMessage: "What is a Team Space?",
        description: "The title of the first paragraph in Team Spaces FAQ page.",
    },
    content: {
        id: "teamsSpacesFaq1.content",
        defaultMessage: "A Team Space is a convenient way for you to group things that you are working on. They can be open to everyone in your organisation, or locked so that only certain organisation members can see and access them.",
        description: "The body of the first paragraph in Team Spaces FAQ page.",
    },
});

const teamsSpacesFaq2 = defineMessages({
    title: {
        id: "teamsSpacesFaq2.title",
        defaultMessage: "Who can create a Team Space?",
        description: "The title of the second paragraph in Team Spaces FAQ page.",
    },
    content: {
        id: "teamsSpacesFaq2.content",
        defaultMessage: "Anyone who is a Data Hub Admin or a Data Hub User has the ability to create a Team Space.",
        description: "The body of the second paragraph in Team Spaces FAQ page.",
    },
});

const teamsSpacesFaq3 = defineMessages({
    title: {
        id: "teamsSpacesFaq3.title",
        defaultMessage: "Can anyone be made an Owner of a Team Space?",
        description: "The title of the third paragraph in Team Spaces FAQ page.",
    },
    content: {
        id: "teamsSpacesFaq3.content",
        defaultMessage: `<p>No, anyone who is a Contractor (Read/Write) or Contractor (Read-Only) is unable to be made an Owner.</p><p>Data Hub Admins are added by default to the list Owners, they cannot be removed as Owners unless their Data Hub profile is amended. A Team Space must have at least one Owner.</p>`,
        description: "The body of the third paragraph in Team Spaces FAQ page.",
    },
});

const teamsSpacesFaq4 = defineMessages({
    title: {
        id: "teamsSpacesFaq4.title",
        defaultMessage: "Who can have what permissions within Team Spaces?",
        description: "The title of the fourth paragraph in Team Spaces FAQ page.",
    },
    content: {
        id: "teamsSpacesFaq4.content",
        defaultMessage: "TEAM_SPACES_ROLES_TABLE_PLACEHOLDER",
        description: "Table showing permissions within Team Spaces.",
    },
});

const teamsSpacesFaq5 = defineMessages({
    title: {
        id: "teamsSpacesFaq5.title",
        defaultMessage: "What can an Owner do?",
        description: "The title of the fifth paragraph in Team Spaces FAQ page.",
    },
    content: {
        id: "teamsSpacesFaq5.content",
        defaultMessage: "Owners can do anything in a Team Space, including deleting it, and managing who has access (all Data Hub Admins can also manage all Team Spaces).",
        description: "The body of the fifth paragraph in Team Spaces FAQ page.",
    },
});

const teamsSpacesFaq6 = defineMessages({
    title: {
        id: "teamsSpacesFaq6.title",
        defaultMessage: "What can an Editor do?",
        description: "The title of the sixth paragraph in Team Spaces FAQ page.",
    },
    content: {
        id: "teamsSpacesFaq6.content",
        defaultMessage: "Editors can add and remove API Projects. But they can't manage who has access to the Team Space.",
        description: "The body of the sixth paragraph in Team Spaces FAQ page.",
    },
});

const teamsSpacesFaq7 = defineMessages({
    title: {
        id: "teamsSpacesFaq7.title",
        defaultMessage: "What can a Viewer do?",
        description: "The title of the seventh paragraph in Team Spaces FAQ page.",
    },
    content: {
        id: "teamsSpacesFaq7.content",
        defaultMessage: "Viewers can view the Team Space, access APIs, but can't add or make changes to API Projects inside the Team Space.",
        description: "The body of the seventh paragraph in Team Spaces FAQ page.",
    },
});

const teamsSpacesFaq8 = defineMessages({
    title: {
        id: "teamsSpacesFaq8.title",
        defaultMessage: "Can people see Team Spaces that are locked?",
        description: "The title of the eighth paragraph in Team Spaces FAQ page.",
    },
    content: {
        id: "teamsSpacesFaq8.content",
        defaultMessage: "Only users who have been added as an Owner or a member of a locked Team Space will be able to see a Team Space in their Data Hub account.",
        description: "The body of the eighth paragraph in Team Spaces FAQ page.",
    },
});

const teamsSpacesFaq9 = defineMessages({
    title: {
        id: "teamsSpacesFaq9.title",
        defaultMessage: "What if I create an API Project and don’t put it into a Team Space?",
        description: "The title of the ninth paragraph in Team Spaces FAQ page.",
    },
    content: {
        id: "teamsSpacesFaq9.content",
        defaultMessage: "Any API Project that isn't allocated to a Team Space will be accessible to any member of your organisation within the Data Hub.",
        description: "The body of the ninth paragraph in Team Spaces FAQ page.",
    },
});

const teamsSpacesFaq10 = defineMessages({
    title: {
        id: "teamsSpacesFaq10.title",
        defaultMessage: "What happens to API Projects if a Team Space is deleted?",
        description: "The title of the tenth paragraph in Team Spaces FAQ page.",
    },
    content: {
        id: "teamsSpacesFaq10.content",
        defaultMessage: "When an Owner decides to delete a Team Space they then have to decide what they would like to do to the API Projects within the existing Team Space.",
        description: "The body of the tenth paragraph in Team Spaces FAQ page.",
    },
});

const teamsSpacesFaq11 = defineMessages({
    title: {
        id: "teamsSpacesFaq11.title",
        defaultMessage: "Can I move an API Project to another Team Space?",
        description: "The title of the eleventh paragraph in Team Spaces FAQ page.",
    },
    content: {
        id: "teamsSpacesFaq11.content",
        defaultMessage: "Owners/Editors can move existing API Projects between Team Spaces. An API Project can only exist within one Team Space at a time and Owners/Editors can only move API Projects to other Team Spaces that they are also an Owner/Editor of.",
        description: "The body of the eleventh paragraph in Team Spaces FAQ page.",
    },
});

const teamsSpacesFaq12 = defineMessages({
    title: {
        id: "teamsSpacesFaq12.title",
        defaultMessage: "Can I remove an API Project from a Team Space?",
        description: "The title of the twelfth paragraph in Team Spaces FAQ page.",
    },
    content: {
        id: "teamsSpacesFaq12.content",
        defaultMessage:
            "Owners/Editors can remove an API Project from a Team Space, this will place the API Project within the Data Hub and mean any member of your organisation within the Data Hub will be able to view and edit this in line with their existing Data Hub profile.",
        description: "The body of the twelfth paragraph in Team Spaces FAQ page.",
    },
});

const teamsSpacesFaq13 = defineMessages({
    title: {
        id: "teamsSpacesFaq13.title",
        defaultMessage: "Can I delete an API Project from a Team Space?",
        description: "The title of the thirteenth paragraph in Team Spaces FAQ page.",
    },
    content: {
        id: "teamsSpacesFaq13.content",
        defaultMessage: "Owners/Editors can delete an API Project from a Team Space, any API Project deleted is no longer accessible in Data Hub.",
        description: "The body of the thirteenth paragraph in Team Spaces FAQ page.",
    },
});

const teamsSpacesFaq14 = defineMessages({
    title: {
        id: "teamsSpacesFaq14.title",
        defaultMessage: "How do I add Data Packages or Select+Build to Team Spaces?",
        description: "The title of the fourteenth paragraph in Team Spaces FAQ page.",
    },
    content: {
        id: "teamsSpacesFaq14.content",
        defaultMessage: "Currently there is no ability to add Data Packages or Select+Build to Team Spaces, however we are looking to make this available in future releases.",
        description: "The body of the fourteenth paragraph in Team Spaces FAQ page.",
    },
});

const teamsSpacesFaqList = [
    teamsSpacesFaq1,
    teamsSpacesFaq2,
    teamsSpacesFaq3,
    teamsSpacesFaq4,
    teamsSpacesFaq5,
    teamsSpacesFaq6,
    teamsSpacesFaq7,
    teamsSpacesFaq8,
    teamsSpacesFaq9,
    teamsSpacesFaq10,
    teamsSpacesFaq11,
    teamsSpacesFaq12,
    teamsSpacesFaq13,
    teamsSpacesFaq14,
];

export { teamSpacesFaqHeader, teamsSpacesFaqList };
