import { useState, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import manageTeamSpaceMessages from "./ManageTeamSpace.msg";
import { AddButton, ExternalLink, Notification } from "omse-components";
import { Button, Typography } from "@mui/material";
import { GroupAddOutlined, LockOpenOutlined, LockOutlined } from "@mui/icons-material";
import TeamSpaceAccess from "./manageTeamSpace/TeamSpaceAccess";
import {
    getOrgUsers,
    getTeamSpace,
    setTeamSpaceAccess,
    setTeamSpaceAccessClear,
} from "../../modules/teamSpaces/actions";
import {
    getTeamSpaceMembers,
    getTeamSpaceOwners,
    isContractor,
    isOrgAdmin,
} from "./util/teamSpaceUser";
import { useManageTeamSpacePermission } from "./util/useManageTeamSpacePermission";
import routePaths, { useRedirect } from "../../util/routes";
import manageTeamErrorMessages from "./manageTeamSpace/manageTeamErrors.msg";
import { getTeamSpacesErrorMessage } from "./util/getTeamSpacesErrorMessage";
import TeamSpaceDialog from "./shared/Dialog";
import { dialogVariants } from "./shared/constants";

export default function ManageTeamSpace(props) {
    const userState = useSelector((state) => state.user.current);

    if (userState.loading || isContractor(userState.result)) {
        return null;
    }

    return <ManageTeamSpaceDialog {...props} />;
}

const defaultTeamSpaceAccessParameters = {};

function ManageTeamSpaceDialog() {
    const intl = useIntl();
    const dispatch = useDispatch();
    const redirect = useRedirect();

    const [showDialog, setShowDialog] = useState(false);
    const [disableNextAction, setDisableNextAction] = useState(true);
    const [teamSpaceAccessParameters, setTeamSpaceAccessParameters] = useState(
        defaultTeamSpaceAccessParameters
    );
    const [owners, setOwners] = useState([]);
    const [members, setMembers] = useState([]);

    const [addedOwnerIds, setAddedOwnerIds] = useState([]);
    const [removedOwnerIds, setRemovedOwnerIds] = useState([]);
    const [addedMembers, setAddedMembers] = useState([]);
    const [removedMemberIds, setRemovedMemberIds] = useState([]);
    const [modifiedMembers, setModifiedMembers] = useState([]);

    const user = useSelector((state) => state.user.current.result);
    const setTeamSpaceAccessResult = useSelector((state) => state.teamSpaces.setTeamSpaceAccess);
    const loadingOrgUsers = useSelector((state) => state.teamSpaces.getOrgUsers.loading);
    const orgUsers = useSelector((state) => state.teamSpaces.getOrgUsers.result);
    const teamSpaceResult = useSelector((state) => state.teamSpaces.getTeamSpace.result);
    const hasManageTeamSpacePermission = useManageTeamSpacePermission(user, teamSpaceResult);

    const { locked } = teamSpaceResult || {};

    const teamSpaceOwnerIds = useMemo(() => {
        if (teamSpaceResult && orgUsers) {
            const teamSpaceOwners = getTeamSpaceOwners(teamSpaceResult);
            return orgUsers
                .filter(
                    (orgUser) =>
                        teamSpaceOwners.find(
                            (owner) => owner.datahubDeveloperId === orgUser.datahubDeveloperId
                        ) || isOrgAdmin(orgUser)
                )
                .map((orgUser) => orgUser.datahubDeveloperId);
        } else {
            return [];
        }
    }, [orgUsers, teamSpaceResult]);

    const teamSpaceMembers = useMemo(() => {
        if (showDialog && teamSpaceResult && orgUsers) {
            const teamSpaceMembers = getTeamSpaceMembers(teamSpaceResult);
            return teamSpaceMembers.filter((member) => orgUsers.find(orgUser => orgUser.datahubDeveloperId === member.datahubDeveloperId));
        }
        return [];
    }, [orgUsers, teamSpaceResult, showDialog]);
    
    const teamSpaceMemberIds = useMemo(
        () => teamSpaceMembers.map((teamMember) => teamMember.datahubDeveloperId),
        [teamSpaceMembers]
    );

    const selectedUserIds = useMemo(() => {
        const ids = [
            ...owners.map((owner) => owner.datahubDeveloperId),
            ...members.map((member) => member.datahubDeveloperId),
        ];
        return ids;
    }, [owners, members]);

    const handleCloseEvent = () => {
        setShowDialog(false);
        dispatch(setTeamSpaceAccessClear());

        // Remove user information from the state
        // AFTER the dialog has fully closed.
        setTimeout(() => {
            setDisableNextAction(true);
            setTeamSpaceAccessParameters(defaultTeamSpaceAccessParameters);
            setOwners([]);
            setMembers([]);
        }, 250);
    };

    useEffect(() => {
        if (teamSpaceResult) {
            const changedEntries = !!(
                addedOwnerIds.length ||
                removedOwnerIds.length ||
                addedMembers.length ||
                removedMemberIds.length ||
                modifiedMembers.length
            );
            const changedLockedState = teamSpaceAccessParameters.locked !== teamSpaceResult.locked;
            setDisableNextAction(!(changedEntries || changedLockedState));
        }
    }, [
        addedOwnerIds,
        removedOwnerIds,
        addedMembers,
        removedMemberIds,
        modifiedMembers,
        teamSpaceResult,
        teamSpaceAccessParameters,
    ]);

    useEffect(() => {
        if (members && owners) {

            const ownerIds = owners.map((owner) => owner.datahubDeveloperId);
            setAddedOwnerIds(ownerIds.filter((ownerId) => !teamSpaceOwnerIds.includes(ownerId)));
            setRemovedOwnerIds(
                teamSpaceOwnerIds.filter((existingOwnerId) => !ownerIds.includes(existingOwnerId))
            );

            const memberIds = members.map((member) => member.datahubDeveloperId);
            setAddedMembers(
                members.filter((member) => !teamSpaceMemberIds.includes(member.datahubDeveloperId))
            );
            setRemovedMemberIds(
                teamSpaceMemberIds.filter(
                    (existingMemberId) => !memberIds.includes(existingMemberId)
                )
            );
            setModifiedMembers(
                members.filter((member) => {
                    const existingMember = teamSpaceMembers.find(
                        (teamMember) => member.datahubDeveloperId === teamMember.datahubDeveloperId
                    );
                    return (
                        existingMember && existingMember.apiProjectRole !== member.apiProjectRole
                    );
                })
            );
        }
    }, [owners, members]);

    useEffect(() => {
        if (showDialog && !orgUsers) {
            dispatch(getOrgUsers());
        }
    }, [showDialog, orgUsers, dispatch]);

    // Initialise owners and members
    useEffect(() => {
        if (teamSpaceResult) {
            setTeamSpaceAccessParameters({ locked: teamSpaceResult.locked });
        }
        if (teamSpaceResult && orgUsers?.length) {
            const existingOwnerTeamMembers = getTeamSpaceOwners(teamSpaceResult).map(
                (teamMember) => teamMember.datahubDeveloperId
            );
            setOwners(
                orgUsers.filter(
                    (orgUser) =>
                        existingOwnerTeamMembers.includes(orgUser.datahubDeveloperId) ||
                        isOrgAdmin(orgUser)
                )
            );
            setMembers(getTeamSpaceMembers(teamSpaceResult));
        }
    }, [teamSpaceResult, orgUsers, teamSpaceMembers]);

    // Save completion
    useEffect(() => {
        if (setTeamSpaceAccessResult.result?.complete) {
            if (teamSpaceResult.id) {
                if (setTeamSpaceAccessResult.result.indexRedirect) {
                    redirect.push(routePaths.teamSpaces);
                } else {
                    dispatch(getTeamSpace(teamSpaceResult.id));
                }
            }
            dispatch(setTeamSpaceAccessClear());
            handleCloseEvent();
        }
    }, [setTeamSpaceAccessResult, handleCloseEvent, dispatch, teamSpaceResult, redirect]);

    // Save/dispatch handler
    function handleSetTeamSpaceAccess() {
        let hasIndexRedirect;
        if (user && !isOrgAdmin(user)) {
            hasIndexRedirect = !!(
                teamSpaceResult.locked &&
                !owners.find((owner) => owner.datahubDeveloperId === user.datahubDeveloperId) &&
                !members.find((member) => member.datahubDeveloperId === user.datahubDeveloperId)
            );
        }
        dispatch(
            setTeamSpaceAccess(
                teamSpaceResult.id,
                {
                    addedOwnerIds,
                    removedOwnerIds,
                    addedMembers,
                    removedMemberIds,
                    modifiedMembers,
                    locked: teamSpaceAccessParameters.locked,
                },
                hasIndexRedirect
            )
        );
    }

    const LockButtonIcon = locked ? LockOutlined : LockOpenOutlined;

    return (
        <>
            <Button
                variant="outlined"
                startIcon={<LockButtonIcon color={locked ? "error" : "success"} />}
                endIcon={<GroupAddOutlined fontSize="large" />}
                data-testid={`dialog-${dialogVariants.manageTeamSpace}-open`}
                onClick={() => setShowDialog(true)}
                aria-label={intl.formatMessage(manageTeamSpaceMessages.membersButtonAria)}
            >
                <FormattedMessage {...manageTeamSpaceMessages.membersButtonLabel} />
            </Button>
            {showDialog && (
                <TeamSpaceDialog
                    variant={dialogVariants.manageTeamSpace}
                    working={setTeamSpaceAccessResult.loading}
                    onClose={() => handleCloseEvent()}
                    contentHead={
                        <Typography variant="h2">
                            {intl.formatMessage(manageTeamSpaceMessages.manageAccess)}
                        </Typography>
                    }
                    contentMain={
                        <>
                            {setTeamSpaceAccessResult.error ? (
                                <Notification variant="error" appearance="inline">
                                    <Typography variant="body1">
                                        <FormattedMessage
                                            {...getTeamSpacesErrorMessage(
                                                setTeamSpaceAccessResult,
                                                manageTeamErrorMessages.genericServerError
                                            )}
                                            values={{ link: <ExternalLink type="support" /> }}
                                        />
                                    </Typography>
                                </Notification>
                            ) : (
                                <TeamSpaceAccess
                                    teamSpaceAccessParameters={teamSpaceAccessParameters}
                                    setTeamSpaceAccessParameters={setTeamSpaceAccessParameters}
                                    setDisableNextAction={setDisableNextAction}
                                    owners={owners}
                                    setOwners={setOwners}
                                    members={members}
                                    setMembers={setMembers}
                                    selectedUserIds={selectedUserIds}
                                    working={setTeamSpaceAccessResult.loading}
                                />
                            )}
                        </>
                    }
                    contentFooter={
                        <>
                            {hasManageTeamSpacePermission && (
                                <Button
                                    variant="outlined"
                                    data-testid={`dialog-${dialogVariants.manageTeamSpace}-cancel`}
                                    onClick={() => handleCloseEvent()}
                                >
                                    {intl.formatMessage(manageTeamSpaceMessages.cancelButton)}
                                </Button>
                            )}
                            {hasManageTeamSpacePermission ? (
                                <AddButton
                                    variant="contained"
                                    label={manageTeamSpaceMessages.saveButton}
                                    showIcon={false}
                                    working={setTeamSpaceAccessResult.loading}
                                    disabled={
                                        disableNextAction ||
                                        loadingOrgUsers ||
                                        setTeamSpaceAccessResult.loading
                                    }
                                    action={handleSetTeamSpaceAccess}
                                >
                                    {intl.formatMessage(manageTeamSpaceMessages.saveButton)}
                                </AddButton>
                            ) : (
                                <AddButton
                                    variant="contained"
                                    label={manageTeamSpaceMessages.doneButton}
                                    showIcon={false}
                                    action={handleCloseEvent}
                                >
                                    {intl.formatMessage(manageTeamSpaceMessages.doneButton)}
                                </AddButton>
                            )}
                        </>
                    }
                />
            )}
        </>
    );
}
