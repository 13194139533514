import {legacy_createStore as createStore, combineReducers, applyMiddleware} from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import {reducer as history} from "./modules/history";
import {accountReducer as account, accountMiddleware} from "./modules/account";
import {paymentsReducer as payments, paymentsMiddleware} from "./modules/payments";
import {productsReducer as products, productsMiddleware} from "./modules/products";
import {projectReducer as project, projectMiddleware} from "./modules/project";
import {projectsReducer as projects, projectsMiddleware} from "./modules/projects";
import {statsReducer as stats, statsMiddleware} from "./modules/stats";
import {notificationsReducer as notifications, notificationsMiddleware} from "./modules/notifications";
import {premiumReducer as premium, premiumMiddleware} from "./modules/premium";
import {psgaReducer as psga, psgaMiddleware} from "./modules/psga"
import {recipeEditorReducer as recipeEditor, recipeEditorMiddleware} from "./modules/recipeEditor";
import {recipesReducer as recipes, recipesMiddleware} from "./modules/recipes";
import {dataPackageReducer as dataPackages, dataPackageMiddleware} from "./modules/dataPackages";
import {partnerReducer as partners, partnerMiddleware} from "./modules/partners";
import {videosReducer as videos, videosMiddleware} from "./modules/videos";
import {organisationReducer as organisation, organisationMiddleware} from "./modules/organisation";
import {serviceStatusReducer as serviceStatus, serviceStatusMiddleware} from "./modules/serviceStatus";
import {polygonReducer as polygon, polygonMiddleware} from "./modules/polygon";
import {menuReducer as menu, menuMiddleware} from "./modules/menu";
import {termsReducer as terms, termsMiddleware} from "./modules/terms";
import {teamSpacesReducer as teamSpaces, teamSpacesMiddleware} from "./modules/teamSpaces";
import {toolbarReducer as toolbar} from './modules/toolbar';
import _flattenDeep from 'lodash/flattenDeep';
import {modules} from 'omse-components';

const reducer = combineReducers({
    account,
    history,
    dataPackages,
    partners,
    products,
    payments,
    project,
    projects,
    serviceStatus,
    stats,
    notifications,
    polygon,
    premium,
    psga,
    recipeEditor,
    recipes,
    toolbar,
    videos,
    organisation,
    menu,
    terms,
    teamSpaces,
    ...modules.reducers
});

const middleware = applyMiddleware.apply(null, _flattenDeep([
    accountMiddleware,
    paymentsMiddleware,
    dataPackageMiddleware,
    partnerMiddleware,
    productsMiddleware,
    projectMiddleware,
    projectsMiddleware,
    serviceStatusMiddleware,
    statsMiddleware,
    notificationsMiddleware,
    polygonMiddleware,
    premiumMiddleware,
    psgaMiddleware,
    recipeEditorMiddleware,
    recipesMiddleware,
    videosMiddleware,
    organisationMiddleware,
    menuMiddleware,
    termsMiddleware,
    teamSpacesMiddleware,
    ...modules.middleware
]));

const composeEnhancers = composeWithDevTools({}); // Integrate with Redux DevTools.
export const createPortalStore = (preloadedState) => createStore(reducer, preloadedState, composeEnhancers(middleware));

const store = createPortalStore();
export default store;
