import PropTypes from "prop-types";
import { ConfirmationDialog } from "omse-components";
import { defineMessages } from "react-intl";

const messages = defineMessages({
    deleteProjectTitle: {
        id: "DeleteAPIProjectDialog.deleteTitle",
        defaultMessage: "Permanently delete this API Project and its API key?",
        description: "Title for the delete API Project dialog",
    },
    content1: {
        id: "DeleteAPIProjectDialog.content1",
        defaultMessage: "This action is permanent.",
        description: "Warning text for the delete API Project dialog",
    },
    content2: {
        id: "DeleteAPIProjectDialog.content2",
        defaultMessage: "Any apps you have built that use this API key will stop working.",
        description: "Warning text for the delete API Project dialog",
    },
    buttonLabel: {
        id: "DeleteAPIProjectDialog.buttonLabel",
        defaultMessage: "Permanently delete API Project",
        description: "Button label for the delete API Project dialog",
    },
});

export const DeleteAPIProjectDialog = ({ closed, confirmed, working, error }) => {
    return (
        <ConfirmationDialog
            title={messages.deleteProjectTitle}
            confirmationAction={confirmed}
            contentMessages={[messages.content1, messages.content2]}
            handleClose={closed}
            confirmationButtonLabel={messages.buttonLabel}
            confirmationCodeRequired={true}
            working={working}
            error={error}
        />
    );
};

DeleteAPIProjectDialog.propTypes = {
    closed: PropTypes.func.isRequired,
    confirmed: PropTypes.func.isRequired,
    working: PropTypes.bool,
    error: PropTypes.string,
};
