import PropTypes from "prop-types";
import { css } from "@emotion/css";
import { Autocomplete } from "@mui/material";
import { useIntl } from "react-intl";
import SearchField from "../shared/SearchField";
import messages from "./DeleteTeamSpaceDialog.msg";
import { useTheme } from "@emotion/react";
import { MOVE_TEAMSPACE_OPTIONS } from "./constants";

const usePopupIndicatorClass = (theme) => css`
    margin-right: ${theme.spacing(0.5)};
`;

const TeamSpaceSearchField = ({
    filteredTeamSpaces,
    loading,
    setDestinationTeamSpaceId,
    autocompleteKey,
    setRadioValue,
    resetAutocomplete,
}) => {
    const intl = useIntl();
    const theme = useTheme();
    const popupIndicatorClass = usePopupIndicatorClass(theme);

    function handleAutoCompleteChange(setter, selectedItem, reason) {
        if (reason === "selectOption" && selectedItem) {
            setDestinationTeamSpaceId(selectedItem.id);
        }
        if (reason === "clear") {
            setDestinationTeamSpaceId(null);
        }
    }

    function selectMoveToTeamSpace() {
        setRadioValue(MOVE_TEAMSPACE_OPTIONS.alternative);
    }

    return (
        <Autocomplete
            key={autocompleteKey}
            fullWidth
            clearOnEscape
            handleHomeEndKeys
            autoHighlight
            clearOnBlur={false}
            getOptionLabel={(option) => option?.name}
            getOptionDisabled={(option) => option === ""}
            forcePopupIcon={true}
            options={filteredTeamSpaces || []}
            loading={loading}
            onChange={handleAutoCompleteChange}
            onInputChange={selectMoveToTeamSpace}
            filterOptions={(options, state) =>
                options.filter(
                    (option) =>
                        state.inputValue === "" ||
                        option.name.toLowerCase().indexOf(state.inputValue.toLowerCase().trim()) !==
                            -1
                )
            }
            onOpen={selectMoveToTeamSpace}
            renderInput={(params) => (
                <SearchField
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        "aria-label": intl.formatMessage(messages.findATeamSpace),
                    }}
                    showClearSearchTerm={false}
                    onClearSearchTerm={resetAutocomplete}
                    placeholder={intl.formatMessage(messages.findATeamSpace)}
                />
            )}
            renderOption={(props, option) => (
                <li {...props} key={option.id}>
                    {option.name}
                </li>
            )}
            classes={{
                inputRoot: "inputRoot",
                popupIndicator: popupIndicatorClass,
            }}
            noOptionsText={intl.formatMessage(messages.noResultsPrompt)}
        />
    );
};

export default TeamSpaceSearchField;

TeamSpaceSearchField.propTypes = {
    filteredTeamSpaces: PropTypes.array.isRequired,
    setDestinationTeamSpaceId: PropTypes.func.isRequired,
    autocompleteKey: PropTypes.string.isRequired,
    setRadioValue: PropTypes.func.isRequired,
    resetAutocomplete: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};
