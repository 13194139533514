import { defineMessages } from "react-intl";

const ownersTabMessages = defineMessages({
    noResultsAdminPrompt: {
        id: "ownersTabMessages.noResultsAdminPrompt",
        defaultMessage: "No results. To add a user not shown, <a>invite them to the OS Data Hub</a>.",
        description: "No results admin prompt",
    },
    noResultsPrompt: {
        id: "ownersTabMessages.noResultsPrompt",
        defaultMessage: "No results. To add a user not shown, ask an Admin to invite them to the OS Data Hub.",
        description: "No results prompt",
    },
    ownerUserEntry: {
        id: "ownersTabMessages.ownerUserEntry",
        defaultMessage: "{firstName} {lastName} (you)",
        description: "Entry with user as an Owner",
    },
});

export default ownersTabMessages;
