export const COMMERCIAL_ENQUIRIES_EMAIL = 'commercialenquiries@os.uk';
export const COMMERCIAL_ENQUIRIES_PHONE = '02380 055991';

export const TEN_MINUTES_IN_MS = 600000;

/*
    Add unique LocalStorage keys used across omse-portal to this LOCAL_STORAGE_KEYS object. This will avoid unintentional
    key re-usage with undesired effects.

    NOTE: In order for these localStorage values to be deleted if a user changes their cookie preferences to reject
    functional cookies etc., we need to edit the DataHub cookie control script. This is maintained by the website team
    (contact: James Trivet). If you need to add a new localStorage key below, you should also speak to the website team
    and make sure the key is appropriately deleted if the customer requests it.
 */
export const LOCAL_STORAGE_KEYS = {
    dashboardPremiumAd: 'apiDashPremAdBanner',
    sampleDataForm: 'sampleDataForm',
}

export const COOKIE_KEYS = {
    workspaceOptIn: "workspaceOptIn"
}