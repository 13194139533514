import React, {useState, useEffect, Fragment} from 'react';
import { defineMessages, FormattedDate, FormattedMessage, useIntl } from "react-intl";
import {useSelector, useDispatch} from 'react-redux';
import {loadDataPackageVersion} from "../../../modules/dataPackages/actions";
import GroupedDownloads from "./GroupedDownloads";
import IndividualDownloads from "./IndividualDownloads";
import {Typography, CircularProgress, Select, InputLabel, MenuItem } from '@mui/material';
import {osColour } from 'omse-components';
import {createUseStyles} from 'react-jss';
import ClickAwayTooltip from "../../../components/ClickAwayTooltip";
import {ReactComponent as HelpIcon} from "../../../components/icons/help-notification.svg";
import dateformat from 'dateformat';
import {ReactComponent as ExpandIcon} from '../../../components/icons/expand.svg';

const messages = defineMessages({

    date: {
        id: 'DataPackageVersions.date',
        defaultMessage: 'Date: ',
        description: 'Label for the date value.'
    },
    reason: {
        id: 'DataPackageVersions.reason',
        defaultMessage: 'Reason: ',
        description: 'Label for the reason value.'
    },
    INITIAL: {
        id: 'DataPackageVersions.INITIAL',
        defaultMessage: 'Initial',
        description: 'Reason label for INITIAL versions'
    },
    EXPANSION: {
        id: 'DataPackageVersions.EXPANSION',
        defaultMessage: 'Expansion',
        description: 'Reason label for EXPANSION versions'
    },
    RESUPPLY: {
        id: 'DataPackageVersions.RESUPPLY',
        defaultMessage: 'Resupply',
        description: 'Reason label for RESUPPLY versions'
    },
    UPDATE: {
        id: 'DataPackageVersions.UPDATE',
        defaultMessage: 'Update',
        description: 'Reason label for UPDATE versions'
    },
    UPDATE_FORMAT: {
        id: 'DataPackageVersions.UPDATE_FORMAT',
        defaultMessage: 'Data format changed',
        description: 'Reason label for UPDATE_FORMAT versions'
    },
    ONLINE_ORDER: {
        id: 'DataPackageVersions.ONLINE_ORDER',
        defaultMessage: 'OS Orders - Online Order',
        description: 'Reason label for ONLINE_ORDER versions'
    },
    supplyType: {
        id: 'DataPackageVersions.supplyType',
        defaultMessage: 'Supply type: ',
        description: 'Label for the supply type value.'
    },
    FULL: {
        id: 'DataPackageVersions.FULL',
        defaultMessage: 'Full',
        description: 'Supply Type label for FULL supply'
    },
    COU: {
        id: 'DataPackageVersions.COU',
        defaultMessage: 'Change Only Update',
        description: 'Supply Type label for COU supply'
    },
    productVersion: {
        id: 'DataPackageVersions.productVersion',
        defaultMessage: 'Product version: ',
        description: 'Label for the product version value.'
    },
    chooseVersion: {
        id: 'DataPackageVersions.chooseVersion',
        defaultMessage: 'Choose download version',
        description: 'Label for the choose download version tooltip.'
    },
    tooltip: {
        id: 'DataPackageVersions.tooltip',
        defaultMessage: 'Versions are ordered by their creation date, with the latest first, along with the reason for the change and the file format.',
        description: 'tooltip text'
    },
    tooltipAriaLabel: {
        id: 'DataPackageVersions.tooltipAriaLabel',
        defaultMessage: 'Tooltip button describing how data package versions are ordered',
        description: 'Aria label for the tooltip button'
    }
});

const styles = createUseStyles(theme => ({
    dataPackageVersion: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column-reverse',
        }
    },
    versionSelect: {
        display: 'flex',
        flexDirection: 'column',
    },
    metaDataLabel: {
        marginRight: theme.spacing(1),
        width: 113
    },
    aligned: {
        display: 'flex',
    },
    row: {
        display: 'flex',
        alignItems: 'baseline',
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(1)
        }
    },
    label: {
        margin: theme.spacing(0,1,0,0),
        fontWeight: 'normal',
        color: theme.palette.textSecondary.main,
        padding: 0
    },
    dropDown: {
        width: theme.spacing(24),
    },
    menuList: {
        '& li': {
            margin: 6
        }
    },
    select: {
        width: theme.spacing(25),
        border: 'solid 1px ' + osColour.primary.evenLighterBerry,
        color: osColour.primary.berry,
        borderRadius: 3,
        padding: theme.spacing(1, 1, 1, 2),
        '&:focus': {
            backgroundColor: osColour.neutral.white,
            borderRadius: 0,
        },
    },
    button: {
        display: 'block',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    selectIcon: {
        color: osColour.primary.berry,
        marginLeft: theme.spacing(1),
        height: 24,
        width: 24,
        top: "auto"

    }
}));

export default function DataPackageVersions({dataPackage}) {
    const intl = useIntl();
    let versionFiles = useSelector(state => state.dataPackages.version.result);
    let versionLoading = useSelector(state => state.dataPackages.version.loading);
    let [versionIndex, setVersionIndex] = useState(0);
    const dispatch = useDispatch();
    const classes = styles();

    let packageId = dataPackage.id;
    let version = dataPackage.versions[versionIndex];
    let versionId = version.id;

    // Load the files for the data package version when the data package version changes. We don't need to load
    // version 0, as the files for that version are included in the data package anyway
    useEffect(() => {
        if(versionIndex > 0) {
            dispatch(loadDataPackageVersion(packageId, versionId));
        }
    }, [packageId, versionId, versionIndex, dispatch]);

    if(versionIndex === 0) {
        versionFiles = dataPackage &&
            dataPackage.versions &&
            dataPackage.versions[0] &&
            dataPackage.versions[0].downloads;
    }

    let content;
    if(versionLoading) {
        content = <CircularProgress size={32}/>;
    } else if(versionFiles && versionFiles.length > 0) {
        content = <Fragment>
            <GroupedDownloads packageId={packageId} versionId={versionId} links={versionFiles}/>
            <IndividualDownloads packageId={packageId} version={version} links={versionFiles}/>
        </Fragment>
    } else {
        content = <Typography variant="body1">
            <FormattedMessage id='DataPackageVersions.noFiles'
                              defaultMessage='This data package version does not have any files available for download'
                              description='Message shown when the selected data package version does not have any files'/>
        </Typography>
    }

    function formatReason(reason) {
        const message = messages[reason];
        if(message) {
            return <FormattedMessage {...message}/>;
        }

        // We don't have a translation for this string, so just pass the raw value to the screen.
        return reason;
    }

    function formatReasonToString(reason) {
        const message = messages[reason];
        if(message) {
            return ' - ' + intl.formatMessage(message);
        }

        return ''
    }

    function formatSupplyType(supplyType) {
        const message = messages[supplyType];
        if(message) {
            return <FormattedMessage {...message}/>;
        }

        // We don't have a translation for this string, so just pass the raw value to the screen.
        return supplyType;
    }

    const metaData = [
        {
            label: messages.date,
            value: <FormattedDate value={version.createdOn}
                                  day='numeric'
                                  month='long'
                                  year='numeric'/>
        },
        {
            label: messages.reason,
            value: formatReason(version.reason)
        },
        {
            label: messages.supplyType,
            value: formatSupplyType(version.supplyType)
        },
        {
            label: messages.productVersion,
            value: version.productVersion
        },
    ];

    dataPackage.versions.forEach(v => v.label = dateformat(new Date(v.createdOn), 'dd/mm/yyyy') + formatReasonToString(v.reason) + ' - ' + v.productFormat);

    let versionItems = dataPackage.versions.map((v, index) => ({ value: index, label: v.label }));

    return <Fragment>
        <div className={classes.dataPackageVersion}>
            <div>
            {
                metaData.map(item =>
                    <div key={item.label.id} className={classes.aligned}>
                        <Typography variant='body1' color='textSecondary' className={classes.metaDataLabel}>
                            <FormattedMessage {...item.label}/>
                        </Typography>
                        <Typography variant='body1' component={'div'}>
                            {item.value}
                        </Typography>
                    </div>
                )
            }
            </div>
            <div className={classes.versionSelect}>
                <div className={classes.row}>
                    <InputLabel classes={{inputLabel: classes.label}} id='versionSelectMessage'>
                        <FormattedMessage {...messages.chooseVersion}/>
                    </InputLabel>
                    <ClickAwayTooltip id='versionSelectTip' 
                        ariaLabel={messages.tooltipAriaLabel.defaultMessage} 
                        body={messages.tooltip}
                        icon={<HelpIcon width={24} height={24} style={{color: osColour.primary.berry}}/>} 
                    />
                </div>
                <div className={classes.row}>
                    <Select
                        id='versionSelect'
                        aria-describedby='versionSelectMessage'
                        classes={{
                            select: classes.select,
                            icon: classes.selectIcon
                        }}
                        MenuProps={{
                            classes: {menuList: classes.button, list: classes.menuList},
                            anchorOrigin: {vertical: 'bottom', horizontal: 'center'},
                        }}
                        value={versionIndex}
                        onChange={event => setVersionIndex(event.target.value)}
                        IconComponent={ExpandIcon}
                        variant='outlined'
                    >
                        {versionItems.map((version, id ) => (
                            <MenuItem id={id} value={id}>{version.label}</MenuItem>
                         ))}
                    </Select>
                </div>
            </div>
        </div>
        {content}
    </Fragment>
}