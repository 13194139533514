import { osColour } from "omse-components";
import styled from "@emotion/styled";
import { useMediaQuery } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import PropTypes from "prop-types";
import newTeamSpaceMessages from "../NewTeamSpace.msg";
import memberInfoMessages from "./MemberInfo.msg";
import roleMessages from "../../../../shared/roles.msg";
import { useSelector } from "react-redux";

const Container = styled.div`
    display: flex;
    flex-direction: column;

    & > div {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    & > div:last-of-type {
        color: ${osColour.neutral.stone};
    }

    & > div,
    div > span {
        word-break: break-all;
    }

    & > div > span.selected {
        font-style: italic;
    }
`;

export default function MemberInfo({ info, user, currentTab, prevSelected }) {
    const intl = useIntl();
    const mobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
    const isLoggedInUser = info.datahubDeveloperId === user.datahubDeveloperId;
    const orgUsers = useSelector((state) => state.teamSpaces.getOrgUsers.result);
    const contact = orgUsers?.find(u => u.datahubDeveloperId === info.datahubDeveloperId);
    return (
        <Container>
            <div>
                {contact?.firstName}&nbsp;{contact?.lastName}
                {isLoggedInUser ? intl.formatMessage(newTeamSpaceMessages.youSuffix) : ""}
            </div>
            <div>
                <span>
                    {contact? intl.formatMessage(roleMessages[contact.role]) : ""}
                    {mobile ? <br aria-hidden="true" /> : <>&ensp;|&ensp;</>}
                    {contact?.email}
                </span>
                {prevSelected && (
                    <span className="selected">
                        <FormattedMessage
                            {...newTeamSpaceMessages.alreadySelected}
                            values={{
                                tab:
                                    currentTab === "owners"
                                        ? intl.formatMessage(memberInfoMessages.aMember)
                                        : intl.formatMessage(memberInfoMessages.anOwner),
                            }}
                        />
                    </span>
                )}
            </div>
        </Container>
    );
}

MemberInfo.propTypes = {
    info: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    currentTab: PropTypes.string,
    prevSelected: PropTypes.bool,
};
