import teamSpacesErrorMessages from "./teamSpacesErrorMessages.msg.js";
import genericErrorMessages from "./genericErrors.msg";
import { getErrorKeys } from "./getErrorKeys";

// Field validation, permission and generic error message map.
export function getTeamSpacesErrorMessage(teamSpaceResult, defaultError) {
    const { errorKey, httpStatus } = getErrorKeys(teamSpaceResult);
    switch (httpStatus) {
        case 400:
            switch (errorKey) {
                case "field:name:required":
                    return teamSpacesErrorMessages.nameFieldRequired;
                case "field:name:length":
                    return teamSpacesErrorMessages.nameFieldLengthInvalid;
                case "field:description:length":
                    return teamSpacesErrorMessages.descriptionFieldLengthInvalid;
                case "permission:invalid":
                    return teamSpacesErrorMessages.invalidPermission;
                default:
                // Continue to default/generic server error
            }
            break;
        case 403:
            switch (errorKey) {
                case "role:invalid":
                    return teamSpacesErrorMessages.invalidRole;
                default:
                    return genericErrorMessages.genericUnauthorized;
            }
        default:
            // Continue to default/generic server error
    }
    return defaultError || genericErrorMessages.genericServerError;
}
