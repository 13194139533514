import { defineMessages } from "react-intl";

const teamSpacesListMessages = defineMessages({
    name: {
        id: "selectionResultsMessages.name",
        description: "Label for the Team Space name",
        defaultMessage: "Team Space",
    },
    createdBy: {
        id: "selectionResultsMessages.createdBy",
        description: "Label for the Team Space author",
        defaultMessage: "Created by",
    },
    description: {
        id: "selectionResultsMessages.description",
        description: "Label for the Team Space description",
        defaultMessage: "Description",
    },
    lockedAria: {
        id: "selectionResultsMessages.lockedAria",
        description: "Aria-label for a locked Team Space",
        defaultMessage: "Locked Team Space",
    },
    unlockedAria: {
        id: "selectionResultsMessages.unlockedAria",
        description: "Aria-label for an unlocked Team Space",
        defaultMessage: "Unlocked Team Space",
    },
    searchHasResults: {
        id: "selectionResultsMessages.searchHasResults",
        description: "The total number of results",
        defaultMessage: "{total} result{isPlural}.",
    },
    searchHasNoResults: {
        // Note: This is not the "Empty State" message - it
        // only affects situations when result filters are in use.
        id: "selectionResultsMessages.searchHasNoResults",
        description: "The error message displayed when the user has no results.",
        defaultMessage: "No results. Please refine your search filters.",
    }
});

export default teamSpacesListMessages;
