import React from "react";
import Header from "../../documentation/Header";
import { withWidth } from "omse-components";
import Content from "../../documentation/Content";
import { List, Typography } from "@mui/material";
import { createUseStyles } from "react-jss";
import getBaseStyles from "./styles/support";
import Faq from "./components/Faq";
import { LinkToAnchor } from "../../../components/LinkToAnchor";
import { useIntl } from "react-intl";
import { teamSpacesFaqHeader, teamsSpacesFaqList } from "./TeamSpaces.msg";
import TeamSpacesRolesTable from "./tables/TeamSpacesRolesTable";

const useStylesBase = createUseStyles((theme) => getBaseStyles(theme));

export function TeamSpaces(props) {
    const intl = useIntl();
    const classesBase = useStylesBase(props);

    return (
        <>
            <Header>
                <span>{intl.formatMessage(teamSpacesFaqHeader.title)}</span>
            </Header>
            <Content classes={{ content: classesBase.support }}>
                <Typography variant="h2" id={teamSpacesFaqHeader.heading.id}>
                    {intl.formatMessage(teamSpacesFaqHeader.heading)}
                </Typography>
                <Typography variant="body1" id={teamSpacesFaqHeader.subHeading.id}>
                    {intl.formatMessage(teamSpacesFaqHeader.subHeading)}
                </Typography>

                <nav className={classesBase.faqs}>
                    <List>
                        {teamsSpacesFaqList.map((faq) => (
                            <LinkToAnchor
                                key={faq.title.id}
                                item={{
                                    id: faq.title.id,
                                    title: intl.formatMessage(faq.title),
                                }}
                            />
                        ))}
                    </List>
                </nav>

                <div role="separator" className={classesBase.divider} />

                {teamsSpacesFaqList.map((faq) => (
                    <Faq
                        key={faq.title.id}
                        linkId={faq.title.id}
                        title={intl.formatMessage(faq.title)}
                    >
                        {faq.content.defaultMessage === "TEAM_SPACES_ROLES_TABLE_PLACEHOLDER" ? (
                            <TeamSpacesRolesTable />
                        ) : (
                            intl.formatMessage(faq.content, {
                                p: (chunks) => <Typography variant="body1">{chunks}</Typography>,
                            })
                        )}
                    </Faq>
                ))}

                <br aria-hidden="true" />
                <List>
                    <LinkToAnchor />
                </List>
            </Content>
        </>
    );
}

export default withWidth()(TeamSpaces);
