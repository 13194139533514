import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@mui/material";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import Button from "@mui/material/Button";
import {useHistory} from "react-router-dom";
import Link from "../../../components/Link";
import {useSelector} from "react-redux";
import {CRM_PENDING_PSGA_PLAN, USER_OPEN_DATA_PLAN, USER_PREMIUM_DATA_PLAN} from "../../../../shared/plans";
import { hasPartnerCatalogue } from "../../../util/permissions";
import { createUseStyles } from "react-jss";
import { LinkButton } from "omse-components";
import { handleLogIn } from "./DelTermsActions";
import routePaths from "../../../util/routes";

const messages = defineMessages({
    genericTitle: {
        id: "DelApplyIneligibleDialog.genericTitle",
        defaultMessage: "Not Currently Eligible",
        description: "Generic title for DelApplyIneligibleDialog"
    },
    openDataTitle: {
        id: "DelApplyIneligibleDialog.openDataTitle",
        defaultMessage: "Ineligible for the DEL",
        description: "Open Data title for DelApplyIneligibleDialog"
    },
    partnerTitle: {
        id: "DelApplyIneligibleDialog.partnerTitle",
        defaultMessage: "Already a Partner",
        description: "Partner title for DelApplyIneligibleDialog"
    },
    loggedOutTitle: {
        id: "DelApplyIneligibleDialog.loggedOutTitle",
        defaultMessage: "Login",
        description: "Logged out title for DelApplyIneligibleDialog"
    },
    genericP1: {
        id: "DelApplyIneligibleDialog.genericP1",
        defaultMessage: "The Data Exploration Licence is not currently available for this account type.",
        description: "Generic paragraph 1 for DelApplyIneligibleDialog"
    },
    openDataP1: {
        id: "DelApplyIneligibleDialog.p1",
        defaultMessage: "You are ineligible to apply for the Data Exploration Licence (DEL). In order to apply, you " +
            "must be logged in to a <a>Premium or Public Sector Plan</a> account.",
        description: "Paragraph 1 for DelApplyIneligibleDialog"
    },
    partnerP1: {
        id: 'DelApplyIneligibleDialog.partnerP1',
        defaultMessage: "As a Partner, you don't need the Data Exploration Licence. You already have access to Premium Data.",
        description: 'Partner paragraph 1'
    },
    loggedOutP1: {
        id: 'DelApplyIneligibleDialog.loggedOutP1',
        defaultMessage: "Please <logIn>log in</logIn> to check eligibility.",
        description: 'Logged out  paragraph 1'
    },
    p1LinkAriaLabel: {
        id: 'DelApplyIneligibleDialog.p1LinkAriaLabel',
        defaultMessage: 'Click here to view information on our account plans',
        description: 'Aria label for the link in p1'
    },
    p2OpenData: {
        id: "DelApplyIneligibleDialog.p2OpenData",
        defaultMessage: "Click here to upgrade to a Premium Plan.",
        description: "Paragraph 2 for DelApplyIneligibleDialog OpenData plan account"
    },
    cancelButton: {
        id: "DelApplyIneligibleDialog.cancelButton",
        defaultMessage: "Return to homepage",
        description: "Cancel button for DelApplyIneligibleDialog"
    }
});

const useStyles = createUseStyles(theme => ({
    logInButton: {
        minWidth: "auto",
        verticalAlign: "baseline"
    }
}))

function DelApplyIneligibleDialog({open}) {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const userDetails = useSelector((state) => state.user.current?.result);

    const isOpenData = userDetails?.plan === USER_OPEN_DATA_PLAN && userDetails?.orgPlan !== CRM_PENDING_PSGA_PLAN;
    const isPartner = userDetails?.plan === USER_PREMIUM_DATA_PLAN && hasPartnerCatalogue(userDetails);
    const loggedOut = userDetails === undefined;

    function setDialogTitle() {
        if (isOpenData) {
            return <FormattedMessage {...messages.openDataTitle} />
        }

        if (isPartner) {
            return <FormattedMessage {...messages.partnerTitle} />
        }

        if (loggedOut) {
            return <FormattedMessage {...messages.loggedOutTitle} />
        }

        return <FormattedMessage {...messages.genericTitle} />
    }

    function setDialogText() {
        if(isOpenData) {
            return <FormattedMessage {...messages.openDataP1} values={{
                        a: message =>
                            <Link
                                path={routePaths.plans}
                                ariaLabel={intl.formatMessage(messages.p1LinkAriaLabel)}>
                                {message}
                            </Link>
                    }}/>
        }

        if(isPartner) {
            return <FormattedMessage {...messages.partnerP1}/>
        }

        if(loggedOut) {
            return <Typography>
                <FormattedMessage {...messages.loggedOutP1} values={{
                    logIn: message => (
                        <LinkButton key='login'
                                    onClick={handleLogIn}
                                    size='normal'
                                    variant='text'
                                    id="logInButton"
                                    className={classes.logInButton}>
                            {message}
                        </LinkButton>
                    )}}
                />
            </Typography>
        }

        return <FormattedMessage {...messages.genericP1} />
    }

    const handleCancel = () => {
        history.push('/');
    };

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>
                {setDialogTitle()}
            </DialogTitle>
            <DialogContent>
                <DialogContentText paragraph={true}>
                    {setDialogText()}
                </DialogContentText>
                {isOpenData && <DialogContentText paragraph={true}>
                   <Link path={routePaths.premiumSetup}><FormattedMessage {...messages.p2OpenData} /></Link>
                </DialogContentText>}
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={handleCancel}>
                    <FormattedMessage {...messages.cancelButton} />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default DelApplyIneligibleDialog;