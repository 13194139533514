import { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { LockOutlined } from "@mui/icons-material";
import { osColour } from "omse-components";
import {
    Box,
    Typography,
    FormControlLabel,
    Switch,
    ClickAwayListener,
    Tooltip,
    IconButton,
    Autocomplete,
    CircularProgress,
} from "@mui/material";
import { ReactComponent as InfoIcon } from "../../../components/icons/info-notification.svg";
import Link from "../../../components/Link";
import SearchField from "./SearchField";
import MembersTable from "./MembersTable";
import MemberInfo from "./MemberInfo";
import { getOrgUsers } from "../../../modules/teamSpaces/actions";
import {
    TEAM_PERMISSION_GROUPS,
    API_PROJECT_PERMISSION_GROUPS,
} from "../../../../shared/teamSpaces";
import { ADMIN_ROLE } from "../../../../shared/roles";
import routePaths from "../../../util/routes";
import membersTabMessages from "./MembersTab.msg";
import { dialogVariants, membersTab } from "./constants";
import { css } from "@emotion/css";
import { useManageTeamSpacePermission } from "../util/useManageTeamSpacePermission";

const noOptionsClass = css`
    &:empty {
        display: none;
    }
`;

const LockedTeamSwitcher = styled(FormControlLabel)(
    ({ theme }) => `
    margin: 0 0 ${theme.spacing(2)} 0;
    gap: 1em;
    display: flex;
    place-items: center;
    justify-content: space-between;

    & span {
        gap: 0.3em;
        display: flex;
        place-items: center;
    }
`
);

const MIN_SEARCH_LENGTH = 3;

const MembersTab = ({
    teamSpaceParameters,
    setTeamSpaceParameters,
    members,
    setMembers,
    selectedUserIds,
    variant,
}) => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.current.result);
    const orgUsers = useSelector((state) => state.teamSpaces.getOrgUsers.result);
    const orgUsersLoading = useSelector((state) => state.teamSpaces.getOrgUsers.loading);
    const teamSpaceResult = useSelector((state) => state.teamSpaces.getTeamSpace.result);
    const hasManageTeamSpacePermission = useManageTeamSpacePermission(user, teamSpaceResult);

    const isManageAccessView =
        variant === dialogVariants.newTeamSpace || hasManageTeamSpacePermission;

    const [filteredOrgUsers, setFilteredOrgUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [autocompleteKey, setAutocompleteKey] = useState("autocomplete-members");

    const [infoTooltipVisible, setInfoTooltipVisible] = useState(false);

    useEffect(
        function () {
            if (!orgUsers && !orgUsersLoading) {
                dispatch(getOrgUsers());
            }
        },
        [dispatch, orgUsers, orgUsersLoading]
    );

    useEffect(
        function () {
            if (user && orgUsers) {
                setFilteredOrgUsers([...orgUsers.filter((orgUser) => orgUser.role !== ADMIN_ROLE)]);
            }
        },
        [user, orgUsers, setFilteredOrgUsers]
    );

    const toggleLocked = () => {
        resetAutocomplete();
        setTeamSpaceParameters((prevState) => ({
            ...prevState,
            locked: !teamSpaceParameters.locked,
        }));
    };

    function resetAutocomplete() {
        setSearchTerm("");
        setAutocompleteKey(`autocomplete-members-${Date.now()}`);
    }

    // Autocomplete change: select/clear option
    function handleAutoCompleteChange(setter, selectedUserItem, reason) {
        if (reason === "selectOption") {
            if (selectedUserItem) {
                setMembers([
                    {
                        ...selectedUserItem,
                        teamRole: TEAM_PERMISSION_GROUPS.TeamViewer,
                        apiProjectRole: API_PROJECT_PERMISSION_GROUPS.ApiProjectViewer,
                    },
                    ...members,
                ]);
                resetAutocomplete();
            }
        }
    }

    const onKeyUpHandler = (event) => {
        if (event) {
            setSearchTerm(event.target.value || "");
        }
    };

    const orgUserDetailsContainSearchTerm = useMemo(
        () => (orgUser) => {
            const st = searchTerm?.toLowerCase();
            const fn = orgUser.firstName?.toLowerCase();
            const ln = orgUser.lastName?.toLowerCase();
            const em = orgUser.email?.toLowerCase();
            const result =
                st &&
                ((fn && fn.indexOf(st) !== -1) ||
                    (ln && ln.indexOf(st) !== -1) ||
                    (em && em.indexOf(st) !== -1) ||
                    (fn && ln && (fn + " " + ln).indexOf(st) !== -1));
            return result;
        },
        [searchTerm]
    );

    return (
        <>
            <LockedTeamSwitcher
                label={
                    <>
                        <LockOutlined
                            fontSize="medium"
                            aria-hidden={true}
                            sx={{ color: osColour.neutral.stone }}
                        />
                        <Typography variant="body1">
                            {intl.formatMessage(membersTabMessages.makePrivate)}
                        </Typography>
                        <ClickAwayListener onClickAway={() => setInfoTooltipVisible(false)}>
                            <Tooltip
                                open={infoTooltipVisible}
                                onClose={() => setInfoTooltipVisible(false)}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                arrow
                                title={
                                    teamSpaceParameters.locked ? (
                                        <>
                                            <Typography variant="body1" marginBottom={2}>
                                                {intl.formatMessage(
                                                    membersTabMessages.unlockedTooltipP1,
                                                    {
                                                        strong: (chunks) => (
                                                            <strong>{chunks}</strong>
                                                        ),
                                                    }
                                                )}
                                            </Typography>
                                            <Typography variant="body1">
                                                {intl.formatMessage(
                                                    membersTabMessages.unlockedTooltipP2,
                                                    {
                                                        strong: (chunks) => (
                                                            <strong>{chunks}</strong>
                                                        ),
                                                    }
                                                )}
                                            </Typography>
                                        </>
                                    ) : (
                                        <Typography variant="body1">
                                            {intl.formatMessage(membersTabMessages.lockedTooltip)}
                                        </Typography>
                                    )
                                }
                            >
                                <IconButton
                                    size="small"
                                    onClick={() => setInfoTooltipVisible(true)}
                                >
                                    <InfoIcon
                                        width={24}
                                        height={24}
                                        color={osColour.status.warning}
                                    />
                                </IconButton>
                            </Tooltip>
                        </ClickAwayListener>
                    </>
                }
                labelPlacement="start"
                control={
                    <Switch
                        data-testid="toggleTeamLockedSwitch"
                        checked={teamSpaceParameters.locked || false}
                        onChange={() => toggleLocked()}
                        disabled={
                            !user ||
                            (variant === dialogVariants.manageTeamSpace && !teamSpaceResult) ||
                            !isManageAccessView
                        }
                    />
                }
            />
            {teamSpaceParameters.locked ? (
                <>
                    {isManageAccessView && (
                        <Autocomplete
                            key={autocompleteKey}
                            fullWidth
                            clearOnEscape
                            handleHomeEndKeys
                            autoHighlight
                            clearOnBlur={false}
                            getOptionLabel={() => ""}
                            getOptionDisabled={(option) =>
                                option === "" || selectedUserIds.includes(option.datahubDeveloperId)
                            }
                            forcePopupIcon={false}
                            options={filteredOrgUsers || []}
                            loading={orgUsersLoading}
                            onChange={handleAutoCompleteChange}
                            filterOptions={(options, state) => {
                                const results = options.filter(
                                    (orgUser) =>
                                        searchTerm?.length >= MIN_SEARCH_LENGTH &&
                                        !members.find(
                                            (member) =>
                                                member.datahubDeveloperId ===
                                                orgUser.datahubDeveloperId
                                        ) &&
                                        orgUserDetailsContainSearchTerm(orgUser)
                                );
                                return results?.length > 0 ? results : [];
                            }}
                            renderInput={(params) => (
                                <SearchField
                                    {...params}
                                    showClearSearchTerm={searchTerm?.length > 0}
                                    onClearSearchTerm={resetAutocomplete}
                                />
                            )}
                            renderOption={(props, option) => {
                                const prevSelected = selectedUserIds.includes(
                                    option.datahubDeveloperId
                                );
                                return (
                                    <li {...props} key={option.datahubDeveloperId}>
                                        <MemberInfo
                                            info={option}
                                            user={user}
                                            currentTab={membersTab}
                                            prevSelected={prevSelected}
                                        />
                                    </li>
                                );
                            }}
                            classes={{
                                inputRoot: "inputRoot",
                                noOptions: noOptionsClass,
                            }}
                            noOptionsText={
                                searchTerm.length >= MIN_SEARCH_LENGTH
                                    ? intl.formatMessage(membersTabMessages.noResultsAdminPrompt, {
                                          a: (chunks) => (
                                              <Link path={routePaths.manageTeamMembers}>
                                                  {chunks}
                                              </Link>
                                          ),
                                      })
                                    : ""
                            }
                            onKeyUp={onKeyUpHandler}
                        />
                    )}

                    {(orgUsersLoading || !user) && (
                        <CircularProgress size={32} className="loading" />
                    )}

                    {!orgUsersLoading && user && members.length > 0 && (
                        <MembersTable members={members} setMembers={setMembers} variant={variant} />
                    )}
                </>
            ) : (
                <Typography variant="body1">
                    {intl.formatMessage(membersTabMessages.openMessage, {
                        br: () => <br aria-hidden="true" />,
                        isManageAccessViewContent: (chunks) => (isManageAccessView ? chunks : ""),
                    })}
                </Typography>
            )}
        </>
    );
};

export default MembersTab;

MembersTab.propTypes = {
    teamSpaceParameters: PropTypes.object.isRequired,
    setTeamSpaceParameters: PropTypes.func.isRequired,
    members: PropTypes.arrayOf(PropTypes.object).isRequired,
    setMembers: PropTypes.func.isRequired,
    selectedUserIds: PropTypes.arrayOf(PropTypes.string),
    variant: PropTypes.oneOf(Object.values(dialogVariants)),
};
