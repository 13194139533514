import React, { useState, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { createUseStyles } from 'react-jss';
import Input from '@mui/material/Input';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { osColour, FieldError } from 'omse-components';
import { ReactComponent as DrawIcon } from "./icons/draw.svg";
import classNames from 'classnames';
import { CircularProgress } from '@mui/material';

const messages = defineMessages({
    loading: {
        id: 'EditableName.loading',
        defaultMessage: 'Loading...',
        description: 'Loading message for the Editable Name component'
    }
});

const useStyles = createUseStyles(theme => {
    // The maxWidth: '100%' below is a bit counter intuitive, but we need to set it on the button and name so that
    // overflows can happen, and then we get the ellipsis that we want.
    return {
        button: {
            flex: '1 0 auto',
            padding: 0,
            margin: 0,
            border: 0,
            cursor: 'pointer',
            backgroundColor: 'white',
            display: 'flex',
            maxWidth: '100%',
            '&:active': {
                // This helps to avoid a flash of outline when the button is clicked. The focus state will
                // still trigger an outline, so the button is still accessible to keyboard users.
                outline: 0
            },
            marginBottom: 17,
            textAlign: 'left'
        },
        name: {
            paddingLeft: 0,
            wordBreak: "break-all",
            whiteSpace: "normal"
        },
        inputName: {
            ...theme.typography.h1,
            marginBottom: theme.spacing(1.25),
            border: 'none'
        },
        description: {
            maxWidth: '800px',
            wordBreak: "break-all",
            whiteSpace: "normal"
        },
        inputDescription: {
            ...theme.typography.body1,
            marginBottom: theme.spacing(1.25),
            border: 'none'
        },
        innerInput: {
            color: osColour.neutral.charcoal,
            border: 'solid 1px ' + osColour.primary.berry,
            borderRadius: 3,
            padding: theme.spacing(0.5)
        },
        icon: {
            flex: '0 0 auto',
            width: 32,
            height: 32,
            position: 'relative',
            top: 6,
            marginLeft: theme.spacing(1),
            color: osColour.neutral.rock
        },
        descriptionIcon: {
            flex: '0 0 auto',
            width: 16,
            height: 16,
            position: 'relative',
            top: 4,
            marginLeft: theme.spacing(1),
            color: osColour.neutral.rock
        },
        container: {
            // todo: fix shared tag placement for recipes without breaking other styling
            flex: '2 0 auto',
            maxWidth: '100%'
        },
        loadingContainer: {
            marginLeft: theme.spacing(1)
        }
    }
});



export default function EditableField(props) {
    const [buttonWidth, setButtonWidth] = useState(null);
    const [editing, setEditing] = useState(false);
    const [newFieldValue, setNewFieldValue] = useState('');
    const inputRef = useRef();
    const classes = useStyles();
    const intl = useIntl();

    const fieldTypeMap = {
        name: {
            typography: {
                className: classes.name,
                variant: 'h1',
                noWrap: false,
                color: 'primary'
            },
            input: {
                className: classes.inputName
            },
            icon: {
                className: classes.icon
            }
        },
        description: {
            typography: {
                className: classes.description,
                variant: 'body1',
                paragraph: true
            },
            input: {
                className: classes.inputDescription,
                multiline: true
            },
            icon: {
                className: classes.descriptionIcon
            }
        }
    }

    const {
        fieldValue, setFieldValue, readonly, buttonAriaLabel, iconAriaLabel, inputAriaLabel,
        maxLength, maxLengthMessage, fieldTypeName, placeholder, showLoading, loading
    } = props;
    const fieldType = fieldTypeMap[fieldTypeName || "name"]; // Default to name if nothing is provided rather than throwing an error
    // As soon as we have newName stored in our state then we use that name instead of the one passed in as a prop.
    // This helps to stop the name flashing when we trigger an update, and the update has gone out to the server,
    // but has not come back again yet.
    const nameToDisplay = newFieldValue || fieldValue;

    function startEditing(event) {
        // We want the input control to be the same size as the button that it replaces. The input has 2 pixels of
        // border that we need to allow for, so we remove those here. The clientWidth has been rounded down already
        // so we also need to allow for that
        const width = event.currentTarget.clientWidth - 2 - 1;
        setButtonWidth(width);
        setNewFieldValue(nameToDisplay === placeholder ? "" : nameToDisplay);
        setEditing(true);
    }
    function endEditing() {
        let trimmedName = newFieldValue.trim();

        if (trimmedName.length > maxLength) {
            // We cannot save the name, as it is too long. Revert to the old name
            setNewFieldValue('');
        } else {
            if (trimmedName !== fieldValue && (trimmedName.length > 0 || placeholder)) {
                setFieldValue(trimmedName);
            }
            if (placeholder && !trimmedName) {
                setNewFieldValue(placeholder);
            } else {
                setNewFieldValue(trimmedName);
            }
        }
        setEditing(false);
    }
    function handleChange(event) {
        setNewFieldValue(event.target.value);
    }
    function handleKey(event) {
        if (event.key === 'Enter') {
            event.preventDefault();

            endEditing();
        }
        if (event.key === 'Escape') {
            event.preventDefault();

            // Killing the edit this way will skip the attempt to save the project name
            setNewFieldValue('');
            setEditing(false);
        }
    }

    let content = null;
    if (!nameToDisplay && !placeholder) {
        content = <Typography variant={fieldType.typography.variant}>
            <FormattedMessage {...messages.loading} />
        </Typography>;
    } else if (readonly && nameToDisplay) {
        content = <Typography {...fieldType.typography}>
            {nameToDisplay}
        </Typography>;
    } else if (!readonly && !editing) {
        content = <button aria-label={intl.formatMessage(buttonAriaLabel)}
            className={classes.button}
            onClick={startEditing}>
            <Typography {...fieldType.typography}>
                {nameToDisplay || placeholder}
            </Typography>
            <DrawIcon alt={intl.formatMessage(iconAriaLabel)} className={fieldType.icon.className} />
        </button>;
    } else if (editing) {
        content = <Fragment>
            <Input {...fieldType.input}
                autoFocus={true}
                inputRef={inputRef}
                onChange={handleChange}
                onBlur={endEditing}
                onKeyDown={handleKey}
                type={"text"}
                value={newFieldValue}
                classes={{ input: classes.innerInput }}
                inputProps={{
                    'aria-label': intl.formatMessage(inputAriaLabel),
                    'style': { width: buttonWidth }
                }}
                disableUnderline={true}
                placeholder={placeholder}
            />
            <FieldError error={
                newFieldValue.trim().length > maxLength ? maxLengthMessage : undefined
            } />
        </Fragment>
    }

    return (<div className={classNames(classes.container, props.classes?.container)}>
        {content}
        {(showLoading && loading) &&
            <div className={classNames(classes.loadingContainer, props.classes?.loadingContainer)}>
                <CircularProgress size={fieldTypeName === 'name' ? 32 : 18} data-testid={`set-${fieldTypeName}-loading`} />
            </div>
        }
    </div>);
}

EditableField.propTypes = {
    fieldValue: PropTypes.string,
    fieldTypeName: PropTypes.string.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    readonly: PropTypes.bool,
    buttonAriaLabel: PropTypes.object.isRequired,
    iconAriaLabel: PropTypes.object.isRequired,
    inputAriaLabel: PropTypes.object.isRequired,
    maxLength: PropTypes.number,
    maxLengthMessage: PropTypes.object,
    placeholder: PropTypes.string,
    classes: PropTypes.object,
    showLoading: PropTypes.bool,
    loading: PropTypes.bool
};

EditableField.defaultProps = {
    maxLength: Number.MAX_VALUE
};
