export const teamSpaceSortOptions = [
    {
        id: "dateUpdatedDesc",
        label: "Date updated - newest to oldest",
        value: 0,
    },
    {
        id: "dateUpdatedAsc",
        label: "Date updated - oldest to newest",
        value: 1,
    },
    {
        id: "nameAsc",
        label: "Team Space name - A to Z",
        value: 2,
    },
    {
        id: "nameDesc",
        label: "Team Space name - Z to A",
        value: 3,
    },
    {
        id: "dateCreatedDesc",
        label: "Date created - newest to oldest",
        value: 4,
    },
    {
        id: "dateCreatedAsc",
        label: "Date created - oldest to newest",
        value: 5,
    },
];

export const teamSpaceSortFunction = filterOrdering => (a, b) => {
    switch (filterOrdering) {
        case 1:
            return a.updatedAtMs - b.updatedAtMs;
        case 2:
            return (!a.name) || (a.name.toLowerCase() > b.name?.toLowerCase()) ? 1 : -1;
        case 3:
            return (!a.name) || (a.name.toLowerCase() < b.name?.toLowerCase()) ? 1 : -1;
        case 4:
            return b.createdAtMs - a.createdAtMs;
        case 5:
            return a.createdAtMs - b.createdAtMs;
        default:
            return b.updatedAtMs - a.updatedAtMs;
    }
};

export const teamSpaceMap = teamSpace => {
    teamSpace.createdAtMs = new Date(teamSpace.createdAt).getTime();
    teamSpace.updatedAtMs = new Date(teamSpace.updatedAt).getTime();
    return teamSpace;
}