import DelApplyIneligibleDialog from "./DelApplyIneligibleDialog";
import useDelEligibility from "../../../hooks/useDelEligibility";
import {ReactComponent as LeftArrow} from "../../../components/icons/backward-arrow-large.svg";
import { LinkButton, osColour } from "omse-components";
import {
    Box,
    Step,
    StepLabel,
    Stepper,
} from "@mui/material";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "omse-components";
import { createUseStyles } from "react-jss";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useSelector } from "react-redux";
import { DelApplyFormOrgInfo } from "./DelApplyFormOrgInfo";
import { DelApplyFormUsage } from "./DelApplyFormUsage";
import { DelApplyFormConfirmation } from "./DelApplyFormConfirmation";
import DelApplicationPending from "./DelApplicationPending";
import DelCancelApplicationDialog from "./DelCancelApplicationDialog";
import styled from "@emotion/styled";
import useDelStatus from "../../../hooks/useDelStatus";
import DelApplicationSuccessful from "./DelApplicationSuccessful";
import DelApplicationRevoked from "./DelApplicationRevoked";

const StepConnector = styled("span")(
    ({ isComplete, theme }) => `
    margin: 0 0.6em;
    min-width: ${theme.spacing(5)};
    border-bottom: 2px solid ${isComplete ? osColour.primary.berry : osColour.neutral.rock};
`
);

const ControlTop = styled(Box)(({ theme }) => `
    padding: ${theme.spacing(3, 4, 1, 4)};
    display: flex;
    gap: ${theme.spacing(3)};
    align-items: center;
    justify-content: space-between;
`);

const messages = defineMessages({
    title: {
        id: 'DelApply.title',
        defaultMessage: 'Data Exploration Licence application',
        description: 'Vernacular Names Terms Title',
    },
    subtitle: {
        id: 'DelApply.subtitle',
        defaultMessage: 'Follow the sign up process below to start you application and begin exploring our data',
        description: 'OS Data Hub Vernacular Names Terms Title',
    },
    stepOne: {
        id: 'DelApply.stepOne',
        defaultMessage: 'About your business',
        description: 'OS Data Hub Vernacular Names Terms Title',
    },
    stepTwo: {
        id: 'DelApply.stepTwo',
        defaultMessage: 'Use of the data',
        description: 'OS Data Hub Vernacular Names Terms Title',
    },
    stepThree: {
        id: 'DelApply.stepThree',
        defaultMessage: 'Confirm details and apply',
        description: 'OS Data Hub Vernacular Names Terms Title',
    },
    back: {
        id: 'DelApply.back',
        defaultMessage: 'Homepage',
        description: 'back button text'
    },
    backIconAlt: {
        id: 'DelApply.backIconAlt',
        defaultMessage: 'Back to',
        description: 'Alt text for the back icon'
    }
});
const useStyles = createUseStyles(theme => ({
    title: {
        display: 'flex',
        justifyContent: "space-around",
        padding: theme.spacing(2)
    },
    stepper: {
        justifyContent: "space-around"
    },
    container: {
        height: '100%'
    },
    linkStyle: {
        color: osColour.primary.berry,
        marginTop: theme.spacing(4.5),
        marginLeft: theme.spacing(3),
        widthMax: theme.spacing(4),
        display: 'inline-flex',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(2)
        }
    },
    linkIcon: {
        width: '16px',
        height: '16px',
        paddingTop: '2px',
        paddingRight: theme.spacing(1)
    }
}));

const applicationEndScreens = {
    "Applied": <DelApplicationPending/>,
    "Approved": <DelApplicationSuccessful/>,
    "Rejected": <DelApplicationRevoked/>,
    "Expired": <DelApplicationRevoked/>,
    "Revoked": <DelApplicationRevoked/>,
    "": <DelApplicationPending/>
};

function DelApply() {
    const { eligible } = useDelEligibility();
    const intl = useIntl();
    const isMobile = useMediaQuery(
        `(max-height: 800px), (max-width: ${theme.breakpoints.values.sm}px)`,
    );
    const classes = useStyles();
    const user = useSelector(state => state.user.current.result);

    const [orgInfo, setOrgInfo] = useState({
        organisation: '',
        organisationType: '',
        streetAddress: '',
        addressLine2: '',
        city: '',
        county: '',
        postcode: '',
        country: '',
        jobTitle: '',
        phoneNumber: '',
    });

    const [usageInfo, setUsageInfo] = useState({
        usageType: '',
        internalBusinessUse: false,
        acceptedTerms: false
    });

    // Del application status ca be one of the following: "Applied", "Approved", "Rejected", "Expired", "Revoked", ""
    // A blank string means the user has not applied for a DEL in which case we show the application form
    // If the user has applied for a DEL we show the status of the DelApplicationComplete
    const delApplicationStatus = useDelStatus();
    const [activeStepId, setActiveStepId] = useState(0);
    const [applicationSubmitted, setApplicationSubmitted] = useState(0);
    const [showCancelDialog, setShowCancelDialog] = useState(false);

    function stepBack(){
        setShowCancelDialog(true);
    }

    const applicationEndScreen = applicationEndScreens[delApplicationStatus];

    return <>
        { applicationSubmitted || !!delApplicationStatus ?
            applicationEndScreen : <div className={classes.container}>
                <LinkButton className={classes.linkStyle} onClick={stepBack}>
                    <LeftArrow className={classes.linkIcon} aria-label={intl.formatMessage(messages.backIconAlt)}/>
                    <Typography variant='body1'>
                        <FormattedMessage {...messages.back}/>
                    </Typography>
                </LinkButton>
                <Typography variant="h1"
                            color="primary"
                            className={classes.title}>
                    <FormattedMessage {...messages.title} ></FormattedMessage>
                </Typography>
                <ControlTop className={classes.stepper}>
                    <Stepper
                        activeStep={activeStepId}
                        connector={
                            !isMobile && (
                                <StepConnector
                                    aria-hidden={true}
                                    isComplete={activeStepId >= 1}
                                />
                            )
                        }
                    >
                        <Step data-testid="del-application-step1">
                            <StepLabel>
                                {intl.formatMessage(messages.stepOne)}
                            </StepLabel>
                        </Step>
                        <Step data-testid="del-application-step2">
                            <StepLabel>
                                {intl.formatMessage(messages.stepTwo)}
                            </StepLabel>
                        </Step>
                        <Step data-testid="del-application-step3">
                            <StepLabel>
                                {intl.formatMessage(messages.stepThree)}
                            </StepLabel>
                        </Step>
                    </Stepper>
                </ControlTop>
                {activeStepId === 0 && <DelApplyFormOrgInfo orgInfo={orgInfo} setOrgInfo={setOrgInfo} setFormIndex={setActiveStepId} user={user} setShowCancelDialog={setShowCancelDialog} />}
                {activeStepId === 1 && <DelApplyFormUsage usageInfo={usageInfo} setUsageInfo={setUsageInfo} setFormIndex={setActiveStepId} user={user} />}
                {activeStepId === 2 && <DelApplyFormConfirmation orgInfo={orgInfo} usageInfo={usageInfo} setFormIndex={setActiveStepId} user={user} setApplicationSubmitted={setApplicationSubmitted} />}
                <DelApplyIneligibleDialog open={!eligible}/>
                <DelCancelApplicationDialog open={showCancelDialog} setShowCancelDialog={setShowCancelDialog} />
            </div>
        }
    </>;
}

export default DelApply;