const TEAM_PERMISSION_GROUPS = {
    TeamOwner: "teamOwner",
    TeamViewer: "teamViewer",
};

const API_PROJECT_PERMISSION_GROUPS = {
    ApiProjectEditor: "apiProjectEditor",
    ApiProjectViewer: "apiProjectViewer",
};

const RESOURCE_TYPES = { ApiProject: "apiProject" };

exports.TEAM_PERMISSION_GROUPS = TEAM_PERMISSION_GROUPS;
exports.API_PROJECT_PERMISSION_GROUPS = API_PROJECT_PERMISSION_GROUPS;
exports.RESOURCE_TYPES = RESOURCE_TYPES;
