import useDelTerms from "../../../hooks/useDelTerms";
import {createUseStyles} from "react-jss";
import {defineMessages, FormattedMessage} from "react-intl";
import {logInLink, signUpLink} from "../../../util/routes";
import Button from "@mui/material/Button";
import {LinkButton} from "omse-components";
import {Typography} from "@mui/material";
import {PREMIUM_PLAN} from "../../../../shared/plans";

const messages = defineMessages({
    cancelButton: {
        id: 'DelTerms.cancelButton',
        defaultMessage: 'Cancel',
        description: 'Cancel button'
    },
    logInOptions: {
        id: 'DelTerms.logInOptions',
        defaultMessage: 'To get started <logIn>log in</logIn> or <signUp>sign up for a Premium Plan</signUp>',
        description: 'Log in button'
    },
    startApplicationButton: {
        id: 'DelTerms.startApplicationButton',
        defaultMessage: 'Start my application',
        description: 'Start application button'
    },
});

const useStyles = createUseStyles(theme => ({
    actionsContainer: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(2),
        paddingTop: theme.spacing(2)
    },
    linkButton: {
        minWidth: "0px",
        verticalAlign: "baseline"
    }
}));

export function handleLogIn() {
    window.location = logInLink + '?redirect_url=/downloads/del/apply';
}

function DelTermsActions() {
    const classes = useStyles();
    const { history, isLoggedIn } = useDelTerms();

    function handleCancel() {
        history.push('/');
    }

    function handleSignUp() {
        window.location = signUpLink(PREMIUM_PLAN) + '&redirect_url=/downloads/del/apply';
    }

    function handleStartApplication() {
        history.push('/downloads/del/apply');
    }

    const cancelButton =
        <Button color="primary" variant="outlined" onClick={handleCancel}>
            <FormattedMessage {...messages.cancelButton} />
        </Button>;

    const logInButton =
        <Typography variant="body1" color="textPrimary">
            <FormattedMessage {...messages.logInOptions} values={{
                logIn: message => (
                    <LinkButton key='login'
                                onClick={handleLogIn}
                                size='normal'
                                variant='text'
                                id="logInButton"
                                className={classes.linkButton}>
                        {message}
                    </LinkButton>
                ),
                signUp: message => (
                    <LinkButton key='signUp'
                                onClick={handleSignUp}
                                size='normal'
                                variant='text'
                                id="signUpButton"
                                className={classes.linkButton}>
                        {message}
                    </LinkButton>
                ),
            }} />
        </Typography>;

    const startApplicationButton =
        <Button color="primary" variant="contained" onClick={handleStartApplication}>
            <FormattedMessage {...messages.startApplicationButton} />
        </Button>;

    return (
        <div className={classes.actionsContainer}>
            {cancelButton}
            {isLoggedIn
                ? startApplicationButton
                : logInButton}
        </div>
    )
}

export default DelTermsActions;