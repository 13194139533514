import { defineMessages } from "react-intl";

const memberInfoMessages = defineMessages({
    aMember: {
        id: "memberInfoMessages.aMember",
        defaultMessage: "a Member",
        description: "a Member",
    },
    anOwner: {
        id: "memberInfoMessages.anOwner",
        defaultMessage: "an Owner",
        description: "an Owner",
    },
});

export default memberInfoMessages;
