export const apiProjectSortOptions = [
    {
        id: "apiProjectDateUpdatedDesc",
        label: "Date updated - newest to oldest",
        value: 0,
    },
    {
        id: "apiProjectDateUpdatedAsc",
        label: "Date updated - oldest to newest",
        value: 1,
    },
    {
        id: "apiProjectNameAsc",
        label: "API Project name - A to Z",
        value: 2,
    },
    {
        id: "apiProjectNameDesc",
        label: "API Project name - Z to A",
        value: 3,
    },
];

export const apiProjectSortFunction = filterOrdering => (a, b) => {
    switch (filterOrdering) {
        case 1:
            return a.updatedAtMs - b.updatedAtMs;
        case 2:
            return (!a.name) || (a.name.toLowerCase() > b.name?.toLowerCase()) ? 1 : -1;
        case 3:
            return (!a.name) || (a.name.toLowerCase() < b.name?.toLowerCase()) ? 1 : -1;
        default:
            return b.updatedAtMs - a.updatedAtMs;
    }
};

export const apiProjectMap = apiProject => {
    apiProject.createdAtMs = apiProject.createdAt;
    apiProject.updatedAtMs = apiProject.lastModifiedAt;
    return apiProject;
}