import React, { useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Route, Switch, matchPath } from "react-router";
import { useLocation } from 'react-router-dom';
import { matchRoute, orgSelectRoutes, routes } from '../util/routes';
import Menu from './appContent/Menu';
import Landing from './Landing';
import Footer from "./Footer";
import Error404 from "./Error404";
import ResponsiveNavigation from './appContent/ResponsiveNavigation';
import { headerHeight } from 'omse-components';
import HoldingPage from './holding/HoldingPage';
import { holdingPageCheck } from "../../shared/holdingPageCheck";
import { getDefaultOrganisation } from '../util/organisation';
import { selectOrganisation } from '../modules/organisation/action';

const styles = createUseStyles(({
    root: {
        position: 'relative',
        flex: '1 1 auto',
        display: 'flex',
        minHeight: `calc(100vh - ${headerHeight}px)`,
    },
    content: {
        flex: '1 1 auto',
        overflowY: 'auto',
        '-webkit-overflow-scrolling': 'touch',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    }
}));

const AppContent = ({ headerRef }) => {
    const { loading: userLoading, result: userDetails } = useSelector(
        (state) => state.user.current
    );
    const configLoading = useSelector((state) => state.config.current.loading);
    const config = useSelector((state) => state.config.current.result);
    const orgId = useSelector((state) => state.organisation.current.id);

    const active = userDetails && userDetails.active;
    const loading = userLoading || configLoading;
    const loggedIn = !!userDetails;

    const dispatch = useDispatch();
    const contentRef = useRef();
    const accessibleContentSkip = useRef();
    const location = useLocation();
    const classes = styles();

    useEffect(() => {
        if (window.location.href.includes("#main-content")) {
            accessibleContentSkip.current.focus();
        }
    });

    useEffect(() => {
        if (contentRef.current) {
            const scrollPosition = location?.state?.scrollPosition;
            const desiredAppContentPosition = scrollPosition?.currentElement || 0;
            const desiredHTMLPosition = scrollPosition?.htmlElement || 0;
            contentRef.current.scrollTop = desiredAppContentPosition;
            document.children[0].scrollTop = desiredHTMLPosition;
        }
    }, [location.state, location.pathname]);

    // When we navigate to a section that does not allow org selection then revert to the default org 
    // for the user.
    useEffect(() => {
            if (matchRoute(location.pathname)) {
                if (!orgSelectRoutes.find((r) => matchRoute(location.pathname).path === r.path)) {
                    const defaultOrg = getDefaultOrganisation(userDetails);
                    if (orgId && orgId !== defaultOrg.value) {
                        dispatch(selectOrganisation(defaultOrg));
                    }
                }
            }
        },
        [location.pathname, userDetails, dispatch, orgId]
    );

    if (loading) {
        // Lock the user down to the dashboard, for now
        return (
            <div className={classes.root} id='main-content' ref={accessibleContentSkip} tabIndex='-1'>
                <main className={classes.content}>
                    <Landing />
                </main>
            </div>
        )
    }

    let validRoutes = routes;
    if (!loggedIn || !active) {
        // Lock the user down to the public pages
        validRoutes = validRoutes.filter(route => route.public);
    }
    let showMenu = false;
    if (location) {
        // Use the complete routes list to decide if we should show the menu. This way if the user is not
        // logged in then they still get the same page layout and menus that they would normally see.
        const match = routes.find(r => {
            // match the path to valid routes, allow partial matches
            const match = matchPath(location.pathname, {
                path: r.path,
                exact: true
            });
            return match;
        });
        showMenu = match && !match.hideMenu;
    }



    if (holdingPageCheck(userDetails, config?.holdingPageUserPlans)) {
        return (
            <div className={classes.root} id='main-content' ref={accessibleContentSkip} tabIndex='-1'>
                <ResponsiveNavigation headerRef={headerRef} />
                <main className={classes.content} ref={contentRef}>
                    <HoldingPage />
                    <Footer />
                </main>
            </div>
        )
    }

    return (
        <div className={classes.root} id='main-content' ref={accessibleContentSkip} tabIndex='-1'>
            <ResponsiveNavigation headerRef={headerRef} />
            {showMenu &&
                <Menu headerRef={headerRef} />
            }
            <main className={classes.content} ref={contentRef}>
                <Switch>
                    {
                        validRoutes.map(route => <Route key={route.path}
                            exact path={route.path}
                            component={route.component} />)
                    }
                    <Route path='/' component={Error404} />
                </Switch>
                <Footer />
            </main>
        </div>
    )
}

AppContent.propTypes = {
    classes: PropTypes.object,
    loading: PropTypes.bool,
    loggedIn: PropTypes.bool,
    active: PropTypes.bool,
    userDetails: PropTypes.object
};

export default AppContent;
