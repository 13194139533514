import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import { contentPadding } from "omse-components";
import AddAPIProject from "../addResource/AddAPIProject";
import { PRODUCT_TYPES } from "../../../../shared/constants";
import resourceEmptyStateMessages from "./ResourceEmptyState.msg";

const Container = styled(Box)(
    ({ theme }) => `
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 0.5em;
    align-items: center;
    padding-top: ${contentPadding.top}px;
    
    ${theme.breakpoints.down("sm")} {
        padding-top: ${contentPadding.top / 2}px;
    }

    & > button {
        margin-top: 24px;
    }
`
);

const ResourceEmptyState = ({ resourceType, canAddResource }) => {
    const intl = useIntl();
    const testId = `${resourceType}EmptyState${canAddResource ? "RW" : "RO"}`;

    return (
        <Container data-testid={testId}>
            <Typography variant="h2">
                {intl.formatMessage(resourceEmptyStateMessages.heading)}
            </Typography>
            {canAddResource && resourceType === PRODUCT_TYPES.API_PROJECT && (
                <>
                    <Typography variant="body1">
                        {intl.formatMessage(resourceEmptyStateMessages.summaryApiProjects)}
                    </Typography>
                    <AddAPIProject />
                </>
            )}
        </Container>
    );
};

ResourceEmptyState.propTypes = {
    resourceType: PropTypes.string.isRequired,
    canAddResource: PropTypes.bool,
};

export default ResourceEmptyState;
