import { defineMessages } from "react-intl";

const addAPIProjectMessages = defineMessages({
    openButton: {
        id: "addAPIProjectMessages.openButton",
        defaultMessage: "Add an API Project",
        description: "Add API project button label",
    },
    dialogHeading: {
        id: "addAPIProjectMessages.dialogHeading",
        defaultMessage: "Add an API Project",
        description: "Add API projects Dialog > Heading",
    },
    closeButtonAriaLabel: {
        id: "addAPIProjectMessages.closeButtonAriaLabel",
        defaultMessage: "Close",
        description: "Add API project Dialog > Close Button (Aria Label)",
    },
    saveButton: {
        id: "addAPIProjectMessages.saveButton",
        defaultMessage: "Save",
        description: "Add API project Dialog > Save Button",
    },
    cancelButton: {
        id: "addAPIProjectMessages.cancelButton",
        defaultMessage: "Cancel",
        description: "Add API project Dialog > Cancel Button",
    },
    serverError: {
        id: "addAPIProjectMessages.serverError",
        description: "Add API project Dialog > Generic Server Error",
        defaultMessage: "We ran into a problem our end whilst updating this Team Space. Please try again and if the problem persists, please {link}",
    },
    successfullyAdded: {
        id: "addAPIProjectMessages.successfullyAdded",
        description: "Add API project Dialog > Success",
        defaultMessage: "Added an API Project",
    },
});

export default addAPIProjectMessages;
