import styled from "@emotion/styled";
import { useState, useEffect, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useSelector } from "react-redux";
import newTeamSpaceMessages from "./NewTeamSpace.msg";
import {
    AddButton,
    ExternalLink,
    Notification,
    theme,
    useActionIdSelector,
    osColour,
} from "omse-components";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Button, Stepper, Step, StepLabel, Typography } from "@mui/material";
import {
    AddCircle as AddCircleIcon,
    KeyboardArrowLeft as KeyboardArrowLeftIcon,
    KeyboardArrowRight as KeyboardArrowRightIcon,
} from "@mui/icons-material";
import TeamSpaceAccess from "./newTeamSpace/TeamSpaceAccess";
import TeamSpaceDetails from "./newTeamSpace/TeamSpaceDetails";
import { createTeamSpace, getOrgUsers } from "../../modules/teamSpaces/actions";
import routePaths, { useRedirect } from "../../util/routes";
import { isContractor } from "./util/teamSpaceUser";
import createTeamErrorMessages from "./newTeamSpace/createTeamErrors.msg";
import { getTeamSpacesErrorMessage } from "./util/getTeamSpacesErrorMessage";
import TeamSpaceDialog from "./shared/Dialog";
import { dialogVariants } from "./shared/constants";

export const StepConnector = styled("span")(
    ({ isComplete, theme }) => `
    margin: 0 0.6em;
    min-width: ${theme.spacing(5)};
    border-bottom: 2px solid ${isComplete ? osColour.primary.berry : osColour.neutral.rock};
`
);

export default function NewTeamSpace(props) {
    const userState = useSelector((state) => state.user.current);

    if (userState.loading || isContractor(userState.result)) {
        return null;
    }

    return <NewTeamSpaceDialog {...props} />;
}

const defaultNewTeamSpaceParameters = {
    name: "",
    description: "",
    locked: false,
    // ...
};

function NewTeamSpaceDialog({ buttonMessage }) {
    const intl = useIntl();
    const redirect = useRedirect();
    const isMobile = useMediaQuery(
        `(max-height: 800px), (max-width: ${theme.breakpoints.values.sm}px)`
    );
    const [showDialog, setShowDialog] = useState(false);
    const [activeStepId, setActiveStepId] = useState(0);
    const [disableNextAction, setDisableNextAction] = useState(true);
    const [owners, setOwners] = useState([]);
    const [members, setMembers] = useState([]);

    const [newTeamSpaceParameters, setNewTeamSpaceParameters] = useState(
        defaultNewTeamSpaceParameters
    );
    const [createTeamSpaceNameResult, dispatch] = useActionIdSelector("teamSpaces.createTeamSpace");
    const loadingOrgUsers = useSelector((state) => state.teamSpaces.getOrgUsers.loading);
    const orgUsers = useSelector((state) => state.teamSpaces.getOrgUsers.result);

    const selectedUserIds = useMemo(() => {
        const ids = [
            ...owners.map((owner) => owner.datahubDeveloperId),
            ...members.map((member) => member.datahubDeveloperId),
        ];
        return ids;
    }, [owners, members]);

    useEffect(() => {
        if (createTeamSpaceNameResult.result) {
            if (createTeamSpaceNameResult.result.content?.id) {
                redirect.push(
                    routePaths.teamSpace.replace(
                        ":teamSpaceId",
                        createTeamSpaceNameResult.result.content.id
                    )
                );
            }
        }
    }, [createTeamSpaceNameResult, redirect]);

    useEffect(() => {
        if (showDialog && !orgUsers) {
            dispatch(getOrgUsers());
        }
    }, [showDialog, orgUsers, dispatch]);

    const handleCloseEvent = () => {
        setShowDialog(false);

        // Remove user information from the state
        // AFTER the dialog has fully closed.
        setTimeout(() => {
            setActiveStepId(0);
            setNewTeamSpaceParameters(defaultNewTeamSpaceParameters);
            setDisableNextAction(true);
            setOwners([]);
            setMembers([]);
        }, 250);
    };

    function handleCreateTeamSpace() {
        dispatch(
            createTeamSpace({
                ...newTeamSpaceParameters,
                name: newTeamSpaceParameters.name.trimEnd(),
                ownerDatahubDeveloperIds: owners.map((owner) => owner.datahubDeveloperId),
                members: members,
            })
        );
    }

    return (
        <>
            <Button
                variant="contained"
                data-testid={`dialog-${dialogVariants.newTeamSpace}-open`}
                startIcon={<AddCircleIcon />}
                onClick={() => setShowDialog(true)}
            >
                {intl.formatMessage(buttonMessage || newTeamSpaceMessages.openButton)}
            </Button>

            {showDialog && (
                <TeamSpaceDialog
                    variant={dialogVariants.newTeamSpace}
                    onClose={() => handleCloseEvent()}
                    contentHead={
                        <Stepper
                            activeStep={activeStepId}
                            connector={
                                !isMobile && (
                                    <StepConnector
                                        aria-hidden={true}
                                        isComplete={activeStepId >= 1}
                                    />
                                )
                            }
                        >
                            <Step data-testid="step1Indicator">
                                <StepLabel>
                                    {intl.formatMessage(newTeamSpaceMessages.stepLabelDetails)}
                                </StepLabel>
                            </Step>
                            <Step data-testid="step2Indicator">
                                <StepLabel>
                                    {intl.formatMessage(newTeamSpaceMessages.stepLabelAccess)}
                                </StepLabel>
                            </Step>
                        </Stepper>
                    }
                    contentMain={
                        <>
                            {createTeamSpaceNameResult.error && (
                                <Notification variant="error" appearance="inline">
                                    <Typography variant="body1">
                                        <FormattedMessage
                                            {...getTeamSpacesErrorMessage(
                                                createTeamSpaceNameResult,
                                                createTeamErrorMessages.genericServerError
                                            )}
                                            values={{ link: <ExternalLink type="support" /> }}
                                        />
                                    </Typography>
                                </Notification>
                            )}
                            {!activeStepId && (
                                <TeamSpaceDetails
                                    newTeamSpaceParameters={newTeamSpaceParameters}
                                    setNewTeamSpaceParameters={setNewTeamSpaceParameters}
                                    setDisableNextAction={setDisableNextAction}
                                />
                            )}
                            {activeStepId >= 1 && (
                                <TeamSpaceAccess
                                    newTeamSpaceParameters={newTeamSpaceParameters}
                                    setNewTeamSpaceParameters={setNewTeamSpaceParameters}
                                    setDisableNextAction={setDisableNextAction}
                                    owners={owners}
                                    setOwners={setOwners}
                                    members={members}
                                    setMembers={setMembers}
                                    selectedUserIds={selectedUserIds}
                                />
                            )}
                        </>
                    }
                    contentFooter={
                        <>
                            {!activeStepId && (
                                <Button
                                    variant="outlined"
                                    data-testid={`dialog-${dialogVariants.newTeamSpace}-cancel`}
                                    onClick={() => handleCloseEvent()}
                                >
                                    {intl.formatMessage(newTeamSpaceMessages.cancelButton)}
                                </Button>
                            )}
                            {activeStepId >= 1 && (
                                <Button
                                    variant="outlined"
                                    onClick={() => setActiveStepId(0)}
                                    startIcon={<KeyboardArrowLeftIcon />}
                                >
                                    {intl.formatMessage(newTeamSpaceMessages.backButton)}
                                </Button>
                            )}
                            {!activeStepId && (
                                <Button
                                    variant="contained"
                                    disabled={disableNextAction}
                                    onClick={() => setActiveStepId(1)}
                                    endIcon={<KeyboardArrowRightIcon />}
                                >
                                    {intl.formatMessage(newTeamSpaceMessages.nextButton)}
                                </Button>
                            )}
                            {activeStepId >= 1 && (
                                <AddButton
                                    variant="contained"
                                    label={newTeamSpaceMessages.doneButton}
                                    showIcon={false}
                                    working={createTeamSpaceNameResult.working}
                                    disabled={
                                        disableNextAction ||
                                        loadingOrgUsers ||
                                        createTeamSpaceNameResult.working
                                    }
                                    action={handleCreateTeamSpace}
                                >
                                    {intl.formatMessage(newTeamSpaceMessages.doneButton)}
                                </AddButton>
                            )}
                        </>
                    }
                />
            )}
        </>
    );
}
