import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { isContractorReadOnly } from "./../util/teamSpaceUser";
import AddResourceButton from "../shared/AddResourceButton";
import addAPIProjectMessages from "./AddAPIProject.msg";
import TeamResources from "./TeamResources";
import {
    createTeamResource,
    getTeamSpace,
    getTeamSpaces,
} from "../../../modules/teamSpaces/actions";
import { getProjects } from "../../../modules/projects";
import useActionIdSelector from "../../../hooks/useActionIdSelector";
import TeamSpaceDialog from "../shared/Dialog";
import { dialogVariants } from "../shared/constants";
import { Typography, Button } from "@mui/material";
import { useIntl } from "react-intl";
import { AddButton } from "omse-components";

const TEAM_RESOURCES_TYPES = {
    ApiProject: "apiProject",
};

export default function AddAPIProject(props) {
    const userState = useSelector((state) => state.user.current);

    if (userState.loading || isContractorReadOnly(userState.result)) {
        return null;
    }

    return <AddAPIProjectDialog {...props} />;
}

function AddAPIProjectDialog() {
    const intl = useIntl();
    const params = useParams();
    const [showDialog, setShowDialog] = useState(false);
    const [disableNextAction, setDisableNextAction] = useState(true);
    const [selectedApiProjects, setSelectedApiProjects] = useState(null);
    const [createTeamResourceResult, dispatch] = useActionIdSelector(
        "teamSpaces.createTeamResource"
    );
    const apiProjectsLoading = useSelector((state) => state.projects.current.loading);

    useEffect(() => {
        if (selectedApiProjects) {
            setDisableNextAction(false);
        } else {
            setDisableNextAction(true);
        }
    }, [selectedApiProjects]);

    useEffect(() => {
        if (showDialog) {
            dispatch(getProjects());
        }
    }, [showDialog, dispatch]);

    useEffect(() => {
        if (createTeamResourceResult.result) {
            if (createTeamResourceResult.result?.teamResource.content.id) {
                setShowDialog(false);
            }
        }
    }, [createTeamResourceResult]);

    const handleCloseEvent = () => {
        setShowDialog(false);
        setSelectedApiProjects(null);
        setTimeout(() => {
            setDisableNextAction(true);
        }, 250);
    };

    const handleSetTeamResources = () => {
        dispatch(
            createTeamResource({
                teamId: params.teamSpaceId,
                resourceId: selectedApiProjects.projectId,
                resourceType: TEAM_RESOURCES_TYPES.ApiProject,
            })
        );
        setSelectedApiProjects(null);
    };
    return (
        <>
            <AddResourceButton
                actionText={"Add an"}
                productType={`API Project`}
                onClick={() => setShowDialog(true)}
            />
            {showDialog && (
                <TeamSpaceDialog
                    onClose={handleCloseEvent}
                    working={apiProjectsLoading || createTeamResourceResult?.working}
                    variant={dialogVariants.addApiProject}
                    contentHead={
                        <Typography variant="h2">
                            {intl.formatMessage(addAPIProjectMessages.dialogHeading)}
                        </Typography>
                    }
                    contentMain={
                        <TeamResources
                            setSelectedApiProjects={setSelectedApiProjects}
                            createTeamResourceError={createTeamResourceResult.error}
                            working={false}
                        />
                    }
                    contentFooter={
                        <>
                            <Button
                                variant="outlined"
                                onClick={() => handleCloseEvent()}
                                data-testid={`dialog-${dialogVariants.addApiProject}-cancel`}
                            >
                                {intl.formatMessage(addAPIProjectMessages.cancelButton)}
                            </Button>
                            <AddButton
                                variant={"contained"}
                                label={addAPIProjectMessages.saveButton}
                                showIcon={false}
                                working={createTeamResourceResult?.working}
                                disabled={
                                    disableNextAction ||
                                    apiProjectsLoading ||
                                    createTeamResourceResult?.working
                                }
                                action={handleSetTeamResources}
                            >
                                {intl.formatMessage(addAPIProjectMessages.saveButton)}
                            </AddButton>
                        </>
                    }
                ></TeamSpaceDialog>
            )}
        </>
    );
}
