import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { CommonDialog, CommonDialogActions, StyledCheckbox } from "omse-components";
import { defineMessages, FormattedMessage } from "react-intl";
import RoleSelection from "./RoleSelection";
import { FormControlLabel, Typography } from "@mui/material";
import { setRole } from "../../../modules/organisation/action";
import featureCheck from "../../../util/featureCheck";
import { TEAM_SPACES } from "../../../../../src/shared/features";

const changeRoleDialogMessages = defineMessages({
    title: {
        id: "changeRoleDialogMessages.title",
        defaultMessage: "Change role",
        description: "Title for the change role dialog",
    },
    intro: {
        id: "changeRoleDialogMessages.intro",
        defaultMessage: "Please choose a new role for {first} {last}:",
        description: "Intro text for the change role dialog",
    },
    ok: {
        id: "changeRoleDialogMessages.ok",
        defaultMessage: "Change role",
        description: "Label for the confirm button",
    },
    cancel: {
        id: "changeRoleDialogMessages.cancel",
        defaultMessage: "Cancel",
        description: "Label for the cancel button",
    },
    sendEmail: {
        id: "changeRoleDialogMessages.sendEmail",
        defaultMessage: "Send {first} {last} an email confirming that their role has been changed.",
        description: "Label for the email checkbox",
    },
    changeRoleNotice: {
        id: "changeRoleDialogMessages.changeRoleNotice",
        defaultMessage: "Team Space permissions may be updated as a result of the role change.",
        description: "Change role notice"
    }
});

let actionIdCounter = 0;

export default function ChangeRoleDialog(p) {
    const { user, onClose, testActionId } = p;

    const [sendEmail, setSendEmail] = useState(true);
    const [selectedRole, setSelectedRole] = useState(user.role);
    const [actionId] = useState(testActionId || actionIdCounter++);
    const { working, result } = useSelector((state) => {
        if (state.organisation.setRole.actionId === actionId) {
            return state.organisation.setRole;
        }
        return {};
    });
    const config = useSelector((state) => state.config.current.result);
    const dispatch = useDispatch();

    // Auto-close the dialog once we have finished setting the role
    useEffect(() => {
        if (result !== undefined) {
            onClose();
        }
    }, [result, onClose]);

    const actions = (
        <CommonDialogActions
            confirmLabel={changeRoleDialogMessages.ok}
            confirmAllowed={selectedRole !== user.role}
            cancelLabel={changeRoleDialogMessages.cancel}
            onClose={onClose}
            onConfirm={() => {
                const action = setRole(user.id, selectedRole, sendEmail);
                action.actionId = actionId;
                dispatch(action);
            }}
            working={working}
        />
    );

    return (
        <CommonDialog title={changeRoleDialogMessages.title} actions={actions} onClose={onClose}>
            {featureCheck(TEAM_SPACES, config) &&
                <Typography paragraph={true}>
                    <FormattedMessage
                        {...changeRoleDialogMessages.changeRoleNotice}
                    />
                </Typography>
            }
            <FormattedMessage
                {...changeRoleDialogMessages.intro}
                values={{
                    first: user.firstName,
                    last: user.lastName,
                }}
            />
            <RoleSelection
                setRole={setSelectedRole}
                currentUserRole={user.role}
                currentSelectedRole={selectedRole}
            />
            <FormControlLabel
                control={
                    <StyledCheckbox checked={sendEmail} onClick={() => setSendEmail(!sendEmail)} />
                }
                label={
                    <div style={{ marginLeft: "1em" }}>
                        <FormattedMessage
                            {...changeRoleDialogMessages.sendEmail}
                            values={{ first: user.firstName, last: user.lastName }}
                        />
                    </div>
                }
            />
        </CommonDialog>
    );
}

ChangeRoleDialog.propTypes = {
    user: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
};
