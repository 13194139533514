import { defineMessages } from "react-intl";

const teamResourcesMessages = defineMessages({
    searchInputLabel: {
        id: "teamResourcesMessages.searchInputLabel",
        defaultMessage: "Search API Projects",
        description: "Add API Projects Dialog > Search bar > Placeholder",
    },
    searchPlaceholder: {
        id: "teamResourcesMessages.searchPlaceholder",
        defaultMessage: "Find an API Project by name",
        description: "Add API Projects Dialog > Search bar > Placeholder",
    },
    dialogToolTipLabel: {
        id: "teamResourcesMessages.dialogToolTipLabel",
        defaultMessage: "Can't find the API Project?",
        description: "Add API Projects Dialog > Tooltip > Label",
    },
    dialogToolTipContent: {
        id: "teamResourcesMessages.dialogToolTipContent",
        defaultMessage: "You can only see API Projects that are not in a Team Space or are in a Team Space you have access to.",
        description: "Add API Projects Dialog > Tooltip > Content",
    },
    apiProject: {
        id: "teamResourcesMessages.apiProject",
        defaultMessage: "API Project",
        description: "API Project > Label",
    },
    teamSpace: {
        id: "teamResourcesMessages.teamSpace",
        defaultMessage: "Team Space",
        description: "Team Space > Label",
    },
    notAssignedTeamSpace: {
        id: "teamResourcesMessages.notAssignedTeamSpace",
        defaultMessage: "Not assigned to a Team Space.",
        description: "Team Resource Table > No Team Space Label",
    },
    noApiProjects: {
        id: "teamResourcesMessages.noApiProjects",
        defaultMessage: "No API Projects found. {link}",
        description: "Team Resource Table > No API Projects",
    },
    noApiProjectsLink: {
        id: "teamResourcesMessages.noApiProjectsLink",
        defaultMessage: "Create a new API Project",
        description: "Team Resource Table > Create a new API Project",
    },
    genericServerError: {
        id: "teamResourcesMessages.genericServerError",
        description: "User interaction error > Generic Server Error",
        defaultMessage: "We ran into a problem our end whilst adding the API Project. Please try again and if the problem persists, please {link}",
    },
});

export default teamResourcesMessages;
