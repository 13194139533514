import { css } from "@emotion/css";

// Tooltip
export const clickAwayTooltipClass = css`
    float: none !important;
    margin-left: 6px;
    display: inline-flex;
`;

export const clickAwayTooltipLinkClass = css`
    & > span:nth-child(1) {
        display: flex;
    }
`;