import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import LineGraph from "../../components/LineGraph";
import {defineMessages, useIntl, FormattedMessage} from 'react-intl';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import {createUseStyles} from 'react-jss';
import {osColour} from 'omse-components';
import {useSelector} from "react-redux";
import _forOwn from 'lodash/forOwn';
import {USER_OS_INTERNAL_PLAN, USER_PREMIUM_DATA_PLAN, USER_PSGA_PLAN} from '../../../shared/plans';

import classNames from 'classnames';
import stripesSvg from '../../components/icons/stripes.svg';
import {DEV_MODE} from '../../../shared/project-modes';
import useStripyFill from '../../hooks/useStripyFill';
import {isOpenDataPersonal} from '../../util/organisation';

const messages = defineMessages({
    openData: {
        id: 'HistoryGraph.openData',
        defaultMessage: 'OS OpenData',
        description: 'OS OpenData label, used for the transaction graph'
    },
    psga: {
        id: 'HistoryGraph.psga',
        defaultMessage: 'Public Sector',
        description: 'Public sector data label'
    },
    premiumFree: {
        id: 'HistoryGraph.premiumFree',
        defaultMessage: 'Free Premium',
        description: 'Premium free label'
    },
    premiumChargeable: {
        id: 'HistoryGraph.premiumChargeable',
        defaultMessage: 'Costed Premium',
        description: 'Premium paid label'
    },
    devPremium: {
        id: 'HistoryGraph.devPremium',
        defaultMessage: 'Dev Premium',
        description: 'Dev Premium label'
    },
    psgaData: {
        id: 'HistoryGraph.psgaData',
        defaultMessage: 'Public Sector data',
        description: 'Public sector data label'
    },
    premiumFreeData: {
        id: 'HistoryGraph.premiumFreeData',
        defaultMessage: 'Free Premium data',
        description: 'Premium free data label'
    },
    premiumChargeableData: {
        id: 'HistoryGraph.premiumChargeableData',
        defaultMessage: 'Costed Premium data',
        description: 'Premium paid data label'
    },
    devPremiumData: {
        id: 'HistoryGraph.devPremiumData',
        defaultMessage: 'Dev Premium data',
        description: 'Dev Premium data label'
    },
    internalData: {
        id: 'HistoryGraph.internalData',
        defaultMessage: 'OS Internal',
        description: 'OS Internal data label'
    },
    total: {
        id: 'HistoryGraph.total',
        defaultMessage: '{total, number}',
        description: 'Total for the graph'
    }
});

const alphaValue = 0.2;

const openColour = osColour.primary.berry;
const openFill = alpha(openColour, alphaValue);

const premiumColour = osColour.primary.foxglove;
const premiumFill = alpha(premiumColour, alphaValue);

const premiumChargeableLineColour = '#D40058';

const premiumFreeSecondaryColour = '#757575';
const premiumFreeSecondaryFill = alpha(premiumFreeSecondaryColour, alphaValue);

const psgaColour = osColour.primary.foxglove;
const psgaFill = alpha(psgaColour, alphaValue);

const internalColour = osColour.primary.foxglove;
const internalFill = alpha(premiumColour, alphaValue);

const styles = createUseStyles(theme => ({
    contentGraph: {
        marginTop: theme.spacing(4),
        paddingBottom: theme.spacing(2)
    },
    legend: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: theme.spacing(0.5)
    },
    key: {
        height: theme.spacing(2),
        width: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(1)
    },
    chartLabel: {
        display: 'flex',
        alignItems: 'center'
    },
    openLegend: {
        backgroundColor: openColour
    },
    premiumTotalLegend: {
        backgroundColor: premiumColour
    },
    premiumFreeLegend: {
        backgroundColor: premiumColour
    },
    premiumChargeableLegend: {
        backgroundImage: `url(${stripesSvg})`
    },
    psgaLegend: {
        backgroundColor: psgaColour
    },
    premiumFreeSecondaryLegend: {
        backgroundColor: premiumFreeSecondaryColour
    },
    internalLegend: {
        backgroundColor: premiumColour
    },
    total: {
        marginBottom: theme.spacing(1.5),
        color: osColour.neutral.stone
    },
    graphTitle: {
        color: osColour.neutral.stone
    },
    graphHeader: {
        display: 'flex'
    },
    secondaryInfo: {
        flexGrow: 1,
        paddingTop: theme.spacing(0.5),
        marginRight: theme.spacing(2)
    }
}));

export default function HistoryGraph(props) {
    const {interval, graphMode, historyStats, graphTitle, graphTooltip} = props;
    const intl = useIntl();
    const classes = styles();
    const userDetails = useSelector(state => state.user.current.result);
    const org = useSelector(state => state.organisation.current);
    const stripyFill = useStripyFill(osColour.primary.foxglove, alphaValue);

    function formatDate(timestamp) {
        if (interval !== 'year') {
            return intl.formatDate(timestamp, {weekday: 'short', day: 'numeric', month: 'short'});
        }
        return intl.formatDate(timestamp, {month: 'short', year: 'numeric'});
    }

    function hideDatasetIfNoValues(datasets, transactionType) {
        if(datasets[transactionType]) {
            const list = datasets[transactionType].data;
            let allZeros = true;
            for (let i = 0; i < list.length; i++) {
                if (list[i] !== 0) {
                    allZeros = false;
                    break;
                }
            }
            datasets[transactionType].hidden = allZeros;
        }
    }


    const labels = [];
    const chartLabels = [];
    let total = 0;
    let graphData;

    if (historyStats) {
        const datasets = getAllDataSets();

        if (graphMode === DEV_MODE) {
            delete datasets.premiumFree;
            delete datasets.premiumChargeable;
        } else {
            delete datasets.premiumTotal;
        }

        const allValueFunctions = getAllValueFunctions();
        historyStats.forEach(entry => {
            _forOwn(datasets, (dataset, transactionType) => {
                const valueFunction = allValueFunctions[transactionType];
                const value = valueFunction(entry);
                dataset.data.push(value);
                total += value;
            });
            labels.push(formatDate(entry.timestamp));
        });

        // Show all labels that are needed by the current plan. This is because for the current plan it is
        // useful information seeing that there are zero entries. Also show labels for any non-zero historic data
        // within the timeframe. This is because they used that kind of data in the past so we should show them it.
        if (userDetails.plan !== USER_PREMIUM_DATA_PLAN || isOpenDataPersonal(userDetails, org)) {
            hideDatasetIfNoValues(datasets, 'premiumFree');
            hideDatasetIfNoValues(datasets, 'premiumChargeable');
            hideDatasetIfNoValues(datasets, 'premiumTotal');
        }
        if (userDetails.plan !== USER_PSGA_PLAN || isOpenDataPersonal(userDetails, org)) {
            hideDatasetIfNoValues(datasets, 'psga');
        }
        if (userDetails.plan !== USER_OS_INTERNAL_PLAN || isOpenDataPersonal(userDetails, org)) {
            hideDatasetIfNoValues(datasets, 'internal');
        }

        graphData = {
            labels,
            datasets: []
        };

        const allChartLabels = getAllChartLabels();

        // psga and premiumFree both want to use same colour. This is okay as normally only one of these is shown.
        // If both need to be displayed at the same time then change the colour for premiumFree.
        if (!datasets.psga.hidden && !datasets.premiumFree.hidden) {
            datasets.premiumFree.backgroundColor = datasets.premiumFree.pointBorderColor = premiumFreeSecondaryFill;
            datasets.premiumFree.borderColor = datasets.premiumFree.pointBackgroundColor = premiumFreeSecondaryColour;
            datasets.premiumFree.keyClass = allChartLabels.premiumFree.labelClass = classes.premiumFreeSecondaryLegend;
        }

        _forOwn(datasets, (dataset, transactionType) => {
            // We need the hidden datasets as part of the graph data so they are there ready for the situation
            // where we switch to a different time frame (e.g. year) and the previously hidden dataset now has data.
            // Without passing in the hidden datasets the tooltips end up without figures when you switch time
            // frame.
            graphData.datasets.push(dataset);
            if (!dataset.hidden) {
                // We only need the chart labels for the unhidden datasets as these will update when we switch timeframe.
                chartLabels.push(allChartLabels[transactionType]);
            }
        });
    }

    return <div className={classes.contentGraph}>
        <div className={classes.graphHeader}>
            <div className={classes.primaryInfo}>
                <Typography variant='body2' className={classes.graphTitle}>
                    <FormattedMessage {...graphTitle} />
                </Typography>
                <Typography variant='h2' component='div' className={classes.total}>
                    <FormattedMessage {...messages.total} values={{total}}/>
                </Typography>
            </div>
            {graphTooltip &&
            <div className={classes.secondaryInfo}>
                {graphTooltip}
            </div>}
        </div>
        {graphData && <Fragment>
            <LineGraph data={graphData}/>
            <div className={classes.legend}>
                {chartLabels.map(chartLabel =>
                    <div key={chartLabel.label.id} className={classes.chartLabel}>
                        <div className={classNames(classes.key, chartLabel.labelClass)}/>
                        <Typography variant='body1'>
                            <FormattedMessage {...chartLabel.label} />
                        </Typography>
                    </div>)}
            </div>
        </Fragment>}
    </div>

    /**
     * Dataset labels:
     * 
     *   label: ...         // Unique identifier used by chartjs.
     *   displayLabel: ...  // Label shown in the tooltip.
     *   ... 
     */
    function getAllDataSets() {
        return {
            open: {
                label: 'open',
                displayLabel: intl.formatMessage(messages.openData),
                backgroundColor: openFill,
                borderColor: openColour,
                pointBackgroundColor: openColour,
                pointBorderColor: openColour,
                data: [],
                keyClass: classes.openLegend
            },
            psga: {
                label: 'psga',
                displayLabel: intl.formatMessage(messages.psga),
                backgroundColor: psgaFill,
                borderColor: psgaColour,
                pointBackgroundColor: psgaColour,
                pointBorderColor: psgaColour,
                data: [],
                keyClass: classes.psgaLegend
            },
            premiumFree: {
                label: "premiumFree",
                displayLabel: intl.formatMessage(messages.premiumFree),
                backgroundColor: premiumFill,
                borderColor: premiumColour,
                pointBackgroundColor: premiumColour,
                pointBorderColor: premiumColour,
                data: [],
                keyClass: classes.premiumFreeLegend
            },
            premiumChargeable: {
                label: 'premiumChargeable',
                displayLabel: intl.formatMessage(messages.premiumChargeable),
                backgroundColor: stripyFill,
                borderColor: premiumChargeableLineColour,
                borderDash: [5, 2],
                borderWidth: 4,
                pointBackgroundColor: premiumChargeableLineColour,
                pointBorderColor: premiumChargeableLineColour,
                pointRadius: 1.5,
                data: [],
                keyClass: classes.premiumChargeableLegend
            },
            premiumTotal: {
                label: 'premiumTotal',
                displayLabel: intl.formatMessage(messages.devPremium),
                backgroundColor: premiumFill,
                borderColor: premiumColour,
                pointBackgroundColor: premiumColour,
                pointBorderColor: premiumColour,
                data: [],
                keyClass: classes.premiumTotalLegend
            },
            internal: {
                label: 'internal',
                displayLabel: intl.formatMessage(messages.internalData),
                backgroundColor: internalFill,
                borderColor: internalColour,
                pointBackgroundColor: internalColour,
                pointBorderColor: internalColour,
                data: [],
                keyClass: classes.internalLegend
            }
        };
    }

    /**
     * Chart legend labels: May differ from dataset displayLabel by including a "data" suffix.
     */
    function getAllChartLabels() {
        return {
            open: {
                label: messages.openData,
                labelClass: classes.openLegend,
            },
            psga: {
                label: messages.psgaData,
                labelClass: classes.psgaLegend
            },
            premiumFree: {
                label: messages.premiumFreeData,
                labelClass: classes.premiumFreeLegend
            },
            premiumChargeable: {
                label: messages.premiumChargeableData,
                labelClass: classes.premiumChargeableLegend
            },
            premiumTotal: {
                label: messages.devPremiumData,
                labelClass: classes.premiumTotalLegend
            },
            internal: {
                label: messages.internalData,
                labelClass: classes.internalLegend
            }
        };
    }

    function getAllValueFunctions() {
        return {
            open: entry => entry.open,
            psga: entry => entry.psga,
            premiumFree: entry => entry.premium.free,
            premiumChargeable: entry => entry.premium.chargeable,
            premiumTotal: entry => entry.premium.total,
            internal: entry => entry.internal
        };
    }
}

HistoryGraph.propTypes = {
    historyStats: PropTypes.array,
    graphTitle: PropTypes.object.isRequired,
    graphTooltip: PropTypes.object,
    graphMode: PropTypes.string,
    interval: PropTypes.oneOf(['week', 'month', 'year'])
};

HistoryGraph.defaultProps = {
    graphMode: DEV_MODE
};

