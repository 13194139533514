const { defineMessages } = require("react-intl");

const moveResourceErrors = defineMessages({
    genericServerError: {
        id: "moveResourceErrors.genericServerError",
        description: "User interaction error > Generic Server Error",
        defaultMessage: "We ran into a problem our end moving the resource. Please try again and if the problem persists, please {link}",
    },
});

export default moveResourceErrors;
