export const PREFIX = 'action:teamSpaces:';
export const GET_TEAMSPACES_LOAD_ACTION = PREFIX + 'getTeamSpaces:load';
export const GET_TEAMSPACES_LOADED_ACTION = PREFIX + 'getTeamSpaces:loaded';
export const GET_TEAMSPACE_LOAD_ACTION = PREFIX + 'getTeamSpace:load';
export const GET_TEAMSPACE_LOADED_ACTION = PREFIX + 'getTeamSpace:loaded';
export const CREATE_TEAMSPACE_ACTION = PREFIX + 'createTeamSpace';
export const GET_ORG_USERS_LOAD_ACTION = PREFIX + 'getOrgUsers:load';
export const GET_ORG_USERS_LOADED_ACTION = PREFIX + 'getOrgUsers:loaded';
export const GET_ORG_USERS_CLEAR_ACTION = PREFIX + 'getOrgUsers:clear';
export const GET_TEAMSPACE_CLEAR_ACTION = PREFIX + 'getTeamSpace:clear';
export const GET_TEAMSPACES_CLEAR_ACTION = PREFIX + 'getTeamSpaces:clear';
export const SET_TEAM_SPACE_NAME = PREFIX + 'setTeamSpaceName';
export const SET_TEAM_SPACE_NAME_LOADED = PREFIX + 'setTeamSpaceName:loaded';
export const SET_TEAM_SPACE_DESCRIPTION = PREFIX + 'setTeamSpaceDescription';
export const SET_TEAM_SPACE_DESCRIPTION_LOADED = PREFIX + 'setTeamSpaceDescription:loaded';
export const SET_TEAM_SPACE_DETAILS_CLEAR = PREFIX + 'setTeamSpaceDetails:clear';
export const SET_TEAM_SPACE_ACCESS_ACTION = PREFIX + 'setTeamSpaceAccess';
export const SET_TEAM_SPACE_ACCESS_LOADED_ACTION = PREFIX + 'setTeamSpaceAccess:loaded';
export const SET_TEAM_SPACE_ACCESS_CLEAR_ACTION = PREFIX + 'setTeamSpaceAccess:clear';
export const CREATE_TEAM_RESOURCE_ACTION = PREFIX + 'createTeamResource';
export const CREATE_TEAM_RESOURCE_CLEAR_ACTION = PREFIX + 'createTeamResource:clear'
export const CREATE_TEAM_RESOURCE_LOADED_ACTION = PREFIX + 'createTeamResource:loaded'
export const UPDATE_TEAM_RESOURCE_ACTION = PREFIX + 'updateTeamResource';
export const UPDATE_TEAM_RESOURCE_CLEAR_ACTION = PREFIX + 'updateTeamResource:clear';
export const REMOVE_TEAM_RESOURCE_LOAD_ACTION = PREFIX + 'removeTeamResource';
export const REMOVE_TEAM_RESOURCE_LOADED_ACTION = PREFIX + 'removeTeamResource:loaded';
export const REMOVE_TEAM_RESOURCE_CLEAR_ACTION = PREFIX + 'removeTeamResource:clear';
export const DELETE_TEAM_SPACE_LOAD_ACTION = PREFIX + 'deleteTeamSpace';
export const DELETE_TEAM_SPACE_LOADED_ACTION = PREFIX + 'deleteTeamSpace:loaded';
export const DELETE_TEAM_SPACE_CLEAR_ACTION = PREFIX + 'deleteTeamSpace:clear';

export function createTeamSpace(teamSpace) {
    return {
        type: CREATE_TEAMSPACE_ACTION,
        name: teamSpace.name,
        description: teamSpace.description,
        ownerDatahubDeveloperIds: teamSpace.ownerDatahubDeveloperIds,
        members: teamSpace.members,
        locked: teamSpace.locked
    }
}

export function getTeamSpaces() {
    return {
        type: GET_TEAMSPACES_LOAD_ACTION
    }
}

export function getTeamSpace(teamSpaceId) {
    return {
        type: GET_TEAMSPACE_LOAD_ACTION,
        teamSpaceId
    }
}

export function clearTeamSpaces() {
    return {
        type: GET_TEAMSPACES_CLEAR_ACTION
    }
}

export function clearTeamSpace() {
    return {
        type: GET_TEAMSPACE_CLEAR_ACTION
    }
}

export function getOrgUsers() {
    return {
        type: GET_ORG_USERS_LOAD_ACTION
    }
}

export function clearOrgUsers() {
    return {
        type: GET_ORG_USERS_CLEAR_ACTION
    }
}

export function setTeamSpaceName(id, name) {
    return {
        type: SET_TEAM_SPACE_NAME,
        teamSpaceId: id,
        name: name
    };
}

export function setTeamSpaceDescription(id, description) {
    return {
        type: SET_TEAM_SPACE_DESCRIPTION,
        teamSpaceId: id,
        description
    };
}

export function setTeamSpaceDetailsClear() {
    return {
        type: SET_TEAM_SPACE_DETAILS_CLEAR
    };
}

export function setTeamSpaceAccess(id, teamSpaceAccess, indexRedirect) {
    return {
        type: SET_TEAM_SPACE_ACCESS_ACTION,
        teamSpaceId: id,
        addedOwnerIds: teamSpaceAccess.addedOwnerIds,
        removedOwnerIds: teamSpaceAccess.removedOwnerIds,
        addedMembers: teamSpaceAccess.addedMembers,
        removedMemberIds: teamSpaceAccess.removedMemberIds,
        modifiedMembers: teamSpaceAccess.modifiedMembers,
        locked: teamSpaceAccess.locked,
        indexRedirect
    }
}

export function setTeamSpaceAccessClear() {
    return {
        type: SET_TEAM_SPACE_ACCESS_CLEAR_ACTION
    }
}

export function createTeamResource(teamResource) {
    return {
        type: CREATE_TEAM_RESOURCE_ACTION,
        teamId: teamResource.teamId,
        resourceId: teamResource.resourceId,
        resourceType: teamResource.resourceType
    }
}

export function createTeamResourceClear() {
    return {
        type: CREATE_TEAM_RESOURCE_CLEAR_ACTION
    }
}

export function updateTeamResource(teamResource) {
    return {
        type: UPDATE_TEAM_RESOURCE_ACTION,
        id: teamResource.id,
        originTeamId: teamResource.originTeamId,
        targetTeamId: teamResource.targetTeamId,
    }
}

export function updateTeamResourceClear() {
    return {
        type: UPDATE_TEAM_RESOURCE_CLEAR_ACTION,
    }
}


export function removeTeamResource(teamResource) {
    return {
        type: REMOVE_TEAM_RESOURCE_LOAD_ACTION,
        resourceId: teamResource.resourceId,
        teamId: teamResource.teamId,
        deleteManagedResource: teamResource.deleteManagedResource
    }
}

export function removeTeamResourceClear() {
    return {
        type: REMOVE_TEAM_RESOURCE_CLEAR_ACTION
    }
}

export function deleteTeamSpace(teamSpaceDeletion) {
    return {
        type: DELETE_TEAM_SPACE_LOAD_ACTION,
        teamSpaceId: teamSpaceDeletion.teamSpaceId,
        destinationTeamSpaceId: teamSpaceDeletion.destinationTeamSpaceId
    }
}

export function deleteTeamSpaceClear() {
    return {
        type: DELETE_TEAM_SPACE_CLEAR_ACTION,
    }
}
