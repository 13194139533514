import { defineMessages } from "react-intl";

const genericErrorMessages = defineMessages({
    genericUnauthorized: {
        id: "genericErrorMessages.genericUnauthorized",
        description: "User interaction error > genericUnauthorized",
        defaultMessage: "Unauthorized. Please contact your organisation administrator.",
    },
    genericServerError: {
        id: "genericErrorMessages.genericServerError",
        description: "User interaction error > Generic Server Error",
        defaultMessage: "We ran into a problem with the Team Spaces service. Please try again and if the problem persists, please {link}",
    },
});

export default genericErrorMessages;
