import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import dialogMessages from "./Dialog.msg";
import { theme, osColour } from "omse-components";
import { Close as CloseIcon } from "@mui/icons-material";
import { Box, Dialog, IconButton, Grow, CircularProgress, useMediaQuery } from "@mui/material";

const Container = styled(Box)(
    ({ theme, mobile }) => `
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;

    & > .contentHead {
        padding: ${theme.spacing(3, 4, 1, 4)};
        display: flex;
        gap: ${theme.spacing(3)};
        align-items: center;
        justify-content: space-between;
    }

    & > .contentMain {
        overflow: hidden;
        padding: ${theme.spacing(3, 4, 4, 4)};
        max-height: ${mobile ? "none" : "42dvh"};
        min-height: ${mobile ? "14dvh" : "42dvh"};
    }

    & > .contentMain > .working {
        height: 100%;
        display: grid;
        place-items: center;
    }

    & > .contentFooter {
        padding: ${theme.spacing(3, 4)};
        background: ${osColour.neutral.clouds};
        display: flex;
        gap: ${theme.spacing(3)};
        align-items: center;
        justify-content: ${mobile ? "end" : "space-between"};
    }
`
);

export default function TeamSpaceDialog({
    variant,
    onClose,
    working,
    contentHead,
    contentMain,
    contentFooter,
}) {
    const intl = useIntl();
    const mobile = useMediaQuery(
        `(max-height: 800px), (max-width: ${theme.breakpoints.values.sm}px)`
    );

    return (
        <Dialog
            open={true}
            maxWidth={"sm"}
            fullWidth={true}
            fullScreen={mobile}
            TransitionComponent={Grow}
            data-testid={`dialog-${variant}`}
        >
            <Container mobile={mobile}>
                <Box className="contentHead">
                    {contentHead}
                    <IconButton
                        onClick={() => onClose()}
                        data-testid={`dialog-${variant}-close`}
                        aria-label={intl.formatMessage(dialogMessages.closeButton)}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box className="contentMain">
                    {working ? (
                        <Box className="working">
                            <CircularProgress size={30} />
                        </Box>
                    ) : (
                        contentMain
                    )}
                </Box>
                <Box className="contentFooter">{contentFooter}</Box>
            </Container>
        </Dialog>
    );
}

TeamSpaceDialog.propTypes = {
    variant: PropTypes.string,
    onClose: PropTypes.func,
    working: PropTypes.bool,
    contentHead: PropTypes.node,
    contentMain: PropTypes.node,
    contentFooter: PropTypes.node,
};
