import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import { findRoute } from "../../../util/routes";
import { Alert, Box, Typography } from "@mui/material";
import { Done as DoneIcon } from "@mui/icons-material";
import { osColour, ExternalLink } from "omse-components";
import moveAPIProjectCompleteMessages from "./MoveAPIProjectComplete.msg";

const Container = styled(Box)`
    height: 100%;
    display: grid;
    place-items: center;

    & > div {
        text-align: center;
    }

    & > div > h3 {
        font-size: 1.2rem;
        margin: 0 0 0.6em 0;
    }

    & > div > p {
        margin: 0 0 3em 0;
        color: ${osColour.neutral.stone};
    }

    & > div > svg {
        margin: 0 0 1.6em 0;
        padding: 1em;
        width: 2em;
        height: 2em;
        border-radius: 100%;
        color: ${osColour.neutral.white};
        background: ${osColour.status.success};

        // https://getcssscan.com/css-box-shadow-examples
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }
`;

const MoveAPIProjectComplete = ({ apiProjectName, newTeamSpaceProperties }) => {
    const intl = useIntl();
    const teamSpaceRoute = findRoute("teamSpaces");
    const teamSpaceFullUrl = `${teamSpaceRoute.path}/${newTeamSpaceProperties.id}`;

    return (
        <Container>
            <Box>
                <DoneIcon aria-hidden={true} />
                <Typography variant="h3">
                    {intl.formatMessage(moveAPIProjectCompleteMessages.title)}
                </Typography>
                <Typography variant="body1">
                    {intl.formatMessage(moveAPIProjectCompleteMessages.summary, {
                        apiProjectName: apiProjectName,
                        newTeamSpaceNameLink: (
                            // FYI: The 'new tab' approach mitigates
                            // a bug related to the moveComplete state.
                            <ExternalLink
                                href={teamSpaceFullUrl}
                                message={newTeamSpaceProperties.name}
                            />
                        ),
                    })}
                </Typography>
                <Alert severity="warning" icon={false}>
                    {intl.formatMessage(moveAPIProjectCompleteMessages.regenerateApiKey)}
                </Alert>
            </Box>
        </Container>
    );
};

export default MoveAPIProjectComplete;

MoveAPIProjectComplete.propTypes = {
    apiProjectName: PropTypes.string.isRequired,
    newTeamSpaceProperties: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    }).isRequired,
};
