import PropTypes from "prop-types";
import messages from "./DeleteTeamSpaceDialog.msg";
import { MOVE_TEAMSPACE_OPTIONS } from "./constants";
import { FormControl, RadioGroup, FormControlLabel, Radio, Typography } from "@mui/material";
const { useIntl } = require("react-intl");

const TeamSpaceDestinationOptions = ({ radioValue, filteredTeamSpaces, handleRadioChange }) => {
    const intl = useIntl();
    return (
        <FormControl>
            <RadioGroup
                defaultValue={MOVE_TEAMSPACE_OPTIONS.unassigned}
                name="radio-buttons-group"
                onChange={(event) => handleRadioChange(event.target.value)}
                value={radioValue}
            >
                <Typography variant="body1">
                    <FormControlLabel
                        control={<Radio />}
                        value={MOVE_TEAMSPACE_OPTIONS.unassigned}
                        label={intl.formatMessage(messages.moveToUnassigned, {
                            strong: (chunks) => <strong>{chunks}</strong>,
                        })}
                    />
                </Typography>
                <Typography variant="body1">
                    <FormControlLabel
                        control={<Radio />}
                        value={MOVE_TEAMSPACE_OPTIONS.alternative}
                        label={intl.formatMessage(messages.moveToAnotherTeamSpace, {
                            strong: (chunks) => <strong>{chunks}</strong>,
                        })}
                        disabled={!!(!filteredTeamSpaces.length)}
                    />
                </Typography>
            </RadioGroup>
        </FormControl>
    );
};

export default TeamSpaceDestinationOptions;

TeamSpaceDestinationOptions.propTypes = {
    radioValue: PropTypes.string,
    filteredTeamSpaces: PropTypes.array.isRequired,
    handleRadioChange: PropTypes.func.isRequired,
};
