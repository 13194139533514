import React, {Fragment, useEffect, useState} from 'react';
import {defineMessages, FormattedMessage} from "react-intl";
import {
    CommonDialog,
    CommonDialogActions,
    Notification,
    osColour,
    ExternalLink
} from "omse-components";
import {MenuItem, Select, Typography} from "@mui/material";
import {updateDataPackageDownloadFormat} from "../../../modules/dataPackages/actions";
import {createUseStyles} from 'react-jss';
import {ReactComponent as InfoIcon} from '../../../components/icons/warning-notification.svg';
import {ReactComponent as ExpandIcon} from '../../../components/icons/expand.svg';
import {useDispatch} from "react-redux";
import classNames from 'classnames';
import useActionIdSelector from "../../../hooks/useActionIdSelector";
import {useHistory} from "react-router-dom";
import routes, { getLocation } from "../../../util/routes";
import {isNgdDataPackage} from "../../../../shared/ngd";

const messages = defineMessages({
    updateFormatRequestTitle: {
        id: 'DataPackage.updateFormatRequestTitle',
        defaultMessage: "Change download format",
        description: 'change download format dialog title'
    },
    createNewDataPackage: {
        id:'DataPackage.createNewDataPackage',
        defaultMessage: 'Create new data package',
        description: 'Create new data package (appears if no formats are available)'
    },
    updateFormatRequestProceed: {
        id: 'DataPackage.updateFormatRequestProceed',
        defaultMessage: "Change format",
        description: 'change download format dialog proceed message'
    },
    updateFormatRequestCancel: {
        id: 'DataPackage.updateFormatRequestCancel',
        defaultMessage: "Cancel",
        description: 'change download format dialog cancel message'
    },
    updateFormatSuccess: {
        id: 'DataPackage.updateFormatSuccess',
        defaultMessage: "We're changing the download format of your data package. We'll email you when it's ready.",
        description: 'change download format success message'
    },
    updateFormatFailure: {
        id: 'DataPackage.updateFormatFailure',
        defaultMessage: 'There was a problem requesting your request. Please retry, and if the problem persists, then {link}.',
        description: 'change download format error message text'
    },
    changeFrom: {
        id: 'DataPackage.changeFrom',
        defaultMessage: "Change from",
        description: 'current data package format label'
    },
    changeTo: {
        id: 'DataPackage.changeTo',
        defaultMessage: "Change to",
        description: 'proposed data package format label'
    },
    newUpdatesInfo: {
        id: 'DataPackage.newUpdatesInfo',
        // Ideally we want to inform the user of a successful change to the data format both by in-app notification
        // and by email. However, we're calling the same endpoint that is used by the Stop Updates action, so we would
        // need to change the way that azure/dynamics/omse-events deals with that endpoint being accessed by more than
        // one action. as such, this message is left generic for now, with the intent to clarify things before this
        // change hits production.
        defaultMessage: "When changing to a new format, any new updates will be supplied in the new format. " +
            "After you have made a change, it may take up to 48 hours for any changes to show in your account. " +
            "We will notify you once any changes have been processed.",
        description: 'change download format dialog information message'
    },
    newUpdatesInfoNGD: {
        id: 'DataPackage.newUpdatesInfoNGD',
        defaultMessage: "After changing to a new format, you will receive a new data package version providing " +
            "a full supply of data at your last data date, and packaged in the new format. " +
            "We will notify you once the new data package version is available.",
        description: 'change download format dialog information message for NGD orders'
    },
    currentFormatName: {
        id: 'DataPackage.currentFormatName',
        defaultMessage: "{dataFormatName}",
        description: 'change download format current format name message'
    },
    selectNewFormat: {
        id: 'DataPackage.selectNewFormat',
        defaultMessage: "Select a new download format",
        description: 'change download format select new format default dropdown message'
    },
    noAvailableFormat:{
        id: 'DataPackage.noAvailableFormat',
        defaultMessage: "There are no other available formats for your selection",
        description: "Error message for no available formats"
    }
});

const styles = createUseStyles(theme => ({
    changeFrom: {
        marginBottom: theme.spacing(2)
    },
    changeTo: {
        marginBottom: theme.spacing(2)
    },
    info: {
        alignItems: 'flex-end'
    },
    infoIcon: {
        alignItems: 'flex-end',
        margin: theme.spacing(0, 1, -1.5, 0)
    },
    disabled: {
        color: osColour.neutral.rock
    },
    dropdown: {
        lineHeight: '1.5rem',
        padding: 0,
        backgroundColor: osColour.neutral.white,
        border: 'none',
        '&.is-error $select': {
            borderColor: osColour.status.error
        }
    },
    select: {
        '&:focus': {
            backgroundColor: osColour.neutral.white,
            borderRadius: 3
        },
        border: 'solid 1px ' +osColour.neutral.stone,
        borderRadius: 3,
        color: osColour.neutral.charcoal,
        paddingTop: 7,
        paddingBottom: 7,
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(5)+' !important'
    },
    selectIcon: {
        color: osColour.primary.berry,
        right: theme.spacing(1.5),
        height: 24,
        width: 24,
        top: "auto"
    },
    menuList: {
        '& li': {
            margin: 6
        }
    }
}));

const SELECT_MENU_ITEM = 'select';

export default function UpdateFormatDialog({dataPackage, onClose, formatOptions, premiumProductFormats, premiumRelationships}) {
    const history = useHistory();
    const dispatch = useDispatch();
    const [{working, error}] = useActionIdSelector("dataPackages.updateFormat");
    const [updating, setUpdating] = useState(false);
    const {id: dataPackageId, dataFormatId, dataFormatName, productId, updateScheduleId, type, tiles, polygon} = dataPackage;
    const [draftOrderState, setDraftOrderState] = useState({
        id: dataPackageId,
        type
    });
    const selectedFormatId = draftOrderState?.format?.id || SELECT_MENU_ITEM;
    const classes = styles();
    const area = tiles || polygon ? "Polygon" : "GB";
    const filteredRels = premiumRelationships.filter(rel => updateScheduleId === rel.schedule && area === rel.area);
    const filteredProductFormats = formatOptions ?
        formatOptions.filter(format => format.id !== dataFormatId) :
        premiumProductFormats.filter(format => !!filteredRels.find(r => r.format === format.id) && format.id !== dataFormatId);

    let infoMessage = messages.newUpdatesInfo;
    if(isNgdDataPackage(dataPackage)) {
        infoMessage = messages.newUpdatesInfoNGD;
    }

    useEffect(() => {
        if (updating && !working) {
            onClose();
        }
    }, [working, updating, onClose]);

    function handleChange(event) {
        const newFormatId = event.target.value;

        if (filteredProductFormats) {
            const foundFormat = filteredProductFormats.find(item => item.id === newFormatId)
            setDraftOrderState({...draftOrderState, format: foundFormat});
        }
    }

    function confirmUpdateFormat() {
        dispatch(updateDataPackageDownloadFormat(draftOrderState));
        setUpdating(true);
    }
    function goToNewDataPackage() {
        const path = routes.premiumItem.replace(':productId', productId);
        const newLocation = getLocation(path, history.location);
        history.push(newLocation);
    }

    let confirmAction = confirmUpdateFormat;
    let confirmLabel = messages.updateFormatRequestProceed;
    if(filteredProductFormats.length < 1){
        confirmAction = goToNewDataPackage;
        confirmLabel = messages.createNewDataPackage
    }
    const actions = <CommonDialogActions onClose={onClose}
                                         onConfirm={confirmAction}
                                         // if there are no formats to select, then the button is enabled
                                         // if there are formats, but none have been selected, then the button is disabled
                                         confirmAllowed={filteredProductFormats.length === 0 || !!draftOrderState.format?.id}
                                         confirmLabel={confirmLabel}
                                         cancelLabel={messages.updateFormatRequestCancel}
                                         working={updating}/>;

    let content =
        <CommonDialog onClose={onClose}
                      title={messages.updateFormatRequestTitle}
                      actions={actions}>
            <div className={classes.changeFrom} data-testid="dialog-from">
                <Typography variant='body2'>
                    <FormattedMessage {...messages.changeFrom} />
                </Typography>
                <Typography variant='body1'>
                    <FormattedMessage {...messages.currentFormatName} values={{dataFormatName: dataFormatName}} />
                </Typography>
            </div>
            <div className={classes.changeTo}>
                <Typography variant='body2'>
                    <FormattedMessage {...messages.changeTo} />
                </Typography>
                <Select className={classNames(classes.dropdown)}
                        classes={{
                            select: classes.select,
                            icon: classes.selectIcon
                        }}
                    MenuProps={{classes: {list: classes.menuList},
                                anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
                    }}
                    value={selectedFormatId}
                    onChange={handleChange}
                    disabled={!filteredProductFormats?.length}
                    IconComponent={ExpandIcon}
                    error={true}
                    disableUnderline={true}
                    fullWidth
                    data-testid="format-select"
                    variant='standard'
                    buttonvariant='outlined'
                    displayEmpty
                >
                    <MenuItem value={SELECT_MENU_ITEM} disabled>
                        {messages.selectNewFormat.defaultMessage}
                    </MenuItem>
                    {filteredProductFormats.map(version => (
                        <MenuItem id={version.id} value={version.id}>
                            {version.label}
                        </MenuItem>
                    ))}
                </Select>
            </div>
            <div>
                {filteredProductFormats.length < 1 &&
                    <Typography color="error" variant="body1">
                        <FormattedMessage {...messages.noAvailableFormat}/>
                    </Typography>
                }
            </div>
            <div className={classes.info}>
                <Typography variant='body1'>
                    <InfoIcon className={classes.infoIcon} width={36} height={36} />
                    <FormattedMessage {...infoMessage} />
                </Typography>
            </div>
        </CommonDialog>;

    if (error) {
        content = <Notification variant='error' appearance='toast' onClose={onClose}>
            <Typography variant='body1'>
                <FormattedMessage {...messages.updateFormatFailure} values={{ link: <ExternalLink type='support' /> }} />
            </Typography>
        </Notification>
    }

    return <Fragment>
        {content}
    </Fragment>

}
