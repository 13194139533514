import { createLoadingReducer, createLoadingMiddleware, createActionMiddleware, createActionReducer } from 'omse-components';
import {
    GET_TEAMSPACES_LOAD_ACTION, GET_TEAMSPACES_LOADED_ACTION, GET_TEAMSPACE_LOAD_ACTION, GET_TEAMSPACE_LOADED_ACTION,
    CREATE_TEAMSPACE_ACTION,
    GET_ORG_USERS_LOAD_ACTION, GET_ORG_USERS_LOADED_ACTION, GET_ORG_USERS_CLEAR_ACTION,
    GET_TEAMSPACE_CLEAR_ACTION,
    GET_TEAMSPACES_CLEAR_ACTION,
    SET_TEAM_SPACE_NAME, SET_TEAM_SPACE_NAME_LOADED,
    SET_TEAM_SPACE_DESCRIPTION, SET_TEAM_SPACE_DESCRIPTION_LOADED,
    SET_TEAM_SPACE_DETAILS_CLEAR,
    SET_TEAM_SPACE_ACCESS_ACTION,
    SET_TEAM_SPACE_ACCESS_LOADED_ACTION,
    SET_TEAM_SPACE_ACCESS_CLEAR_ACTION,
    CREATE_TEAM_RESOURCE_ACTION,
    CREATE_TEAM_RESOURCE_CLEAR_ACTION,
    UPDATE_TEAM_RESOURCE_ACTION,
    REMOVE_TEAM_RESOURCE_LOAD_ACTION,
    REMOVE_TEAM_RESOURCE_LOADED_ACTION,
    REMOVE_TEAM_RESOURCE_CLEAR_ACTION,
    DELETE_TEAM_SPACE_LOAD_ACTION,
    DELETE_TEAM_SPACE_LOADED_ACTION,
    DELETE_TEAM_SPACE_CLEAR_ACTION,
    CREATE_TEAM_RESOURCE_LOADED_ACTION,
} from "./teamSpaces/actions";
import { combineReducers } from 'redux';

export const teamSpacesReducer = combineReducers({
    getTeamSpaces: createLoadingReducer(GET_TEAMSPACES_LOAD_ACTION, GET_TEAMSPACES_LOADED_ACTION, true),
    getTeamSpace: createLoadingReducer(GET_TEAMSPACE_LOAD_ACTION, GET_TEAMSPACE_LOADED_ACTION, true),
    createTeamSpace: createActionReducer(CREATE_TEAMSPACE_ACTION),
    getOrgUsers: createLoadingReducer(GET_ORG_USERS_LOAD_ACTION, GET_ORG_USERS_LOADED_ACTION, true),
    setTeamSpaceName: createLoadingReducer(SET_TEAM_SPACE_NAME, SET_TEAM_SPACE_NAME_LOADED),
    setTeamSpaceDescription: createLoadingReducer(SET_TEAM_SPACE_DESCRIPTION, SET_TEAM_SPACE_DESCRIPTION_LOADED),
    setTeamSpaceAccess: createLoadingReducer(SET_TEAM_SPACE_ACCESS_ACTION, SET_TEAM_SPACE_ACCESS_LOADED_ACTION, false, patchTeamSpaceAccessSuccess),
    createTeamResource: createActionReducer(CREATE_TEAM_RESOURCE_ACTION),
    updateTeamResource: createActionReducer(UPDATE_TEAM_RESOURCE_ACTION),
    removeTeamResource: createLoadingReducer(REMOVE_TEAM_RESOURCE_LOAD_ACTION, REMOVE_TEAM_RESOURCE_LOADED_ACTION, false),
    deleteTeamSpace: createLoadingReducer(DELETE_TEAM_SPACE_LOAD_ACTION, DELETE_TEAM_SPACE_LOADED_ACTION, false),
});

const TEAM_SPACES_API_URL = '/api/teamSpaces';

// Users

function getOrgUsersPrepareCall() {
    return TEAM_SPACES_API_URL + '/orgUsers';
}

// Team Space

function getTeamSpacesPrepareCall() {
    return TEAM_SPACES_API_URL + '/teamSpaces';
}

function getTeamSpacePrepareCall(action) {
    return TEAM_SPACES_API_URL + `/teamSpaces/${action.teamSpaceId}`;
}

function postTeamSpacePrepareCall(action) {
    return {
        method: 'POST',
        url: TEAM_SPACES_API_URL + '/teamSpaces',
        body: {
            name: action.name,
            description: action.description,
            ownerDatahubDeveloperIds: action.ownerDatahubDeveloperIds,
            members: action.members,
            locked: action.locked
        }
    };
}

function patchTeamSpacePrepareCall(action) {
    const body = {};
    if (action.name?.trim()) {
        body.name = action.name;
    }
    const description = action.description?.trim();
    if (description || description === '') {
        body.description = description;
    }
    if (action.addedOwnerIds) {
        body.addedOwnerIds = action.addedOwnerIds;
    }
    if (action.removedOwnerIds) {
        body.removedOwnerIds = action.removedOwnerIds;
    }
    if (action.locked) {
        body.locked = action.locked;
    }
    if (action.addedMembers) {
        body.addedMembers = action.addedMembers;
    }
    if (action.removedMemberIds) {
        body.removedMemberIds = action.removedMemberIds;
    }
    if (action.modifiedMembers) {
        body.modifiedMembers = action.modifiedMembers;
    }
    return {
        method: 'PATCH',
        url: TEAM_SPACES_API_URL + `/teamSpaces/${action.teamSpaceId}`,
        body
    }
}

function removeTeamSpacePrepareCall(action) {
    return {
        method: 'DELETE',
        url: TEAM_SPACES_API_URL + '/teamSpaces/' + action.teamSpaceId,
        body: {
            destinationTeamSpaceId: action.destinationTeamSpaceId
        }
    };
}

// Callbacks

function patchTeamSpaceAccessSuccess(action) {
    if (action.originalAction?.indexRedirect) {
        action.result.indexRedirect = true;
    }
}

// Team Resource

function postTeamResourceCall(action) {
    return {
        method: 'POST',
        url: TEAM_SPACES_API_URL + '/teamResources',
        body: {
            teamId: action.teamId,
            resourceId: action.resourceId,
            resourceType: action.resourceType
        }
    };
}

function patchTeamResourceCall(action) {
    return {
        method: "PATCH",
        url: TEAM_SPACES_API_URL + `/teamResources/${action.id}`,
        body: {
            originTeamId: action.originTeamId,
            targetTeamId: action.targetTeamId,
        },
    };
}

function removeTeamResourcePrepareCall(action) {
    return {
        method: 'DELETE',
        url: TEAM_SPACES_API_URL + '/teamResources/' + action.resourceId,
        body: {
            teamId: action.teamId,
            deleteManagedResource: action.deleteManagedResource
        }
    };
}

export const teamSpacesMiddleware = [
    createLoadingMiddleware(GET_TEAMSPACES_LOAD_ACTION, GET_TEAMSPACES_LOADED_ACTION, getTeamSpacesPrepareCall),
    createLoadingMiddleware(GET_TEAMSPACE_LOAD_ACTION, GET_TEAMSPACE_LOADED_ACTION, getTeamSpacePrepareCall),
    createActionMiddleware(CREATE_TEAMSPACE_ACTION, postTeamSpacePrepareCall),
    createLoadingMiddleware(GET_ORG_USERS_LOAD_ACTION, GET_ORG_USERS_LOADED_ACTION, getOrgUsersPrepareCall),
    createLoadingMiddleware(SET_TEAM_SPACE_NAME, SET_TEAM_SPACE_NAME_LOADED, patchTeamSpacePrepareCall),
    createLoadingMiddleware(SET_TEAM_SPACE_DESCRIPTION, SET_TEAM_SPACE_DESCRIPTION_LOADED, patchTeamSpacePrepareCall),
    createLoadingMiddleware(SET_TEAM_SPACE_ACCESS_ACTION, SET_TEAM_SPACE_ACCESS_LOADED_ACTION, patchTeamSpacePrepareCall, true),
    createActionMiddleware(CREATE_TEAM_RESOURCE_ACTION, postTeamResourceCall),
    createActionMiddleware(UPDATE_TEAM_RESOURCE_ACTION, patchTeamResourceCall),
    createLoadingMiddleware(REMOVE_TEAM_RESOURCE_LOAD_ACTION, REMOVE_TEAM_RESOURCE_LOADED_ACTION, removeTeamResourcePrepareCall, true),
    createLoadingMiddleware(DELETE_TEAM_SPACE_LOAD_ACTION, DELETE_TEAM_SPACE_LOADED_ACTION, removeTeamSpacePrepareCall),

    store => next => action => {
        if (action.type === GET_ORG_USERS_CLEAR_ACTION) {
            store.dispatch({ type: GET_ORG_USERS_LOADED_ACTION, result: null, error: null });
        }
        if (action.type === GET_TEAMSPACES_CLEAR_ACTION) {
            store.dispatch({ type: GET_TEAMSPACES_LOADED_ACTION, result: null, error: null });
        }
        if (action.type === GET_TEAMSPACE_CLEAR_ACTION) {
            store.dispatch({ type: GET_TEAMSPACE_LOADED_ACTION, result: null, error: null });
        }
        if (action.type === SET_TEAM_SPACE_DETAILS_CLEAR) {
            store.dispatch({ type: SET_TEAM_SPACE_NAME_LOADED, result: null, error: null });
            store.dispatch({ type: SET_TEAM_SPACE_DESCRIPTION_LOADED, result: null, error: null });
        }
        if (action.type === SET_TEAM_SPACE_ACCESS_CLEAR_ACTION) {
            store.dispatch({ type: SET_TEAM_SPACE_ACCESS_LOADED_ACTION, result: null, error: null });
        }
        if (action.type === REMOVE_TEAM_RESOURCE_CLEAR_ACTION) {
            store.dispatch({ type: REMOVE_TEAM_RESOURCE_LOADED_ACTION, result: null, error: null });
        }
        if (action.type === CREATE_TEAM_RESOURCE_CLEAR_ACTION) {
            store.dispatch({ type: CREATE_TEAM_RESOURCE_LOADED_ACTION, result:  null, error: null })
        }
        if (action.type === DELETE_TEAM_SPACE_CLEAR_ACTION) {
            store.dispatch({ type: DELETE_TEAM_SPACE_LOADED_ACTION, result: null, error: null });
        }
        if (action.type === SET_TEAM_SPACE_NAME_LOADED || action.type === SET_TEAM_SPACE_DESCRIPTION_LOADED) {
            if (action.result) {
                const getTeamSpaceState = store.getState().teamSpaces.getTeamSpace;
                if (getTeamSpaceState.result && !getTeamSpaceState.loading) {
                    const teamSpace = getTeamSpaceState.result;
                    teamSpace.name = action.result.name;
                    teamSpace.description = action.result.description;
                    store.dispatch({ type: GET_TEAMSPACE_LOADED_ACTION, result: teamSpace, error: getTeamSpaceState.error });
                }
            }
        }
        next(action);
    }
];