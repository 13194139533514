import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {Typography} from "@mui/material";
import {createUseStyles} from "react-jss";
import {Container} from "@mui/system";
import {ReactComponent as LicenceIcon} from "../../../components/icons/licence.svg"
import routePaths from "../../../util/routes";
import { useHistory } from "react-router-dom";

const messages = defineMessages({
    title: {
        id: 'DelApplicationPending.title',
        defaultMessage: 'Data Exploration Licence application',
        description: 'Title of DEL Application Complete page'
    },
    successMessage: {
        id: 'DelApplicationPending.successMessage',
        defaultMessage: 'Thank you, we\'ll be in touch soon',
        description: 'Success message of DEL Application Complete page'
    },
    p1: {
        id: 'DelApplicationPending.p1',
        defaultMessage: 'We have received your application for the 6 month Data Exploration Licence. ' +
            'Our team are reviewing your application and will be in touch within 3 working days. ' +
            'Develop your ideas before deciding if the paid-for service is for you.',
        description: 'Paragraph 1 of DEL Application Complete page'
    },
    p2: {
        id: 'DelApplicationPending.p2',
        defaultMessage: 'While we process your application, have a look at our <a>Sample Data</a> to see what products are available.',
        description: 'Paragraph 2 of DEL Application Complete page'
    },
    p2LinkAriaLabel: {
        id: 'DelApplicationPending.p2LinkAriaLabel',
        defaultMessage: 'Click here to view our sample data products',
        description: 'Aria label for sample data products link'
    }
});

const useStyles = createUseStyles(theme => ({
    delContainer: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
        maxWidth: theme.spacing(160),
        [theme.breakpoints.down("md")]: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        }
    },
    title: {
        paddingBottom: theme.spacing(4)
    },
    subtitle: {
        paddingBottom: theme.spacing(2)
    },
    successMessage:{
        fontWeight: '900',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: theme.spacing(3)
    },
    paragraphContainer: {
        paddingLeft: theme.spacing(4),
        maxWidth: theme.spacing(100)
    }
}));

function DelApplicationPending() {
    const classes = useStyles();
    const intl = useIntl();
    const history = useHistory();

    return (
        <Container maxWidth="lg"  className={classes.delContainer}>
            <Typography variant="h1" color="primary" className={classes.title}>
                <FormattedMessage {...messages.title} />
            </Typography>
            <div className={classes.successMessage}>
                <LicenceIcon aria-hidden="true" />
                <Typography variant="h2" color="primary" >
                    <FormattedMessage {...messages.successMessage} />
                </Typography>
            </div>
            <div className={classes.paragraphContainer}>
                <Typography variant="body1" color="textPrimary" paragraph={true}>
                    <FormattedMessage {...messages.p1} />
                </Typography>
                <Typography variant="body1" color="textPrimary" paragraph={true}>
                    <FormattedMessage {...messages.p2} values={{
                        a: message =>
                            <a onClick={() => {history.push(routePaths.sampleDataDownloads)}}
                               aria-label={intl.formatMessage(messages.p2LinkAriaLabel)}
                            >{message}</a>
                    }}/>
                </Typography>
            </div>
        </Container>
    )
}

export default DelApplicationPending;