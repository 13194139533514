import { useIntl } from "react-intl";
import { PropTypes } from "prop-types";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
    getTeamSpace,
    getTeamSpaces,
    updateTeamResource,
    updateTeamResourceClear,
} from "../../../modules/teamSpaces/actions";
import { ExternalLink, Notification } from "omse-components";
import { getTeamSpacesErrorMessage } from "../util/getTeamSpacesErrorMessage";
import { Button, Typography, CircularProgress } from "@mui/material";
import moveAPIProjectMessages from "./MoveAPIProject.msg";
import MoveAPIProjectResults from "./MoveAPIProjectResults";
import MoveAPIProjectComplete from "./MoveAPIProjectComplete";
import { ADMIN_ROLE } from "../../../../shared/roles";
import { API_PROJECT_PERMISSION_GROUPS } from "../../../../shared/teamSpaces";
import moveResourceErrors from "./moveResourceErrors.msg";
import TeamSpaceDialog from "../shared/Dialog";
import { dialogVariants } from "../shared/constants";

const MoveAPIProject = ({ apiProjectId, apiProjectName, setShowDialog }) => {
    const intl = useIntl();
    const params = useParams();
    const dispatch = useDispatch();
    const activeTeamSpace = Number(params.teamSpaceId);

    const user = useSelector((state) => state.user);
    const teamSpaces = useSelector((state) => state.teamSpaces.getTeamSpaces);
    const updateTeamResourceEvent = useSelector((state) => state.teamSpaces.updateTeamResource);

    const [moveError, setMoveError] = useState(false);
    const [moveWorking, setMoveWorking] = useState(false);
    const [moveComplete, setMoveComplete] = useState(false);
    const [moveCandidateTeamSpaces, setMoveCandidateTeamSpaces] = useState([]);
    const [moveSelectedTeamSpaceId, setMoveSelectedTeamSpaceId] = useState(null);

    const handleClose = () => {
        setShowDialog(false);
        setMoveWorking(false);
        setMoveComplete(false);
        setMoveCandidateTeamSpaces(false);
        setMoveSelectedTeamSpaceId(false);
        dispatch(updateTeamResourceClear());
        dispatch(getTeamSpace(activeTeamSpace));
    };

    const handleSaveChanges = () => {
        setMoveError(false);
        setMoveWorking(true);

        const action = {
            id: apiProjectId,
            originTeamId: activeTeamSpace,
            targetTeamId: moveSelectedTeamSpaceId,
        };

        dispatch(updateTeamResource(action));
    };

    useEffect(() => {
        dispatch(getTeamSpaces());
    }, [dispatch]);

    useEffect(() => {
        // Compiles and sorts the list of candidate Team Spaces.

        if (Array.isArray(teamSpaces.result) && user.current.result) {
            setMoveCandidateTeamSpaces([
                ...teamSpaces.result.filter(
                    (teamSpace) =>
                        teamSpace.id !== activeTeamSpace &&
                        (user.current.result.role === ADMIN_ROLE ||
                            teamSpace.userRoles?.apiProjectRole ===
                                API_PROJECT_PERMISSION_GROUPS.ApiProjectEditor)
                ),
            ]);
        }
    }, [teamSpaces.result, user.current.result]);

    useEffect(() => {
        // Listens for the move completion event.

        if (updateTeamResourceEvent.error) {
            setMoveError(true);
            setMoveWorking(false);
            return;
        } else if (
            updateTeamResourceEvent.result &&
            updateTeamResourceEvent.result.teamResource.teamId !== activeTeamSpace
        ) {
            setMoveComplete(true);
            setMoveWorking(false);
        }
    }, [updateTeamResourceEvent]);

    return (
        <TeamSpaceDialog
            onClose={() => handleClose()}
            variant={dialogVariants.moveApiProject}
            working={teamSpaces.loading}
            contentHead={
                <Typography variant="h2">
                    {intl.formatMessage(moveAPIProjectMessages.dialogHeading)}
                </Typography>
            }
            contentMain={
                <>
                    {moveComplete ? (
                        <MoveAPIProjectComplete
                            apiProjectName={apiProjectName}
                            newTeamSpaceProperties={moveCandidateTeamSpaces.find(
                                (teamSpace) => teamSpace.id === moveSelectedTeamSpaceId
                            )}
                        />
                    ) : (
                        <>
                            {teamSpaces.error ? (
                                <Typography variant="body1" className="error">
                                    {intl.formatMessage(
                                        getTeamSpacesErrorMessage(
                                            teamSpaces,
                                            moveResourceErrors.genericServerError
                                        ),
                                        {
                                            link: <ExternalLink type="support" />,
                                        }
                                    )}
                                </Typography>
                            ) : (
                                <>
                                    {moveCandidateTeamSpaces.length ? (
                                        <MoveAPIProjectResults
                                            teamSpaces={moveCandidateTeamSpaces}
                                            selectedTeamSpace={moveSelectedTeamSpaceId}
                                            setSelectedTeamSpace={setMoveSelectedTeamSpaceId}
                                        />
                                    ) : (
                                        <Notification variant="info" appearance="inline">
                                            <Typography
                                                variant="body1"
                                                data-testid={`dialog-${dialogVariants.moveApiProject}-noneFound`}
                                            >
                                                {intl.formatMessage(
                                                    moveAPIProjectMessages.noEligibleTeamSpaces
                                                )}
                                            </Typography>
                                        </Notification>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {moveError && (
                        <Notification
                            variant="error"
                            appearance="toast"
                            onClose={() => setMoveError(false)}
                            closeAriaLabel={intl.formatMessage(
                                moveAPIProjectMessages.closeButtonAriaLabel
                            )}
                        >
                            <Typography variant="body1">
                                {intl.formatMessage(moveAPIProjectMessages.submissionError, {
                                    link: <ExternalLink type="support" />,
                                })}
                            </Typography>
                        </Notification>
                    )}
                </>
            }
            contentFooter={
                <>
                    <Button
                        data-testid={`dialog-${dialogVariants.moveApiProject}-cancel`}
                        variant="outlined"
                        disabled={moveWorking}
                        onClick={() => handleClose()}
                    >
                        {intl.formatMessage(
                            moveComplete
                                ? moveAPIProjectMessages.dismissButton
                                : moveAPIProjectMessages.cancelButton
                        )}
                    </Button>
                    {!moveComplete && (
                        <Button
                            data-testid={`dialog-${dialogVariants.moveApiProject}-save`}
                            variant="contained"
                            disabled={moveWorking || moveComplete || !moveSelectedTeamSpaceId}
                            onClick={() => handleSaveChanges()}
                            aria-label={intl.formatMessage(moveAPIProjectMessages.saveButton)}
                            sx={{ minWidth: "6em" }}
                        >
                            {moveWorking ? (
                                <CircularProgress size={24} />
                            ) : (
                                intl.formatMessage(moveAPIProjectMessages.saveButton)
                            )}
                        </Button>
                    )}
                </>
            }
        />
    );
};

export default MoveAPIProject;

MoveAPIProject.propTypes = {
    setShowDialog: PropTypes.func,
    apiProjectId: PropTypes.number.isRequired,
    apiProjectName: PropTypes.string.isRequired,
};
