import { defineMessages } from "react-intl";

const manageTeamSpaceMessages = defineMessages({
    manageAccess: {
        id: "manageTeamSpaceMessages.manageAccess",
        defaultMessage: "Manage Access",
        description: "Manage Team Space > Manage Access",
    },
    saveButton: {
        id: "manageTeamSpaceMessages.saveButton",
        defaultMessage: "Save",
        description: "Manage Team Space > Save Button",
    },
    cancelButton: {
        id: "manageTeamSpaceMessages.cancelButton",
        defaultMessage: "Cancel",
        description: "Manage Team Space > Cancel Button",
    },
    membersButtonLabel: {
        id: "manageTeamSpaceMessages.membersButtonLabel",
        defaultMessage: "Members",
        description: "Members button label",
    },
    membersButtonAria: {
        id: "manageTeamSpaceMessages.membersButtonAria",
        defaultMessage: "Open Members dialog",
        description: "Members button aria label",
    },
    doneButton: {
        id: "manageTeamSpaceMessages.doneButton",
        defaultMessage: "Done",
        description: "Manage Team Space > Done Button",
    },
});

export default manageTeamSpaceMessages;
