import PropTypes from "prop-types";
import { useIntl } from "react-intl";
import { Typography } from "@mui/material";
import teamSpaceErrorMessages from "./TeamSpaceError.msg";
import { Notification, ExternalLink } from "omse-components";
import InlineCodeSnippet from "../../components/InlineCodeSnippet";

const TeamSpaceError = ({ httpStatus, teamSpaceId }) => {
    const intl = useIntl();

    if (httpStatus === 400) {
        return (
            <Notification variant="warning" appearance="inline">
                <Typography variant="body1" data-testid="errorMessageContent-400">
                    {intl.formatMessage(teamSpaceErrorMessages.errorBadRequest, {
                        id: (
                            <>
                                (<InlineCodeSnippet>{teamSpaceId}</InlineCodeSnippet>)
                            </>
                        ),
                    })}
                </Typography>
            </Notification>
        );
    }

    if (httpStatus === 404) {
        return (
            <Notification variant="warning" appearance="inline">
                <Typography variant="body1" data-testid="errorMessageContent-404">
                    {intl.formatMessage(teamSpaceErrorMessages.errorResNotFound)}
                </Typography>
            </Notification>
        );
    }

    return (
        <Notification variant="error" appearance="inline">
            <Typography variant="body1" data-testid="errorMessageContent-500" paragraph={true}>
                {intl.formatMessage(teamSpaceErrorMessages.errorGeneric)}
            </Typography>
            <Typography variant="body1">
                {intl.formatMessage(teamSpaceErrorMessages.errorGenericContent, {
                    link: <ExternalLink type="support" />,
                })}
            </Typography>
        </Notification>
    );
};

TeamSpaceError.propTypes = {
    httpStatus: PropTypes.number,
    teamSpaceId: PropTypes.string,
};

export default TeamSpaceError;
