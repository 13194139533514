import { defineMessages } from "react-intl";

const teamSpacesMessages = defineMessages({
    title: {
        id: "teamSpacesPageMessages.title",
        defaultMessage: "Team Spaces",
        description: "Team Spaces > Title",
    },
    searchBoxLabel: {
        id: "teamSpacesMessages.searchBoxLabel",
        description: "Team Spaces > Search Label",
        defaultMessage: "Search Team Spaces",
    },
    searchBoxPlaceholder: {
        id: "teamSpacesMessages.searchBoxPlaceholder",
        description: "Team Spaces > Search Filter",
        defaultMessage: "Find Team Spaces by name",
    },
    dataSort: {
        id: "teamSpacesMessages.dataSort",
        description: "Team Spaces > Sort Label",
        defaultMessage: "Sort",
    },
    dataSortAria: {
        id: "teamSpacesMessages.dataSortAria",
        description: "Team Spaces > Data Sort > Aria Label",
        defaultMessage: "Sort Team Spaces by property",
    },
    dataFilter: {
        id: "teamSpacesMessages.dataFilter",
        description: "Team Spaces > Filter Label",
        defaultMessage: "Filter",
    },
    dataFilterAria: {
        id: "teamSpacesMessages.dataFilterAria",
        description: "Team Spaces > Data Filter > Aria Label",
        defaultMessage: "Filter Team Spaces by property",
    },
});

export default teamSpacesMessages;
