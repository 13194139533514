import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { useIntl } from "react-intl";
import routePaths from "../../util/routes";
import Link from "../../components/Link";
import { osColour, Notification } from "omse-components";
import teamSpacesListMessages from "./TeamSpacesList.msg";
import { Box, Typography } from "@mui/material";
import { ReactComponent as TeamSpaceIcon } from "../../components/icons/teamSpace.svg";
import { ReactComponent as TeamSpaceLockedIcon } from "../../components/icons/teamSpaceLocked.svg";
import { useCreatedByName } from "./util/useCreatedByName";

const CardContainer = styled(Box)`
    margin: 1em 0 0 0;
    display: grid;
    gap: 1em;
    grid-template-columns: 1fr;
`;

const Card = styled(Box)`
    overflow: hidden;
    border: 1px solid ${osColour.neutral.mist};
    border-radius: 0.3em;

    & > div {
        padding: 1em 1em 1.2em 1em;
        display: flex;
        align-items: start;
        gap: 1em;
        background: ${osColour.neutral.clouds}80;
    }

    & > div > svg {
        width: 2em;
        height: 2em;
        flex-shrink: 0;
    }

    & > div > h2 {
        margin: 0.3em 0 0 0;
    }

    & > div > h2 > a {
        font-size: 1.14rem;
        font-weight: 500;
    }

    & > dl {
        padding: 1em;
        margin: 0;
    }

    & > dl > dt {
        margin: 0.6em 0 0 0;
        font-size: 0.9rem;
        color: ${osColour.neutral.stone};
    }

    & > dl > dt:first-of-type {
        margin: 0;
    }

    & > dl > dd {
        margin-inline-start: 0;
        color: ${osColour.neutral.charcoal};
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        @supports (-webkit-line-clamp: 3) {
            overflow: hidden;
            white-space: initial;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }
    }
`;

const TeamSpacesListMobile = ({ results }) => {
    const intl = useIntl();
    const getCreatedByName = useCreatedByName();
    return (
        <CardContainer>
            {!results.length && (
                <Notification variant="warning" appearance="inline">
                    <Typography variant="body1" data-testid="no-results-error">
                        {intl.formatMessage(teamSpacesListMessages.searchHasNoResults)}
                    </Typography>
                </Notification>
            )}
            {results.map((result) => (
                <Card key={result.id} component={"article"}>
                    <Box>
                        {result.locked ? (
                            <TeamSpaceLockedIcon
                                aria-label={intl.formatMessage(teamSpacesListMessages.lockedAria)}
                            />
                        ) : (
                            <TeamSpaceIcon
                                aria-label={intl.formatMessage(teamSpacesListMessages.unlockedAria)}
                            />
                        )}
                        <Typography variant="h3" component="h2">
                            <Link path={routePaths.teamSpace.replace(':teamSpaceId', result.id)}
                                state={{
                                    teamSpace: {
                                        name: result.name,
                                        locked: result.locked,
                                        description: result.description,
                                        createdByName: getCreatedByName(result)
                                    }
                                }}>
                                {result.name}
                            </Link>
                        </Typography>
                    </Box>
                    <dl>
                        <Typography variant="body1" component="dt">
                            {intl.formatMessage(teamSpacesListMessages.createdBy)}
                        </Typography>
                        <Typography variant="body1" component="dd">
                            {getCreatedByName(result)}
                        </Typography>
                        {result.description && (
                            <>
                                <Typography variant="body1" component="dt">
                                    {intl.formatMessage(teamSpacesListMessages.description)}
                                </Typography>
                                <Typography variant="body1" component="dd">
                                    {result.description}
                                </Typography>
                            </>
                        )}
                    </dl>
                </Card>
            ))}
        </CardContainer>
    );
};

TeamSpacesListMobile.propTypes = {
    multiCols: PropTypes.bool,
    results: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            locked: PropTypes.bool,
            description: PropTypes.string,
            createdByName: PropTypes.string,
        }),
    ),
};

export default TeamSpacesListMobile;
