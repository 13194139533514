import { useState, useEffect, useId, useMemo } from "react";
import styled from "@emotion/styled";
import { contentPadding, DropDownMenu, Notification, theme } from "omse-components";
import { FormattedMessage, useIntl } from "react-intl";
import { Box, CircularProgress, Typography, useMediaQuery } from "@mui/material";
import SearchBox from "../components/SearchBox";
import Header from "./teamSpaces/Header";
import EmptyState from "./teamSpaces/EmptyState";
import TeamSpacesListMobile from "./teamSpaces/TeamSpacesListMobile";
import TeamSpacesListDesktop from "./teamSpaces/TeamSpacesListDesktop";
import FeatureCheck from "../components/FeatureCheck";
import { TEAM_SPACES } from "../../shared/features";
import { useDispatch, useSelector } from "react-redux";
import { clearTeamSpaces, getOrgUsers, getTeamSpaces } from "../modules/teamSpaces/actions";
import teamSpacesMessages from "./TeamSpaces.msg";
import { ContentContainer } from "./teamSpaces/ContentContainer";
import NewTeamSpace from "./teamSpaces/NewTeamSpace";
import {
    teamSpaceMap,
    teamSpaceSortFunction,
    teamSpaceSortOptions,
} from "./teamSpaces/sort/teamSpaces";
import TeamSpaceError from "./teamSpaces/TeamSpaceError";
import { useLocation } from "react-router";
import { isContractor } from "./teamSpaces/util/teamSpaceUser";

const FilterControls = styled(Box)`
    & > div:first-of-type {
        max-width: 16em;
        ${theme.breakpoints.down("md")} {
            min-width: 100%;
        }
    }

    & > div:last-of-type {
        display: flex;
        gap: 1em;
        flex-wrap: wrap;
        align-items: end;
        ${theme.breakpoints.down("md")} {
            flex-direction: column-reverse;
            align-items: stretch;
        }
        justify-content: space-between;
        max-width: ${contentPadding.maxWidth}px;
        padding-top: 18px;
    }

    & > div:last-of-type > div:first-of-type {
        flex-grow: 1;
    }

    & > div:last-of-type > div:last-of-type {
        // Prevents layout shift.
        ${theme.breakpoints.up("md")} {
            min-width: 22em;
        }
    }

    & .dropdownBox > button {
        ${theme.breakpoints.up("md")} {
            max-height: 2.5em;
        }
    }
`;

const viewOptions = [
    { id: "all", label: "All Team Spaces", value: 0 },
    { id: "locked", label: "Locked Team Spaces", value: 1 },
    { id: "unlocked", label: "Unlocked Team Spaces", value: 2 },
];

const TeamSpaces = () => {
    const intl = useIntl();
    const dataSortId = useId();
    const dataFilterId = useId();
    const dispatch = useDispatch();
    const isMobileViewport = useMediaQuery("(max-width: 800px)");
    const location = useLocation();

    const {
        result: teamSpacesData,
        loading: teamSpacesLoading,
        error: teamSpacesError,
    } = useSelector((state) => state.teamSpaces.getTeamSpaces);

    const orgUsers = useSelector((state) => state.teamSpaces.getOrgUsers.result);
    const user = useSelector((state) => state.user.current.result);

    const [filterView, setFilterView] = useState(0);
    const [filterSearch, setFilterSearch] = useState("");
    const [filterOrdering, setFilterOrdering] = useState(0);
    const [successNotice, setSuccessNotice] = useState();

    useEffect(() => {
        if (location.successMessage && successNotice === undefined) {
            setSuccessNotice(location.successMessage);
        }
    }, [location]);

    useEffect(() => {
        dispatch(getTeamSpaces());
        return () => {
            dispatch(clearTeamSpaces());
        };
    }, [dispatch]);

    useEffect(() => {
        if (user && !isContractor(user) && !orgUsers && teamSpacesData?.length) {
            dispatch(getOrgUsers());            
        }      
    }, [dispatch, user, orgUsers, teamSpacesData]);

    const teamSpacesFiltered = useMemo(() => {
        if (!teamSpacesData) return [];
        let filteredResults = teamSpacesData.map(teamSpaceMap);

        // Apply view filter
        if (filterView !== 0) {
            filteredResults = filteredResults.filter((project) =>
                filterView === 1 ? project.locked : !project.locked,
            );
        }

        // Apply search filter
        const searchTerm = filterSearch.toLowerCase();
        if (searchTerm) {
            filteredResults = filteredResults.filter((project) =>
                project.name.toLowerCase().includes(searchTerm),
            );
        }

        // Sort
        filteredResults = [...filteredResults].sort(teamSpaceSortFunction(filterOrdering));

        return filteredResults;
    }, [filterView, filterSearch, filterOrdering, teamSpacesData]);

    return (
        <FeatureCheck feature={TEAM_SPACES}>
            <Header>
                <Typography variant="h1">
                    <FormattedMessage {...teamSpacesMessages.title} />
                </Typography>
                {!!teamSpacesData?.length && <NewTeamSpace />}
                {successNotice && (
                    <Notification
                        variant="success"
                        appearance="toast"
                        onClose={() => setSuccessNotice(null)}
                        autoClose
                    >
                        <Typography variant="body1">{successNotice}</Typography>
                    </Notification>
                )}
            </Header>
            <ContentContainer>
                {teamSpacesLoading && <CircularProgress size={32} data-testid="loading-spinner" />}

                {!teamSpacesLoading && teamSpacesError && <TeamSpaceError />}

                {!teamSpacesLoading && !teamSpacesError && (
                    <>
                        {!!teamSpacesData?.length && (
                            <>
                                <FilterControls data-testid="teamspaces-filter-controls">
                                    <DropDownMenu
                                        label={teamSpacesMessages.dataFilter}
                                        buttonId={dataFilterId}
                                        buttonProps={{
                                            "aria-label": intl.formatMessage(
                                                teamSpacesMessages.dataFilterAria
                                            ),
                                        }}
                                        buttonFontWeight="bold"
                                        value={filterView}
                                        buttonVariant="outlined"
                                        items={viewOptions}
                                        onChange={(selection) => setFilterView(selection.value)}
                                        className="dropdownBox"
                                    />
                                    <Box>
                                        <SearchBox
                                            label={teamSpacesMessages.searchBoxLabel}
                                            placeholder={teamSpacesMessages.searchBoxPlaceholder}
                                            search={filterSearch}
                                            setSearch={setFilterSearch}
                                        />
                                        <DropDownMenu
                                            label={teamSpacesMessages.dataSort}
                                            buttonId={dataSortId}
                                            buttonFontWeight="bold"
                                            value={filterOrdering}
                                            buttonVariant="outlined"
                                            items={teamSpaceSortOptions}
                                            onChange={(selection) =>
                                                setFilterOrdering(selection.value)
                                            }
                                            className="dropdownBox"
                                        />
                                    </Box>
                                </FilterControls>
                                {isMobileViewport ? (
                                    <TeamSpacesListMobile results={teamSpacesFiltered} />
                                ) : (
                                    <TeamSpacesListDesktop results={teamSpacesFiltered} />
                                )}
                            </>
                        )}
                        {teamSpacesData?.length === 0 && teamSpacesFiltered?.length === 0 && (
                            <EmptyState />
                        )}
                    </>
                )}
            </ContentContainer>
        </FeatureCheck>
    );
};

export default TeamSpaces;
