function getErrorKeys(teamSpaceResult) {
    const httpStatus = teamSpaceResult.error.httpStatus;
    const httpResponse = teamSpaceResult.error.httpResponse;

    let errorKey = "";

    try {
        errorKey = JSON.parse(httpResponse).error;
    } catch (error) {
        console.error(error);
    }

    if (errorKey.includes(";")) {
        const errorKeys = errorKey.split(";");
        if (errorKeys.length > 0) {
            errorKey = errorKeys[0]; // Show the first error.
        }
    }

    return { errorKey, httpStatus };
}

exports.getErrorKeys = getErrorKeys;
