export const ownersTab = "owners";
export const membersTab = "members";

export const dialogVariants = {
    newTeamSpace: "newTeamSpace",
    manageTeamSpace: "manageTeamSpace",
    addApiProject: "addApiProject",
    moveApiProject: "moveApiProject",
};

export const teamSpacePanels = {
    access: "teamSpaceAccessPanel",
    details: "teamSpaceDetailsPanel",
};
