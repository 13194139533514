import { apiTermsName, downloadTermsName, generalTermsName } from '../../../../../shared/constants';
import {
	hasShowPremiumDownloadsPermissionAndCataloguesToShow,
	hasUseOSPlacesPermission,
	hasViewDataPackagesPermission,
	hasShowNgdDownloadsPermission,
	hasShowVernacularNamesPermission,
	hasShowWorkspacePermission,
	hasManageUsersPermission,
	hasManageLicencesPermission,
	hasShowTeamSpacesPermission
} from "../../../../util/permissions";
import { dataHubExamplesUrl, findRoute, osDataHubExplorerUrl, osNgdGitBook, osSelectAndBuildAccess } from '../../../../util/routes';
import messages from '../../MenuMessages';
import features from '../../../../../shared/features';
import { showMatchDocs } from '../../menu-util';
import { isEaiUser } from "../../../../util/plans";
import featureCheck from "../../../../util/featureCheck";
import { userHasEnabledWorkspace } from '../../../../hooks/useTryWorkspace';
import { getUserDetailsDefaultContext } from '../../../../util/organisation';

export const getMainMenu = (userDetails, config) => {
	const userDetailsWithDefaultOrgActive = getUserDetailsDefaultContext(userDetails);
	const dataPackagesMenuItem = {
		label: messages.dataPackages,
		route: findRoute('dataPackages'),
		userCheck: hasViewDataPackagesPermission
	};
	const downloadsMenuItem = {
		label: messages.download, public: true,
		items: [{
			label: messages.premiumDownloads,
			feature: 'premium',
			userCheck: hasShowPremiumDownloadsPermissionAndCataloguesToShow,
			items: [
				{ label: messages.premiumDownloads, route: findRoute('premiumDownloads') },
				{ label: messages.manageLicences, route: findRoute('manageLicences'), userCheck: hasManageLicencesPermission }
			]
		}, {
			label: messages.openDataDownloads, route: findRoute('openDataDownloads'), partialMatch: true
		}, {
			label: messages.sampleDataDownloads, route: findRoute('sampleDataDownloads'), partialMatch: true, feature: features.SAMPLE_DATA,
		}, {
			label: messages.delTerms, route: findRoute('delTerms'), partialMatch: true, feature: features.DEL, hidden: true,
		}, {
			label: messages.delApply, route: findRoute('delApply'), partialMatch: true, feature: features.DEL, hidden: true,
		}, {
			label: messages.osNetRinexData, route: findRoute('osNetRinexData'), partialMatch: true, feature: features.OS_NET_RINEX_DATA,
		}]
	};
	// If NGD is enabled then the data packages menu item appears at the top level.
	if (featureCheck(features.NGD_DOWNLOADS, config)) {
		dataPackagesMenuItem.divider = true;
		downloadsMenuItem.items.splice(1, 0, dataPackagesMenuItem);
		downloadsMenuItem.items.splice(1, 0, {
			label: messages.osSelectBuild,
			feature: features.NGD_DOWNLOADS,
			route: findRoute('recipeLibrary'),
			userCheck: hasShowNgdDownloadsPermission
		});
	} else {
		downloadsMenuItem.items[0].items.unshift(dataPackagesMenuItem);
	}

	const ofaMenuItems = featureCheck(features.NGD_FEATURES, config) ? [{
		label: messages.ngdFeaturesApi,
		public: true,
		items: [
			{ label: messages.overview, route: findRoute('ofaOverview') },
			{ label: messages.gettingStarted, route: findRoute('ofaIntro') },
			{ label: messages.techSpec, route: findRoute('ofaDetail') },
		]
	}] : [];

	const otaMenuItems = featureCheck(features.NGD_TILES, config) ? [{
		label: messages.ngdTilesApi,
		public: true,
		items: [
			{ label: messages.overview, route: findRoute('otaOverview') },
			{ label: messages.gettingStarted, route: findRoute('otaIntro') },
			{ label: messages.techSpec, route: findRoute('otaDetail') },
		]
	}] : [];

	const placesMenuItems = hasUseOSPlacesPermission(userDetailsWithDefaultOrgActive) || featureCheck(features.PREMIUM_OS_PLACES, config) ? [{
		label: messages.placesApi,
		public: true,
		items: [
			{ label: messages.overview, route: findRoute('placesOverview') },
			{ label: messages.gettingStarted, route: findRoute('placesIntro') },
			{ label: messages.techSpec, route: findRoute('placesDetail') }
		]
	}] : [];

	const matchMenuItems = showMatchDocs(userDetailsWithDefaultOrgActive, config) ? [{
		label: messages.matchApi,
		public: true,
		items: [
			{ label: messages.overview, route: findRoute('matchOverview') },
			{ label: messages.gettingStarted, route: findRoute('matchIntro') },
			{ label: messages.techSpec, route: findRoute('matchDetail') }
		]
	}] : [];

	const vnMenu = featureCheck(features.VN, config) ? [{
		label: messages.vernacularNames,
		public: false,
		userCheck: hasShowVernacularNamesPermission,
		route: findRoute('vernacularNames')
	}] : [];

    const workspaceMenuItem = (featureCheck(features.WORKSPACE, config) && userHasEnabledWorkspace(userDetailsWithDefaultOrgActive?.id)) ? [{
        label: messages.workspace,
        userCheck: (userCheckDetails) => {
			const userCheckDetailsDefaultActive = getUserDetailsDefaultContext(userCheckDetails);
			return hasShowWorkspacePermission(userCheckDetailsDefaultActive);
		},
        items: [
			{ label: messages.workspace, route: findRoute('workspace')},
			{
                label: messages.apis,
				composite: true,
                items: [
                    { label: messages.apiProjects, route: findRoute('projects') },
                    { label: messages.transactionHistory, route: findRoute('history') },
                    { label: messages.documentation, route: findRoute('documentation') },
                    { label: messages.codeExamples, externalLink: true, href: dataHubExamplesUrl },
                ]
            },
			{
                label: messages.downloads,
				composite: true,
                items: [
                    { label: messages.openData, route: findRoute('openDataDownloads') },
                    { label: messages.premiumData, route: findRoute('premiumDownloads'), userCheck: hasShowPremiumDownloadsPermissionAndCataloguesToShow },
                    { label: messages.osSelectBuild, externalLink: true, href: osSelectAndBuildAccess, userCheck: hasShowNgdDownloadsPermission },
                    { label: messages.ngdDocs, externalLink: true, href: osNgdGitBook, userCheck: hasShowNgdDownloadsPermission},
                ],
            },
			{
                label: messages.apps,
				composite: true,
                items: [
                    { label: messages.errorsReporting, route: findRoute('errorReporting') },
                    { label: messages.vernacularNames, route: findRoute('vernacularNames'), userCheck: hasShowVernacularNamesPermission },
                    { label: messages.dataExplorer, externalLink: true, href: osDataHubExplorerUrl },
                ],
            },
            {
                label: messages.myTeam, userCheck: hasManageUsersPermission,
				composite: true,
                items: [
                    { label: messages.manageTeamMembers, route: findRoute('manageTeamMembers'), userCheck: hasManageUsersPermission },
                ],
            },
            {
                label: messages.myAccount, route: findRoute('account'),
				composite: true,
            },
        ]
    }] : [];

    const teamSpacesMenuItem  = (featureCheck(features.TEAM_SPACES, config) && hasShowTeamSpacesPermission(userDetails)) ? [{
		label: messages.teamSpaces,
		public: false,
		userCheck: hasShowTeamSpacesPermission,
		route: findRoute("teamSpaces"),
    }] : []

	return {
		label: messages.dataHub,
		items: [
			...workspaceMenuItem,
			...teamSpacesMenuItem,
			{
				label: messages.api,
				public: true,
				items: [
					{
						label: messages.dashboard,
						route: findRoute("dashboard"),
						inline: [{ label: messages.history, route: findRoute("history") }]
					},
					{ label: messages.apiProjects, route: findRoute("projects"), partialMatch: true },
					{ label: messages.apiProducts, route: findRoute("apis") }
				]
			},
			downloadsMenuItem,
			{
				label: messages.documentation,
				public: true,
				items: [
					{ label: messages.documentation, route: findRoute("documentation") },
					...ofaMenuItems,
					...otaMenuItems,
					{
						label: messages.downloadsApi,
						public: true,
						items: [
							{ label: messages.overview, route: findRoute("downloadsOverview") },
							{ label: messages.gettingStarted, route: findRoute("downloadsIntro") },
							{ label: messages.techSpec, route: findRoute("downloadsDetail") }
						]
					},
					{
						label: messages.featuresApi,
						public: true,
						items: [
							{ label: messages.overview, route: findRoute("wfsOverview") },
							{ label: messages.gettingStarted, route: findRoute("wfsIntro") },
							{ label: messages.techSpec, route: findRoute("wfsDetail") }
						]
					},
					{
						label: messages.linkedIdentifiersApi,
						public: true,
						items: [
							{ label: messages.overview, route: findRoute("linkedIdentifiersOverview") },
							{ label: messages.gettingStarted, route: findRoute("linkedIdentifiersIntro") },
							{ label: messages.techSpec, route: findRoute("linkedIdentifiersDetail") }
						]
					},
					{
						label: messages.mapsApi,
						public: true,
						items: [
							{ label: messages.overview, route: findRoute("wmtsOverview") },
							{ label: messages.gettingStarted, route: findRoute("wmtsIntro") },
							{ label: messages.techSpec, route: findRoute("wmtsDetail") }
						]
					},
					...matchMenuItems,
					{
						label: messages.namesApi,
						public: true,
						items: [
							{ label: messages.overview, route: findRoute("namesOverview") },
							{ label: messages.gettingStarted, route: findRoute("namesIntro") },
							{ label: messages.techSpec, route: findRoute("namesDetail") }
						]
					},
					...placesMenuItems,
					{
						label: messages.vectorApi,
						public: true,
						items: [
							{ label: messages.overview, route: findRoute("vtsOverview") },
							{ label: messages.gettingStarted, route: findRoute("vtsIntro") },
							{ label: messages.techSpec, route: findRoute("vtsDetail") }
						]
					},
					{
						label: messages.oauthApi,
						public: true,
						items: [
							{ label: messages.overview, route: findRoute("oauthOverview") },
							{ label: messages.gettingStarted, route: findRoute("oauthIntro") },
							{ label: messages.techSpec, route: findRoute("oauthDetail") }
						]
					},
					{
						label: messages.legal,
						public: true,
						items: [
							{ label: messages.legalOverview, route: findRoute("legalOverview") },
							{ label: messages[apiTermsName], route: findRoute("apiTermsConditions") },
							{ label: messages[generalTermsName], route: findRoute("termsConditions") },
							{ label: messages[downloadTermsName], route: findRoute("downloadTerms") },
							{ label: messages.errorReportingTerms, route: findRoute("errorReportingTerms") },
							{ label: messages.frameworkContractTerms, route: findRoute("frameworkContractTerms") },
							{ label: messages.partnerContracts, route: findRoute("partnerContracts") },
							{ label: messages.trialModeTerms, route: findRoute("trialModeTerms") },
							{ label: messages.sla, route: findRoute("sla") }
						]
					},
					{
						label: messages.brandLogo,
						public: true,
						items: [
							{ label: messages.brandImportance, route: findRoute("brandImportance") },
							{ label: messages.logoOverview, route: findRoute("logoOverview") },
							{ label: messages.logoFullColour, route: findRoute("logoFullColour") },
							{ label: messages.logoWhite, route: findRoute("logoWhite") },
							{ label: messages.copyrightOverview, route: findRoute("copyrightOverview") },
							{ label: messages.logoCopyrightPositioning, route: findRoute("logoCopyrightPositioning") },
							{ label: messages.exclusionZone, route: findRoute("exclusionZone") },
							{ label: messages.minMax, route: findRoute("minMax") },
							{ label: messages.prohibitedUse, route: findRoute("prohibitedUse") }
						]
					}
				]
			},
			{
				label: messages.support,
				public: true,
				items: [
					{
						label: messages.faqs,
						public: true,
						items: [
							{ label: messages.supportAccountApi, route: findRoute("support") },
							{ label: messages.supportPlans, route: findRoute("supportPlans") },
							{ label: messages.supportDownload, route: findRoute("supportDownload") },
							{ label: messages.supportOsSelectAndBuild, route: findRoute("supportOsSelectAndBuild") },
							{ label: messages.supportTeamSpaces, route: findRoute("supportTeamSpaces"), feature: features.TEAM_SPACES }
						]
					},
					{ label: messages.serviceStatus, route: findRoute("serviceStatus") }
				]
			},
			{ label: messages.plans, route: findRoute("plans") },
			{ label: messages.errorsReporting, route: findRoute("errorsAndOmissions") },
			...vnMenu
		]
	};
}
