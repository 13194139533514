const { defineMessages } = require("react-intl");

const removeTeamSpaceResourceErrors = defineMessages({
    removeApiProjectServerError: {
        id: "removeTeamSpaceResourceErrors.removeApiProjectServerError",
        description: "Remove API Project Server Error",
        defaultMessage: "We ran into a problem our end while removing the API Project from the Team Space. Please try again and if the problem persists, please {link}",
    },
});

export default removeTeamSpaceResourceErrors;
