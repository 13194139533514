import { defineMessages } from "react-intl";

const teamSpaceErrorMessages = defineMessages({
    errorGeneric: {
        id: "teamSpaceMessages.errorGeneric",
        description: "Team Space > Error > Generic",
        defaultMessage: "There was a problem accessing the Team Spaces service.",
    },
    errorGenericContent: {
        id: "teamSpaceMessages.errorContent",
        description: "Team Space > Error > Content",
        defaultMessage: "Please try again, and if the problem persists then {link}.",
    },
    errorBadRequest: {
        id: "teamSpaceMessages.errorBadRequest",
        description: "Team Space > Error > Bad Request",
        defaultMessage: "The requested Team Space ID {id} is invalid.",
    },
    errorResNotFound: {
        id: "teamSpaceMessages.errorResNotFound",
        description: "Team Space > Error > Resource Not Found",
        defaultMessage: "The Team Space you are attempting to access either does not exist, is locked, or has been deleted.",
    },
});

export default teamSpaceErrorMessages;
