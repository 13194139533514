import { defineMessages } from "react-intl";

const moveAPIProjectMessages = defineMessages({
    dialogHeading: {
        id: "moveAPIProjectMessages.dialogHeading",
        defaultMessage: "Move API Project",
        description: "Move API Project dialog > Heading",
    },
    closeButtonAriaLabel: {
        id: "moveAPIProjectMessages.closeButtonAriaLabel",
        defaultMessage: "Close",
        description: "Error Notification > Close Button (Aria Label)",
    },
    saveButton: {
        id: "moveAPIProjectMessages.saveButton",
        defaultMessage: "Save",
        description: "Move API Project dialog > Save Button",
    },
    cancelButton: {
        id: "moveAPIProjectMessages.cancelButton",
        defaultMessage: "Cancel",
        description: "Move API Project dialog > Cancel Button",
    },
    dismissButton: {
        id: "moveAPIProjectMessages.dismissButton",
        defaultMessage: "Dismiss",
        description: "Move API Project dialog > Dismiss Button",
    },
    submissionError: {
        id: "moveAPIProjectMessages.submissionError",
        defaultMessage: "We ran into a problem our end whilst moving this API Project. Please try again and if the problem persists, please {link}",
        description: "Move API Project dialog > Submission Error Message",
    },
    noEligibleTeamSpaces: {
        id: "moveAPIProjectMessages.noEligibleTeamSpaces",
        defaultMessage: "There are no eligible Team Spaces in your organisation that you can move this API Project into.",
        description: "Move API Project dialog > No Eligible Team Spaces Error",
    },
});

export default moveAPIProjectMessages;
