import { useState } from "react";
import { useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography, ClickAwayListener, Tooltip, IconButton } from "@mui/material";
import { osColour, DropDownMenu } from "omse-components";
import styled from "@emotion/styled";
import { ReactComponent as InfoIcon } from "../../../components/icons/info-notification.svg";
import MemberInfo from "./MemberInfo";
import { ReactComponent as OverflowIcon } from "../../../components/icons/overflow-menu.svg";
import membersTableMessages from "./MembersTable.msg";
import { ADMIN_ROLE } from "../../../../shared/roles";
import RoleSelect from "./RoleSelect";
import { viewerAccessOnlyPermitted, VIEWER, getRoleSelectValue } from "../util/roleSelection";
import { dialogVariants } from "./constants";
import { useManageTeamSpacePermission } from "../util/useManageTeamSpacePermission";
import { StaticViewerRole } from "./RoleSelectReadOnly";

const StyledTable = styled("table")(
    ({ theme }) => `
    width: 100%;
    border: none;
    border-collapse: collapse;
    margin: ${theme.spacing(2)} 0 0 0;

    & > thead {
        color: ${osColour.neutral.charcoal};
        top: 0;
        position: sticky;
        width: 100%;
        box-shadow: 0 2px 0 ${osColour.neutral.rock};
    }

    & > thead > tr {
        display: flex;
        align-items: center;
    }

    & > thead > tr > th {
        padding: 0 0 0.4em 0;
        font-weight: bold;
        gap: 0.4em;
        display: flex;
        align-items: center;
    }

    & > thead > tr > th:last-of-type {
        padding-right: 5.6em;
    }

    & > thead > tr > th:first-of-type {
        flex-grow: 1;
    }

    & > tbody {
        width: 100%;
        display: block;
        overflow-y: auto;
        min-height: 8dvh;
        max-height: 8dvh;

        @media screen and (min-height: 400px) {
            min-height: 36dvh;
            max-height: 36dvh;
        }

        @media screen and (min-height: 600px) {
            min-height: 38dvh;
            max-height: 38dvh;
        }

        @media screen and (min-height: 750px) {
            min-height: 46dvh;
            max-height: 46dvh;
        }

        @media screen and (min-height: 801px) {
            min-height: 16dvh;
            max-height: 16dvh;
        }

        @media screen and (min-height: 801px) and (max-width: 600px) {
            min-height: 48dvh;
            max-height: 48dvh;
        }

        @media screen and (min-height: 900px) and (max-width: 600px) {
            min-height: 54dvh;
            max-height: 54dvh;
        }

        @media screen and (min-height: 1050px) {
            min-height: 21dvh;
            max-height: 21dvh;
        }

        @media screen and (min-height: 1000px) and (max-width: 600px) {
            min-height: 58dvh;
            max-height: 58dvh;
        }
    }

    & > tbody > tr {
        padding: 0.4em 0.6em 0 0;
        display: flex;
        gap: 1em;
        flex-wrap: none;
        align-items: center;
    }

    & > tbody > tr > td:first-of-type {
        flex-grow: 1;
    }

    ${theme.breakpoints.down("sm")} {
        & > tbody > tr td:first-of-type {
            padding: ${theme.spacing(1, 0, 0.5, 0)}
        }

    }
`,
);

const MemberActions = styled.div(
    ({ theme }) => `
    & svg {
        color: ${osColour.neutral.charcoal}
    }
    & li:first-of-type:not(.Mui-disabled) {
        color: ${osColour.status.error};
    }
    & button {
        padding: ${theme.spacing(1)};
    }
    ${theme.breakpoints.down("sm")} {
        & button {
            padding: ${theme.spacing(0.5)};
        }
    }
`,
);

export default function MembersTable({ setMembers, members, variant }) {
    const intl = useIntl();
    const user = useSelector((state) => state.user.current.result);
    const teamSpaceResult = useSelector(state => state.teamSpaces.getTeamSpace.result);
    const hasManageTeamSpacePermission = useManageTeamSpacePermission(user, teamSpaceResult);
    const isManageAccessView = (variant === dialogVariants.newTeamSpace || hasManageTeamSpacePermission);

    const createTeamSpaceWorking = useSelector((state) => state.teamSpaces.createTeamSpace.working);

    const [infoTooltipVisible, setInfoTooltipVisible] = useState(false);

    const removeMember = (member) => {
        if (member?.datahubDeveloperId) {
            setMembers(
                members.filter(
                    (existing) => existing.datahubDeveloperId !== member.datahubDeveloperId,
                ),
            );
        }
    };

    const handleRoleSelectSetMembers = membersResult => {
        setMembers(membersResult);
    }

    return (
        <StyledTable>
            <thead>
                <tr>
                    <Typography variant="body1" component="th">
                        <FormattedMessage {...membersTableMessages.membersTableHeading} />
                        <ClickAwayListener onClickAway={() => setInfoTooltipVisible(false)}>
                            <Tooltip
                                open={infoTooltipVisible}
                                onClose={() => setInfoTooltipVisible(false)}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                arrow
                                placement="bottom-start"
                                title={
                                    <>
                                        <FormattedMessage
                                            {...membersTableMessages.membersHelpViewer}
                                            values={{
                                                strong: (chunks) => <strong>{chunks}</strong>,
                                            }}
                                        />
                                        <br aria-hidden="true" />
                                        <br aria-hidden="true" />
                                        <FormattedMessage
                                            {...membersTableMessages.membersHelpEditor}
                                            values={{
                                                strong: (chunks) => <strong>{chunks}</strong>,
                                            }}
                                        />
                                    </>
                                }
                            >
                                <IconButton
                                    size="small"
                                    onClick={() => setInfoTooltipVisible(true)}
                                >
                                    <InfoIcon
                                        width={24}
                                        height={24}
                                        color={osColour.status.warning}
                                    />
                                </IconButton>
                            </Tooltip>
                        </ClickAwayListener>
                    </Typography>
                    <Typography variant="body1" component="th" colSpan={2}>
                        <FormattedMessage {...membersTableMessages.membersTableAPIsHeading} />
                    </Typography>
                </tr>
            </thead>
            <tbody>
                {members.length > 0 &&
                    members
                        .filter((m) => m.role !== ADMIN_ROLE)
                        .sort((a) => (a.datahubDeveloperId - user.datahubDeveloperId))
                        .map((member) => (
                            <tr key={member.datahubDeveloperId}>
                                <td>
                                    <Typography component="div">
                                        <MemberInfo info={member} user={user} />
                                    </Typography>
                                </td>
                                <td>
                                    {viewerAccessOnlyPermitted(member) ? (
                                        <StaticViewerRole>
                                            <FormattedMessage {...membersTableMessages.viewer} />
                                        </StaticViewerRole>
                                    ) : (
                                        <RoleSelect
                                            initialRole={(variant === dialogVariants.newTeamSpace) ? VIEWER : getRoleSelectValue(member)}
                                            members={members}
                                            member={member}
                                            setMembers={handleRoleSelectSetMembers}
                                            readOnly={!isManageAccessView}
                                        />
                                    )}
                                </td>
                                <td>
                                    {isManageAccessView &&
                                        <MemberActions>
                                            <DropDownMenu
                                                disabled={createTeamSpaceWorking}
                                                icon={<OverflowIcon height={24} width={24} />}
                                                items={[
                                                    {
                                                        label: intl.formatMessage(
                                                            membersTableMessages.remove,
                                                        ),
                                                        value: intl.formatMessage(
                                                            membersTableMessages.remove,
                                                        ),
                                                        action: () => removeMember(member),
                                                    },
                                                ]}
                                                placement="bottom-end"
                                            />
                                        </MemberActions>
                                    }
                                </td>
                            </tr>
                        ))}
            </tbody>
        </StyledTable>
    );
}
