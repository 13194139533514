import { defineMessages } from "react-intl";

const teamSpacesRolesTableMessages = defineMessages({
    permissions: {
        id: "teamSpacesRolesTableMessages.permissions",
        description: "Label for the Permissions column",
        defaultMessage: "Permissions",
    },
    dataHubAdmin: {
        id: "teamSpacesRolesTableMessages.dataHubAdmin",
        description: "Label for the Data Hub Admin column",
        defaultMessage: "Data Hub Admin",
    },
    financeUser: {
        id: "teamSpacesRolesTableMessages.financeUser",
        description: "Label for the Finance User column",
        defaultMessage: "Finance User",
    },
    user: {
        id: "teamSpacesRolesTableMessages.user",
        description: "Label for the User column",
        defaultMessage: "User",
    },
    contractorReadWrite: {
        id: "teamSpacesRolesTableMessages.contractorReadWrite",
        description: "Label for the Contractor Read Write column",
        defaultMessage: "Contractor (Read/Write)",
    },
    contractorReadOnly: {
        id: "teamSpacesRolesTableMessages.contractorReadOnly",
        description: "Label for the Contractor Read Only column",
        defaultMessage: "Contractor (Read-Only)",
    },
    owner: {
        id: "teamSpacesRolesTableMessages.owner",
        description: "Label for the Owner row",
        defaultMessage: "Owner",
    },
    editor: {
        id: "teamSpacesRolesTableMessages.editor",
        description: "Label for the Editor row",
        defaultMessage: "Editor",
    },
    viewer: {
        id: "teamSpacesRolesTableMessages.viewer",
        description: "Label for the Viewer row",
        defaultMessage: "Viewer",
    },
    checkAria: {
        id: "teamSpacesRolesTableMessages.checkAria",
        description: "Aria-label for a check icon",
        defaultMessage: "Yes",
    },
    crossAria: {
        id: "teamSpacesRolesTableMessages.crossAria",
        description: "Aria-label for a cross icon",
        defaultMessage: "No",
    },
});

export default teamSpacesRolesTableMessages;