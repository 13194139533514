import { Tabs } from "@mui/material";
import styled from "@emotion/styled";
import { osColour } from "omse-components";

export const StyledTabs = styled(Tabs)`
    margin-bottom: 1.4em;
    border-bottom: solid 1px ${osColour.neutral.mist};

    & > div > div > button {
        display: flex;
        flex-direction: row;
        place-items: center;
        gap: 0.2em;
        font-size: 1em;
        margin: 0.4em 0.6em 0.6em 0.4em;
        padding: 0 0.4em;
        min-height: 2.2em;
    }

    & > div > div > button > svg {
        width: 0.7em;
        height: 0.7em;
        flex-shrink: 0;
    }

    & > div > div > button > svg.teamLocked {
        color: ${osColour.status.error};
    }

    & > div > div > button > svg.teamUnlocked {
        color: ${osColour.status.success};
    }

    & > div > div > button > svg {
        height: 0.8em;
    }

    & > div > .indicator {
        background: ${osColour.primary.berry};
        box-shadow: -0.4em 0 ${osColour.primary.berry}, 0.4em 0 ${osColour.primary.berry};
    }
`;
