import {useForm} from "@tanstack/react-form";
import {
    Checkbox,
    DialogContent,
    MenuItem,
    TextField
} from "@mui/material";
import {createUseStyles} from "react-jss";
import PropTypes from 'prop-types';
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import Button from "@mui/material/Button";
import classNames from "classnames";
import Typography from "@mui/material/Typography";
import { ExternalLink } from "omse-components";
import { privacyPolicyAddress } from "../../../util/routes";

const messages = defineMessages({
    usageIntentionsDropdownTitle:{
        id: 'DelApplyFormUsage.companyNameTitle',
        defaultMessage: 'What do you plan to do with the data?',
        description: 'Usage intent drop down title'
    },
    usageIntentionsDropdownRequired:{
        id: 'DelApplyFormUsage.usageIntentionsDropdownRequired',
        defaultMessage: "Please provide your plans for usage of the data",
        description: 'Error message required data usage field'
    },
    businessImprovement:{
        id: 'DelApplyFormUsage.businessImprovement',
        defaultMessage: 'To investigate how it could help my business run better',
        description: 'Business Improvement Option Text'
    },
    productOrService :{
        id: 'DelApplyFormUsage.productOrService',
        defaultMessage: 'To investigate using the data in a commercial product or service',
        description: 'development of product or service option text'
    },
    softwareDevelopment:{
        id: 'DelApplyFormUsage.softwareDevelopment',
        defaultMessage: 'To develop compatible software',
        description: 'software development option text'
    },
    businessProblem :{
        id: 'DelApplyFormUsage.businessProblem',
        defaultMessage: 'To use the data to fix a problem within my business',
        description: 'Usage option fix a problem within business text'
    },
    acceptTermsMessage:{
        id: 'DelApplyFormUsage.acceptedTermsMessage',
        defaultMessage: 'I have read and understand the Data Exploration Licence and agree to the terms that will apply during and beyond the trial',
        description: 'Accept Terms message'
    },
    acceptTermsLabel:{
        id: 'DelApplyFormUsage.acceptTermsLabel',
        defaultMessage: 'Check this box to accept the terms of the del license',
        description: 'Accept Terms message'
    },
    iBUMessage:{
        id: 'DelApplyFormUsage.iBUMessage',
        defaultMessage: 'I am intending to use the data for internal business use only',
        description: 'Internal business use message'
    },
    iBULabel:{
        id: 'DelApplyFormUsage.iBULabel',
        defaultMessage: 'Check this box if you are using the data for internal business use only',
        description: 'Accept Terms message'
    },
    fullDelTermsMessage:{
        id: 'DelApplyFormUsage.fullDelTermsMessage',
        defaultMessage: '<a>Read the full Data Exploration Licence Terms here before you continue</a>',
        description: 'View Terms link message'
    },
    fullDelTermsMessageLabel:{
        id: 'DelApplyFormUsage.fullDelTermsMessageLabel',
        defaultMessage: 'Click to read the full terms of the Data Exploration Licence',
        description: 'View Terms link message label'
    },
    acceptTermsRequiredMessage:{
        id: 'DelApplyFormUsage.acceptTermsRequiredMessage',
        defaultMessage: 'To apply for the Data Exploration Licence you must accept the terms',
        description: 'Accept Terms required error message'
    },
    privacyPolicyMessage:{
        id: 'DelApplyFormUsage.privacyPolicyMessage',
        defaultMessage: 'All the information you provide will be used in accordance with our <a>privacy policy</a>.',
        description: 'Privacy policy message with link'
    },
    privacyPolicyMessageLabel:{
        id: 'DelApplyFormUsage.privacyPolicyMessageLabel',
        defaultMessage: 'Click to view privacy policy.',
        description: 'View Privacy policy message lable'
    },
    nextButtonText :{
        id: 'DelApplyFormUsage.nextButtonText',
        defaultMessage: 'Next',
        description: 'Next Button Text'
    },
    previousButtonText :{
        id: 'DelApplyFormUsage.previousButtonText',
        defaultMessage: 'Previous',
        description: 'Previous Button Text'
    }
});

const useStyles = createUseStyles(theme => ({
    formContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    },
    formContentContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1.5),
        padding: theme.spacing(1.5),
        minWidth: theme.spacing(50),
        maxWidth: theme.spacing(75)
    },
    errorText: {
        color: "#c63d54",
        fontWeight: 400,
        fontSize: "0.875rem"
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: theme.spacing(1.5),
        '& button': {
            width: theme.spacing(10)
        }
    },
    checkboxContainer: {
        display: 'flex',
        gap: theme.spacing(1)
    },
    errorField: {
        backgroundColor: "#faeef1"
    },
    middleCheckBoxSpacer: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5)
    }
}));

// Default form values when there is no user
const defaultFormValues = {
    usageType: '',
    acceptedTerms: false,
    internalBusinessUse: false
};

export function DelApplyFormUsage({usageInfo, setUsageInfo, setFormIndex}) {
    const classes = useStyles();
    const intl = useIntl();
    const form = useForm({
        defaultValues: usageInfo ? usageInfo : defaultFormValues,
        onSubmit: async ({value}) => {
            setUsageInfo(value);
            setFormIndex(2);
        }
    });

    // ToDo: Add terms link for del once it's been created
    const termsLink = "http://www.os.uk"
    const privacyPoliceLink = privacyPolicyAddress;

    const errorMessage = ({message}) => {
        return <span aria-live={"polite"} role={"alert"} className={classes.errorText}>{message}</span>
    }

    return (
        <div className={classes.formContainer}>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    void form.handleSubmit();
                }}>
                <DialogContent className={classes.formContentContainer}>
                    <form.Field
                        name="usageType"
                        validators={{
                            onBlur: ({ value }) => {
                                if (value === "") return intl.formatMessage(messages.usageIntentionsDropdownRequired);
                            }
                        }}
                        children={field => (
                            <TextField
                                select
                                id={field.name}
                                required={true}
                                className={classes.textField}
                                label={intl.formatMessage(messages.usageIntentionsDropdownTitle)}
                                defaultValue={""}
                                value={field.state.value}
                                onChange={(e) => {
                                    field.handleChange(e.target.value);
                                }}
                                onBlur={field.handleBlur}
                                error={field.state.meta.errors.length > 0}
                                helperText={errorMessage({ message: field.state.meta.errors })}>
                                <MenuItem value="To investigate how it could help my business run better">
                                    <FormattedMessage {...messages.businessImprovement} />
                                </MenuItem>
                                <MenuItem value="To investigate using the data in a commercial product or service">
                                    <FormattedMessage {...messages.productOrService} />
                                </MenuItem>
                                <MenuItem value="To develop compatible software">
                                    <FormattedMessage {...messages.softwareDevelopment} />
                                </MenuItem>
                                <MenuItem value="To use the data to fix a problem within my business">
                                    <FormattedMessage {...messages.businessProblem} />
                                </MenuItem>
                            </TextField>
                        )} />

                     <div className={classes.middleCheckBoxSpacer}>
                        <form.Field
                            name="internalBusinessUse"
                            children={field => (
                                <div className={classNames(classes.checkboxContainer, classes.formContentItem)}>
                                    <Checkbox
                                        aria-label={intl.formatMessage(messages.iBULabel)}
                                        checked={field.state.value}
                                        onChange={(e) => field.handleChange(e.target.checked)}
                                        onBlur={field.handleBlur}
                                    />
                                    <Typography onClick={() => field.handleChange(!field.state.value)}>
                                        <FormattedMessage {...messages.iBUMessage} values={{
                                            a: chunks => (
                                                <ExternalLink
                                                    type="generic"
                                                    href={termsLink}
                                                    message={chunks}
                                                />
                                            )
                                        }} />
                                    </Typography>
                                </div>
                            )}
                        />
                     </div>



                        <Typography>
                            <FormattedMessage {...messages.fullDelTermsMessage} values={{
                                a: chunks => (
                                    <ExternalLink
                                        ariaLabel={intl.formatMessage(messages.fullDelTermsMessageLabel)}
                                        type="generic"
                                        href={termsLink}
                                        message={chunks[0]}
                                    />
                                )
                            }} />
                        </Typography>

                        <form.Field
                            validators={{
                                onChange: ({ value }) => {
                                    if (!value) return intl.formatMessage(messages.acceptTermsRequiredMessage);
                                }
                            }}
                            name="acceptedTerms"
                            children={field => (
                                <div
                                    className={classNames(field.state.meta.errors.length > 0 && classes.errorField, classes.formPadding)}>
                                    <div className={classNames(classes.checkboxContainer, classes.formContentItem)}>
                                        <Checkbox
                                            aria-label={intl.formatMessage(messages.acceptTermsLabel)}
                                            checked={field.state.value}
                                            onChange={(e) => field.handleChange(e.target.checked)}
                                            onBlur={field.handleBlur}
                                            required={true}
                                        />
                                        <Typography onClick={() => field.handleChange(!field.state.value)}>
                                            <FormattedMessage {...messages.acceptTermsMessage} values={{
                                                a: chunks => (
                                                    <ExternalLink
                                                        type="generic"
                                                        href={termsLink}
                                                        message={chunks}
                                                    />
                                                )
                                            }} />
                                        </Typography>
                                    </div>
                                    {field.state.meta.errors.length > 0 && errorMessage({ message: field.state.meta.errors })}
                                </div>
                            )}
                        />
                        <Typography>
                            <FormattedMessage {...messages.privacyPolicyMessage} values={{
                                a: chunks => (
                                    <ExternalLink
                                        ariaLabel={intl.formatMessage(messages.privacyPolicyMessageLabel)}
                                        type="generic"
                                        href={privacyPoliceLink}
                                        message={chunks[0]}
                                    />
                                )
                            }} />
                        </Typography>
                </DialogContent>
                <div className={classes.buttonContainer}>
                    <Button variant="outlined" onClick={() => {
                        setFormIndex(0)
                    }}>
                        <FormattedMessage {...messages.previousButtonText} />
                    </Button>
                    <Button variant="contained" type="submit">
                        <FormattedMessage {...messages.nextButtonText} />
                    </Button>
                </div>
            </form>
        </div>
    )
}

DelApplyFormUsage.propTypes = {
    setShowForm: PropTypes.func
}