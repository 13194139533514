import React, {useState} from 'react';
import { createUseStyles } from "react-jss";
import PropTypes from 'prop-types';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import {defineMessages, FormattedMessage} from 'react-intl';
import {formatBytes} from '../../../../modules/downloads';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import {ReactComponent as SaveAlt} from "../../../../components/icons/download.svg";
import {Dialog} from "@mui/material";
import {SampleDataForm} from "./SampleDataForm";
import Button from "@mui/material/Button";
import {useSelector} from "react-redux";

const messages = defineMessages({
    downloadLink: {
        id: 'SampleDataDownloadsItem.downloadLink',
        defaultMessage: '{name}{fileInfo, select, false {} other { ({fileInfo})}} - {format} {tile} ({size})',
        description: 'Download link text'
    },
    links: {
        id: 'SampleDataDownloadsItem.links',
        defaultMessage: 'Direct {data, plural, one {link} zero {link} other {links}}:',
        description: 'Link label'
    },
    button: {
        id: 'SampleDataDownloadsItem.button',
        defaultMessage: 'Fill in form and download',
        description: 'Form button label'
    },
});

const useStyles = createUseStyles(theme => ({
    downloadLink: {
        padding: 0,
        '& svg': {
            marginRight: theme.spacing(1),
            position: 'relative',
            top: -5,
            flex: '0 0 auto'
        },
        [theme.breakpoints.down('md')]: {
            '&:not(:last-child)': {
                marginBottom: 15
            }
        }
    },
    downloadLinks: {
        padding: '7px 0 0 0',
        [theme.breakpoints.down('md')]: {
            padding: 0
        }
    },
    linksLabel: {
        color: theme.palette.text.primary,
        margin: '20px 0 7px 0'
    }
}));

export default function SampleDataLinks(props) {
    let {name, format, showLabel, productId, getFormatLabel, filteredDownloads} = props;

    const classes = useStyles();
    const [showForm, setShowForm] = useState(false);
    const [downloads, setDownloads] = useState([]);

    // if we've been provided with download links already use those
    if(!!filteredDownloads?.length && !downloads.length){
        setDownloads(filteredDownloads);
    }

    const user = useSelector(state => state.user.current.result);
    return (
        <>
            <List className={classes.downloadLinks}>
                {showLabel &&
                    <Typography variant='body1' className={classNames(classes.stageLabels, classes.linksLabel)}>
                        <FormattedMessage {...messages.links} values={{data: downloads.length}}/>
                    </Typography>
                }
                {!downloads.length ?
                    <Button variant='outlined' onClick={() => setShowForm(true)}>
                        <FormattedMessage {...messages.button} />
                    </Button> :
                    downloads
                        .filter(download => format === getFormatLabel(download))
                        .map( (dl, idx) => <ListItem key={idx} className={classes.downloadLink}>
                                <ListItemText>
                                    <a href={dl.url} download>
                                        <SaveAlt width={24}/>
                                        <FormattedMessage {...messages.downloadLink} values={{
                                            name: name,
                                            fileInfo: false,
                                            format: format,
                                            tile: dl.area,
                                            size: formatBytes(dl.size)
                                        }}/>
                                    </a>
                                </ListItemText>
                            </ListItem>
                        )
                }
            </List>
            <Dialog open={showForm} onClose={() => setShowForm(false)}>
                <SampleDataForm setShowForm={setShowForm} user={user} productId={productId} setDownloads={setDownloads}/>
            </Dialog>
        </>
    );
}

SampleDataLinks.propTypes = {
    classes: PropTypes.object.isRequired,
    downloads: PropTypes.arrayOf(PropTypes.object),
    name: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
    showLabel: PropTypes.bool
};

SampleDataLinks.defaultProps = {
    downloads: [],
    showLabel: false
};
