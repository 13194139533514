import React from "react";
import styled from "@emotion/styled";
import { FormattedMessage } from "react-intl";
import { Typography } from "@mui/material";
import { osColour } from "omse-components";
import emptyStateMessages from "./EmptyState.msg";
import NewTeamSpace from "./NewTeamSpace";
import { FolderOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { isContractor } from "./util/teamSpaceUser";

const Container = styled.div`
    & h2 {
        font-size: 1.3em;
        margin-bottom: 0.4em;
    }
    & > div:first-of-type {
        margin-bottom: 5em;
    }
    & > div:first-of-type > p {
        margin-bottom: 2em;
    }
`;

const EmptyNotice = styled.div(
    ({ theme }) => `
    margin-top: 2em;
    text-align: center;
    .folder {
        width: 80px;
        height: 80px;
        color: ${osColour.primary.berry};
        margin-bottom: 1em;
    }
    ${theme.breakpoints.down("sm")} {
        padding: 0;
        .folder {
            margin-bottom: 1em;
        }
    }
`,
);

const TeamSpaceGuide = styled.div`
    padding: 1.6em;
    background: ${osColour.primary.lightestBerry};
    border: 1px solid ${osColour.neutral.mist};
    border-radius: 0.4em;
`;

function EmptyState() {
    const user = useSelector((state) => state.user.current.result);
    const contractor = user && isContractor(user);

    return (
        <Container>
            <EmptyNotice>
                <FolderOutlined className="folder" />
                <Typography variant="h2">
                    <FormattedMessage
                        {...(contractor
                            ? emptyStateMessages.title1Contractor
                            : emptyStateMessages.title1Generic)}
                    />
                </Typography>
                <Typography variant="body1">
                    <FormattedMessage
                        {...(contractor
                            ? emptyStateMessages.body1Contractor
                            : emptyStateMessages.body1Generic)}
                        values={{ br: <br aria-hidden="true" /> }}
                    />
                </Typography>
                {!contractor && (
                    <NewTeamSpace buttonMessage={emptyStateMessages.createTeamSpaceButton} />
                )}
            </EmptyNotice>
            <TeamSpaceGuide>
                <Typography variant="h2">
                    <FormattedMessage {...emptyStateMessages.title2} />
                </Typography>
                <Typography variant="body1">
                    <FormattedMessage
                        {...(contractor
                            ? emptyStateMessages.body2Contractor
                            : emptyStateMessages.body2Generic)}
                    />
                </Typography>
            </TeamSpaceGuide>
        </Container>
    );
}

export default EmptyState;
