// https://stackoverflow.com/a/18650828/388951
export function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 B';

    const k = 1000,
        dm = decimals,
        sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
        i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
}

// Callbacks to restructure the download API responses, to make it easier for the UI to consume
function reformatDownload(download) {
    download.readableSize = formatBytes(download.size)
    return download;
}

async function reformatProduct(product, sample) {
    if(product) {
        let formats = [];
        let squares = false;
        product.formats && product.formats.forEach(f => {
            let format = f.format;
            if(formats.indexOf(format) === -1) {
                formats.push(format);
            }
        });
        if(product.areas && !product.areas.every(area => area === 'GB' || area === 'NI' || area === 'UK')) {
            squares = true;
        }

        // metadata
        product.customAreaEnabled = squares;
        product.dataStructures = product.dataStructures && product.dataStructures.sort();
        product.supplyFormats = formats.sort();
        product.images = [];

        if (product.imageTemplate && product.imageCount) {
            if(sample){
                product.images = await fetchSampleImages(product.id);
            }else{
                getOpenImages(product);
            }
        }
    }
    return product;
}

function getOpenImages(product){
    for(let i = 0; i < product.imageCount; i++) {
        let imageUrl = product.imageTemplate.replace('{index}', i);
        product.images.push({small: imageUrl, large: imageUrl + '?large'});
    }
}

// OpenData Products
export async function fetchCatalogue(downloadsEndpoint) {
    const resp = await fetch(`${downloadsEndpoint}/products?expanded`);
    const json = await resp.json();
    if (!resp.ok) {
        throwFetchError("Error fetching product", {...json, status: resp.status});
    }
    return Promise.all(json.map(async (product) => {
        return await reformatProduct(product);
    })).then( result => result);
};

export async function fetchProduct(downloadsEndpoint, id) {
    const resp = await fetch(`${downloadsEndpoint}/products/${id}`);
    if (!resp.ok) {
        throwFetchError("Error fetching product",{...await resp.json(), status: resp.status, id});
    }
    return resp.json().then(result => reformatProduct(result));
};

export async function fetchDownloads(downloadsEndpoint, id) {
    const resp = await fetch(`${downloadsEndpoint}/products/${id}/downloads`);
    if (!resp.ok) {
        throwFetchError("Error fetching product downloads", {...await resp.json(), status: resp.status, id});
    }
    return resp.json().then(
        (result) => {
            result = result.map(reformatDownload);
            result.id = id;
            return result;
        });
};

// SampleData Products
export async function fetchSampleCatalogue () {
    const resp = await fetch(`/api/sample/products`);
    const json = await resp.json();
    if (!resp.ok) {
        throwFetchError("Error fetching catalogue", {...json, status: resp.status});
    }
    return Promise.all(json.map(async (product) => {
        return await reformatProduct(product, true);
    })).then( result => result);
};

export async function fetchSampleProduct(id) {
    const resp = await fetch(`/api/sample/products/${id}`);
    const json = await resp.json();
    if (!resp.ok) {
        throwFetchError("Error fetching product", {...json, status: resp.status, id});
    }
    return reformatProduct(json, true).then(result => result);
};

export async function fetchSampleDownloads(id) {
    const resp = await fetch(`/api/sample/products/${id}/downloads`);
    if (!resp.ok) {
        throwFetchError("Error fetching product downloads", {...await resp.json(), status: resp.status, id});
    }
    return resp.json().then(
        (result) => {
            result = result.map(reformatDownload);
            result.id = id;
            return result;
        }
    );
};

export async function fetchSampleImages(id) {
    const resp = await fetch(`/api/sample/products/${id}/images`);
    if (!resp.ok) {
        throwFetchError("Error fetching Image", {...await resp.json(), status: resp.status, id});
    }
    return resp.json().then(result => result);
};

export async function postLead(leadDetails, productId){
    const resp = await fetch(`/api/sample/acceptTerms/${productId}`, {
        method: "POST",
        body: JSON.stringify(leadDetails),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    if (!resp.ok) {
        throwFetchError("Error posting lead information", {...await resp.json(), status: resp.status});
    }
    return resp.json().then(result => result);
};

export async function postDelApplication(applicationDetails){
    const resp = await fetch(`/api/del/apply`, {
        method: "POST",
        body: JSON.stringify(applicationDetails),
        headers: {
            'Content-Type': 'application/json'
        }
    });
    if (!resp.ok) {
        throwFetchError("Error posting lead information", {...await resp.json(), status: resp.status});
    }
    return resp.json().then(result => result);
};


function throwFetchError(message, info){
    const error =  Error( message);
    throw Object.assign(error, info);
}



