import React, { useCallback, useEffect, useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useHistory, useParams} from 'react-router-dom';
import {defineMessages} from 'react-intl';
import { createUseStyles } from 'react-jss';
import {deleteProject, getProject, regenerateKey} from "../modules/project/actions";
import {getProjectStats} from '../modules/stats/actions'
import {getProducts} from "../modules/products";
import {getPaymentConfigData} from "../modules/payments/actions";
import Product from './apiProject/Product';
import routes, {getLocation} from "../util/routes";
import APIKey from './apiProject/APIKey';
import EmptyProject from './apiProject/EmptyProject';
import ProjectName from './apiProject/ProjectName';
import DeleteProjectDialog from './apiProject/DeleteProjectDialog';
import RegenerateKeyDialog from './apiProject/RegenerateKeyDialog';
import AddAPIDialog from '../components/AddAPIDialog';
import BarGraph from "../components/BarGraph";
import {AddButton, DropDownMenu, border1, contentPadding, theme} from "omse-components";
import StatsTimestamp from '../components/StatsTimestamp';
import BackLink from '../components/BackLink';
import {generateTitle} from '../util/titles';
import ProjectMode from "../components/ProjectMode";
import {USER_PREMIUM_DATA_PLAN, USER_PSGA_PLAN} from '../../shared/plans';
import {
    OPEN,
    PREMIUM_FREE,
    PREMIUM_CHARGEABLE,
    statsLabels,
    statsMessages,
    PSGA,
    INTERNAL,
    PREMIUM_FREE_SECONDARY
} from '../components/barGraph/styles/graph.js';
import {hasManageProjectsPermission} from "../util/permissions";
import {CircularProgress} from '@mui/material';
import {withOrganisationMessage} from '../util/organisation';
import {setOrgFromSearch} from '../modules/organisation/action';
import {keyBy} from 'lodash';
import classNames from 'classnames';
import {TEAM_SPACES} from '../../shared/features';
import featureCheck from "../util/featureCheck";
import { getTeamSpaces } from '../modules/teamSpaces/actions';
import { API_PROJECT_PERMISSION_GROUPS } from '../../shared/teamSpaces';
import { isOrgAdmin } from './teamSpaces/util/teamSpaceUser';

const messages = defineMessages({
    projectLink: {
        id: 'APIProject.projectLink',
        defaultMessage: 'All projects',
        description: 'Label for the link that navigates back to the project list'
    },
    orgProjectLink: {
        id: 'APIProject.orgProjectLink',
        defaultMessage: '{org} projects',
        description: 'Label for the link when an org is selected'
    },
    personalLink: {
        id: 'APIProject.personalLink',
        defaultMessage: 'My Personal projects',
        description: 'Label for the link when an org is personal'
    },
    originLink: {
        id: 'APIProject.originLink',
        defaultMessage: '{origin} projects',
        description: 'Label for the link that navigates back to the origin of project'
    },
    actions: {
        id: 'APIProject.actions',
        defaultMessage: 'Actions',
        description: 'Label for actions drop-down menu'
    },
    deleteProject: {
        id: 'APIProject.delete',
        defaultMessage: 'Delete this project',
        description: 'Label for the Delete this project option'
    },
    regenerateApiKey: {
        id: 'APIProject.regenerateKey',
        defaultMessage: 'Regenerate API Key',
        description: 'Label for the regenerate API key option'
    },
    addAPIButton: {
        id: 'APIProject.addAPIButton',
        defaultMessage: 'Add API',
        description: 'Add API button text'
    },
    addAPILabel: {
        id: 'APIProject.addAPILabel',
        defaultMessage: 'Add API to this project',
        description: 'Add API to project title'
    }
});

const useStyles = createUseStyles((theme) => {
    return {
        root: {
            paddingTop: contentPadding.top - contentPadding.backLink,
            paddingRight: contentPadding.right,
            paddingBottom: theme.spacing(6),
            paddingLeft: contentPadding.left,
            borderBottom: border1,
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile
            }
        },
        products: {
            flex: '1 1 auto',
            paddingLeft: contentPadding.left,
            paddingRight: contentPadding.right,
            [theme.breakpoints.down('sm')]: {
                padding: contentPadding.mobile,
                paddingTop: 0,
                paddingBottom: 0
            }
        },
        heading: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            alignItems: 'flex-start',
            // Similar to the overall max, but a bit bigger to give the buttons some room
            maxWidth: contentPadding.maxWidth + contentPadding.left * 3
        },
        headerWidth: {
            maxWidth: contentPadding.maxWidth
        },
        marginLeft: {
            marginLeft: theme.spacing(2)
        },
        actions: {
            display: 'flex',
            flex: 'none'
        },
        projectMetadata: {
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: theme.spacing(2),
            '& > div': {
                flex: 'none',
                alignItems: 'center',
                marginBottom: 0,
                marginRight: theme.spacing(0.5)
            },
            '& > div:last-of-type': {
                marginRight: 0
            }
        },
        statsTimestamp: {
            height: theme.spacing(4.5),
            padding: '6px 0',
            '& > p': {
                margin: 0
            }
        },
        loader: {
            margin: theme.spacing(6) + ' 0'
        }
    };
})

const APIProject = () => {
    const classes = useStyles({ theme})
    const location = useLocation()
    const history = useHistory()
    const dispatch = useDispatch()
    const { projectId } = useParams();

    const [deleting, setDeleting] = useState(false);
    const [regenerate, setRegenerate] = useState(false);
    const [adding, setAdding] = useState(false);

    const project = useSelector((state) => state.project.current.result);
    const config = useSelector((state) => state.config.current.result);

    const projectError = useSelector((state) => state.project.current.error);
    const loading = useSelector((state) => state.project.current.loading || state.products.current.loading || featureCheck(TEAM_SPACES, config) && state.teamSpaces.getTeamSpaces.loading);
    const stats = useSelector((state) => state.stats.project);
    const products = useSelector((state) => state.products.current.result);
    const userDetails = useSelector((state) => state.user.current.result);
    const org = useSelector((state) => state.organisation.current);
    const {result: paymentResult, loading: paymentLoading, error: paymentError} = useSelector((state) => state.payments.config);
    const vatRate = paymentResult?.vatRate;

    const linkLabel = withOrganisationMessage(messages.projectLink, messages.orgProjectLink, messages.personalLink, userDetails, org);
    const name = location?.state?.projectName || project?.name

    const teamSpacesResult = useSelector((state) => state.teamSpaces.getTeamSpaces.result);

    const teamSpaceReadWriteRole = useMemo(() => {
        const teamSpaceWithProject = project && teamSpacesResult?.find(teamSpace =>
                teamSpace?.teamResources?.apiProjects.some(apiProject =>
                apiProject.apiProjectId === project?.id
            )
        );
        return isOrgAdmin(userDetails) || teamSpaceWithProject?.userRoles?.apiProjectRole === API_PROJECT_PERMISSION_GROUPS.ApiProjectEditor
    }, [teamSpacesResult, project]);

    const canManageProject = useMemo(() => {
        if (featureCheck(TEAM_SPACES, config)) {
            return hasManageProjectsPermission(userDetails) && teamSpaceReadWriteRole;
        } else {
            return hasManageProjectsPermission(userDetails);
        }
    }, [userDetails, teamSpaceReadWriteRole]);

    useEffect(() => {
        dispatch(setOrgFromSearch());
        dispatch(getProducts());
        if(featureCheck(TEAM_SPACES, config)) {
            if (!teamSpacesResult) {
                dispatch(getTeamSpaces());
            }
        }
    }, [dispatch, teamSpacesResult]);

    useEffect(() => {
        dispatch(getProject(projectId));
        dispatch(getProjectStats(projectId));
    }, [dispatch, projectId]);

    useEffect(() => {
        if (!vatRate && !paymentLoading && !paymentError) {
            dispatch(getPaymentConfigData());
        }
    }, [dispatch,vatRate, paymentLoading, paymentError]);

    useEffect(() => {
        if (projectError) {
            const newLocation = getLocation(routes.error404Projects, location);
            if(location.pathname !== newLocation.pathname) {
                history.push(newLocation);
            }
        }
    }, [projectError, history, location]);
    
    useEffect(() => {
        if (project?.name) {
            const titledLocation = { ...location, state: { title: project.name } };
            document.title = generateTitle(titledLocation);
        }
    }, [project?.name, location]);

    const confirmationAction = useCallback(() => {
        dispatch(regenerateKey(project?.id));
        setRegenerate(false);
    }, [dispatch, project?.id]);

    const deleteConfirmed = () => {
        dispatch(deleteProject(project?.id));
    };

    const productPageState = () => {
        const statsResult = stats.result;

        if (!project || !products) {
            return null;
        }
        if (project?.products && project?.products.length && products) {
            const productsByName = keyBy(products, 'name');
            return project.products.map((product) => {
                const { name, displayName, ...otherProductProps } = productsByName[product.id];
                Object.assign(product, otherProductProps);
                let productStats = -1;
                let projectTotalUsage = undefined;
                if (statsResult?.timestamp) {
                    projectTotalUsage = statsResult.open + statsResult.premium.total.transactions + statsResult.psga + statsResult.internal;
                    productStats = statsResult.products[product.id];
                }
                return (
                    <Product
                        key={product.id}
                        projectId={project.id}
                        projectMode={project.mode}
                        product={product}
                        productStats={productStats}
                        projectTotalUsage={projectTotalUsage}
                        removeProductPermitted={canManageProject}
                    />
                );
            });
        } else {
            return <EmptyProject addAPIAction={() => setAdding(true)} addAPIActionPermitted={canManageProject} />;
        }
    };
    const displayProjectStats = () => {
        const statsResult = stats.result;

        if (statsResult?.timestamp) {
            let totalUsage = 0;
            let totalCost;
            if (vatRate && !isNaN(vatRate)) {
                totalCost = statsResult.premium.total.price * (1 + vatRate);
            }

            let breakdown = [];
            const allDataSets = {
                open: {
                    name: statsLabels.open,
                    data: statsResult.open,
                    class: OPEN
                },
                premiumFree: {
                    name: statsLabels.premium.free,
                    data: statsResult.premium.free,
                    class: PREMIUM_FREE
                },
                premiumChargeable: {
                    name: statsLabels.premium.chargeable,
                    data: statsResult.premium.chargeable,
                    class: PREMIUM_CHARGEABLE
                },
                psga: {
                    name: statsLabels.psga,
                    data: statsResult.psga,
                    class: PSGA
                },
                internal: {
                    name: statsLabels.internal,
                    data: statsResult.internal,
                    class: INTERNAL
                }
            };

            for (let dataType in allDataSets) {
                totalUsage += allDataSets[dataType].data;
                breakdown.push(allDataSets[dataType]);
            }

            if (userDetails.plan === USER_PSGA_PLAN) {
                allDataSets.premiumFree.class = PREMIUM_FREE_SECONDARY;
            }

            const graphData = [{ total: totalUsage, breakdown: breakdown }];

            return (
                <BarGraph
                    graphData={graphData}
                    total={totalUsage}
                    count={totalUsage}
                    countLabel={{ ...statsMessages.countLabel }}
                    costInPence={totalCost}
                    costLabel={{ ...statsMessages.costLabel }}
                    graphStyle={{ height: 22, marginTop: 4, marginBottom: 0 }}
                    displayCountLabel={true}
                    displayCount={true}
                    displayKey={true}
                    displayCost={userDetails.plan === USER_PREMIUM_DATA_PLAN}
                />
            );
        }
    };

    const projectActions = [
        { label: messages.deleteProject, action: () => setDeleting(true) }
    ];

    if (project?.products && project.products.length > 0) {
        projectActions.push({ label: messages.regenerateApiKey, action: () => setRegenerate(true) });
    }

    const { route, label } = useMemo(() => {
        const { prevLocation, prevLocationLabel } = location.state || {};
        return prevLocation && prevLocationLabel
            ? { route: prevLocation, label: { ...messages.originLink, values: { origin: prevLocationLabel }} }
            : { route: routes.projects, label: linkLabel };
    }, [location.state, linkLabel]);

    return (
        <>
        <BackLink path={route} label={label}/>
        <div className={classes.root} data-testid="apiproject">
            <header className={classes.heading}>
                <ProjectName name={name} readonly={!canManageProject}/>
                {project && canManageProject &&
                    <div className={classes.actions}>
                        <DropDownMenu 
                            buttonLabel={messages.actions}
                            buttonVariant='outlined'
                            buttonColor='primary'
                            variant='block'
                            placement='bottom-end'
                            items={projectActions}
                            buttonClasses={{root: classes.marginLeft}}
                            staticButtonText
                        />
                        <AddButton 
                            classes={{button: classes.marginLeft}} 
                            action={() => setAdding(true)}
                            label={messages.addAPIButton} 
                        />
                    </div>
                }
            </header>
            <div className={classNames(classes.projectMetadata, classes.headerWidth)}>
                <ProjectMode project={project} allowChange={canManageProject}/>
                <StatsTimestamp stats={stats} classes={{root: classes.statsTimestamp}} />
            </div>
            <div className={classes.headerWidth}>
                {displayProjectStats()}
                {project && project.products && project.products.length > 0 && <APIKey project={project} />}
            </div>
        </div>
        <div className={classes.products}> 
            {loading?
                <CircularProgress size={32} className={classes.loader} />
            :
                <>{productPageState()}</>
            }
        </div>
        {deleting && 
            <DeleteProjectDialog 
                closed={() => setDeleting(true)}
                confirmed={deleteConfirmed}
            />
        }

        {regenerate && 
            <RegenerateKeyDialog 
                closed={() => setRegenerate(true)}
                confirmed={confirmationAction}
            />
        }
        {adding &&
            <AddAPIDialog 
                title={messages.addAPILabel}
                confirmationAction={() => setAdding(false)}
                open={true}
                handleClose={() => setAdding(false)}
                products={products}
                project={project}
            />
        }
        </>
    )
}

export default APIProject