import React from 'react';
import PropTypes from 'prop-types';
import {setProjectName} from "../../modules/project/actions";
import EditableField from "../../components/EditableField";
import {useSelector, useDispatch} from 'react-redux';
import {defineMessages} from 'react-intl';

const messages = defineMessages({
    buttonLabel: {
        id: 'ProjectName.buttonLabel',
        defaultMessage: 'Project name, click to edit',
        description: 'Label used for the aria-label attribute on the project name button'
    },
    inputLabel: {
        id: 'ProjectName.inputLabel',
        defaultMessage: 'Project name',
        description: 'Label used for the aria-label attribute on the project name input field'
    },
    clickToEdit: {
        id: 'ProjectName.clickToEdit',
        defaultMessage: 'Click to edit the project name',
        description: 'Label used for the alt text on the edit icon'
    }
});

export default function ProjectName(props) {
    const {name, readonly} = props;
    const {result} = useSelector(state => state.project.current);
    const dispatch = useDispatch();

    function saveName(newName) {
        if(result && result.id) {
            dispatch(setProjectName(result.id, newName));
        }
    }

    return <EditableField setFieldValue={saveName}
                        fieldValue={name}
                        readonly={readonly}
                        buttonAriaLabel={messages.buttonLabel}
                        iconAriaLabel={messages.clickToEdit}
                        inputAriaLabel={messages.inputLabel}
                        fieldTypeName={"name"}
    />;
}

ProjectName.propTypes = {
    name: PropTypes.string,
};
