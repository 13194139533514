import { defineMessages } from "react-intl";

const membersTableMessages = defineMessages({
    membersTableHeading: {
        id: "membersTableMessages.membersTableHeading",
        defaultMessage: "Members",
        description: "Members table Members column heading",
    },
    membersTableAPIsHeading: {
        id: "membersTableMessages.membersTableAPIsHeading",
        defaultMessage: "API Projects",
        description: "Members table APIs column heading",
    },
    membersTableDataHeading: {
        id: "membersTableMessages.membersTableDataHeading",
        defaultMessage: "Data Packages",
        description: "Members table Data packages column heading",
    },
    emptyOpenTable: {
        id: "membersTableMessages.emptyOpenTable",
        defaultMessage: "The Team Space is unlocked and is visible to everyone in your organisation.",
        description: "Members table open text",
    },
    membersHelpViewer: {
        id: "membersTableMessages.membersHelpViewer",
        defaultMessage: "<strong>Viewers</strong> can view the Team Space and access API Projects, but can’t add or make changes to API Projects inside the Team Space.",
        description: "Members help viewer",
    },
    membersHelpEditor: {
        id: "membersTableMessages.membersHelpEditor",
        defaultMessage: "<strong>Editors</strong> can add and remove API Projects, but can’t manage who has access to the Team Space.",
        description: "Members help editor",
    },
    remove: {
        id: "membersTableMessages.remove",
        defaultMessage: "Remove",
        description: "Remove label",
    },
    viewer: {
        id: "membersTableMessages.viewer",
        defaultMessage: "Viewer",
        description: "Viewer label",
    },
    editor: {
        id: "membersTableMessages.editor",
        defaultMessage: "Editor",
        description: "Editor label",
    },
    roleSelectAriaLabel: {
        id: "membersTableMessages.roleSelectAriaLabel",
        defaultMessage: "Select role",
        description: "Select role label",
    }
});

export default membersTableMessages;
