import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { osColour } from "omse-components";

export const StaticViewerRole = styled(Typography)`
    color: ${osColour.primary.berry};
`;

export const StaticEditorRole = styled(Typography)`
    color: ${osColour.primary.darkerMoss};
`;
