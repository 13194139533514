import { useSelector } from "react-redux";
import { formatContactName, isContractor } from "./teamSpaceUser";

function useCreatedByName() {

    const orgUsers = useSelector((state) => state.teamSpaces.getOrgUsers.result);
    const loading = useSelector((state) => state.teamSpaces.getOrgUsers.loading);
    const user = useSelector((state) => state.user.current.result);

    return (result) => {
        // createdByName is conditionally populated based on role.
        if (result?.createdByName) {
            return result?.createdByName;
        }

        let revokedContact = false;

        // Contractors - user should be returned from result, otherwise user is revoked.
        if (isContractor(user) && result) {
            revokedContact = true;

        // Non-contractors - look up the created user:
        } else {
            if (result && !loading) {
                if (orgUsers?.length > 0) {
                    let contact =  orgUsers.find(u => result.createdBy === u.datahubDeveloperId);
                    if (contact) {
                        return formatContactName(contact);
                    }
                }
                revokedContact = true
            }
        }

        if (revokedContact) {
            return "Deleted User";
        }
        return;
    }
}

export { useCreatedByName };