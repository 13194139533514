import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages} from 'react-intl';
import ProjectModeModal from "./ProjectModeModal";

const messages = defineMessages({
    toLiveTitle: {
        id: 'DevToLiveModal.toLiveTitle',
        defaultMessage: 'Change project mode to Live?',
        description: 'Title for the change dialog when switching to dev mode'
    },
    toLiveButton: {
        id: 'DevToLiveModal.toLiveButton',
        defaultMessage: 'Set project to Live mode',
        description: 'Title for the accept button when switching to dev mode'
    },
    toLiveHeading: {
        id: 'DevToLiveModal.toLiveHeading',
        defaultMessage: 'Setting a project to live mode:',
        description: 'Heading used when setting the project into dev mode'
    },
    toLiveItem1: {
        id: 'DevToLiveModal.toLiveItem1',
        defaultMessage: 'makes any Premium data transactions subject to your free monthly allocation',
        description: 'Text used when setting the project into dev mode'
    },
    eaitoLiveItem1: {
        id: 'DevToLiveModal.eaitoLiveItem1',
        defaultMessage:'You have no API payment plan, so premium data transactions will not work in Live mode projects. Please contact your account manager for further details on how to get access to premium data.',
        description: 'Text used when setting the project into dev mode for eai users'
    },
    toLiveItem2: {
        id: 'DevToLiveModal.toLiveItem2',
        defaultMessage: 'gives you a generous throttle of 600 transactions per minute',
        description: 'Text used when setting the project into dev mode'
    },
    toLiveTerms: {
        id: 'DevToLiveModal.toLiveTerms',
        defaultMessage: 'You may use Live mode for projects in a production environment.',
        description: 'Text used when setting the project into dev mode'
    }
});

export default function DevToLiveModal({onClose, isEai}) {
    const points = isEai ? [messages.toLiveItem2] : [messages.toLiveItem1, messages.toLiveItem2];

    return <ProjectModeModal onClose={onClose}
                             title={messages.toLiveTitle}
                             confirmLabel={messages.toLiveButton}
                             heading={messages.toLiveHeading}
                             points={points}
                             termsLabel={messages.toLiveTerms}
                             warning={isEai && messages.eaitoLiveItem1}
                             targetMode='live'
    />
}

DevToLiveModal.propTypes = {
    onClose: PropTypes.func.isRequired
};
