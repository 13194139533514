import stripesSvg from '../../icons/stripes.svg';
import stripesAltSvg from '../../icons/stripes-alt.svg';
import {osColour} from 'omse-components';
import { defineMessages } from 'react-intl';

export const OPEN = 'open';

// psga and premiumFree both want to use same colour. This is okay as normally only one of these is shown.
// If both need to be displayed at the same time then change the colour for premiumFree.
export const PREMIUM_FREE = 'premiumFree';
export const PREMIUM_FREE_SECONDARY = 'premiumFreeSecondary';

export const PREMIUM_CHARGEABLE = 'premiumChargeable';
export const PSGA = 'psga';
export const INTERNAL = 'internal';

export const statsLabels = {
    open: 'OS OpenData',
    premium: {
        free: 'Free Premium data',
        chargeable: 'Costed Premium data',
        dev: 'Dev Premium data'
    },
    psga: 'Public Sector data',
    internal: 'OS Internal data'
};

export const statsMessages = defineMessages({
    countLabel: {
        id: 'StatsGraph.countLabel',
        defaultMessage: 'total transactions this month',
        description: 'total transactions graph label '
    },
    costLabel: {
        id:'StatsGraph.costLabel',
        defaultMessage: 'this month',
        description: 'label for cost'
    },
    openDataLabel: {
        id:'StatsGraph.openDataLabel',
        defaultMessage: 'OS OpenData',
        description: 'Label for OS OpenData stats'
    },
    psgaDataLabel: {
        id:'StatsGraph.psgaDataLabel',
        defaultMessage: 'Public Sector data',
        description: 'Label for Public Sector data stats'
    }
});

export default function graphStyles(theme) {
    return {
        keyLabel: {
            marginBottom: theme.spacing(0.5),
            color: osColour.neutral.charcoal,
            display: 'flex'
        },
        graphKey: {
            height: 16,
            width: 16,
            marginRight: theme.spacing(0.5),
            marginTop: theme.spacing(0.5)
        },
        open: {
            background: osColour.primary.lighterBerry,
            '&:hover': {
                background: osColour.primary.berry
            }
        },
        premiumFree: {
            background: osColour.primary.foxglove,
            '&:hover': {
                background: '#a00e4a'
            }
        },
        premiumFreeSecondary: {
            background: '#757575',
            '&:hover': {
                background: '#575757'
            }
        },
        premiumChargeable: {
            backgroundImage: `url(${stripesSvg})`,
            '&:hover': {
                backgroundImage: `url(${stripesAltSvg})`
            }
        },
        psga: {
            background: osColour.primary.foxglove,
            '&:hover': {
                background: '#a00e4a'
            }
        },
        internal: {
            background: osColour.primary.foxglove,
            '&:hover': {
                background: '#a00e4a'
            }
        }
    }
}

export const keyStyles = theme => `
    & .keyLabel {
        margin-bottom: ${theme.spacing(0.5)};
        color: ${osColour.neutral.charcoal};
        display: flex;
    }
    & .graphKey {
        height: 14px;
        width: 14px;
        margin-right: calc(${theme.spacing(0.5)} + 1px);
    }
`;

export const graphPlanStyles = `
    & .open {
        background: ${osColour.primary.lighterBerry};
        outline: solid 1px ${osColour.primary.lighterBerry};
        &:hover {
            background: ${osColour.primary.berry};
            outline: solid 1px ${osColour.primary.berry};
        }
    }
    & .premiumFree {
        background: ${osColour.primary.foxglove};
        outline: solid 1px ${osColour.primary.foxglove};
        &:hover {
            background: #a00e4a;
            outline: solid 1px #a00e4a;
        }
    }
    & .premiumFreeSecondary {
        background: #757575;
        outline: solid 1px #757575;
        &:hover {
            background: #575757;
            outline: solid 1px #575757;
        }
    }
    & .premiumChargeable {
        background-image: url(${stripesSvg});
        outline: solid 1px #f6cbdd;
        &:hover {
            background-image: url(${stripesAltSvg});
        }
    }
    & .psga {
        background: ${osColour.primary.foxglove};
        outline: solid 1px ${osColour.primary.foxglove};
        &:hover {
            background: #a00e4a;
            outline: solid 1px #a00e4a;
        }
    }
    & .internal {
        background: ${osColour.primary.foxglove};
        outline: solid 1px ${osColour.primary.foxglove};
        &:hover {
            background: #a00e4a;
            outline: solid 1px #a00e4a;
        }
    }
`;
