import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "@emotion/styled";
import { FormattedMessage, useIntl } from "react-intl";
import { Typography } from "@mui/material";
import { contentPadding, DropDownMenu, osColour, ExternalLink } from "omse-components";
import BackLink from "../../components/BackLink";
import EditableField from "../../components/EditableField";
import teamSpaceMessages from "../TeamSpace.msg";
import messages from "./TeamSpaceHeader.msg";
import { ReactComponent as TeamSpaceIcon } from "../../components/icons/teamSpace.svg";
import { ReactComponent as TeamSpaceLockedIcon } from "../../components/icons/teamSpaceLocked.svg";
import routePaths, { useRedirect } from "../../util/routes";
import {
    setTeamSpaceName,
    setTeamSpaceDescription,
    setTeamSpaceDetailsClear,
    deleteTeamSpace,
    deleteTeamSpaceClear,
} from "../../modules/teamSpaces/actions";
import { useSelector, useDispatch } from "react-redux";
import ManageTeamSpace from "./ManageTeamSpace";
import { css } from "@emotion/css";
import { useManageTeamSpacePermission } from "./util/useManageTeamSpacePermission";
import teamSpacesErrorMessages from "./util/teamSpacesErrorMessages.msg";
import { isContractor } from "./util/teamSpaceUser";
import { getTeamSpacesErrorMessage } from "./util/getTeamSpacesErrorMessage";
import { DeleteTeamSpaceDialog } from "./deleteTeamSpace/DeleteTeamSpaceDialog";
import deleteTeamSpaceErrors from "./deleteTeamSpace/deleteTeamSpaceErrors.msg";
import { useLocation } from 'react-router-dom';
import { useCreatedByName } from "./util/useCreatedByName";

const Container = styled("div")`
    display: flex;
    gap: 0.5em;
    align-items: flex-start;
    & > div {
        flex: 1 0;
    }
`;

const ButtonContainer = styled("div")`
    display: flex;
    gap: 0.5em;
    align-items: flex-start;
    & > button {
        height: 42px;
        font-weight: 500;
    }
`;

const nameEditableFieldContainerClass = css`
    display: flex;
    & > button, & > div {
        flex-grow: 0;
    }
`;

const loadingContainerClass = css`
    flex: 2 1 auto;
`;

const descriptionEditableFieldContainerClass = css`
    display: flex;
    & > button {
        flex-grow: 0;
    }
    & > div:has(textarea) {
        min-width: 40%;
    }
    & > div > textarea {
        min-width: 100%;
    }
`;

const HeaderContainer = styled("header")(({ theme }) => `
    padding-top: ${contentPadding.top / 2}px;
    padding-left: ${contentPadding.left}px;
    padding-right: ${contentPadding.right}px;
    max-width: ${contentPadding.maxWidth}px;

    display: flex;
    flex-direction: column;
    gap: 1em;

    & > div {
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        gap: 1em;
        ${theme.breakpoints.down("md")} {
            flex-direction: column;
            gap: 1em;
        }
    }

    & > nav > a {
        margin: 0px;
    }

    & > div > h1 {
        color: ${osColour.primary.berry};
        flex-grow: 1;
    }

    & > div > div > svg {
        flex-shrink: 0;
    }

    & > div > div > div > button {
        margin-bottom: 0px;
    }

    & > section {
        & p:first-of-type {
            margin-bottom: 6px;
        }
    }

    & .descriptionContainer {
        display: flex;
    }

    ${theme.breakpoints.up("lg")} {
        /* INTERIM UNTIL TEAM SPACES IS
            LOCATED IN THE SECONDARY NAV */
        margin: 0 auto;
        min-width: ${contentPadding.maxWidth}px;
    }

    ${theme.breakpoints.down("sm")} {
        flex-direction: column;
        align-items: stretch;
        padding: ${contentPadding.mobile};
    }
`
);

function TeamSpaceHeader({ user, error }) {
    const intl = useIntl();
    const dispatch = useDispatch();
    const redirect = useRedirect();
    const location = useLocation();

    const teamSpaceResult = useSelector((state) => state.teamSpaces.getTeamSpace.result);
    const teamSpaceLoading = useSelector((state) => state.teamSpaces.getTeamSpace.loading);

    const locationStateTeamSpace = location?.state?.teamSpace;
    const name = teamSpaceResult?.name || locationStateTeamSpace?.name;

    const getCreatedByName = useCreatedByName();
    const createdByName = getCreatedByName(teamSpaceResult);

    const locked = (teamSpaceResult?.locked !== undefined)? teamSpaceResult?.locked : locationStateTeamSpace?.locked;
    const description = teamSpaceResult?.description || locationStateTeamSpace?.description || "";

    const setTeamSpaceNameLoading = useSelector((state) => state.teamSpaces.setTeamSpaceName.loading);
    const setTeamSpaceDescriptionLoading = useSelector((state) => state.teamSpaces.setTeamSpaceDescription.loading);
    const deleteTeamSpaceResult = useSelector(state => state.teamSpaces.deleteTeamSpace);

    const [showDeleteTeamSpaceDialog, setShowDeleteTeamSpaceDialog] = useState();
    const [destinationTeamSpaceId, setDestinationTeamSpaceId] = useState();
    const [deleteTeamSpaceErrorText, setRemoveTeamSpaceErrorText] = useState();

    const LockIcon = locked ? TeamSpaceLockedIcon : TeamSpaceIcon;
    const lockAriaLabel = locked
        ? intl.formatMessage(messages.lockedAria)
        : intl.formatMessage(messages.unlockedAria);

    const teamSpaceActions = [];
    const hasManageTeamSpacePermission = useManageTeamSpacePermission(user, teamSpaceResult);
    if (hasManageTeamSpacePermission) {
        teamSpaceActions.push({
            value: teamSpaceMessages.deleteTeamSpace.id,
            label: intl.formatMessage(teamSpaceMessages.deleteTeamSpace),
            action: () => setShowDeleteTeamSpaceDialog(true)
        });
    }

    function saveName(newName) {
        if (teamSpaceResult?.id && newName) {
            dispatch(setTeamSpaceName(teamSpaceResult.id, newName));
        }
    }

    function saveDescription(newDescription) {
        if (teamSpaceResult?.id) {
            dispatch(setTeamSpaceDescription(teamSpaceResult.id, newDescription));
        }
    }

    useEffect(() => {
        return () => {
            dispatch(setTeamSpaceDetailsClear());
        };
    }, [dispatch]);

    // The Team Space was deleted.
    useEffect(() => {
        if (deleteTeamSpaceResult?.result?.deletedTeamSpaceId) {
            const { movedApiProjects } = deleteTeamSpaceResult.result;
            setShowDeleteTeamSpaceDialog(false);
            dispatch(deleteTeamSpaceClear());
            redirect.push(routePaths.teamSpaces, {
                successMessage: intl.formatMessage(
                    movedApiProjects
                        ? teamSpaceMessages.deletedTeamSpaceMovedApiProjectsSuccess
                        : teamSpaceMessages.deletedTeamSpaceSuccess
                ),
            });
        }
    }, [deleteTeamSpaceResult, dispatch, redirect]);

    // An error occured deleting the Team Space.
    useEffect(() => {
        if (deleteTeamSpaceResult?.error) {
            let errorMessage = getTeamSpacesErrorMessage(
                deleteTeamSpaceResult,
                deleteTeamSpaceErrors.genericServerError
            );
            setRemoveTeamSpaceErrorText(
                intl.formatMessage(errorMessage, { link: <ExternalLink type="support" /> })
            );
        }
    }, [deleteTeamSpaceResult, getTeamSpacesErrorMessage, setRemoveTeamSpaceErrorText]);

    const handleCloseDeleteTeamSpaceDialog = () => {
        setDestinationTeamSpaceId(null);
        setRemoveTeamSpaceErrorText(null);
        setShowDeleteTeamSpaceDialog(false);
    };

    const handleDeleteTeamSpaceConfirmed = () => {
        if (teamSpaceResult.teamResources.apiProjects.length) {
            dispatch(deleteTeamSpace({ teamSpaceId: teamSpaceResult.id, destinationTeamSpaceId }));
        } else {
            dispatch(deleteTeamSpace({ teamSpaceId: teamSpaceResult.id }));
        }
    };

    return (
        <HeaderContainer>
            <BackLink path={routePaths.teamSpaces} label={teamSpaceMessages.backToTeamSpacesPage} />
            {!error && (
                <>
                    <div>
                        <Container>
                            {(locked !== undefined) &&
                                <LockIcon height={42} width={42} aria-label={lockAriaLabel} />
                            }
                            {name &&
                                <EditableField
                                    fieldValue={name}
                                    fieldTypeName="name"
                                    setFieldValue={saveName}
                                    readonly={!hasManageTeamSpacePermission || !teamSpaceResult || teamSpaceLoading || setTeamSpaceNameLoading}
                                    buttonAriaLabel={messages.nameEditButtonLabel}
                                    iconAriaLabel={messages.nameClickToEdit}
                                    inputAriaLabel={messages.nameInputLabel}
                                    maxLength={255}
                                    maxLengthMessage={teamSpacesErrorMessages.nameFieldLengthInvalid}
                                    classes={{container: nameEditableFieldContainerClass, loadingContainer: loadingContainerClass}}
                                    showLoading={true}
                                    loading={!!setTeamSpaceNameLoading}
                                />
                            }
                        </Container>
                        {!isContractor(user) && teamSpaceResult && (
                            <ButtonContainer>
                                <ManageTeamSpace />
                                {hasManageTeamSpacePermission && (
                                    <DropDownMenu
                                        items={teamSpaceActions}
                                        buttonLabel={intl.formatMessage(messages.actions)}
                                        buttonProps={{
                                            "aria-label": intl.formatMessage(messages.actionsAria),
                                        }}
                                        buttonVariant="outlined"
                                        placement="bottom-end"
                                    />
                                )}
                            </ButtonContainer>
                        )}
                    </div>
                    <section>
                        {createdByName &&
                            <Typography>
                                <FormattedMessage
                                    {...messages.createdBy}
                                    values={{ name: createdByName }}
                                />
                            </Typography>
                        }
                        <div className="descriptionContainer">
                            <EditableField
                                fieldValue={description}
                                fieldTypeName="description"
                                setFieldValue={saveDescription}
                                readonly={!hasManageTeamSpacePermission || !teamSpaceResult || teamSpaceLoading || setTeamSpaceDescriptionLoading}
                                buttonAriaLabel={messages.descriptionEditButtonLabel}
                                iconAriaLabel={messages.descriptionClickToEdit}
                                inputAriaLabel={messages.descriptionInputLabel}
                                placeholder={intl.formatMessage(messages.descriptionPlaceholder)}
                                maxLength={255}
                                maxLengthMessage={teamSpacesErrorMessages.descriptionFieldLengthInvalid}
                                classes={{container: descriptionEditableFieldContainerClass, loadingContainer: loadingContainerClass}}
                                showLoading={true}
                                loading={!!setTeamSpaceDescriptionLoading}
                            />
                        </div>
                    </section>
                </>
            )}
            {showDeleteTeamSpaceDialog && (
                <DeleteTeamSpaceDialog
                    working={deleteTeamSpaceResult.loading}
                    destinationTeamSpaceId={destinationTeamSpaceId}
                    setDestinationTeamSpaceId={setDestinationTeamSpaceId}
                    error={deleteTeamSpaceErrorText}
                    handleClose={handleCloseDeleteTeamSpaceDialog}
                    confirmationAction={handleDeleteTeamSpaceConfirmed}
                />
            )}
        </HeaderContainer>
    );
}

TeamSpaceHeader.propTypes = {
    name: PropTypes.string,
    description: PropTypes.string,
    createdByName: PropTypes.string,
    locked: PropTypes.bool,
    user: PropTypes.object,
    error: PropTypes.string,
};

export default TeamSpaceHeader;
